import { Component, OnInit, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { CommonApiService } from '../shared/services/common-api.service';
import { ActivatedRoute } from '@angular/router';
import { UtilsService } from '../shared/services/utils.service';

@Component({
  selector: 'app-external-webchat-script',
  templateUrl: './external-webchat-script.component.html',
  styleUrls: ['./external-webchat-script.component.scss'],
  providers: [CommonApiService]
})
export class ExternalWebchatScriptComponent implements OnInit {

  constructor(
    private http: CommonApiService,
    private route: ActivatedRoute,
    private utilsService: UtilsService,
    @Inject(DOCUMENT) private _document: Document
  ) { }

  ngOnInit(): void {
    const pageName = this.utilsService.getTenantFromWindow();
    this.http.get(`accountConfiguration?accountCode=${pageName}`, null, true).subscribe(resp => {
      if (resp && resp.page_configuration && resp.page_configuration.externalWebChatScript) {
        let script = document.createElement('script');
        script.type = `text/javascript`;
        script.textContent = resp.page_configuration.externalWebChatScript.content;
        document.getElementsByTagName('head')[0].appendChild(script);
      }
    })


  }

}
