import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { ThemeChangerService } from '../../shared/services/themeChanger.service';
import { FormBuilder } from '@angular/forms';
import { NotifytoastService } from '../../shared/services/notificationToast.service';
import { DasboardService } from '../dashboard/dasboard.service';
import { CommonApiService } from '../../shared/services/common-api.service';
import { ActivatedRoute, Router } from '@angular/router';
import { InlinePageStylingComponent } from './inline-page-styling/inline-page-styling.component';
import { UtilsService } from '../../shared/services/utils.service';

@Component({
  selector: 'app-styling-configuration',
  templateUrl: './styling-configuration.component.html',
  styleUrls: ['./styling-configuration.component.scss'],
  providers: [DasboardService, CommonApiService]
})
export class StylingConfigurationComponent implements OnInit, OnDestroy {
  @ViewChild(InlinePageStylingComponent) cardStyleComponent: InlinePageStylingComponent;
  stylingForm: any;
  availableThemes = []
  initialTheme: any;
  tempConfiguration: any;
  tenant = this.route.parent.snapshot.params.tenant
  savedData = false;
  isComponentLoaded = false
  styling =this.utilsService.getItemfromSession('themeObject')
  constructor(
      private formBuilder: FormBuilder,
      private themeService: ThemeChangerService,
      private notifyToast: NotifytoastService,
      private dashboardService: DasboardService,
      private route: ActivatedRoute,
      private router: Router,
      private utilsService: UtilsService
    ) { }

  ngOnInit(): void {
    this.isComponentLoaded = true
    this.initForm()
    this.getThemeConfiguration()
    this.availableThemes = this.themeService.getAvailableThemes()
  }

  initForm(){
    this.stylingForm = this.formBuilder.group({
      selectedTheme: [null]
    })
  }

  selectChange(eve){
    // console.log(eve);
    const theme = this.availableThemes.find(resp => resp.name === eve.target.id)
    this.themeService.setTheme(theme)
  }

  getThemeConfiguration(){
    this.dashboardService.getAccountConfiguration().subscribe(resp => {
      this.tempConfiguration = resp;
      const selectedTheme = resp.page_configuration.themeName ? resp.page_configuration.themeName : 'Default'
      this.initialTheme = selectedTheme
      this.stylingForm.patchValue({selectedTheme})
    })
  }

  ngOnDestroy(){
    if(this.isComponentLoaded && !this.savedData){
      this.themeService.setTheme(this.availableThemes.find(resp => resp.name === this.initialTheme))
    }
  }

  saveTheme(){
    console.log(this.cardStyleComponent.cardStyling())
    return;
    let tempData = [
        'languageSupport',
        'getAQuote',
        'expressPay',
        'policyView',
        'policyCoverages',
        'policyDocuments',
        'billingView',
        'makePayment',
        'claimView',
        'fileAClaim',
        'addPolicy',
        "styleConfigurator"
    ] 
    this.savedData = true
    this.tempConfiguration.page_configuration.themeName = this.stylingForm.value.selectedTheme
    this.tempConfiguration.page_configuration.cardStyling = this.cardStyleComponent.cardStyling()
    this.tempConfiguration.AccessControl = tempData
    const data = {
      "accountCode":this.tenant,
      "configuration": this.tempConfiguration
    }
    this.dashboardService.uploadConfiguration(data).subscribe(resp => {
      sessionStorage.setItem('themeObject', JSON.stringify(this.tempConfiguration.page_configuration.cardStyling))
      this.notifyToast.success('New Theme applied successfully.')
    this.navHome()
    }, error => {
      const errorText = `Please Contact System Administrator.`
          this.utilsService.confirmModalPopup(errorText)
    })
    // console.log(this.cardStyleComponent.cardStyling(), 'meaow')
  }

  cancelTheme(){
    this.themeService.setTheme(this.availableThemes.find(resp => resp.name === this.initialTheme))
    this.navHome()
  }

  navHome(){
    this.router.navigate([`${this.tenant}/home`])
  }

}
