<body class="fix-header fix-sidebar card-no-border">
    <!-- ============================================================== -->
    <!-- Main wrapper - style you can find in pages.scss -->
    <!-- ============================================================== -->
    <section id="wrapper" class="error-page">
        <div class="error-box">
            <div class="error-body text-center">
                <h1 class="text-info text-uppercase" style="font-size: 80px;">Coming soon</h1>
                <h3 class="text-uppercase" style="color: grey">Site is under construction process</h3>
                <p class="text-muted m-t-30 m-b-30 text-uppercase">Please visit us later</p>
                <button (click)="navigateBack()" class="btn btn-info btn-rounded waves-effect waves-light m-b-40"
                [ngStyle]="styling?.button?.buttonBack">Back to home</button>
            </div>
            <!-- <app-footer></app-footer> -->
        </div>
    </section>
</body>