import { Component, OnInit , Input} from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-additional-home-details',
  templateUrl: './additional-home-details.component.html',
  styleUrls: ['./additional-home-details.component.scss']
})
export class AdditionalHomeDetailsComponent implements OnInit {
  @Input() additionalHomeDetailsForm: FormGroup;
  @Input() buildingMaterials:any;
  @Input() buildingTypes:any;
  @Input() foundationTypes:any;
  @Input() isSubmitted: boolean;
  
  constructor() { }

  ngOnInit(): void {
  }

}
