import { Component, OnInit, Input, EventEmitter, Output, ViewChild, ElementRef } from '@angular/core';
import { CommonApiService } from '../../../shared/services/common-api.service';
import { FormBuilder, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { NotifytoastService } from '../../../shared/services/notificationToast.service';
import { ActivatedRoute, Router } from '@angular/router';
import { LocateMeService } from '../../../shared/services/locate-me.service';
import { UtilsService } from '../../../shared/services/utils.service';

@Component({
  selector: 'app-find-agents-in-gmap',
  templateUrl: './find-agents-in-gmap.component.html',
  styleUrls: ['./find-agents-in-gmap.component.scss'],
  providers: [CommonApiService, LocateMeService],
})
export class FindAgentsInGmapComponent implements OnInit {
  @Input() findAgent:any;
  @Input() address: any;
  @Output() agentDetails = new EventEmitter();
  coOrds = [];
  centerCoOrds: any;
  agentData: any;
  tenant: any;
  selectedId = '';
  isDisabled = false;
  searchAgentForm: any;
  myLocation: {lat: any, lng: any}
  gotRiskAddress = false;
  riskAddress: any;
  showLocation = false;
  styling =this.utilsService.getItemfromSession('themeObject')

  urlProps = {
    url: 'assets/multiTenantImages/png/pin-black.png',
    scaledSize: { width: 35, height: 45 },
  };
  myUrlProps = {
    url: 'assets/multiTenantImages/png/pin-blue.png',
    scaledSize: { width: 35, height: 45 },
  };
  private subject: Subject<string> = new Subject();
  constructor(
    private httpService: CommonApiService,
    private formBuilder: FormBuilder,
    private notifyToast: NotifytoastService,
    private route: ActivatedRoute,
    private router: Router,
    private locateMeService: LocateMeService,
    public utilsService: UtilsService
  ) {}

  ngOnInit(): void {
    this.subject.pipe(debounceTime(1000)).subscribe((resp) => {
      this.centerPositionChanged(resp);
    });
    this.initForm();
    // if(1){
    //   this.gotRiskAddress = true
    //   this.riskAddress = {
    //     city: "Merritt Island",
    //     street: "1015 Ash Drive",
    //     zipCode: 32953,
    //     stateCode: "FL",
    //     lat: 26.0433624,
    //     lng: -80.3074164
    //   }
    //   this.findAgents(this.riskAddress)
    // }
  }

  resetLocation(){
    this.showLocation = false
    this.findAgents(this.riskAddress);
  }

  ngOnChanges(changes: any) {
    if ('findAgent' in changes && changes.findAgent.currentValue
      //changes.address.currentValue && 
      //changes.address.previousValue == undefined
    ) {
      this.subject.pipe(debounceTime(1000)).subscribe((resp) => {
        this.centerPositionChanged(resp);
      });
      this.riskAddress = this.address;
      // this.searchAgentForm.patchValue({
      //   zipCode: this.address.zipCode,
      // });
      this.gotRiskAddress = true;
      this.findAgents(this.riskAddress);
    }
  }

  initialize() {}
  setMyLocation() {
    let isClicked = true
    this.showLocation = true
    this.locateMeService.getMyLocation().subscribe((resp) => {
      this.myLocation = {
        lat: resp.coords.latitude,
        lng: resp.coords.longitude
      }
      if(isClicked){
        isClicked = false
        this.findAgentsFromMap(false) //no agents found so skipping this part edge case scenarios need to be tested
      }
    });
    if(this.myLocation && this.myLocation.lat) {
      if(isClicked){
        isClicked = false
        this.findAgentsFromMap(false) //no agents found so skipping this part edge case scenarios need to be tested
      }
    }
  }

  initForm() {
    this.searchAgentForm = this.formBuilder.group({
      zipCode: [null, [Validators.required]],
      searchRadius: [''],
    });
  }

  eventEmitCenterPosn(eve) {
    this.subject.next(eve);
  }

  centerPositionChanged(coOdrsObj) {
    this.centerCoOrds = coOdrsObj;
  }

  navigateToGmap(agent){
    if(agent){
      const placeName = `${agent.agencyName}, ${agent.agencyLocationAddress.street}, ${agent.agencyLocationAddress.city}, ${agent.agencyLocationAddress.stateCode}, ${agent.agencyLocationAddress.zip}` 
      let url = `https://www.google.com/maps/search/${placeName}`
      window.open(url, '_blank')
    }
  }

  reEnableButton() {
    this.isDisabled = true;
    setTimeout(() => {
      this.isDisabled = false;
    }, 1000);
  }

  findAgentsFromMap(isCenter?: boolean) {
    this.reEnableButton();
    let geoCoder = new google.maps.Geocoder();
    geoCoder.geocode({ location: isCenter ? this.centerCoOrds: this.myLocation}, (results, status) => {
      let foundResult = false;
      if (results.length) {
        results[0].address_components.forEach((element) => {
          if (element.types.includes('postal_code')) {
            console.log(element.long_name);
            // this.searchAgentForm.patchValue({zipCode:element.long_name})
            this.findAgents({ zipCode: element.long_name });
            foundResult = true;
          }
        });
        if (!foundResult)
          this.notifyToast.error(
            'Unable to pinpoint a location. Please try someother area or try entering zipcode'
          );
      }
    });
  }

  searchAgents() {
    this.reEnableButton();
    if (this.searchAgentForm.valid) {
      let tempData = {
        zipCode: this.searchAgentForm.value.zipCode,
        searchRadius: this.searchAgentForm.value.searchRadius,
      };
      this.findAgents(tempData);
    }
  }

  findAgents(searchObj) {
    // console.log('finding agents for:', searchObj);
    this.searchAgentForm.patchValue({ zipCode: searchObj.zipCode });
    let data;
    if (searchObj) {
      data = { ...searchObj };
    }
    let url = `findAgents?accountCode=sfi`;
    if (data && data.agencyName) url = `${url}&agencyName=${data.agencyName}`;
    if (data && data.searchRadius)
      url = `${url}&searchRadius=${data.searchRadius}`;
    if (data && data.zipCode) url = `${url}&zipCode=${data.zipCode}`;
    this.httpService.get(url, null, true).subscribe((resp) => {
      this.gmapInitOnFindingAgents(resp);
    });
  }

  gmapInitOnFindingAgents(resp) {
    if (resp.out) {
      this.agentData = resp.out.AgentDetails;
      resp.out.AgentDetails.forEach((response, index) => {
        let geoCoder = new google.maps.Geocoder();
        geoCoder.geocode(
          {
            address: `${response.agencyLocationAddress.street}, ${response.agencyLocationAddress.city}, ${response.agencyLocationAddress.stateCode}`,
          },
          (results, status) => {
            if (status == google.maps.GeocoderStatus.OK) {
              response.latFromGoogle = results[0].geometry.location.lat();
              response.lngFromGoogle = results[0].geometry.location.lng();
            }
          }
        );
      });
    } else {
      this.agentData = [];
    }
  }

  openWindow(id, infoWindow?) {
    if(infoWindow){
      infoWindow.close()
    }
    this.selectedId = id;
    let element = document.getElementById(`Location_${id}`);
    element.scrollIntoView()
  }

  windowOpenCheck(id) {
    return this.selectedId == id;
  }

  closeInfo() {
    this.selectedId = null;
  }


  // sendQuote(page, agentName) {
  //   console.log('name', agentName);
  //   this.tenant = this.route.parent.snapshot.params.tenant;
  //   this.router.navigate([`${this.tenant}/${page}`]);
  // }

  sendQuote(eve) {
    console.log("Selected agent details returned from the component:", eve);
    this.agentDetails.emit(eve);
  }
}
