import { Component, OnInit, Input } from '@angular/core';
import * as _ from 'lodash'

@Component({
  selector: 'app-inline-page-styling',
  templateUrl: './inline-page-styling.component.html',
  styleUrls: ['./inline-page-styling.component.scss']
})
export class InlinePageStylingComponent implements OnInit {
  @Input() cardStylingObj: any;
  cardThickness="1px";
  cardColor="Green";
  cardRadius="10px"
  availableLengths = ["6px", "8px", "10px", "12px", "14px", "16px", "18px", "20px", "22px", "24px", "26px", "28px", "30px", "32px", "34px", "36px"]
  availableFontFamily = ["Arial", "Calibri", "Cursive", "Fantasy", "Helvetica", "Monospace", "Poppins", "Sans-serif", "Serif", "Verdana", 
  "Whitney-Black", "Whitney-Black-Italics", "Whitney-Bold", "Whitney-Bold-Italics", "Whitney-Book", "Whitney-Book-Italics", "Whitney-Light-Ital", 
  "Whitney-Light", "Whitney-Medium-Ital", "Whitney-Medium", "Whitney-Semibld-Ital", "Whitney-Semibld"]
  availableFontWeight = [400, 500, 600, 700, 800, "unset"]
  availableColors = ["Green", "Yellow", "Grey", "Red", "Black", "Blue", "#0071CE", "#EF8201"] 
  availableThickness = ["1px", "2px", "3px"]
  availableRadius = ["5px", "10px", "15px", "20px", "25px"]
  configuration = {
    "pageTitle": {
      "font-size": "24px",
      "font-family": "Whitney-Semibld",
      "font-weight": "unset"
    },
    "pageSubTitle": {
      "font-size": "24px",
      "font-family": "Whitney-Semibld",
      "font-weight": "unset"
    },
    "sidebarText": {
      "font-size": "16px",
      "font-weight": "unset",
      "font-family": "Whitney-Book"
    },
    "cardTitle": {
      "font-size": "20px",
      "font-weight": "unset",
      "font-family": "Whitney-Semibld"
    },
    "helpTitle": {
      "font-size": "22px",
      "font-family": "Whitney-Semibld",
      "font-weight": "unset"
    },
    "helpText": {
      "font-size": "16px",
      "font-family": "Whitney-Book",
      "font-weight": "unset"
    },
    "hyperlink":{
      "color" : "#0071CE",
      "font-size": "18px",
      "font-family": "Whitney-Book"
    },
    "attributeKey":{
      "font-size": "16px",
      "font-weight": "unset",
      "font-family": "Whitney-Book"
    },
    "fieldPlaceHolder":{
      "font-size": "16px",
      "font-weight": "unset",
      "font-family": "Whitney-Book"
    },
    "attributeValue":{
      "font-size": "16px",
      "font-family": "Whitney-Book",
      "font-weight": "unset"
    },
    "fieldValueInput":{
      "font-size": "16px",
      "font-family": "Whitney-Book",
      "font-weight": "unset"
    },
    "fieldValueCheckBox":{
      "font-size": "16px",
      "font-family": "Whitney-Book",
      "font-weight": "unset"
    },
    "fieldDropDown":{
      "font-size": "16px",
      "font-family": "Whitney-Book",
      "font-weight": "unset"
    },
    "footerHyperlink":{
      "font-size": "10px",
      "font-family": "Whitney-Book",
      "font-weight": "unset"
    },
    "footerText":{
      "font-size": "10px",
      "font-family": "Whitney-Book",
      "font-weight": "unset"
    },
    "tableHeader":{
      "font-size": "20px",
      "font-family": "Whitney-Semibld",
      "font-weight": "unset"
    },
    "tableValue":{
      "font-size": "16px",
      "font-family": "Whitney-Book",
      "font-weight": "unset"
    },
    "button": {
      "buttonPositive": {
        "background-color": "#2698D1",
        "font-family": "Whitney-bold",
        "font-weight": "unset",
        "font-size": "16px"
      },
      "buttonNegative": {
        "background-color": "#EF8201",
        "font-family": "Whitney-bold",
        "font-weight": "unset",
        "font-size": "16px"
      },
      "buttonNavigation": {
        "background-color": "#0071CE",
        "font-family": "Whitney-bold",
        "font-weight": "unset",
        "font-size": "16px"
      }
    },
    "paragraphTitle":{
      "font-size": "16px",
      "font-family": "Whitney-Book",
      "font-weight": "unset"
    },
    "paragraphText":{
      "font-size": "16px",
      "font-family": "Whitney-Book",
      "font-weight": "unset"
    },
    "iconStyling": {
      "color": "#0071CE"
    },
    "card":{
      "bodyStyling": {
        "border": "1px solid Grey",
        "border-radius": "10px"
      }
    }
  }
  constructor() { }
  
  ngOnInit(): void { }

  ngOnChanges(changes){
    if (changes.hasOwnProperty('cardStylingObj')) {
      if (changes.cardStylingObj.currentValue) {
        if(this.configuration){
          this.configuration = _.merge(this.configuration, this.cardStylingObj)
          this.cardRadius = this.configuration.card.bodyStyling['border-radius']
          const borderArray = this.configuration.card.bodyStyling.border.split(" solid ");
          this.cardThickness = borderArray[0]
          this.cardColor = borderArray[1]
        }
      }
    }
  }

  changesColor(){
    // console.log(this)
    this.configuration.card.bodyStyling.border = this.cardThickness + ' solid ' + this.cardColor
  }

  cardStyling(){
    return this.configuration
  }

}
