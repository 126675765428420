import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { forkJoin, Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { throwError as observableThrowError } from 'rxjs'
import { CommonApiService } from '../../shared/services/common-api.service';
import { PrivateDataShare } from '../../shared/services/privateDataShare.service';
import { UtilsService } from '../../shared/services/utils.service';
import { NotifytoastService } from '../../shared/services/notificationToast.service';
import { BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { FilesUploadPopupComponent } from './files-upload-popup/files-upload-popup.component';

@Component({
  selector: 'app-policy-doc-upload-comp',
  templateUrl: './policy-doc-upload-comp.component.html',
  styleUrls: ['./policy-doc-upload-comp.component.scss'],
  providers: [CommonApiService]
})
export class PolicyDocUploadCompComponent implements OnInit {
  policyInfo = this.utilsService.getItemfromSession('policyDetails').PolicySummary;
  styling = this.utilsService.getItemfromSession('themeObject');
  selectedAddressObj = [];
  frontRightCollection = [];
  backLeftCollection = [];
  declarationCollection = [];
  errfrontRightCollection = [];
  errbackLeftCollection = [];
  errdeclarationCollection = [];
  policyNumber: string;
  loader: boolean = false

  constructor(
    private utilsService: UtilsService,
    private privateDataShare: PrivateDataShare,
    private httpService: CommonApiService,
    private route: ActivatedRoute,
    private notifytoast: NotifytoastService,
    private modalService: BsModalService
  ) { }

  ngOnInit(): void {
  }

  getPolicyIndex(policyNo) {
    this.getPolicyDetails(policyNo)
  }

  getPolicyDetails(policyNo) {
    let policyDetails = []
    policyDetails = this.privateDataShare.getPolicyDetails().PolicySummary
    this.policyNumber = policyNo
    // if(policyDetails){
    //   let currentPolicySelected  = policyDetails.find(resp => resp.policyDetails.policyNumber == policyNo)
    //   if(currentPolicySelected.policyDetails) this.selectedAddressObj = currentPolicySelected.policyDetails
    // }
  }

  // fileChange(event, picSide){
  //   const selectedFiles = [...event.target.files];
  //   this.uploadDocs(selectedFiles, picSide).subscribe(resp => {
  //     let filesSet = resp.filter(resp => resp.uploaded == true).map(e => e.fileName)
  //     let errorfilesSet = resp.filter(resp => resp.uploaded == false).map(e => e.fileName)
  //     this.notifytoast.success("Upload Completed")
  //     if(picSide == 'Front Right'){
  //       this.errfrontRightCollection = []
  //       this.frontRightCollection = this.frontRightCollection.concat(filesSet)
  //       this.errfrontRightCollection = this.errfrontRightCollection.concat(errorfilesSet)
  //       console.log(this.frontRightCollection)
  //     } else if(picSide == 'Back Left'){
  //       this.errbackLeftCollection = []
  //       this.backLeftCollection = this.backLeftCollection.concat(filesSet)
  //       this.errbackLeftCollection = this.errbackLeftCollection.concat(errorfilesSet)
  //     } else if(picSide == 'Declarations'){
  //       this.errdeclarationCollection = []
  //       this.declarationCollection = this.declarationCollection.concat(filesSet)
  //       this.errdeclarationCollection = this.errdeclarationCollection.concat(errorfilesSet)
  //     }
  //   })
  // }

  fileChange(event, picSide) { // considering only one pic
    this.loader = true;
    const selectedFiles = [...event.target.files];
    this.uploadDocs(selectedFiles, picSide).subscribe(resp => {
      this.loader = false;
      let successfilesSet = resp.filter(resp => resp.uploaded == true).map(e => e.fileName)
      let errorfilesSet = resp.filter(resp => resp.uploaded == false && resp.sizeLimitExceeded == false).map(e => e.fileName)
      let sizeExceededSet = resp.filter(resp => resp.uploaded == false && resp.sizeLimitExceeded == true).map(e => e.fileName)
      this.notifytoast.success("Upload Completed")
      if (successfilesSet.length) {
        this.openModal(successfilesSet, 'Successfully uploaded', 'Files uploaded:', 'success');
        this.setFlag(successfilesSet, picSide)
      } else if (errorfilesSet.length) {
        this.openModal(errorfilesSet, 'Error', 'Files not uploaded:', 'error');
      } else if (sizeExceededSet.length) {
        this.openModal(sizeExceededSet, 'Error', 'Files not uploaded as the file size is large:', 'error');
      }
    })
  }

  setFlag(file, picSide) {
    if (picSide == 'Property Front Right') this.frontRightCollection = file;
    else if (picSide == 'Property Back Left') this.backLeftCollection = file;
    else if (picSide == 'Declarations') this.declarationCollection = file;
  }

  uploadDocs(files, picSide): Observable<any> {
    let fileUploadSize = this.privateDataShare.getFileUploadSize()
    fileUploadSize = fileUploadSize ? parseInt(fileUploadSize) * 1024 * 1024 : 5242880
    let observables = files.map((file, index) => {
      if (file.size < fileUploadSize) {
        let convertedData = this.convertFormData(file, picSide)
        return this.httpService.privateUpload('uploadpolicydocuments', convertedData, true).pipe(
          map((resp) => {
            if (resp.statusCode == 200) {
              let tempData = this.getPicRespData(file.name, picSide, true, false)
              return tempData
            } else {
              let tempData = this.getPicRespData(file.name, picSide, false, false)
              return tempData
            }
          }),
          catchError(error => this.errorCase(file.name, picSide))
        )
      } else {
        let tempData = this.getPicRespData(file.name, picSide, false, true)
        return of(tempData)
      }
    })
    return forkJoin(observables)
  }

  errorCase(fileName, picSide) {
    let tempData = this.getPicRespData(fileName, picSide, false, false)
    return observableThrowError(tempData)
  }

  getPicRespData(fileName, picSide, uploaded, sizeLimitExceeded?) {
    let tempData = { fileName, picSide, uploaded, sizeLimitExceeded }
    return tempData
  }

  convertFormData(file, docType) {
    const formData = new FormData()
    const tenant = this.route.parent.snapshot.params.tenant
    formData.append('policyNumber', this.policyNumber)
    formData.append('documentType', docType)
    formData.append('accountCode', tenant)
    formData.append('file', file)
    return formData
  }

  openModal(filesList, title, containerText, status) {
    let initialState: ModalOptions = {
      backdrop: 'static',
      // class: 'modal-lg',
      initialState: {
        filesList,
        title,
        containerText,
        status
      }
    }
    this.modalService.show(FilesUploadPopupComponent, initialState);
  }

}
