import { AbstractControl } from '@angular/forms';

export class ValidationService {
    static emailValidator(control) {
        if (control.value) {
            if (control.value.match(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)) {
                return null;
            } else {
                return { 'invalidEmailAddress': true };
            }
        }
    }
    static phoneValidator(control) {
        if (control.value) {
            // var phoneRegExp = '^(?:(?:\+?1\s*(?:[.-]\s*)?)?(?:\(\s*([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9])\s*\)|([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9]))\s*(?:[.-]\s*)?)?([2-9]1[02-9]|[2-9][02-9]1|[2-9][02-9]{2})\s*(?:[.-]\s*)?([0-9]{4})(?:\s*(?:#|x\.?|ext\.?|extension)\s*(\d+))?$';
            if (control.value.match('^[+][0-9]{3}-[0-9]{8}$'
            )) {
                return null;
            } else {
                return { 'invalidPhoneNumber': true };
            }
        }
    }

    static phoneValidatorFor2FA(control) {
        if (control.value) {
            // var phoneRegExp = '^(?:(?:\+?1\s*(?:[.-]\s*)?)?(?:\(\s*([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9])\s*\)|([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9]))\s*(?:[.-]\s*)?)?([2-9]1[02-9]|[2-9][02-9]1|[2-9][02-9]{2})\s*(?:[.-]\s*)?([0-9]{4})(?:\s*(?:#|x\.?|ext\.?|extension)\s*(\d+))?$';
            if (control.value.match('^[+][1]{1}[0-9]{10}$'
            )) {
                return null;
            } else {
                return { 'invalidPhoneNumber': true };
            }
        }
    }

    static strictlyNumbersValidator(control) {
        if (control.value) {
            // console.log('^[+][9][6][5](|[ ])[0-9]{8}$');
            if (String(control.value).match('^[0-9]{8}$'
            )) {
                return null;
            } else {
                return { 'invalidPhoneNoKuwait': true };
            }
        }
    }

    static decimalNumbersValidator(control) {
        if (control.value) {
            if (control.value.match(/^[+]?\d+(\.\d+)?$/)) {
                return null;
            } else {
                return { 'invalidDecimalNumber': true };
            }
        }
    }

    static MatchPassword(AC: AbstractControl) {
        let password = AC.get('password').value; // to get value in input tag
        let retypePassword = AC.get('retypePassword').value; // to get value in input tag
        // if (password && retypePassword) {
        if (password != retypePassword) {
            AC.get('retypePassword').setErrors({ MatchPassword: true })
        } else {
            return null
        }
        // }
    }

    static alphanumeric(control) {

        if (control.value) {
            if (control.value.match(/^[a-zA-Z0-9]*$/)) {
                return null;
            } else {
                return { 'invalidAlphaNumeric': true };
            }
        }

    }

    static noWhitespace(control: AbstractControl) {
        if (control.value) {
            let isWhitespace = (control.value || '').trim().length === 0;
            if (!isWhitespace) {
                return null;
            } else {
                return { 'hasWhiteSpaces': true };
            }
        }
    }

    static passwordTenantValidator(control) {
        if (control.value) {
            let tenant = window.location.hash.split('/')[1]
            let sfiPattern = '^(?=.*[a-z])(?=.*\\d)(?=.*[A-Z])(?=.*[~`!@#$%^&*()<>\"\'\/\._+=?{}\\[\\]])[A-Za-z\\d~`!@#$%^&*()<>\"\'\/\._+=?{}\\[\\]]+$'
            let genPattern = '^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[~`!@#$%^&*()<>\"\'\/\._+=?{}\\[\\]])[A-Za-z\\d~`!@#$%^&*()<>\"\'\/\._+=?{}\\[\\]]+$'
            if(tenant.indexOf('sfi') > -1){
                if(control.value.match(sfiPattern)) return null
                else return { 'invalidPassword': true}
            } else{
                if(control.value.match(genPattern)) return null
                else return { 'invalidPassword': true}
            }
        }
    }
};
