<div class="modal-header" [ngClass]="status == 'success' ? 'success-title-display' : 'error-title-display' ">
    <h4 class="modal-title pull-left text-white header-four-s"  [ngStyle]="styling?.paragraphTitle" [innerHTML]='title'></h4>
</div>
<div class="modal-body">
    <p *ngIf="containerText" [ngStyle]="styling?.paragraphText" [innerHTML]='containerText' style="white-space: pre-line;"></p>
    <p *ngFor="let file of filesList">
        <span class="fas fa-chevron-right m-r-10"></span> <span> {{file}}</span>
    </p>
</div>
<div class="modal-footer">
    <div class="text-right">
        <button type="button" (click)="hideConfirmationModal()" class="btn btn-info buttons">{{buttonContent}}</button>
    </div>
</div>