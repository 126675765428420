import { Component, OnInit } from '@angular/core';
import { CommonApiService } from '../../shared/services/common-api.service';
import { DasboardService } from '../dashboard/dasboard.service';

@Component({
  selector: 'app-find-agents-in-map',
  templateUrl: './find-agents-in-map.component.html',
  styleUrls: ['./find-agents-in-map.component.scss'],
  providers: [CommonApiService, DasboardService]
})
export class FindAgentsInMapComponent implements OnInit {
  coOrds = [];
  agentData:any;
  selectedId = '';
  constructor(
    private dashboardService: DasboardService
  ) { }

  ngOnInit(): void {
    this.findAgents()
  }

  findAgents(){
    const data = {
      zipCode: 32608 
    }
    this.dashboardService.findAgentsTemp(data).subscribe(resp => {
      this.agentData = resp.AgentDetails
      resp.AgentDetails.forEach((response, index) => {
        let geoCoder = new google.maps.Geocoder()
        geoCoder.geocode({'address': `${response.agencyLocationAddress.street}, ${response.agencyLocationAddress.city}, ${response.agencyLocationAddress.stateCode}`}, (results, status) => {
          if(status == google.maps.GeocoderStatus.OK){
            // this.coOrds.push({lat: results[0].geometry.location.lat(), lng: results[0].geometry.location.lng()})
            response.latFromGoogle = results[0].geometry.location.lat()
            response.lngFromGoogle = results[0].geometry.location.lng()
          }
        })
      })
    })
  }

  openWindow(id){
    this.selectedId = id
  }

  windowOpenCheck(id){
    return this.selectedId == id
  }

}
