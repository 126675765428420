import { Component, OnInit, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-additional-roof-info-form',
  templateUrl: './additional-roof-info-form.component.html',
  styleUrls: ['./additional-roof-info-form.component.scss']
})
export class AdditionalRoofInfoFormComponent implements OnInit {
  @Input() roofForm: FormGroup;
  @Input() roofMaterials: any;
  @Input() isSubmitted: boolean;
  constructor() { }

  ngOnInit(): void {
  }

}
