<!-- <div class="card get-quote-container zero-margin"> -->
<mat-horizontal-stepper #stepper class="zero-padding m-t-20 signUp-main-container">
    <mat-step [stepControl]="signUpForm">
        <form [formGroup]="signUpForm">
            <div class="signUp-box card">
                <div class="card-body" style="background-color: #eef5f9;">
                    <h4 class="custom-title text-center header-four-l" [ngStyle]="styling?.pageTitle">
                        {{'REGISTER_NEW_ACCOUNT' | translate}}</h4>
                    <input [hidden]="true" type="password" name="fakepasswordremembered"
                        id="please_ignore_for_autocomplete_1" [ngStyle]="styling?.fieldValueInput" />
                    <div class="row form-grouping">
                        <div class="col-lg-6">
                            <mat-form-field style="width: 100%">
                                <input matInput formControlName="firstName" type="text"
                                    placeholder="{{'FIRST_NAME' | translate}}" [ngStyle]="styling?.fieldValueInput" />
                            </mat-form-field>
                            <p class="error-class mat-angular-error-text"
                                *ngIf="!signUpForm.get('firstName').valid && isSubmitted">{{'REQUIRED_FIELD' |
                                translate: {value:'First Name'} }}</p>
                        </div>
                        <div class="col-lg-6">
                            <mat-form-field style="width: 100%">
                                <input matInput formControlName="lastName" type="text"
                                    placeholder="{{'LAST_NAME' | translate}}" [ngStyle]="styling?.fieldValueInput" />
                            </mat-form-field>
                            <p class="error-class mat-angular-error-text"
                                *ngIf="!signUpForm.get('lastName').valid && isSubmitted">{{'REQUIRED_FIELD' | translate:
                                {value:'Last Name'} }}</p>
                        </div>
                    </div>
                    <div class="row form-group">
                        <div class="col-lg-6">
                            <mat-form-field style="width: 100%">
                                <input matInput formControlName="email" type="text" (input)="keyEmail()"
                                    placeholder="{{'EMAIL' | translate}}" [ngStyle]="styling?.fieldValueInput" />
                            </mat-form-field>
                            <p class="error-class mat-angular-error-text"
                                *ngIf="!signUpForm.get('email').valid && isSubmitted">{{emailError}}</p>
                        </div>
                        <!-- <div class="p">2FA call and message method</div> -->
                        <div class="col-lg-6" style="margin-top: -15px">
                            <div>{{'PHONE_NUMBER' | translate}}</div>
                            <ngx-intl-tel-input [ngStyle]="styling?.fieldValueInput" [required]="phoneNumberRequired"
                                *ngIf="supportedCountryCodesPhoneNumber" [enablePlaceholder]="true"
                                [searchCountryField]="[
                                      SearchCountryField.Iso2,
                                      SearchCountryField.Name
                                    ]" selectedCountryISO="us" [phoneValidation]="true"
                                [onlyCountries]="supportedCountryCodesPhoneNumber" [separateDialCode]="separateDialCode"
                                [numberFormat]="PhoneNumberFormat.National" (input)="keyPhoneNumber()"
                                formControlName="phoneNumber" #phone>
                            </ngx-intl-tel-input>
                            <p class="error-class" *ngIf="!signUpForm.get('phoneNumber').valid && isSubmitted">
                                {{phoneNumberError}}</p>
                        </div>
                    </div>

                    <h4 class="custom-title text-center p-b-20" [ngStyle]="styling?.content">{{'CHOOSE_METHOD_QUESTION'
                        | translate}}</h4>
                    <div class="row col-md-4 offset-md-4">
                        <div class="col-md-4 text-center" style="width: 100%;">
                            <input type="radio" value='email' id="methodEmail" formControlName="chooseMethod"
                                class="radio-col-light-blue" (input)="inputChange('email')" />
                            <label [ngStyle]="styling?.fieldValueCheckBox" for="methodEmail"
                                [ngStyle]="styling?.paragraphTitle">Email</label>
                        </div>
                        <div class="col-md-4 text-center" style="width: 100%;">
                            <input type="radio" value='sms' id="methodText" formControlName="chooseMethod"
                                class="radio-col-light-blue" (input)="inputChange('sms')" />
                            <label [ngStyle]="styling?.fieldValueCheckBox" for="methodText"
                                [ngStyle]="styling?.paragraphTitle">Text</label>
                        </div>
                        <div class="col-md-4 text-center" style="width: 100%;">
                            <input type="radio" value='call' id="methodVoice" formControlName="chooseMethod"
                                class="radio-col-light-blue" (input)="inputChange('call')" />
                            <label [ngStyle]="styling?.fieldValueCheckBox" for="methodVoice"
                                [ngStyle]="styling?.paragraphTitle">Voice</label>
                        </div>

                    </div>

                    <div class="row form-group">
                        <div class="col-md-6 offset-md-3">
                            <div class="row" style="margin: 20px 0; justify-content: center;">
                                <button class="btn btn-danger buttons col-lg-3" type="button"
                                    (click)="navLocation('login')"
                                    [ngStyle]="styling?.button?.buttonNegative">{{'CANCEL' | translate}}</button>
                                <span class="m-r-20 m-b-20"></span>
                                <button class="btn btn-info buttons col-lg-4" type="button"
                                    (click)="saveAcntInfo(stepper)" [ngStyle]="styling?.button?.buttonPositive">{{'NEXT'
                                    | translate}}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </form>
    </mat-step>

    <mat-step>
        <div class="signUp-box card" style="background-color: #eef5f9;">
            <h4 class="custom-title p-t-30 text-center header-four-l" [ngStyle]="styling?.pageTitle">Your code is on the
                way!</h4>
            <form [formGroup]="otpForm" class="form-horizontal form-material" id="otpForm">
                <div class="form-group m-t-20">
                    <div class="col-md-6 offset-md-3 p-b-20">
                        <p class="body-paragraph-s" [ngStyle]="styling?.paragraphText">{{methodMessage}}</p>
                    </div>
                    <div class="col-md-6 offset-md-3">
                        <mat-form-field style="width: 100%">
                            <input matInput formControlName="otp" type="text" placeholder="Enter Verification Code *"
                                numbersOnly maxlength="6" [ngStyle]="styling?.fieldValueInput" />
                        </mat-form-field>
                        <p class="error-class mat-angular-error-text" *ngIf="!otpForm.get('otp').valid && isSubmitted">
                            {{'REQUIRED_FIELD' | translate: {value:'OTP'} }}</p>
                    </div>
                    <div class="col-md-6 offset-md-3">
                        <p class="body-paragraph-s" [ngStyle]="styling?.paragraphText">If you didn't receive a
                            verification code, <span class="custom-link account-title hyperlinks"
                                [ngStyle]="styling?.hyperlink" (click)="reSendOtp(stepper, 'true')">click here</span>
                            <span id="request_note"> to
                                request a new one after </span>
                            <countdown #cd [config]="{leftTime: 60,  notify:1, format: 'ss'}"
                                (event)="handleEvent($event)"></countdown> <span id="seconds_1"> seconds. </span>
                        </p>
                    </div>
                    <div class="col-md-6 offset-md-3">
                        <div class="row" style="margin: 40px 0 0 0; justify-content: center;">
                            <button class="btn btn-info buttons col-lg-3" type="button" (click)="navBack(stepper)"
                                [ngStyle]="styling?.button?.buttonNegative">{{'BACK' | translate}}</button>
                            <span class="m-r-20 m-b-20"></span>
                            <button class="btn btn-info buttons col-lg-4" type="button" (click)="saveAcntInfo(stepper)"
                                [ngStyle]="styling?.button?.buttonNavigation">{{'NEXT' | translate}}</button>
                        </div>
                    </div>
                </div>
            </form>
            <!-- <form [formGroup]="otpForm" class="form-horizontal form-material" id="otpForm">
                        <div class="form-group m-t-20">
                            <div class="col-md-6 offset-md-3">
                                <mat-form-field style="width: 100%">
                                    <input matInput formControlName="otp" type="text" placeholder="{{'ENTER_VERIFICATION_CODE' | translate}}" numbersOnly maxlength="6" [ngStyle]="styling?.fieldValueInput"/>
                                </mat-form-field>
                                <p class="error-class mat-angular-error-text" *ngIf="!otpForm.get('otp').valid && isSubmitted">{{'REQUIRED_FIELD' | translate: {value:'VERIFICATION_CODE'} }}</p>
                            </div>
                            <div class="p-t-10 col-md-6 offset-md-3">
                                <p class="body-paragraph-s" [ngStyle]="styling?.paragraphText">If you were unable to recieve any otp please click the 
                                    <span class="custom-link account-title hyperlinks" [ngStyle]="styling?.hyperlink" 
                                    (click)="reSendOtp(stepper, 'true')">link</span> to get a new OTP after <countdown #cd [config]="{leftTime: 60,  notify:1, format: 'ss'}" (event)="handleEvent($event)"></countdown> seconds.</p>
                            </div>
                            <div class="col-md-6 offset-md-3">
                                <div class="row" style="margin: 40px 0 0 0; justify-content: center;">
                                    <button class="btn btn-info text-uppercase buttons col-lg-3" type="button" (click)="navBack(stepper)"
                                [ngStyle]="styling?.button?.buttonNegative">{{'BACK' | translate}}</button>
                                    <span class="m-r-20 m-b-20"></span>
                                    <button class="btn btn-info text-uppercase buttons col-lg-4" type="button" (click)="saveAcntInfo(stepper)"
                                [ngStyle]="styling?.button?.buttonNavigation">{{'NEXT' | translate | uppercase}}</button>
                                </div>
                            </div>
                        </div>
                    </form> -->
        </div>

    </mat-step>

    <mat-step [stepControl]="signUpForm">
        <form [formGroup]="signUpForm">

            <div class="signUp-box card">

                <div class="card-body" style="background-color: #eef5f9;">
                    <h4 class="custom-title text-center header-four-l" [ngStyle]="styling?.pageTitle">
                        {{'REGISTER_NEW_ACCOUNT' | translate}}</h4>
                    <input [hidden]="true" type="password" name="fakepasswordremembered"
                        id="please_ignore_for_autocomplete_2" [ngStyle]="styling?.fieldValueInput" />
                    <div class="row form-grouping p-t-20">
                        <div class="col-lg-6">
                            <mat-form-field style="width: 100%">
                                <input matInput formControlName="policyNumber" (input)="inputChange('policyNumber')"
                                    type="text" placeholder="{{'POLICY_NUMBER' | translate}}"
                                    [ngStyle]="styling?.fieldValueInput" />
                                <span matSuffix>
                                    <a class="fas fa-question-circle m-r-10" [ngStyle]="styling?.iconStyling"
                                        popoverTitle="Policy Format" [popover]="policyHint" container="body"
                                        triggers="mouseenter:mouseleave"></a>
                                </span>
                            </mat-form-field>
                            <p class="error-class mat-angular-error-text"
                                *ngIf="!signUpForm.get('policyNumber').valid && isSubmitted">{{policyNumberError}}</p>
                        </div>
                        <div class="col-lg-6">
                            <mat-form-field style="width: 100%">
                                <input matInput formControlName="zipCodeInsuredProperty"
                                    (input)="inputChange('zipCodeInsuredProperty')" type="text"
                                    placeholder="{{'PROP_ZIP_CODE' | translate}}"
                                    [ngStyle]="styling?.fieldValueInput" />
                                <span matSuffix>
                                    <a class="fas fa-question-circle m-r-10" [ngStyle]="styling?.iconStyling"
                                        popoverTitle="Zip Code Format" [popover]="'ZIP_CODE_HINT' | translate"
                                        container="body" triggers="mouseenter:mouseleave"></a>
                                </span>
                            </mat-form-field>
                            <p class="error-class mat-angular-error-text"
                                *ngIf="!signUpForm.get('zipCodeInsuredProperty').valid && isSubmitted">{{zipCodeError}}
                            </p>
                        </div>
                    </div>
                    <div class="row form-grouping">
                        <div class="col-lg-6">
                            <mat-form-field style="width: 100%">
                                <input matInput formControlName="desiredUserID" (input)="keyUsername()" maxlength="25"
                                    type="text" placeholder="{{'USERNAME' | translate}}"
                                    [ngStyle]="styling?.fieldValueInput" />
                                <span matSuffix>
                                    <a class="fas fa-question-circle m-r-10" [ngStyle]="styling?.iconStyling"
                                        popoverTitle="Username Format" [popover]="usernameHint" container="body"
                                        triggers="mouseenter:mouseleave"></a>
                                </span>
                            </mat-form-field>
                            <p class="error-class mat-angular-error-text"
                                *ngIf="!signUpForm.get('desiredUserID').valid && isSubmitted">{{usernameError}}</p>
                        </div>
                        <div class="col-lg-6">
                            <mat-form-field style="width: 100%">
                                <input matInput [ngStyle]="styling?.fieldValueInput" autocomplete="new-password"
                                    formControlName="password" (input)="inputChange('password')"
                                    [type]="showPassword ? 'password': 'text'"
                                    placeholder="{{'PASSWORD' | translate}}" />
                                <span matSuffix>
                                    <a class="fas fa-question-circle m-r-10" [ngStyle]="styling?.iconStyling"
                                        popoverTitle="Password Format" [popover]="passwordHint" container="body"
                                        triggers="mouseenter:mouseleave"></a>
                                </span>
                                <span matSuffix>
                                    <a id='signup_togglePassword' class='show-password-data' (click)="togglePassword()">
                                        <i [ngClass]="showPassword?'fa fa-eye': 'fa fa-eye-slash'"
                                            [ngStyle]="styling?.iconStyling"></i>
                                    </a>
                                </span>
                            </mat-form-field>
                            <p class="error-class mat-angular-error-text"
                                *ngIf="!signUpForm.get('password').valid && isSubmitted">{{passwordError}}</p>
                        </div>

                    </div>
                    <div class="row form-group">
                        <div class="col-lg-6">
                            <mat-form-field style="width: 100%">
                                <input matInput [ngStyle]="styling?.fieldValueInput" autocomplete="new-password"
                                    formControlName="confirmPassword" (input)="inputChange('confirmPassword')"
                                    [type]="showPassword ? 'password': 'text'"
                                    placeholder="{{'CONFIRM_PASSWORD' | translate}}" />
                                <span matSuffix>
                                    <a class="fas fa-question-circle m-r-10" [ngStyle]="styling?.iconStyling"
                                        popoverTitle="Password Format" [popover]="passwordHint" container="body"
                                        triggers="mouseenter:mouseleave"></a>
                                </span>
                                <span matSuffix>
                                    <a id='signup_togglePassword' class='show-password-data' (click)="togglePassword()">
                                        <i [ngClass]="showPassword?'fa fa-eye': 'fa fa-eye-slash'"
                                            [ngStyle]="styling?.iconStyling"></i>
                                    </a>
                                </span>
                            </mat-form-field>
                            <p class="error-class mat-angular-error-text"
                                *ngIf="signUpForm.controls.confirmPassword.errors?.required && isSubmitted">Re type
                                password is required</p>
                            <p class="error-class mat-angular-error-text"
                                *ngIf="signUpForm.controls.confirmPassword.errors?.MatchPassword && isSubmitted">The
                                passwords you entered do not match. Please retry.</p>
                        </div>
                    </div>
                    <div class="row form-group">
                        <div class="col-md-6 offset-md-3">
                            <div class="row" style="margin: 20px 0; justify-content: center;">
                                <button class="btn btn-danger buttons col-lg-3" type="button"
                                    (click)="navLocation('login')"
                                    [ngStyle]="styling?.button?.buttonNegative">{{'CANCEL' | translate}}</button>
                                <span class="m-r-20 m-b-20"></span>
                                <button class="btn btn-info buttons col-lg-4" type="button"
                                    (click)="saveAcntInfo(stepper)" [ngStyle]="styling?.button?.buttonPositive">Create
                                    Account</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </mat-step>
    <mat-step>
        <div class="signUp-box card">

            <div class="card-body" style="background-color: #eef5f9;">
                <h4 class="custom-title p-t-30 text-center header-four-l" [ngStyle]="styling?.pageTitle">
                    {{'REGISTER_NEW_ACCOUNT' | translate}}</h4>
                <p class="text-center p-t-30 p-b-30 body-paragraph-s" [ngStyle]="styling?.paragraphText"> You have
                    successfully created a new user account! </p>
                <div class="form-group m-t-20 col-lg-12">
                    <div class="d-flex" style="justify-content: center;">
                        <button class="btn btn-info text-uppercase buttons" type="button" (click)="navLocation('login')"
                            [ngStyle]="styling?.button?.buttonNavigation">{{'SIGN_IN' | translate | uppercase}}</button>
                    </div>
                </div>
            </div>
        </div>

    </mat-step>
</mat-horizontal-stepper>

<ng-template #usernameHint>
    <ul style="padding-left: 25px;">
        <!-- <li>Username format should be enforced by validation</li> -->
        <li>Username must be at least 5 characters long</li>
        <li>You can use the following special characters in your username: - . _ ~</li>
        <li>Usernames are not case sensitive</li>
    </ul>
</ng-template>
<ng-template #passwordHint>
    <ul style="padding-left: 25px;">
        <!-- <li>Password format should be enforced by validation </li> -->
        <li>You must use at least one of the following special characters in your password: <span
                [innerHTML]="characterPassword"></span></li>
        <li>Password must be at least 8 characters long, include a capital letter, and have at least one number</li>
    </ul>
</ng-template>
<ng-template #policyHint>
    <div [innerHTML]="policyNumberHint  | safeHtml"></div>
</ng-template>