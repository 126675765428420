<div [formGroup]="coverageValues">
    <div class="col-12">
        
        <div class="row custom-title-info account-title coverage-container" [ngStyle]="styling?.card?.attributeValue">
            <div class="col-mob-6">
                <span style="font-weight: 600; width: 20%; font-size:24px;">Coverages</span>
            </div>
            <div class="col-mob-6">
                <span style="font-weight: 600; width: 20%; font-size:24px;">Limit Amount</span>
            </div>
        </div>
        
        <div class="row custom-title-info account-title coverage-container" [hidden]="curPolicyType === 'HO4'" [ngStyle]="styling?.card?.attributeValue">
            <div class="col-mob-6 text-box-heading">
                <span>Coverage A Dwelling</span>
            </div>
            <div class="col-mob-6">
                <mat-form-field class="text-field-mat">
                    <input matInput formControlName="coverageAValue" type="text"/>  
                </mat-form-field>  
            </div>
        </div>

        <div class="row custom-title-info account-title coverage-container" [ngStyle]="styling?.card?.attributeValue"
        [hidden]="curPolicyType === 'HO4' || curPolicyType ==='HO6' || curPolicyType ==='DF3-CL' || curPolicyType ==='DF3-CO'">
            <div class="col-mob-6 text-box-heading">
                <span>Coverage B Other Structures</span>
            </div>
            <div class="col-mob-6">
                <mat-form-field class="text-field-mat">
                    <input matInput formControlName="coverageBValue" type="text" />
                </mat-form-field>   
            </div>
        </div>

        <div class="row custom-title-info account-title coverage-container" [ngStyle]="styling?.card?.attributeValue">
            <div class="col-mob-6 text-box-heading">
                <span>Coverage C Personal Property</span>
            </div>
            <div class="col-mob-6">
                <div *ngIf="curPolicyType === 'HO3'">
                    <ng-select id="coverageCValue_1" [searchable]="false" [items]="this.coverageForms.coverageCValue" 
                            appendTo="body" [hideSelected]="true"
                            placeholder="coverage C Value" formControlName="coverageCValue"
                            class="text-field-mat">
                        </ng-select>
                        <p class="error-class" *ngIf="!coverageValues.get('coverageCValue').valid">coverage C Value is required</p> 
                </div>
                <div *ngIf="curPolicyType !== 'HO3'">
                    <mat-form-field class="text-field-mat">
                        <input matInput formControlName="coverageCValue" type="text"/>
                    </mat-form-field>
                </div>
            </div>
        </div>

        <div class="row custom-title-info account-title coverage-container" [ngStyle]="styling?.card?.attributeValue"
        [hidden]="curPolicyType === 'DF3-DL' || curPolicyType ==='DF3-DO' || curPolicyType ==='DF3-CL' || curPolicyType ==='DF3-CO'">
            <div class="col-mob-6 text-box-heading">
                <span>Coverage D Loss of Use</span>
            </div>
            <div class="col-mob-6">
                <mat-form-field class="text-field-mat">
                    <input matInput formControlName="coverageDValue" type="text"/>
                </mat-form-field>
            </div>
        </div>

        <div class="row custom-title-info account-title coverage-container" [ngStyle]="styling?.card?.attributeValue"
        [hidden]="curPolicyType === 'DF3-DL' || curPolicyType ==='DF3-DO' || curPolicyType ==='DF3-CL' || curPolicyType ==='DF3-CO'">
            <div class="col-mob-6 text-box-heading">
                <span>Coverage E Personal Liability</span>
            </div>
            <div class="col-mob-6">
                <ng-select id="coverageEValue_!" [searchable]="false" [items]="coverageForms.coverageEValue" 
                    appendTo="body" [hideSelected]="true"
                    placeholder="coverage E Value" formControlName="coverageEValue"
                    class="text-field-mat">
                </ng-select>
                <p class="error-class" *ngIf="!coverageValues.get('coverageEValue').valid">
                    coverage E Value is required</p>
            </div>
        </div>

        <div class="row custom-title-info account-title coverage-container" [ngStyle]="styling?.card?.attributeValue"
        [hidden]="curPolicyType === 'DF3-DL' || curPolicyType ==='DF3-DO' || curPolicyType ==='DF3-CL' || curPolicyType ==='DF3-CO'">
            <div class="col-mob-6 text-box-heading">
                <span>Coverage F Medical Payments</span>
            </div>
            <div class="col-mob-6">
                <mat-form-field class="text-field-mat">
                    <input matInput formControlName="coverageFValue" type="text"/>
                </mat-form-field>  
            </div>
        </div>

        <div class="row custom-title-info account-title coverage-container" [ngStyle]="styling?.card?.attributeValue"
        [hidden]="curPolicyType === 'HO4' || curPolicyType ==='HO6'">
            <div class="col-mob-6 text-box-heading">
                <span>Coverage L - Personal Liability</span>
            </div>
            <div class="col-mob-6">
                <mat-form-field class="text-field-mat">
                    <input matInput formControlName="coverageLValue" type="text"/>
                </mat-form-field>  
            </div>
        </div>

        <div class="row custom-title-info account-title coverage-container" [ngStyle]="styling?.card?.attributeValue"
        [hidden]="curPolicyType === 'HO4' || curPolicyType ==='HO6'">
            <div class="col-mob-6 text-box-heading">
                <span>Coverage M - Medical Payments</span>
            </div>
            <div class="col-mob-6">
                <mat-form-field class="text-field-mat">
                    <input matInput formControlName="coverageMValue" type="text"/>
                </mat-form-field>  
            </div>
        </div>

        <div class="row custom-title-info account-title coverage-container" [ngStyle]="styling?.card?.attributeValue"
        [hidden]="curPolicyType === 'HO3' || curPolicyType === 'HO4' || curPolicyType ==='HO6'">
            <div class="col-mob-6 text-box-heading">
                <span>Coverage D&E - Fair Rental Value & Additional Living Expenses</span>
            </div>
            <div class="col-mob-6">
                <mat-form-field class="text-field-mat">
                    <input matInput formControlName="coverageDandE" type="text"/>
                </mat-form-field>  
            </div>
        </div>

        <hr style="height:5px;border-width:0;color:gray;background-color:gray; margin-top: 20px; margin-bottom: 20px;">

        <div class="row custom-title-info account-title coverage-container" [ngStyle]="styling?.card?.attributeValue">
            <div class="col-mob-6">
                <span style="font-weight: 600; width: 20%; font-size:24px;">Deductibles</span>
            </div>
            <div class="col-mob-6">
                <span style="font-weight: 600; width: 20%; font-size:24px;">Deductible Amount</span>
            </div>
        </div>

        <div class="row custom-title-info account-title coverage-container" [ngStyle]="styling?.card?.attributeValue">
            <div class="col-mob-6 text-box-heading">
                <span>AOP Deductible</span>
            </div>
            <div class="col-mob-6">
                <ng-select id="aopDeductible" [searchable]="false" [items]="coverageForms.aopDeductible" appendTo="body" [hideSelected]="true"
                    placeholder="aop Deductible  Value" formControlName="aopDeductible"
                    class="text-field-mat">
                </ng-select>
                <p class="error-class" *ngIf="!coverageValues.get('aopDeductible').valid">aop Deductible is required</p>  
            </div>
        </div>

        <div class="row custom-title-info account-title coverage-container" [ngStyle]="styling?.card?.attributeValue">
            <div class="col-mob-6 text-box-heading">
                <span>Hurricane Deductible</span>
            </div>
            <div class="col-mob-6">
                <ng-select id="deductibleApplied_2" [searchable]="false" [items]="coverageForms.deductibleAppliedForHurricaneCoverage" appendTo="body" [hideSelected]="true"
                    placeholder="Hurricane Deductible" formControlName="deductibleAppliedForHurricaneCoverage"
                    class="text-field-mat">
                </ng-select>
                <p class="error-class" *ngIf="!coverageValues.get('deductibleAppliedForHurricaneCoverage').valid">
                Hurricane Deductible is required</p> 
            </div>
        </div>

        <div class="row custom-title-info account-title coverage-container" [ngStyle]="styling?.card?.attributeValue" [hidden]="curPolicyType === 'HO4'">
            <div class="col-mob-6 text-box-heading">
                <span>Water Deductible</span>
            </div>
            <div class="col-mob-6">
                <mat-form-field class="text-field-mat">
                    <input matInput formControlName="waterDeductible" type="text" />
                </mat-form-field>  
            </div>
        </div>

        <div class="row custom-title-info account-title coverage-container" [ngStyle]="styling?.card?.attributeValue" [hidden]="curPolicyType === 'HO4'">
            <div class="col-mob-6 text-box-heading">
                <span>Sinkhole Deductible</span>
            </div>
            <div class="col-mob-6">
                <mat-form-field class="text-field-mat">
                    <input matInput formControlName="sinkholeDeductible" type="text" />
                </mat-form-field>  
            </div>
        </div>

    </div>
</div>

<!-- <div [formGroup]="coverageValues">
    <div class="form-group p-t-20">

    <div class="table">
        <table class="table table-striped" 
        style="box-shadow:none; border: none; align-content: center; margin-left: 10%; margin-right: 10%;">
          <thead class="custom-title-info account-title" 
          [ngStyle]="styling?.card?.attributeValue">
            <tr>
              <th style="font-weight: 600; width: 20%; font-size:24px; border-color: white">Coverages</th>
              <th style="font-weight: 600; width: 20%; font-size:24px; border-color: white">Limit Amount</th>
            </tr>
          </thead>

          <tbody class="custom-title-info account-title">
                <tr class="custom-title-info account-title" 
                    [hidden]="curPolicyType === 'HO4'"
                    [ngStyle]="styling?.card?.attributeValue"
                    style="background-color: white; 
                    vertical-align: middle;">
                    <td style="vertical-align: middle;
                    border-color: white ">
                        <p>Coverage A Dwelling</p>
                    </td>
                    <td style="vertical-align: middle;
                    border-color: white">
                        <mat-form-field style="width: 30%;">
                            <input matInput formControlName="coverageAValue" type="text" mvndrMatCurrencyFormat/>  
                        </mat-form-field>  
                    </td>
                </tr>
                 
                <tr class="custom-title-info account-title" 
                    [hidden]="curPolicyType === 'HO4' || curPolicyType ==='HO6' || curPolicyType ==='DF3-CL' || curPolicyType ==='DF3-CO'"
                    [ngStyle]="styling?.card?.attributeValue"
                    style="background-color: white; 
                    vertical-align: middle;">
                    <td style="vertical-align: middle; 
                    border-color: white">
                        <p>Coverage B Other Structures</p>
                    </td>
                    <td style="vertical-align: middle;
                    border-color: white">
                        <mat-form-field style="width: 30%;">
                            <input matInput formControlName="coverageBValue" type="text" mvndrMatCurrencyFormat />
                        </mat-form-field>  
                    </td>
                </tr>

                <tr class="custom-title-info account-title"
                    [ngStyle]="styling?.card?.attributeValue"
                    style="background-color: white; 
                    vertical-align: middle;">
                    <td style="vertical-align: middle; 
                    border-color: white">
                        <p>Coverage C Personal Property</p>
                    </td>
                    <td style="vertical-align: middle;
                    border-color: white"
                    *ngIf="curPolicyType === 'HO3'">
                         [items]="this.coverageForms.coverageCValue" 
                            appendTo="body" [hideSelected]="true"
                            placeholder="coverage C Value" formControlName="coverageCValue"
                            style="width: 30%;">
                        </ng-select>
                        <p class="error-class" *ngIf="!coverageValues.get('coverageCValue').valid">
                            coverage C Value is required</p> 
                    </td>
                    <td style="vertical-align: middle;
                    border-color: white"
                    *ngIf="curPolicyType !== 'HO3'">
                        <mat-form-field style="width: 30%">
                            <input matInput formControlName="coverageCValue" type="text" mvndrMatCurrencyFormat/>
                        </mat-form-field>
                    </td>
                </tr>

                <tr class="custom-title-info account-title"
                [hidden]="curPolicyType === 'DF3-DL' || curPolicyType ==='DF3-DO' || curPolicyType ==='DF3-CL' || curPolicyType ==='DF3-CO'"
                [ngStyle]="styling?.card?.attributeValue"
                style="background-color: white; 
                vertical-align: middle;">
                    <td style="vertical-align: middle; 
                    border-color: white">
                        <p>Coverage D Loss of Use</p>
                    </td>
                    <td style="vertical-align: middle;
                    border-color: white">
                        <mat-form-field style="width: 30%">
                            <input matInput formControlName="coverageDValue" type="text" mvndrMatCurrencyFormat/>
                        </mat-form-field>
                    </td>
                </tr>

                <tr class="custom-title-info account-title"
                [hidden]="curPolicyType === 'DF3-DL' || curPolicyType ==='DF3-DO' || curPolicyType ==='DF3-CL' || curPolicyType ==='DF3-CO'"
                [ngStyle]="styling?.card?.attributeValue"
                style="background-color: white; 
                vertical-align: middle;">
                    <td style="vertical-align: middle; 
                    border-color: white">
                        <p>Coverage E Personal Liability</p>
                    </td>
                    <td style="vertical-align: middle;
                    border-color: white">
                         [items]="coverageForms.coverageEValue" 
                            appendTo="body" [hideSelected]="true"
                            placeholder="coverage E Value" formControlName="coverageEValue"
                            style="width: 30%">
                        </ng-select>
                        <p class="error-class" *ngIf="!coverageValues.get('coverageEValue').valid">
                            coverage E Value is required</p>
                    </td>
                </tr>

                <tr class="custom-title-info account-title"
                [hidden]="curPolicyType === 'DF3-DL' || curPolicyType ==='DF3-DO' || curPolicyType ==='DF3-CL' || curPolicyType ==='DF3-CO'"
                [ngStyle]="styling?.card?.attributeValue"
                style="background-color: white; 
                vertical-align: middle;">
                    <td style="vertical-align: middle; 
                    border-color: white">
                        <p>Coverage F Medical Payments</p>
                    </td>
                    <td style="vertical-align: middle;
                    border-color: white">
                        <mat-form-field style="width: 30%">
                            <input matInput formControlName="coverageFValue" type="text" mvndrMatCurrencyFormat/>
                        </mat-form-field>
                    </td>
                </tr>

                <tr class="custom-title-info account-title"
                [hidden]="curPolicyType === 'HO4' || curPolicyType ==='HO6'"
                [ngStyle]="styling?.card?.attributeValue"
                style="background-color: white; 
                vertical-align: middle;">
                    <td style="vertical-align: middle; 
                    border-color: white">
                        <p>Coverage L - Personal Liability</p>
                    </td>
                    <td style="vertical-align: middle;
                    border-color: white">
                        <mat-form-field style="width: 30%">
                            <input matInput formControlName="coverageLValue" type="text" mvndrMatCurrencyFormat/>
                        </mat-form-field>
                    </td>
                </tr>

                <tr class="custom-title-info account-title"
                [hidden]="curPolicyType === 'HO4' || curPolicyType ==='HO6'"
                [ngStyle]="styling?.card?.attributeValue"
                style="background-color: white; 
                vertical-align: middle;">
                    <td style="vertical-align: middle; 
                    border-color: white">
                        <p>Coverage M - Medical Payments</p>
                    </td>
                    <td style="vertical-align: middle;
                    border-color: white">
                        <mat-form-field style="width: 30%">
                            <input matInput formControlName="coverageMValue" type="text" mvndrMatCurrencyFormat/>
                        </mat-form-field>
                    </td>
                </tr>

                <tr class="custom-title-info account-title"
                [hidden]="curPolicyType === 'HO3' || curPolicyType === 'HO4' || curPolicyType ==='HO6'"
                [ngStyle]="styling?.card?.attributeValue"
                style="background-color: white; 
                vertical-align: middle;">
                    <td style="vertical-align: middle; 
                    border-color: white">
                        <p>Coverage D&E - Fair Rental Value & Additional Living Expenses</p>
                    </td>
                    <td style="vertical-align: middle; 
                    border-color: white">
                        <mat-form-field style="width: 30%">
                            <input matInput formControlName="coverageDandE" type="text" mvndrMatCurrencyFormat/>
                        </mat-form-field>
                    </td>
                </tr>
            </tbody>
        </table>

        <hr style="height:5px;border-width:0;color:gray;background-color:gray; margin-top: 20px; margin-bottom: 20px;">

        <div class="table">
            <table class="table table-striped" 
            style="box-shadow:none; border: none; align-content: center; margin-left: 10%; margin-right: 10%;">
              <thead class="custom-title-info account-title" 
              [ngStyle]="styling?.card?.attributeValue">
                <tr>
                  <th style="font-weight: 600; width: 20%; font-size:24px; border-color: white">Deductibles</th>
                  <th style="font-weight: 600; width: 20%; font-size:24px; border-color: white">Deductible Amount</th>
                </tr>
              </thead>
    
              <tbody class="custom-title-info account-title">
                    <tr class="custom-title-info account-title"
                        [ngStyle]="styling?.card?.attributeValue"
                        style="background-color: white; 
                        vertical-align: middle;">
                        <td style="vertical-align: middle; 
                        border-color: white">
                            <p>AOP Deductible</p>
                        </td>
                        <td style="vertical-align: middle; 
                        border-color: white">
                             [items]="coverageForms.aopDeductible" appendTo="body" [hideSelected]="true"
                                placeholder="aop Deductible  Value" formControlName="aopDeductible"
                                style="width: 30%;">
                            </ng-select>
                            <p class="error-class" *ngIf="!coverageValues.get('aopDeductible').valid">
                                aop Deductible is required</p>  
                        </td>
                    </tr>
                     
                    <tr class="custom-title-info account-title"
                        [ngStyle]="styling?.card?.attributeValue"
                        style="background-color: white; 
                        vertical-align: middle;">
                        <td style="vertical-align: middle; 
                        border-color: white">
                            <p>Hurricane Deductible</p>
                        </td>
                        <td class ="offset-md-4" style="vertical-align: middle; 
                        border-color: white">
                             [items]="coverageForms.deductibleAppliedForHurricaneCoverage" appendTo="body" [hideSelected]="true"
                                placeholder="Hurricane Deductible" formControlName="deductibleAppliedForHurricaneCoverage"
                                style="width: 30%;">
                            </ng-select>
                            <p class="error-class" *ngIf="!coverageValues.get('deductibleAppliedForHurricaneCoverage').valid">
                            Hurricane Deductible is required</p>  
                        </td>
                    </tr>
    
                    <tr class="custom-title-info account-title"
                        [ngStyle]="styling?.card?.attributeValue"
                        [hidden]="curPolicyType === 'HO4'"
                        style="background-color: white; 
                        vertical-align: middle;">
                        <td style="vertical-align: middle; border-color: white">
                            <p>Water Deductible</p>
                        </td>
                        <td style="vertical-align: middle; border-color: white">
                            <mat-form-field style="width: 30%">
                                <input matInput formControlName="waterDeductible" type="text" />
                            </mat-form-field> 
                        </td>
                    </tr>
    
                    <tr class="custom-title-info account-title"
                    [hidden]="curPolicyType === 'HO4'"
                    [ngStyle]="styling?.card?.attributeValue"
                    style=" vertical-align: middle; background-color: white;">
                        <td style="vertical-align: middle; border-color: white">
                            <p>Sinkhole Deductible</p>
                        </td>
                        <td style="vertical-align: middle; border-color: white">
                            <mat-form-field style="width: 30%">
                                <input matInput formControlName="sinkholeDeductible" type="text" />
                            </mat-form-field>
                        </td>
                    </tr>
                </tbody>
            </table>
    </div>
</div>
</div>






 -->
