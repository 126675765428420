import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Observable, Subject, forkJoin, of } from 'rxjs';
import { throwError as observableThrowError } from 'rxjs'
import { catchError, map } from 'rxjs/operators'
import * as _ from 'lodash'
import { CommonApiService } from '../../../shared/services/common-api.service';
import { NotifytoastService } from '../../../shared/services/notificationToast.service';
import { PrivateDataShare } from '../../../shared/services/privateDataShare.service';
import { UtilsService } from '../../../shared/services/utils.service';
import { element } from 'protractor';

@Component({
  selector: 'app-open-camera',
  templateUrl: './open-camera.component.html',
  styleUrls: ['./open-camera.component.scss'],
  providers: [ CommonApiService ]
})
export class OpenCameraComponent implements OnInit {
  title?: string
  file?: any = []; 
  claimRowData?: any;
  closeBtnName?: string
  currentBrowser?: string
  tenant?: string;
  maxFileSize?: number
  displayImgs = []
  // public onClose: Subject<any>;
  public styling =this.utilsService.getItemfromSession('themeObject')

  constructor(
    private httpService: CommonApiService,
    public bsModalRef: BsModalRef,
    private utilsService: UtilsService,
    private notifyToast: NotifytoastService,

    private privateDataShare: PrivateDataShare,
  ) { }

  ngOnInit(): void {
    // this.onClose = new Subject()
    let temp = '5'
    this.maxFileSize = temp ? parseInt(temp)*1024*1024 : 5242880
    this.displayImgs.push(this.readFileText(this.file[0]))
    Promise.all(this.displayImgs).then(val => {
      this.displayImgs = val
    })
    console.log(this.file, this.displayImgs)
  }

  removeImage(image, index){
    this.displayImgs.splice(index, 1)
  }

  captureImg(eve){
    const file = eve.target.files;
    if (file.length) {
      // const setFiles = [...file]
      this.previewImages(eve)
      // console.log(setFiles);
    }
  }

  previewImages(previewImgs){
    let files = previewImgs.currentTarget.files
    let errorFiles = []

    if(!files.length) return;
    for(let i = 0; i < files.length; i++){
      if(files[i].size < this.maxFileSize){
        if(files[i].type.indexOf('image') > -1) this.displayImgs.push(this.readFileText(files[i]))
      } else{
        errorFiles.push(files[i].name)
      }
    }
    if(errorFiles.length) {
      const fileSizeInBytes = this.maxFileSize/(1024*1024)
      this.notifyToast.error(`${errorFiles.join(", ")} has not been uploaded as file size exceeds ${fileSizeInBytes} MB.`)
    }

    Promise.all(this.displayImgs).then(val => {
      this.displayImgs = val
    })
  }

  readFileText(file){
    return new Promise((resolve,reject) => {
      let fileReader = new FileReader()

      fileReader.onload = () =>{
        resolve({filename: file.name ,result:fileReader.result, actualFile: file})
        // resolve(fileReader.result)
      }

      fileReader.onerror = () =>{
        reject()
      }
      fileReader.readAsDataURL(file)
    })
  }

  uploadDocs(){
    let fileList = this.displayImgs.map(resp => resp.actualFile )
    this.submitClaimDocs(fileList, this.claimRowData).subscribe(resp => {
      // let textDisplay = ''
      // let successDocs = _.filter(resp, {'uploaded': true})
      let failDocs = _.filter(resp, {'uploaded': false, 'sizeLimitExceeded': false})
      let fileSizeExceededDocs = _.filter(resp, {'uploaded': false, 'sizeLimitExceeded': true})
      // failDocs.forEach((claim, index) => {
      //   textDisplay += claim.fileName + failDocs.length == index ? ',' : ''
      // });
      if(failDocs.length || fileSizeExceededDocs.length) this.notifyToast.error("Some Files have not been uploaded")
      else {
        this.notifyToast.success("Files have been uploaded successfully")
        this.bsModalRef.hide()
      }
    })
    console.log('hear')
  }

  submitClaimDocs(files, claimRow): Observable<any>{
    let fileUploadSize = this.privateDataShare.getFileUploadSize() 
    fileUploadSize = fileUploadSize ? parseInt(fileUploadSize)*1024*1024 : 5242880
    let observables = files.map((file, index) => {
      if(file.size < fileUploadSize){
        let convertedData = this.convertFormData(file, claimRow)
        return this.httpService.privateUpload('uploadclaimsdocuments', convertedData, true).pipe(
          map((resp) => {
            if(resp.statusCode == 200){
                let tempData = this.getPicRespData(file.name, claimRow.CLAIM_ID, true, false)
                return tempData
              } else{
                let tempData = this.getPicRespData(file.name, claimRow.CLAIM_ID, false, false)
                return tempData
              }
          }),
          catchError(error => this.errorCase(file.name, claimRow.CLAIM_ID))
        )
      } else{
        let tempData = this.getPicRespData(file.name, claimRow.CLAIM_ID, false, true)
        return of(tempData)
      }
    })
    return forkJoin(observables)
  }

  convertFormData(file, claimRow){
    const formData = new FormData()
    const tenant = this.tenant
    formData.append('claimID', claimRow['CLAIM_ID'])
    formData.append('accountCode', tenant)
    formData.append('file', file)
    return formData
  }

  getPicRespData(fileName, claimNo, uploaded, sizeLimitExceeded?){
    let tempData = {fileName, claimNo, uploaded, sizeLimitExceeded}
    return tempData
  }

  errorCase(fileName, claimNo){
    let tempData = this.getPicRespData(fileName, claimNo, false, false)
    return observableThrowError(tempData)
  }

}
