<div [formGroup]="roofForm">
    <div class="form-group row">

        <div class="col-md-4 offset-md-4">
            <mat-form-field style="width: 100%">
                <input matInput formControlName="roofYear" type="text" placeholder="Year Roof Built/Replaced *" numbersOnly maxlength="4"/>
            </mat-form-field>
            <p class="error-class mat-angular-error-text" *ngIf="roofForm.get('roofYear').errors?.required && isSubmitted">
                {{'REQUIRED_FIELD' | translate: {value:'Year Roof Built/Replaced'} }}</p>
            <p class="error-class mat-angular-error-text" *ngIf="roofForm.get('roofYear').errors?.min && isSubmitted">Year Roof Built/Replaced should be greater than 1969</p>
            <p class="error-class mat-angular-error-text" *ngIf="roofForm.get('roofYear').errors?.max && isSubmitted">Year Roof Built/Replaced should be less than current year</p>

        </div>
    </div>
    <div class="form-group row">
        <div class="col-md-4 offset-md-4">
            <!--   Made it center aligned, If left alignment is desired include style="float: left;" -->
            <mat-label class="custom-title-info account-title" >Roof Material *</mat-label>
            <ng-select id="roofMaterials_6" [items]="roofMaterials" appendTo="body" [hideSelected]="true"
                placeholder="Roof Material" formControlName="roofMaterial">
            </ng-select>
            <p class="error-class" *ngIf="!roofForm.get('roofMaterial').valid && isSubmitted">
                Roof Material is required</p>
        </div>

    </div>
</div>