import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class DataTransferService {
    private data = new Subject<any>();
    recievedObj = this.data.asObservable();

    sendData(dataObj: any) {
        this.data.next(dataObj);
    }
}
