<div class="modal-header">
    <h4 class="modal-title pull-left">{{title}}</h4>
    <button type="button" class="btn-close close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
        <span aria-hidden="true" class="visually-hidden">&times;</span>
    </button>
</div>
<div class="modal-body modal-body-spacer">
    <div class="col-12 image-main-container" *ngIf="displayImgs?.length; else noPicsContainer">
        <h4 class="text-center" [ngStyle]="styling?.formTitle">Images Available:</h4>
        <div class="row el-element-overlay">
            <!-- <ng-container *ngIf="displayImgs?.length"> -->
                <div class="col-sm-4" *ngFor="let image of displayImgs; let i = index">
                    <div class="el-card-item">
                        <div class="p-10">
                            <div class="el-card-avatar el-overlay-1">
                                <span class="fas fa-times-circle close-btn-img" (click)="removeImage(image, i)">
                                    <img *ngIf="image?.result" [src]="image?.result" class="d-flex p-10" alt="default">
                                </span>
                            </div>
                            <div class="el-card-content">
                                <h5 class="">{{image?.filename}}</h5>
                            </div>
                        </div>
                    </div>
                </div>
            <!-- </ng-container> -->
        </div>
    </div>
    <ng-template #noPicsContainer>
        <div class="card-body">
            <div class="text-center" [ngStyle]="styling?.paragraphText">
                No Pictures or video Attached
            </div>
        </div>
    </ng-template>
    
</div>
<div class="modal-footer" style="justify-content: space-between;">

    <!-- <button type="button" class="btn btn-info buttons">
    <label for="claim-take-pic" class="custom-camera-upload">
            <i class="fas fa-camera m-r-10"></i>Camera
        </label>
    </button>  -->

    <label for="claim-take-pic" class="file-uploader-btn">
        <i class="fas fa-camera m-r-10"></i>Camera
        <input 
        id="claim-take-pic" 
        style="display: none;" #cameraUploader
        accept="image/*;capture=camera" 
        (change)="captureImg($event)" type="file">
    </label>

    <!-- <button type="button" class="btn btn-info buttons" (click)="captureImg($event)">
        <i class="fas fa-camera m-r-10"></i>Camera
    </button> -->
    <button type="button" class="btn btn-info buttons" (click)="uploadDocs()">
        <i class="fas fa-upload m-r-10"></i>Upload
    </button>
</div>