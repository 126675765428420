import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { AuthGuardMaintenance } from '../../shared/services/auth-guard.service';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from '../../shared/services/auth.service';
import { map } from 'rxjs/internal/operators/map';

@Component({
  selector: 'app-maintenance-view',
  templateUrl: './maintenance-view.component.html',
  styleUrls: ['./maintenance-view.component.scss']
})
export class MaintenanceViewComponent implements OnInit {
  maintenancePageInfo: any;
  maintenanceModeInfo: any;
  constructor(private sanitizer: DomSanitizer,
    private authGuardService: AuthGuardMaintenance,
    private router: Router,
    private authService: AuthService,
    private route: ActivatedRoute,) { }

  ngOnInit(): void {
    this.maintenanceModeInfo = sessionStorage.getItem('maintenanceMode')? JSON.parse(sessionStorage.getItem('maintenanceMode')) : null;;
    if (this.maintenanceModeInfo != null && this.maintenanceModeInfo.maintenancePage != null) {
      this.maintenancePageInfo = this.sanitizer.bypassSecurityTrustHtml(this.maintenanceModeInfo.maintenancePage);
    }
    this.getStatus();
  }

  getStatus() {
    if (this.authGuardService.canActivate()) {
      const tenant = this.route.snapshot.params.tenant
      console.log("finish the maintenance")
      this.router.navigate([`${tenant}`]);
        
    }
  }

}
