<div class="card h-100">
    <div class="card-body body-mobile">
        <h2 class="custom-title text-center p-b-10 header-two-l" [ngStyle]="styling?.pageTitle" style="padding-bottom:10px;">{{'MY_ACCOUNT_PROFILE' | translate}}</h2>
        <div class="row form-group">
            <div class="col-lg-12 text-right" style="padding-bottom: 5px;">
                <button class="btn btn-info custom-button-info buttons col-md-2" (click)="navToLink('home/my-account-edit')"
                [ngStyle]="styling?.button?.buttonNavigation">{{'EDIT' | translate}}</button>
            </div>
        </div>
        <div style="text-align: justify;">
        <div class="row form-group" >
            <div class="col-lg-6  p-b-5">
                <div class="custom-title-info account-title" [ngStyle]="styling?.attributeKey">{{'FIRST_NAME' | translate}}</div>
                <div class="custom-title-infoDetail account-description" [ngStyle]="styling?.attributeValue">{{accountDetails?.firstName}}</div>
            </div>
            <div class="col-lg-6">
                <div class="custom-title-info account-title" [ngStyle]="styling?.attributeKey">{{'LAST_NAME' | translate}}</div>
                <div class="custom-title-infoDetail account-description" [ngStyle]="styling?.attributeValue">{{accountDetails?.lastName}}</div>
            </div>
        </div>
        <div class="row form-group">
            <div class="col-lg-6  p-b-5">
                <div class="custom-title-info account-title" [ngStyle]="styling?.attributeKey">{{'USERNAME' | translate}}</div>
                <div class="custom-title-infoDetail account-description" [ngStyle]="styling?.attributeValue">{{accountDetails?.userName }}</div>
            </div>
            <div class="col-lg-6">
                <div class="custom-title-info account-title" [ngStyle]="styling?.attributeKey">{{'PASSWORD_LAST_CHANGED' | translate}}</div>
                <div class="custom-title-infoDetail account-description" [ngStyle]="styling?.attributeValue">{{accountDetails?.passwordLastChangedDate}}</div>
            </div>
        </div>
        <div class="row form-group">
            <div class="col-lg-6  p-b-5">
                <div class="custom-title-info account-title" [ngStyle]="styling?.attributeKey">{{'EMAIL_ADDRESS' | translate}}</div>
                <div class="custom-title-infoDetail account-description" [ngStyle]="styling?.attributeValue">{{accountDetails?.email}}</div>
            </div>
        </div>
        <div class="row form-group">
            <div div class="col-lg-12 text-right" style="padding-bottom: 5px;">
                <!-- <button class="col-md-3 btn btn-info custom-button-info buttons" (click)="closeAcntModal()"
                [ngStyle]="styling?.button?.buttonNegative">{{'CLOSE_ACCOUNT' | translate}}</button> -->
            </div>
        </div>
    </div>
        <hr style="height:5px;border-width:0;color:gray;background-color:gray;margin-top:10px;margin-bottom:20px;">
        <h4 class="custom-title text-center p-b-10 header-four-l" [ngStyle]="styling?.pageSubTitle">{{'MY_POLICIES' | translate}}</h4>
        <div class="row form-group">
            <div class="col-lg-12 text-right" style="padding-bottom: 5px;" *ngIf="displayButton.addPolicy">
                <button class="col-md-3 btn btn-info custom-button-info buttons" (click)="navToLink('home/add-policy')"
                [ngStyle]="styling?.button?.buttonNavigation">{{'ADD_POLICY' | translate}}</button>
            </div>
        </div>
        <div class="p-t-20">
            <app-custom-table [tableDataObj]="policyDataToTable" [customTableActions]="customTableActions" (actionEventTable)="actionEventFromTable($event)"></app-custom-table>
            <!-- <div class="table-responsive">
                <table class="table table-striped">
                    <thead class="custom-description" style="background-color: lightgray;">
                        <tr>
                        <th style="width: 200px; text-align:center;" [ngStyle]="styling?.tableHeader" >{{'POLICY_NUMBER' | translate}}</th>
                        <th style="width: 500px; text-align:left;" [ngStyle]="styling?.tableHeader">{{'ADDRESS' | translate}}</th>
                        <th style="width: 200px; text-align:center;" [ngStyle]="styling?.tableHeader">{{'PAPERLESS_ENROLLED' | translate}}</th>
                        <th style="width: 100px; text-align:center;" [ngStyle]="styling?.tableHeader">Actions</th>
                        </tr>
                    </thead>
                    <tbody class="custom-description">
                        <ng-container *ngIf="policyObj !== null; else alternateContent">
                            <ng-container *ngFor="let policy of policyObj">
                                <tr *ngIf="policy.policyNumber !== null" style="font-size: 15px;" [ngStyle]="styling?.tableContent">
                                <td class="text-nowrap custom-link text-center">
                                    <a class="view-policy-button" 
                                    (click)="onClick(policy?.policyDetails?.policyNumber)"
                                    appendToBody=true>
                                    {{policy.policyDetails?.policyNumber}}
                                    </a>
                                </td>
                                <td class="text-nowrap hideContent">
                                    {{policy?.policyDetails?.riskAddress?.addressLine1}}, 
                                    {{policy?.policyDetails?.riskAddress?.city}}, 
                                    {{policy?.policyDetails?.riskAddress?.stateCode}} {{policy?.policyDetails?.riskAddress?.zipCode}}</td>
                                <td class="text-nowrap hideContent text-center">No</td>
                                <td class="text-nowrap hideContent text-center">
                                    <a *ngIf="policyObj?.length > 1"
                                        class="fas fa-trash" 
                                        style="cursor: pointer;" 
                                        popover="Remove Policy" container="body" triggers="mouseenter:mouseleave" (click)="showConfirmModal(policy?.policyDetails)"></a>
                                    <a *ngIf="policyObj?.length === 1"
                                        style="color: grey; cursor: not-allowed;"
                                        class="fas fa-trash"></a>
                                    </td>
                                
                                </tr>
                            </ng-container>
                        </ng-container>
                        <ng-template #alternateContent>
                            <tr>
                            <td colspan="2" style="text-align: center;font-size: 15px;">
                                No policy added</td>
                            </tr>
                        </ng-template>
                    </tbody>
                </table>
            </div> -->
        </div>
    </div>
</div>