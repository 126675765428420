<div class="row" [formGroup]="isRentForm">
    <div class="col-lg-6 p-b-20">
        <div class="w-100 d-flex justify-content-center">
            <mat-card class="card-selection text-center radio-selector-wrapper"
            (click)="onClick('own')">
                <label class="p-2" for="own">
                    <p class="radio-selector-title">Own</p>
    
                    <input type="radio" value="own" formControlName="checkRental" id="own" class="radio-col-light-blue" />
                    <img class="radio-image-selector p-2" src="assets/multiTenantImages/png/quote_own_house.svg">
                </label>
            </mat-card>
        </div>
    </div>
    <div class="col-lg-6">
        <div class="w-100 d-flex justify-content-center">
            <mat-card class="card-selection text-center radio-selector-wrapper"
            (click)="onClick('rent')">
                <label class="p-2" for="rent">
                    <p class="radio-selector-title">Rent</p>
    
                    <input type="radio" value="rent" formControlName="checkRental" id="rent" class="radio-col-light-blue" />
                <img class="radio-image-selector p-2" src="assets/multiTenantImages/png/quote_rent_house.svg">
                </label>
            </mat-card>
        </div>
    </div>
</div>