import { Directive, OnInit, OnDestroy, Input, ElementRef, Renderer2 } from "@angular/core";
import { Subscription } from 'rxjs';
import { NgControl } from '@angular/forms';

@Directive({
    selector: '[phoneDisplay]'
})

export class PhoneDisplayDirective implements OnInit, OnDestroy{

    private _preValue: string;

    @Input()
    set preValue(value: string) {
        this._preValue = value
    }

    private sub: Subscription;
    constructor(
        private el: ElementRef,
        private phoneForm: NgControl,
        private renderer: Renderer2
    ){}
    ngOnInit(){
        this.phoneValidator()
    }

    ngOnDestroy(){
        if(this.sub) this.sub.unsubscribe()
    }

    phoneValidator(){
        this.sub = this.phoneForm.control.valueChanges.subscribe(resp => {
            let initValue: string = this._preValue
            let lastChar: string = initValue.substr(initValue.length - 1)
            let newVal = resp.replace(/\D/g, '')

            let start = this.el.nativeElement.selectionStart
            let end = this.el.nativeElement.selectionEnd

            if(resp.length < initValue.length){
                if(initValue.length < start){
                    if(lastChar === ')'){
                        newVal = newVal.substr(0, newVal.length - 1)
                    }
                }
                if(newVal.length === 0){
                    newVal = '';
                } else if(newVal.length <= 3){
                    newVal = newVal.replace(/^(\d{0,3})/, '($1')
                } else if(newVal.length <= 6){
                    newVal = newVal.replace(/^(\d{0,3})(\d{0,3})/, '($1) $2')
                } else{
                    newVal = newVal.replace(/^(\d{0,3})(\d{0,3})(.*)/, '($1) $2-$3')
                }
                this.phoneForm.control.setValue(newVal, {emitEvent: false})
                this.renderer.selectRootElement(this.el).nativeElement.setSelectionRange(start, end)
            } else{
                let removedData = resp.charAt(start)
                if(newVal.length === 0){
                    newVal = ''
                } else if(newVal.length <= 3){
                    newVal = newVal.replace(/^(\d{0,3})/, '($1)')
                } else if(newVal.length <= 6){
                    newVal = newVal.replace(/^(\d{0,3})(\d{0,3})/, '($1) $2')
                } else{
                    newVal = newVal.replace(/^(\d{0,3})(\d{0,3})(.*)/, '($1) $2-$3')
                }

                if(removedData === '(' || removedData === '-' || removedData === ' ') {
                    start = start + 1
                    end = end + 1
                }
                if(removedData === ')') {
                    start = start + 2
                    end = end + 2
                }
                this.phoneForm.control.setValue(newVal, {emitEvent: false})
                this.renderer.selectRootElement(this.el).nativeElement.setSelectionRange(start+2, end+2)
            }

        })
    }
}