import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { UnderConstructionComponent } from './under-construction/under-construction.component';
import { SiteNotFoundComponent } from '../shared/site-not-found/site-not-found.component';
import { PublicComponent } from './public.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { ForgotUsernameComponent } from './forgot-username/forgot-username.component';
import { SignUpComponent } from './sign-up/sign-up.component';
import { GetAQuoteComponent } from './get-a-quote/get-a-quote.component';
import { DemoGetAQuoteComponent } from './demo-get-aquote/demo-get-aquote.component';
import { GetQuoteComponent } from './get-quote/get-quote.component';
import { PermissionDeniedComponent } from '../shared/permission-denied/permission-denied.component';
import { FindAgentsInGmapComponent } from './get-quote/find-agents-in-gmap/find-agents-in-gmap.component';
import { MaintenanceViewComponent } from './maintenance-view/maintenance-view.component';
import { AuthGuardMaintenance } from '../shared/services/auth-guard.service';
import { CommonApiService } from '../shared/services/common-api.service';
import { AuthService } from '../shared/services/auth.service';
const permissionKeys = require("../shared/services/permission-keys.json")

const routes: Routes = [
   // {path: 'maintenance', component: MaintenanceViewComponent},
    {path: 'site-not-found', component: SiteNotFoundComponent},
    {path:':tenant', redirectTo: ':tenant/login', pathMatch: 'full',canActivate: [AuthGuardMaintenance]},
    {
        path:':tenant',
        component: PublicComponent,
        canActivate: [AuthGuardMaintenance],
        children:[
            { path: 'login', component: LoginComponent },
            { path: 'sign-up', component: SignUpComponent },
            { path: 'forgot-password', component: ForgotPasswordComponent },
            { path: 'forgot-username', component: ForgotUsernameComponent },
            // { path: 'get-a-quote', component: GetAQuoteComponent},
            // { path: 'demo-get-a-quote', component: DemoGetAQuoteComponent},
            // { path: 'get-quote',component:GetQuoteComponent, 
            //     data: {
            //         key: [permissionKeys.GET_A_QUOTE]
            //     }
            // },
            {path: 'find-agents', component: FindAgentsInGmapComponent },
            {path: 'maintenance', component: MaintenanceViewComponent}
        ]
    },
    {
        path:':tenant',
        children:[
            { path: 'under-construction', component: UnderConstructionComponent },
            {path: 'permission-denied', component: PermissionDeniedComponent}
        ]
    },
    {
        path:':tenant',
        children:[
            {path: 'maintenance', component: MaintenanceViewComponent}
        ]
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {useHash: true, scrollPositionRestoration: 'top'})],
    exports: [RouterModule],
    providers: [ CommonApiService, AuthService, AuthGuardMaintenance],
})
export class PublicRoutingModule { }
