import { Injectable } from '@angular/core';
import { ActivatedRoute, CanActivate, Router,  } from '@angular/router';
import { AuthService } from './auth.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { UtilsService } from './utils.service';
import { DatePipe } from '@angular/common';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ConfirmModalComponent } from '../confirm-modal/confirm-modal.component';

@Injectable()
export class AuthGuardMaintenanceLogout implements CanActivate {

  constructor(
    private authService: AuthService, private router: Router,private utilsService: UtilsService, private datepipe: DatePipe, private modalService: BsModalService, private route: ActivatedRoute,
  ) {}

  canActivate(): Observable<boolean> | Promise<boolean> | boolean {
    const data = JSON.parse(sessionStorage.getItem('maintenanceMode'));
    if (data != null && data.control != null && data.control) {
      return this.authService.inMaintenance().pipe(map((resp: string) => {
        let tenant = sessionStorage.getItem("maintenanceModeTenant")
          if (resp === "maintenance") {
              const modal = this.modalService.show(ConfirmModalComponent, {backdrop: 'static'});
              (<ConfirmModalComponent>modal.content).showConfirmationModalOneSelection('<b>Maintenance Mode</b>', `This site is under maintenance. We’ll be back up again soon!`, `Log Out`);
              (<ConfirmModalComponent>modal.content).onClose.subscribe(resp => {
                this.router.navigate([`${tenant}/maintenance`]);
              })
              return false;
            } 
            return true;
      }))
    }
    return true;

  }
}