import { Component, OnInit,Input } from '@angular/core';
import { UtilsService } from '../../../shared/services/utils.service';

@Component({
  selector: 'app-message-card',
  templateUrl: './message-card.component.html',
  styleUrls: ['./message-card.component.scss']
})
export class MessageCardComponent implements OnInit {
  styling = this.utilsService.getItemfromSession('themeObject');
  progressNumber = 0
  interval: any;
  displayMessage = ''
  @Input() message: any
  @Input() loading: any
  @Input() indexStepper: any
  @Input() custName: any
  @Input() custMail: any
  
  constructor(
    private utilsService: UtilsService
  ) {
    
   }

  ngOnInit(): void {
  }

  ngOnChanges(changes: any){
    if (changes.hasOwnProperty('loading')) {
      if (changes.loading.currentValue) {
        if(this.loading == true && this.indexStepper == 10)
        {
          this.progressNumber = 0
          this.fakeLoader()
          this.displayMessage = 'Please give me a moment while I find the best policy coverages and a price for you'
        }
        else if(this.loading == true && this.indexStepper == 12){
          this.progressNumber = 0
          this.fakeLoader()
          this.displayMessage = 'Lets get an updated price for you'
        }
      }
    }
    if(changes.hasOwnProperty('message') && changes.message.currentValue){
      this.assignMessage()
    } if(changes.hasOwnProperty('indexStepper') && changes.indexStepper.currentValue){
      this.assignMessage()
    }
    // console.log(changes.indexStepper)
    
  }

  assignMessage(){
    if(this.message){
      if(this.indexStepper == 0) this.displayMessage = this.message.user_info
      else if(this.indexStepper == 1) this.displayMessage = this.message.property_address.replace('${customer_name}', this.custName)
      else if(this.indexStepper == 2) this.displayMessage = this.message.policy_start_date
      else if(this.indexStepper == 3) this.displayMessage = this.message.rent_or_own
      else if(this.indexStepper == 4) this.displayMessage = this.message.house_or_condo
      else if(this.indexStepper == 5) this.displayMessage = this.message.property_usage.replace('${customer_name}', this.custName)
      else if(this.indexStepper == 6) this.displayMessage = this.message.roof_type.replace('${customer_name}', this.custName)
      else if(this.indexStepper == 7) this.displayMessage = this.message.roof_built
      else if(this.indexStepper == 8) this.displayMessage = this.message.year_built.replace('${customer_name}', this.custName)
      else if(this.indexStepper == 9) this.displayMessage = this.message.building_materials
      else if(this.indexStepper == 10) this.displayMessage = this.message.property_finish
      else if(this.indexStepper == 11) this.displayMessage = this.message.premium_decision.replace('${customer_name}', this.custName)
      else if(this.indexStepper == 12) this.displayMessage = this.message.quote_refinement
      else if(this.indexStepper == 13) this.displayMessage = this.message.send_quote_email
      else if(this.indexStepper == 14) this.displayMessage = this.message.quote_sent_closing.replace('${customer_email}', this.custMail)
    }
  }

  fakeLoader(){
    this.progressNumber = 0
    this.startTimer()
  }

  startTimer(){
    this.interval = window.setInterval(()=> {
      if(this.progressNumber<98) this.progressNumber++
      else if(!this.loading) this.stopTimer(100)
      else this.stopTimer(100)
    }, 200)
  }
  
  stopTimer(time){
    window.clearInterval(this.interval)
    window.clearTimeout(this.interval)
  }

}
