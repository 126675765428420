import { Component, OnInit } from '@angular/core';
import { DasboardService } from '../dashboard/dasboard.service';
import { CommonApiService } from '../../shared/services/common-api.service';
import { Router, ActivatedRoute } from '@angular/router';
import { UtilsService } from '../../shared/services/utils.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ConfirmModalComponent } from '../../shared/confirm-modal/confirm-modal.component';
import { NotifytoastService } from '../../shared/services/notificationToast.service';
import { map } from 'rxjs/operators';
import { DatePipe } from '@angular/common';
import { environment } from '../../../environments/environment';
import { PrivateDataShare } from '../../shared/services/privateDataShare.service';

const permissionKeys = require("../../shared/services/permission-keys.json");

@Component({
  selector: 'app-my-account-view',
  templateUrl: './my-account-view.component.html',
  styleUrls: ['./my-account-view.component.scss'],
  providers: [DasboardService, CommonApiService]
})
export class MyAccountViewComponent implements OnInit {
  keysAvailable = this.utilsService.getItemfromSession('permissionKeys') ? this.utilsService.getItemfromSession('permissionKeys') : []
  accountDetails: any
  policyObj = [];
  listAddress = [];
  styling = this.utilsService.getItemfromSession('themeObject')
  displayButton: { addPolicy: any }
  policyDataToTable: any;
  paperlessEnrolled: any;
  customTableActions = {
    links: ['POLICY_NUMBER'],
    callback: ['POLICY_NUMBER'],
    actions: ['Delete'],
    alignment: {
      POLICY_NUMBER: 'text-center',
      ADDRESS: 'text-left',
      PAPERLESS_ENROLLED: 'text-center'
    }
  }
  constructor(
    private dashboardService: DasboardService,
    private router: Router,
    private route: ActivatedRoute,
    private utilsService: UtilsService,
    private modalService: BsModalService,
    private notifyToast: NotifytoastService,
    private datePipe: DatePipe,
    private privateDataShare: PrivateDataShare
  ) { }

  ngOnInit(): void {
    this.displayButton = {
      addPolicy: this.keysAvailable.includes(permissionKeys.ADD_POLICY)
    }
    this.setPaperlessEnrolled()
    this.getProfileInfo()
    this.getAllPolicies()
  }

  setPaperlessEnrolled() {
    //SPE-2352 CP - Recoop UI - change hardcode Paperless Enrolled value
    this.paperlessEnrolled = this.privateDataShare.getDoucumentPreferenceMapping();
  }

  getProfileInfo() {
    this.dashboardService.getCustomerByID().subscribe(resp => {
      this.accountDetails = resp;
      if (this.accountDetails != null && this.accountDetails.passwordLastChangedDate != null) {
        this.accountDetails.passwordLastChangedDate = this.datePipe.transform(this.accountDetails.passwordLastChangedDate, "MM/dd/yyyy");
      }
    })
  }

  getAllPolicies() {
    if (sessionStorage.getItem('policyDetails')) {
      this.policyObj = JSON.parse(sessionStorage.getItem('policyDetails')).PolicySummary;
      // this.listAddress =  this.policyObj.map(resp => resp.policyDetails.riskAddress.addressLine1 + ', ' +  resp.policyDetails.riskAddress.city + ', ' +  resp.policyDetails.riskAddress.stateCode)
      this.objectStructurize()
    } else {
      return null
    }
  }

  objectStructurize() {

    this.policyDataToTable = this.policyObj.map(resp => {
      let temp = {
        POLICY_NUMBER: resp.policyDetails.policyNumber,
        ADDRESS: resp.policyDetails.riskAddress.addressLine1 + ', ' + resp.policyDetails.riskAddress.city + ', ' + resp.policyDetails.riskAddress.stateCode + ', ' + resp.policyDetails.riskAddress.zipCode,
        PAPERLESS_ENROLLED: this.paperlessEnrolled[resp.policyDetails.documentPreference]
      }
      return temp
    })
    // this.policyDataToTable = environment.mastermeaow
  }

  actionEventFromTable(dataObj: { actionType: any, rowData: any }) {
    if (dataObj.actionType == 'delete' && this.policyObj.length > 1) {
      let pin = dataObj.rowData.ADDRESS
      pin = pin.substring(pin.lastIndexOf(',') + 2)
      const data = {
        policyNumber: dataObj.rowData.POLICY_NUMBER,
        zipCode: pin
      }
      this.showConfirmModal(data, true)
    } else if (dataObj.actionType == 'link') {
      this.onClick(dataObj.rowData.POLICY_NUMBER)
    }
  }

  navToLink(url) {
    const tenant = this.route.parent.snapshot.params.tenant
    this.router.navigate([`${tenant}/${url}`])
  }

  onClick(policyNumber) {
    this.privateDataShare.setPolicyNumber(policyNumber)
    // let index = this.policyObj.findIndex(resp => resp.policyDetails.policyNumber === policyNumber)
    // sessionStorage.setItem('CurPolicySelection', JSON.stringify(index))
    this.navToLink(`home/view-policies`)
  }

  showConfirmModal(policy, fromTable?) {
    let data: any
    if (fromTable) {
      data = policy
    } else {
      data = { policyNumber: policy.policyNumber, zipCode: policy.riskAddress.zipCode }
    }
    const errorText = 'If you remove a policy from your user account, you will not have the ability to view policy details, make payments, or report claims for that policy using this account. Removing a policy from user account does not impact the status of your policy.'
    const modal = this.modalService.show(ConfirmModalComponent, { backdrop: 'static' });
    (<ConfirmModalComponent>modal.content).showConfirmationModal(`<b>Remove Policy ${data.policyNumber} ?</b>`, errorText);
    (<ConfirmModalComponent>modal.content).onClose.subscribe(resp => {
      if (resp === true) {
        this.dashboardService.removePolicy(data).subscribe(resp => {
          console.log("remove policy return result: " + JSON.stringify(resp));
          if (resp != null && resp.statusCode === 200) {
            this.getPoliciesOfUser().subscribe(
              (getPoliciesResp) => {
                if (getPoliciesResp === undefined) {
                  console.log('No response to update all the policies after removing the policy.');
                  this.notifyToast.error('Policy update failed.')
                } else {
                  // this.privateDataShare.setPolicyDetails()
                  this.notifyToast.success('Successfully updated.')
                }
              });
          }
        })
      }
    })
  }

  getPoliciesOfUser() {
    var preSelectedPolicy = this.utilsService.getPolicyNumber();
    return this.dashboardService.getPoliciesByUser().pipe(map((resp => {
      // console.log("update policies response: " + JSON.stringify(resp));
      if (resp != null) {
        this.privateDataShare.setPolicyDetails(resp)
        sessionStorage.setItem('policyDetails', JSON.stringify(resp))
        this.policyObj = JSON.parse(sessionStorage.getItem('policyDetails')).PolicySummary;
        this.objectStructurize()
        // this.checkNumberOfPolicies()
        for (let index in this.policyObj) {
          if (this.policyObj[index].policyDetails.policyNumber == preSelectedPolicy) {
            sessionStorage.setItem('CurPolicySelection', index)
          }
        }
        return true;
      }
    })))
  }

  checkNumberOfPolicies() {
    if (this.policyObj.length > 1) {
      this.customTableActions.actions = ['Delete']
    } else {
      delete this.customTableActions.actions
    }
    this.customTableActions = Object.assign({}, this.customTableActions)
  }

  closeAcntModal() {
    const content = `<p>If you close your user account, you will no longer have the ability to view policy details, make payments, or report claims using this account.
    Closing your user account does not impact the status of your policy.</p>
    <p>After this user account is closed, the policy(ies) that were associated may be added to any new or existing user account. (To add policies to
      an existing account, go to "My Account", click "Add Policy" and enter your information.)</p>
    <strong class="warning-text">You are about to close your user account. Do you want to proceed?</strong>
    `
    const modal = this.modalService.show(ConfirmModalComponent, { backdrop: 'static' });
    (<ConfirmModalComponent>modal.content).showConfirmationModal('<b>Close User Account?</b>', content, 'Cancel', 'Confirm');
    (<ConfirmModalComponent>modal.content).onClose.subscribe(resp => {
      if (resp === true) {
        this.closeAccount()
      }
    })
  }

  closeAccount() {
    this.dashboardService.closeAccount().subscribe(resp => {
      this.navLogin()
    }, error => {
      this.notifyToast.error('Please contact the System Administrator.')
    })
  }

  navLogin() {
    const tenant = this.route.parent.snapshot.params.tenant
    sessionStorage.clear();
    this.router.navigate([`${tenant}/login`]).then(() => { window.location.reload() })
  }
}
