<div class="card thick-border find-agent-card-margin" style="font-family: Helvetica;margin: 20px 0 0 0;">
    <div class="text-left">
        <div class="row">
            <div class="col-md-4 user-actions-order agentbox-padding">
                <form class= "agent-search-border" [formGroup]="searchAgentForm" (ngSubmit)="searchAgents()">
                    <div class="col-md-12 p-t-10 bg-info text-white thick-bottom-border" *ngIf="agentData?.length" style="text-align:center;font-weight:bold;">We found
                        {{agentData?.length}} agents in your area!</div>
                    <div class="col-md-12" style="padding: 10px 20px 0 10px;">
                        <div class="row">
                            <div class="col-md-4">
                                <mat-form-field style="width: 100%">
                                    <input matInput numbersOnly maxlength="6" formControlName="zipCode" type="text"
                                        placeholder="Zip Code" />
                                </mat-form-field>
                            </div>
                            <div class="col-md-4">
                                <mat-form-field style="width: 100%">
                                    <input matInput numbersOnly maxlength="6" formControlName="searchRadius" type="text"
                                        placeholder="Search Radius" />
                                </mat-form-field>
                            </div>
                            <div class="col-md-4">
                                <button type="submit" class="btn btn-info btn-block"
                                    [disabled]="isDisabled" [ngStyle]="styling?.button?.buttonNext">Search</button>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-12" style="padding-bottom: 10px;">
                        <div class="row">
                            <div class="col-mob-6" style="padding-left: 15px;">
                                <a (click)="setMyLocation()" style="cursor: pointer;">
                                    <i class="fab fa-periscope" style="color: #1e88e5;"></i>
                                    <span class="hide-menu ml-2" style="color: #1e88e5;">Use my location</span>
                                </a>
                                <!-- <button class="btn btn-info" type="button" (click)="setMyLocation()" style="margin-bottom:10px;">Use my location</button> -->
                            </div>
                            <div class="col-mob-6">
                                <a (click)="resetLocation()" style="cursor: pointer;">
                                    <i class="fab fa-periscope" style="color: #1e88e5;"></i>
                                    <span class="hide-menu ml-2" style="color: #1e88e5;">Reset location</span>
                                </a>
                                <!-- <button class="btn btn-info" type="button" (click)="resetLocation()">Reset location</button> -->
                            </div>
                        </div>
                    </div>
                </form>
                <div class="agent-info-container">
                    <div class="card thick-border" [id]="'Location_'+agent?.agencyID" *ngFor="let agent of agentData" (click)="openWindow(agent?.agencyID)"
                        [ngClass]="{'highlighted-card': selectedId === agent?.agencyID}" style="border-radius: 20px;">
                        <div class="card-body" style="padding: 12px">
                            <div><span class="font-weight-bold">{{agent?.agentFirstName}} {{agent?.agentLastName}}</span>
                            </div>
                            <div class="address-font-card">{{agent?.agencyName}}</div>
                            <div class="address-font-card">{{agent?.agencyLocationAddress?.street}}, {{agent?.agencyLocationAddress?.city}},
                                {{agent?.agencyLocationAddress?.stateCode}}, {{agent?.agencyLocationAddress?.zip}} <a (click)="navigateToGmap(agent)" style="cursor: pointer;" class="fas fa-globe-americas"></a></div>
                            <div class="address-font-card"><a
                                    href="tel:{{agent?.agentPhoneNumbers?.PhoneNumber[0]?.number}}">{{utilsService.formatNumber(agent?.agentPhoneNumbers?.PhoneNumber[0]?.number)}}</a>
                            </div>
                            <div class="p-t-10">
                                <button class="btn btn-info btn-block address-font-card" type="button"
                                    (click)="sendQuote(agent)" [ngStyle]="styling?.button?.buttonNext">Send
                                    {{agent?.agentFirstName}} your Quote</button>
                            </div>

                        </div>
                        <!-- {{agentData | json}} -->
                    </div>
                </div>
            </div>
            <br />

            <div class="col-md-8 login-box-order thick-left-border"  *ngIf="agentData?.length; else noContent">
                <div class="button-hover-in-map" [ngStyle]="styling?.button?.buttonNext"><button class="btn btn-info m-r-20" (click)="findAgentsFromMap(true)"
                        [disabled]="isDisabled" [ngStyle]="styling?.button?.buttonNext">Search this location</button></div>
                <agm-map (centerChange)="centerPositionChanged($event)" *ngIf="gotRiskAddress"
                    [latitude]="riskAddress.lat" [longitude]="riskAddress.lng" [fitBounds]="true">
                    <agm-marker *ngFor="let position of agentData" [latitude]="position.latFromGoogle"
                        [longitude]="position.lngFromGoogle" [agmFitBounds]="true"
                        (markerClick)="openWindow(position.agencyID)">
                        <agm-info-window [isOpen]="windowOpenCheck(position.agencyID)"
                            [latitude]="position.latFromGoogle" [longitude]="position.lngFromGoogle"
                            (infoWindowClose)="closeInfo()">{{position?.agencyName}}</agm-info-window>
                    </agm-marker>
                    <agm-marker [latitude]="riskAddress.lat" [longitude]="riskAddress.lng" [agmFitBounds]="true"
                        [iconUrl]="urlProps">
                        <agm-info-window [isOpen]="true" [latitude]="riskAddress.lat" [longitude]="riskAddress.lng">My
                            Home Address: {{riskAddress?.zipCode}}</agm-info-window>
                    </agm-marker>
                    <agm-marker *ngIf="showLocation && myLocation" [latitude]="myLocation.lat" [longitude]="myLocation.lng"
                        [agmFitBounds]="true" [iconUrl]="myUrlProps">
                        <agm-info-window [isOpen]="true" [latitude]="myLocation.lat"
                            [longitude]="myLocation.lng">My Current Location</agm-info-window>
                    </agm-marker>
                </agm-map>
            </div>
            <ng-template #noContent>
                <div class="col-md-8 no-agent-found-body">
                    <span style="font-size: 60px;">No Agents Available</span>
                </div>
            </ng-template>
        </div>
    </div>
</div>