import { Component, OnInit, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-email-quote',
  templateUrl: './email-quote.component.html',
  styleUrls: ['./email-quote.component.scss']
})
export class EmailQuoteComponent implements OnInit {
  @Input() emailQuoteForm: FormGroup;
  emailError: any;
  constructor() { }

  ngOnInit(): void {
  }
  keyEmail(){
    const temp = this.emailQuoteForm.get('email')
    if(temp.errors){
      // if (temp.errors.required) {
      //   this.emailError =  'Email is required'
      // } else 
      if (temp.errors.invalidEmailAddress) {
        this.emailError =  'Email is not valid.'
      }
    }
  }

}
