<div class="card custom-card-border h-100" [ngStyle]="styling?.card?.bodyStyling">
    <div class="card-body">
        <div style="display: flex; justify-content: space-between;">
            <h4 class="card-title header-font custom-title header-four-l" [ngStyle]="styling?.cardTitle">
                Most Recent Claim
            </h4>
            <button class="btn btn-info custom-button-info mini-button buttons"
                (click)="navToLink('home/my-claims/new')"
                [ngStyle]="styling?.button?.buttonNavigation"
                >{{'NEW_CLAIM' | translate}}</button>
        </div>
        <div class="row p-t-20">
            <div class="col-md-12" *ngIf="recentClaimInfo && recentClaimInfoObj?.recentClaim; else alternateContent">
                <p class="form-control-static custom-description p-b-5">
                    <span class="account-description" [ngStyle]="styling?.attributeKey">
                        {{'CLAIM_NUMBER' | translate}}: 
                    </span>
                    <span class="custom-link account-title hyperlinks" (click)="navToLink('home/my-claims/list')" [ngStyle]="styling?.hyperlink">{{recentClaimInfoObj?.recentClaim.claimNumber}} </span>
                </p>
                <p class="form-control-static custom-description p-b-5">
                    <span class="account-description" [ngStyle]="styling?.attributeKey">
                        {{'DATE_OF_LOSS' | translate}}: 
                    </span>
                    <span class="account-title" [ngStyle]="styling?.attributeValue">{{recentClaimInfoObj?.recentClaim.dateOfLoss}} </span>
                </p>
                <p class="form-control-static custom-description p-b-5">
                    <span class="account-description" [ngStyle]="styling?.attributeKey">
                        {{'CLAIM_STATUS' | translate}}: 
                    </span>
                    <span class="account-title" [ngStyle]="styling?.attributeValue">{{recentClaimInfoObj?.recentClaim.claimStatus}} </span>
                </p>
            </div>
            <ng-template #alternateContent>
                <div class="card-body">
                  <div class="text-center" style="padding: 28px;" [ngStyle]="styling?.paragraphText">{{'NO_OPEN_CLAIM_MSG' | translate}}</div>
                </div>
            </ng-template>
        </div>
        <div class="col-md-12">
            <div class="custom-link account-title text-center hyperlinks" (click) ="navToLink('home/my-claims/list')" [ngStyle]="styling?.hyperlink">
                {{'VIEW_CLAIMS' | translate}}
            </div>
        </div>
    </div>
</div>