import { Component, OnInit } from '@angular/core';
import { CommonApiService } from '../shared/services/common-api.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ThemeChangerService } from '../shared/services/themeChanger.service';
import { UtilsService } from '../shared/services/utils.service';
import { environment } from '../../environments/environment';
import { NotifytoastService } from '../shared/services/notificationToast.service';

@Component({
  selector: 'app-public',
  templateUrl: './public.component.html',
  styleUrls: ['./public.component.scss'],
  providers: [CommonApiService]
})
export class PublicComponent implements OnInit {
  pageName: any;
  bgImg = '';
  pageDetailsObj: any;
  isSfi = true;
  bannerObj: any;
  date = environment.buildDate
  version = environment.buildVersion
  headerImg : string = ''
  footer_content = {
    footer_left_message: '',
    footer_center_message: '',
    footer_right_message: ''
  }
  clickableLinks = {
    iconHeader: '',
    contactUs: ''
  }
  constructor(
    private http: CommonApiService,
    private route: ActivatedRoute,
    private themeService: ThemeChangerService,
    private router: Router,
    public utilsService: UtilsService,
    private notifyToast: NotifytoastService
    ) { }

    ngOnInit(): void {
    this.pageName = this.route.snapshot.params.tenant
    const hasToken = this.utilsService.getItemfromSession('token') ? JSON.parse(sessionStorage.getItem('token')).token : null
    if(hasToken){
      this.router.navigate([`${this.pageName}/home`])
    }
    if(this.pageName.indexOf('sfi') > -1) {
      this.isSfi = true
      this.bgImg = `url('/assets/multiTenantImages/png/SFILoginBackground.png')`
    } else{
      this.isSfi = false
    }
    this.mobileAppBanner()
    this.pageValid()
  }

  mobileAppBanner(){
    this.http.get(`mobileAppInstallation?accountCode=${this.pageName}`, null, true).subscribe(resp => {
      if(resp && resp.MobileAppInstallation) this.bannerObj = resp.MobileAppInstallation
    })
  }

  pageValid(){
    this.http.get(`accountConfiguration?accountCode=${this.pageName}`, null, true).subscribe(resp => {
      if(resp){
        let data = { ...resp.page_configuration }
        delete data.cardStyling
        this.utilsService.setTabTitleAndIcon(data)
        this.headerImg = data.company_logo
        sessionStorage.setItem('companybackground', JSON.stringify(data))
        let permissionKey = resp['AccessControl'] ? resp['AccessControl']: []
        sessionStorage.setItem('permissionKeys', JSON.stringify(permissionKey))
        if(resp.page_configuration.styling){
          sessionStorage.setItem('themeObject', JSON.stringify(resp.page_configuration.styling))
        }
        if(resp.content_management){
          this.footer_content ={
            footer_left_message: resp.content_management.footer_left_message,
            footer_center_message: resp.content_management.footer_center_message,
            footer_right_message: resp.content_management.footer_right_message
          }
        }
        let navUrl = resp.page_configuration.navigateUrl
        if(navUrl){
          this.clickableLinks = {
            contactUs: navUrl.contactUs,
            iconHeader: navUrl.headericon
          }
        }
        this.themeService.setDefaultTheme()
        if(this.pageName.indexOf('sfi') == -1)this.bgImg = `url(${resp.page_configuration.company_background})`
        this.pageDetailsObj = resp
      } else{
        this.router.navigate(['/site-not-found'])
      }
    }, error => {
      this.router.navigate(['/site-not-found'])
    })
  }

  navTo(isLanding){
    if(isLanding){
      window.open("https://www.securityfirstflorida.com/", '_blank')
    } else{
      window.open("https://www.securityfirstflorida.com/contact-us?_ga=2.244235973.1512143232.1604434318-1069710922.1600780991", '_blank')
    }
  }

  navToPage(page){
    if(page == 'privacy'){
      window.open("https://www.securityfirstflorida.com/privacy-policy", '_blank')
    } else if(page == 'terms'){
      window.open("https://www.securityfirstflorida.com/terms-use", '_blank')
    }
  }

  navigationPageForDefault(page){
    if(page == 'contact-us' && this.clickableLinks.contactUs){
      window.open(this.clickableLinks.contactUs, '_blank')
    } else if(page == 'header-icon' && this.clickableLinks.iconHeader){
      window.open(this.clickableLinks.iconHeader, '_blank')
    } else{
      this.notifyToast.error("Please Contact System Administrator")
    }
  }
}
