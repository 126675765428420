import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';
import { UtilsService } from '../services/utils.service';

@Component({
  selector: 'app-confirm-modal',
  templateUrl: './confirm-modal.component.html',
  styleUrls: ['./confirm-modal.component.scss']
})

export class ConfirmModalComponent implements OnInit {
  public active = false;
  public body: string;
  public title: string;
  public onClose: Subject<boolean>;
  public styling =this.utilsService.getItemfromSession('themeObject')
  rejectBtnTxt: string;
  acceptBtnTxt: string;
  public showRejectOption = true;
  public showBodyInBelow = false;
  constructor(private _bsModal: BsModalRef,
  private utilsService: UtilsService) { }

  ngOnInit() {
    this.onClose = new Subject()
  }

  public showConfirmationModal(title, body, rejectBtnTxt?, acceptBtnTxt?){
    this.showRejectOption = true;
    this.title = title
    this.body = body
    this.rejectBtnTxt = rejectBtnTxt ? rejectBtnTxt : 'No'
    this.acceptBtnTxt = acceptBtnTxt ? acceptBtnTxt : 'Yes'
    this.active = true
  }

  public showConfirmationModalOneSelection(title, body, acceptBtnTxt){
    this.showRejectOption = false;
    this.title = title
    this.body = body
    this.acceptBtnTxt = acceptBtnTxt ? acceptBtnTxt : 'Yes'
    this.active = true
  }

  public showConfirmationModalBodyInBelow(title, body, rejectBtnTxt?, acceptBtnTxt?){
    this.showBodyInBelow = true;
    this.showRejectOption = true;
    this.title = title
    this.body = body
    this.rejectBtnTxt = rejectBtnTxt ? rejectBtnTxt : 'No'
    this.acceptBtnTxt = acceptBtnTxt ? acceptBtnTxt : 'Yes'
    this.active = true
  }

  public onConfirm(){
    this.active = false
    this.onClose.next(true)
    this._bsModal.hide()
  }

  public onCancel(){
    this.active = false
    this.onClose.next(false)
    this._bsModal.hide()
  }

  public hideConfirmationModal(){
    this.active = false
    this.onClose.next(false)
    this._bsModal.hide()
  }

}
