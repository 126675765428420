import { Injectable } from '@angular/core';
import { environment } from '../../default/environments/environment';
import { UtilsService } from './shared/services/utils.service';


@Injectable({ 
    providedIn: 'root'
})

export class AppService {

    constructor(private utilsService: UtilsService){}

    googleAnalytics(){
		const locn = window.location;
		
		if(locn.origin !== 'http://localhost:4200')
		{
			let gtmId = this.getGTMId()
			// console.log("gtmid:", gtmId);
			if(gtmId) this.gAnalyticsScript(window,document,'script','dataLayer', gtmId);
		}
    }

    gAnalyticsScript(w,d,s,l,i){
        w[l]=w[l]||[];w[l].push({'gtm.start': new Date().getTime(),event:'gtm.js'});
        var f=d.getElementsByTagName(s)[0],
          
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';
        j.async=true;
        j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;
		// console.log("j.src:", j.src);
        f.parentNode.insertBefore(j,f);    
    }

    getGTMId(){
        let tenant = this.utilsService.getTenantFromWindow()
		// console.log("GTM tag to be used:", environment.gtmID[tenant]);
        if(environment.gtmID[tenant]) return environment.gtmID[tenant]
        else return null
    }
}