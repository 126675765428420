<header class="custom-topbar topbar" style="position: fixed; top: 0px; width: 100%;">
    <nav class="navbar top-navbar navbar-expand-md navbar-light">
        <div class="navbar-header">
            <a class="navbar-brand" style="color: black;">
                <!-- <b>
                    <a class="sidebar-logo-container"> 
                        <span class="hidden-sm-down" *ngIf="!showSideBar">Multi Tenant</span>
                    </a>
                </b> -->
                <img [src]="companyLogo" style="width: 100%; padding-left:3px; padding-top:3px; padding-bottom:3px"
                    alt="Company Logo" />
            </a>
        </div>
        <div class="navbar-collapse" style="justify-content: space-between;">
            <ul class="navbar-nav">
                <!-- <li class="nav-item">
                    <a class="nav-link nav-toggler hidden-md-up text-muted waves-effect waves-dark">
                        <i class="fas fa-bars"></i>
                    </a>
                </li> -->
                <li class="nav-item">
                    <a class="nav-link sidebartoggler text-muted waves-effect waves-dark" (click)="toggleSideBar()">
                        <i class="fas fa-bars"></i>
                        <!-- <i class="fas" [ngClass]="showSideBar ? 'fa-times': 'fa-bars'"></i> -->
                    </a>
                </li>
            </ul>
            <!-- <ul class="navbar-nav my-lg-0">
                <li class="nav-item" (click)="navigateTo('/under-construction')">
                    <a class="nav-link">
                        <i class="fas fa-comment-alt"></i>
                    </a>
                </li>
            </ul> -->
            <ul class="navbar-nav my-lg-0 hidden-sm-down">
                <li class="nav-item dropdown"><span class="topbar-tenant-title">{{companyObj?.company_name}}</span></li>
            </ul>
            <ul class="navbar-nav my-lg-0">
                <!-- <li class="nav-item dropdown">
                    <a class="nav-link dropdown-toggle text-muted text-muted waves-effect waves-dark" data-toggle="dropdown" aria-haspopup="true"
                        aria-expanded="false" #dropdownForNotification>
                        <i class="fas fa-inbox"></i>
                        <div class="notify" *ngIf="notificationObj?.length">
                            <span class="heartbit"></span>
                            <span class="point"></span>
                        </div>
                    </a>
                    <div class="dropdown-menu dropdown-menu-right mailbox scale-up">
                        <ul>
                            <li>
                                <div class="drop-title">Notifications</div>
                            </li>
                            <li>
                                <div class="message-center">
                                    <ng-container *ngIf="notificationObj.length; else noNotification">
                                        <a *ngFor="let notification of notificationObj">
                                            <i class="fas fa-caret-right"></i>
                                            <div class="mail-contnet" (click)="showModal(notification)">
                                                <h5>{{notification.title}}</h5>
                                                <span class="time">{{notification.creationTime}}</span>
                                            </div>
                                        </a>
                                    </ng-container>
                                    <ng-template #noNotification>
                                        <img _ngcontent-ecn-c11="" alt="user" class="center-img" src="./assets/multiTenant/png/notifications.png">
                                    </ng-template>

                                </div>
                            </li>
                            <li (click)="navigateTo('/under-construction')">
                                <a class="nav-link text-center">
                                    <strong>Check all notifications</strong>
                                    <i class="fa fa-angle-right"></i>
                                </a>
                            </li>
                        </ul>
                    </div>
                </li> -->

                <li class="nav-item dropdown">
                    <a class="nav-link dropdown-toggle text-muted waves-effect waves-dark" data-toggle="dropdown"
                        aria-haspopup="true" aria-expanded="false">
                        <p class="fas fa-user"></p>
                    </a>
                    <div class="dropdown-menu dropdown-menu-right scale-up">
                        <ul class="dropdown-user">
                            <!-- <li>
                                <div class="dw-user-box">
                                    <div class="u-img">
                                        <img src="./assets/insurBotimages/png/insurbot_Blue_Teal.png" alt="user">
                                    </div>
                                </div>
                            </li> 
                            <li role="separator" class="divider"></li> -->
                            <!-- <li>
                                <a (click)="navigateTo('/under-construction')">
                                    <i class="fas fa-user-alt"></i> My Profile</a>
                            </li>
                            <li>
                                <a (click)="navigateTo('/under-construction')">
                                    <i class="fas fa-wallet"></i> My Balance</a>
                            </li>
                            <li role="separator" class="divider"></li> -->
                            <li (click)="navigateTo('home/my-account-view')">
                                <a>
                                    <i class="fas fa-cog"></i><span [ngStyle]="styling?.sidebarText" class="p-l-10">
                                        Account Settings </span></a>
                            </li>
                            <li role="separator" class="divider"></li>
                            <li (click)="navigateTo('home/change-password')">
                                <a>
                                    <i class="fas fa-key"></i><span [ngStyle]="styling?.sidebarText" class="p-l-10">
                                        Change Password </span></a>
                            </li>
                            <li role="separator" class="divider"></li>
                            <li (click)="confirmModalPopup()">
                                <a>
                                    <i class="fas fa-power-off"></i><span [ngStyle]="styling?.sidebarText"
                                        class="p-l-10"> Log Out </span></a>
                            </li>
                        </ul>
                    </div>
                </li>

            </ul>
        </div>
    </nav>
</header>
<div style="position: relative; width: 100%; height: 55px; display: block; float: none;"></div>

<!-- <app-notifications-popup (modalClose)="OnHideModal($event)" [showNotificationPopup]="showNotificationPopup" [selectedNotification]="selectedNotification"></app-notifications-popup> -->