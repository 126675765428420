import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRoute } from '@angular/router';
import { UtilsService } from './utils.service';
import { CommonApiService } from './common-api.service';
import { Observable } from 'rxjs';
import { flatMap, map } from 'rxjs/operators';

@Injectable()
export class AuthGuard implements CanActivate {
    constructor(public router: Router, private utilsService: UtilsService) { }
    canActivate(data) {
        const tenant = this.utilsService.getTenantFromWindow()
        
        
        const isRightTenant = sessionStorage.getItem('token')? JSON.parse(sessionStorage.getItem('token')).tenant === tenant : null
        
        if(data.data && data.data.key){
            let permission = this.utilsService.getItemfromSession('permissionKeys') ? this.utilsService.getItemfromSession('permissionKeys') : [];
            let keyAvailable = data.data.key.every(resp=> permission.includes(resp))
            if(keyAvailable){
                return true
            } else{
                this.router.navigate([`${tenant}/permission-denied`])
                return false
            }
        }
        if (!sessionStorage.getItem('token') || !isRightTenant) {
            sessionStorage.clear()
            this.router.navigate([`${tenant}/login`]).then(() => {window.location.reload()}); //reloaded to revert translation back to english
            return false;
        } 
        return true;
    }
}

@Injectable({
    providedIn: 'root'
  })
export class AuthService {
  constructor(public router: Router, 
    private utilsService: UtilsService,
    private http: CommonApiService,
    private route: ActivatedRoute,) { }


  
    inMaintenance(): Observable<string> {
        let pageName: any;
        let currentDate = new Date();
        let maintenanceSettings = {
          maintenanceControl: false,
          timeSetting: new Date,
          reminderStartPriorDT : 1,
        };
        pageName =  this.utilsService.getTenantFromWindow(); 
       // console.log("auth service page name " + this.pageName)
        return this.http.get(`accountConfiguration?accountCode=${pageName}`, null, true).pipe(map(resp => {
            if(resp){
            let data = { ...resp.maintenanceMode }
                if (data != null && data.control != null && data.UTCTime != null && data.reminderStartPriorDT != null) {
                    sessionStorage.setItem('maintenanceMode', JSON.stringify(data));
                    sessionStorage.setItem('maintenanceModeTenant', pageName);
                   // console.log("maintenance mode data: "+JSON.stringify(data))
                    maintenanceSettings.maintenanceControl = data.control;
                    //let timeSetting = "2022-05-26T07:56:00.123Z"
                    //console.log("control on or off? " + this.maintenanceSettings.maintenanceControl)

                    if (maintenanceSettings.maintenanceControl) {
                        maintenanceSettings.timeSetting = new Date(data.UTCTime);
                        maintenanceSettings.reminderStartPriorDT = data.reminderStartPriorDT;
                        //console.log("setting date" + this.maintenanceSettings.timeSetting)
                        //console.log("current date" + currentDate)
                        //console.log("reminderStartPriorDT " + this.maintenanceSettings.reminderStartPriorDT)
                        let timeDifference = Math.abs(maintenanceSettings.timeSetting.getTime() - currentDate.getTime());
                        let timeDifferenceAsMinutes = timeDifference/1000/60;
                        //console.log("time difference " + timeDifferenceAsMinutes);

                        if (maintenanceSettings.timeSetting <= currentDate) {
                            console.log("maintenance")
                            return "maintenance";
                        } else if (timeDifferenceAsMinutes <= maintenanceSettings.reminderStartPriorDT){
                            console.log("reminder")
                            return "reminder";
                        } else {
                            console.log("not start to remind")
                            return "notMaintenance";
                        }              
                    } else {
                        console.log("not maintenance")
                        return "notMaintenance";
                    }
                }
            } else{
            this.router.navigate(['/site-not-found'])
            return "";
            }
        }))
    
    }    
}
