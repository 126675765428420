import { Injectable } from '@angular/core';

@Injectable()
export class PrivateDataShare {
    globalPolicyNumber: any
    globalPolicyDetails: any;
    globalLoginErrorMsgs: any;
    globalFileUploadSize: any;
    acceptedBillingCodes: any;
    doucumentPreferenceMapping: any;

    setDoucumentPreferenceMapping(mappingInfo) {
        this.doucumentPreferenceMapping = mappingInfo;
    }

    getDoucumentPreferenceMapping() {
        return this.doucumentPreferenceMapping;
    }

    setPolicyNumber(policyNo) {
        this.globalPolicyNumber = policyNo;
    }

    getPolicyNumber() {
        return this.globalPolicyNumber;
    }

    setPolicyDetails(data) {
        console.log(data)
        this.globalPolicyDetails = data;
    }

    getPolicyDetails() {
        return this.globalPolicyDetails;
    }

    setLoginErrorMessages(data) {
        this.globalLoginErrorMsgs = data;
    }

    getLoginErrorMessages() {
        return this.globalLoginErrorMsgs;
    }

    setFileUploadSize(size) {
        this.globalFileUploadSize = size
    }

    getFileUploadSize() {
        return this.globalFileUploadSize
    }

    setBillingCodes(items) {
        this.acceptedBillingCodes = items
    }

    getBillingCodes() {
        return this.acceptedBillingCodes
    }

}
