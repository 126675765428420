<div class="card h-100">
    <div class="card-body">
        <div class="row">
            <div class="col-lg-4">
                <div style="height: 400px; overflow-y: auto;">
                    <div class="card" *ngFor="let agent of agentData">
                        <div class="card-body">
                            <p><span class="font-weight-bold">{{agent?.agentFirstName}} {{agent?.agentLastName}}</span></p>
                            <p>{{agent?.agencyName}}</p>
                            <p>{{agent?.agencyLocationAddress?.street}}, {{agent?.agencyLocationAddress?.city}}, {{agent?.agencyLocationAddress?.stateCode}}, {{agentData?.propertyAddress?.zip}}</p>
                        </div>
                        <!-- {{agentData | json}} -->
                    </div>
                </div>
            </div>
    
            <div class="col-lg-8" *ngIf="agentData?.length; else noContent">
                <agm-map [latitude]="agentData[0]?.latFromGoogle" [longitude]="agentData[0]?.lngFromGoogle" [fitBounds]="true">
                    <agm-marker *ngFor="let position of agentData"
                    [latitude]="position.latFromGoogle" 
                    [longitude]="position.lngFromGoogle"
                    [agmFitBounds]="true" 
                    (markerClick)="openWindow(position.agencyID)"
                    >
                    <agm-info-window
                    [isOpen]="windowOpenCheck(position.agencyID)"
                    [latitude]="position.latFromGoogle" 
                    [longitude]="position.lngFromGoogle"
                    >{{position?.agencyName}}</agm-info-window>
                    </agm-marker>
                </agm-map>
            </div>
            <ng-template #noContent>No Agents Available</ng-template>
        </div>
</div>
</div>