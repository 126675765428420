import { Injectable } from "@angular/core";

@Injectable()

export class PrivateService{
  constructor(){}

  getNeedHelp(needHelpHtml, styling, agencyInfo){

    // let data = {

    // }
        needHelpHtml = needHelpHtml.replace(
          /codeobjects-header-font-size/gi,
          styling.header['font-size']
        );
        needHelpHtml = needHelpHtml.replace(
          /codeobjects-header-font-weight/gi,
          styling.header['font-weight']
        );
        needHelpHtml = needHelpHtml.replace(
          /codeobjects-header-font-family/gi,
          styling.header['font-family']
        );
        needHelpHtml = needHelpHtml.replace(
          /codeobjects-content-font-size/gi,
          styling.content['font-size']
        );
        needHelpHtml = needHelpHtml.replace(
          /codeobjects-content-font-weight/gi,
          styling.content['font-weight']
        );
        needHelpHtml = needHelpHtml.replace(
          /codeobjects-content-font-family/gi,
          styling.content['font-family']
        );
        needHelpHtml = needHelpHtml.replace(
          /codeobjects-contactHeader-font-family/gi,
          styling.contactHeader['font-family']
        );
        needHelpHtml = needHelpHtml.replace(
          /codeobjects-contactHeader-font-size/gi,
          styling.contactHeader['font-size']
        );
        needHelpHtml = needHelpHtml.replace(
          /codeobjects-contactHeader-font-weight/gi,
          styling.contactHeader['font-weight']
        );
        needHelpHtml = needHelpHtml.replace(
          /codeobjects-contactContent-font-family/gi,
          styling.contactContent['font-family']
        );
        needHelpHtml = needHelpHtml.replace(
          /codeobjects-contactContent-font-size/gi,
          styling.contactContent['font-size']
        );
        needHelpHtml = needHelpHtml.replace(
          /codeobjects-contactContent-font-weight/gi,
          styling.contactContent['font-weight']
        );

        const data = {
            agencyInfo,
            needHelp: needHelpHtml
        }
        console.log(data)
        return data
  }

}