<div class="col-12" [formGroup]="emailQuoteForm">
    <h3 class="card-title text-center custom-title p-b-20"></h3>
    <div class="row form-group">
        <div class="col-md-4 offset-md-4">
            <mat-form-field style="width: 100%">
                <input matInput formControlName="email" (input)="keyEmail()" type="text" placeholder="Your Email Address" />
            </mat-form-field>
            <p class="error-class" *ngIf="!emailQuoteForm.get('email').valid">{{emailError}}</p>
        </div>
    </div>
</div>