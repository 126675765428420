import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UtilsService } from '../../../shared/services/utils.service';

@Component({
  selector: 'app-recent-claim-card',
  templateUrl: './recent-claim-card.component.html',
  styleUrls: ['./recent-claim-card.component.scss']
})
export class RecentClaimCardComponent implements OnInit {
  @Input() recentClaimInfo: any;
  @Input() policyNumber: any;
  recentClaimInfoObj: any;
  styling =this.utilsService.getItemfromSession('themeObject')
  constructor(private route: ActivatedRoute, private router: Router, private utilsService: UtilsService) { }

  ngOnInit(): void {
  }
  navToLink(page){
    const tenant = this.route.parent.snapshot.params.tenant
    this.router.navigate([`${tenant}/${page}`])
  }

  ngOnChanges() {
    this.recentClaimInfoObj = this.recentClaimInfo.find(resp => resp.recentClaim.policyNumber == this.policyNumber)
    console.log(this.recentClaimInfoObj)
  }

}
