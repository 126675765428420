import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Resolve, Router } from "@angular/router";
import { throwError as observableThrowError, Observable } from "rxjs";
import { HttpParameterCodec } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators'
import { forkJoin } from 'rxjs'
import { DasboardService } from "./dashboard/dasboard.service";
import { CommonApiService } from "../shared/services/common-api.service";
import { UtilsService } from "../shared/services/utils.service";
import { DatePipe } from "@angular/common";
import { ErrorModalComponent } from "../shared/error-modal/error-modal.component";
import { BsModalService } from "ngx-bootstrap/modal";
import { PrivateDataShare } from "../shared/services/privateDataShare.service";
import { environment } from '../../../default/environments/environment';
const CryptoJS = require("crypto-js");




@Injectable()
export class PrivateResolver implements Resolve<any>{
  constructor(
    // private dashboardService: DasboardService, 
    private datepipe: DatePipe,
    private http: CommonApiService,
    private utilsService: UtilsService,
    private modalService: BsModalService,
    private privateDataShare: PrivateDataShare,
    private router: Router
  ) { }

  resolve(route: ActivatedRouteSnapshot): Observable<any> {

    const tempData = forkJoin([
      this.getPolicyInfoByPolicyNumber(),
      this.getCardInfo("needHelp"),
      this.getAccountConfiguration(),
      this.getPoliciesOfUser()
      // .pipe(
      //     map(res => { return res }),
      //     catchError(error => this.handleError(error))
    ])
    return tempData
    // this
  }

  getCardInfo(infoType) {
    const tenant = this.utilsService.getTenantFromWindow()
    return this.http
      .privateGet(
        `getCardInfo?infoType=${infoType}&accountCode=${tenant}`,
        null,
        false
      )
      .pipe(
        map((data) => {
          // var styling = this.utilsService.getItemfromSession('themeObject');

          // data.out = data.out.replace(
          //   /codeobjects-header-font-size/gi,
          //   styling.header['font-size']
          // );
          // data.out = data.out.replace(
          //   /codeobjects-header-font-weight/gi,
          //   styling.header['font-weight']
          // );
          // data.out = data.out.replace(
          //   /codeobjects-header-font-family/gi,
          //   styling.header['font-family']
          // );
          // data.out = data.out.replace(
          //   /codeobjects-content-font-size/gi,
          //   styling.content['font-size']
          // );
          // data.out = data.out.replace(
          //   /codeobjects-content-font-weight/gi,
          //   styling.content['font-weight']
          // );
          // data.out = data.out.replace(
          //   /codeobjects-content-font-family/gi,
          //   styling.content['font-family']
          // );
          // data.out = data.out.replace(
          //   /codeobjects-contactHeader-font-family/gi,
          //   styling.contactHeader['font-family']
          // );
          // data.out = data.out.replace(
          //   /codeobjects-contactHeader-font-size/gi,
          //   styling.contactHeader['font-size']
          // );
          // data.out = data.out.replace(
          //   /codeobjects-contactHeader-font-weight/gi,
          //   styling.contactHeader['font-weight']
          // );
          // data.out = data.out.replace(
          //   /codeobjects-contactContent-font-family/gi,
          //   styling.contactContent['font-family']
          // );
          // data.out = data.out.replace(
          //   /codeobjects-contactContent-font-size/gi,
          //   styling.contactContent['font-size']
          // );
          // data.out = data.out.replace(
          //   /codeobjects-contactContent-font-weight/gi,
          //   styling.contactContent['font-weight']
          // );

          return data.out;
        })
      );
  }

  getPolicyInfoByPolicyNumber() {
    const tenant = this.utilsService.getTenantFromWindow();
    let policyNumber = this.utilsService.getPolicyNumber();
    let today = this.datepipe.transform(new Date(), 'yyyy-MM-dd');
    const data = {
      "accountCode": tenant,
      "policyNumber": policyNumber,
      "asOfDate": today
    }
    const key = {
      payload: atob(environment.securityCode.policyNumber.inputKey)
    }
    const ciphertext = CryptoJS.AES.encrypt(JSON.stringify(data), key.payload).toString();
    const encryptedData = {
      data: ciphertext
    }
    return this.http.privatePost(`getPolicyInfoByPolicyNumber`, encryptedData, true)
      .pipe(
        map((data) => {
          return data.out;
        })
      );
  }

  getAccountConfiguration() {
    const tenant = this.utilsService.getTenantFromWindow();
    return this.http.privateGet(
      `accountConfiguration?accountCode=${tenant}`,
      null,
      false
    ).pipe(
      map((data) => {
        return data;
      })
    );
  }

  getPoliciesOfUser() {
    const tenant = this.utilsService.getTenantFromWindow();
    return this.http.privateGet(`getPoliciesByUser?accountCode=${tenant}`, null, true).pipe(
      map((data) => {
        this.policiesOfUser(data, tenant)
        // return data;
      })
    );
  }

  handleError(error) {
    return observableThrowError(error)
  }

  policiesOfUser(resp, tenant) {
    if ('out' in resp) {
      this.privateDataShare.setPolicyDetails(resp.out)
      const policyDetails = resp.out.PolicySummary[0].policyDetails
      if (policyDetails && policyDetails.policyNumber) this.privateDataShare.setPolicyNumber(policyDetails.policyNumber)
      sessionStorage.setItem('policyDetails', JSON.stringify(resp.out));
      let num = '0';
      sessionStorage.setItem('CurPolicySelection', num);
      // this.navLocation('home');
    } else {
      //interim until future dated policies are handled
      const errorFromApi = resp.root.Envelope.Body.Fault.faultstring;
      const extractedDate = /\d{4}([.\-/ ])\d{2}\1\d{2}/;
      const extractedPolicy = /[A-Za-z]{1}\d{9}/;
      const loginErrorMessages = this.privateDataShare.getLoginErrorMessages()
      if (extractedDate.test(errorFromApi) && extractedPolicy.test(errorFromApi) && loginErrorMessages) {
        var policy = errorFromApi.match(extractedPolicy)[0];
        this.http.privateGet(`lookupPolicyTerms?accountCode=${tenant}&policyNumber=${policy}`, null, true).subscribe((resp) => {
          if (resp.out && resp.out.termType) {
            if (resp.out.termType == 'Future') {
              var effectiveDate = this.datepipe.transform(resp.out.effectiveDate, 'MM/dd/yyyy')
              this.popErrorMessage(loginErrorMessages['Future'].replace(/MM-DD-YYYY/gi, effectiveDate), tenant)
            } else if (resp.out.termType == 'Past') {
              var expirationDate = this.datepipe.transform(resp.out.expirationDate, 'MM/dd/yyyy')
              this.popErrorMessage(loginErrorMessages['Past'].replace(/MM-DD-YYYY/gi, expirationDate), tenant)
            } else {
              this.popErrorMessage(loginErrorMessages['Other'], tenant)
            }
          } else {
            this.popErrorMessage(loginErrorMessages['Other'], tenant)
          }
        });
      } else {
        if (loginErrorMessages) this.popErrorMessage(loginErrorMessages['Other'], tenant)
      }
    }
    return null
  }

  popErrorMessage(errormessage, tenant) {
    sessionStorage.clear();
    const modal = this.modalService.show(ErrorModalComponent, { backdrop: 'static', });
    (<ErrorModalComponent>modal.content).showConfirmationModal('Error', errormessage);
    (<ErrorModalComponent>modal.content).onClose.subscribe((resp) => {
      if (resp === true) {
        // const tenant = this.route.parent.snapshot.params.tenant;
        this.router.navigate([`${tenant}/login`]).then(() => { window.location.reload() })
      }
    });

  }

}