import { Component, OnInit, Input } from '@angular/core';
import { CommonApiService } from '../../../shared/services/common-api.service';
import { NotifytoastService } from '../../../shared/services/notificationToast.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-calculating-progress',
  templateUrl: './calculating-progress.component.html',
  styleUrls: ['./calculating-progress.component.scss']
})
export class CalculatingProgressComponent implements OnInit {
  @Input() premiumReponse;
  @Input() requestQuoteData;
  constructor(
    private http: CommonApiService,
    private notifyToast: NotifytoastService,
  ) { }

  ngOnInit(): void {
  }

  rateQuote(requestRateQuoteData): Observable<any> {
    return this.http.post('rateQuote', requestRateQuoteData, true, true).pipe(
      map(
        (resp) => {
          if (resp.out === undefined) {
            this.notifyToast.error(
              'Please Contact System Administrator.' +
                '\nError: ' +
                resp.root.Envelope.Body.Fault.faultstring
            );
          } else {
            console.log('return value Rate Quote' + JSON.stringify(resp.out));
            return resp.out;
          }
        }
        // ,
        // (error) => {
        //   this.notifyToast.error('Please Contact System Administrator.');
        // }
      )
    );
  }

  submitQuote(requestSubmitQuoteData): Observable<any> {
    return this.http.post('submitQuote', requestSubmitQuoteData, true, true).pipe(
      map(
        (resp) => {
          if (resp.out === undefined) {
            this.notifyToast.error(
              'Please Contact System Administrator.' +
                '\nError: ' +
                resp.root.Envelope.Body.Fault.faultstring
            );
          } else {
            console.log('return value Submit Quote' + JSON.stringify(resp.out));
            return resp.out;
          }
        }
        // ,
        // (error) => {
        //   this.notifyToast.error('Please Contact System Administrator.');
        // }
      )
    );
  }
}
