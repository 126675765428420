<div class="col-12">
    <!-- <div class="row hidden-xs-down">
        <div class="col-md-9">
            <div class="d-flex">
                <img class="bot-image" src="assets/multiTenantImages/png/insurbot_Primary.png" alt="bot_picture" />
                <div class="card bot-card-message">
                    <div class="card-body" [ngStyle]="styling?.card?.attributeValue">
                        {{message}}
                    </div>
                </div>
            </div>
        </div>
    </div> -->
    <div class="p-b-20">
        <div class="text-center">
            <img src="assets/multiTenantImages/png/insurbot_Primary.png" alt="bot_picture" class="bot-image">
            <div class="message-bot-display" [ngStyle]="styling?.card?.attributeValue">{{displayMessage}}</div>
        </div>
    </div>
</div>

<div *ngIf="loading && (indexStepper == 10 || indexStepper == 12) ">
<!-- <div style="margin-top: 100px;"> -->
    <!-- Progressbar  -->
    <!-- <button class="btn btn-info" (click)="fakeLoader()">Start</button> -->
    <!-- <button class="btn btn-info" (click)="stopTimer()">End</button> -->
    <div class="col-12">
        <div class="d-flex justify-content-center">
            <div class="col-sm-8" style="margin-top: 100px;margin-bottom: 100px;">
                <div class="pipe-container">
                    <div class="pipe-loader" [style.width.%]="progressNumber">
                        {{progressNumber}}%
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>