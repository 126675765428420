import { createReducer, on } from '@ngrx/store';
import { switchState, toggleOn, toggleOff } from './sidebar_toggle_action';

export const initialShowState = false;
 
const _sidebarToggleReducer = createReducer(initialShowState,
  on(switchState, state => !state),
  on(toggleOn, state => true),
  on(toggleOff, state => false)
);
 
export function sidebarToggleReducer(state, action) {
  return _sidebarToggleReducer(state, action);
}