import { Component, OnInit, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-is-residence-form',
  templateUrl: './is-residence-form.component.html',
  styleUrls: ['./is-residence-form.component.scss']
})
export class IsResidenceFormComponent implements OnInit {
  @Input() frequencyUsage: FormGroup;
  @Input() quoteCustomizedFeature: any;
  @Input() showOptionAtStepSix: any;
  constructor() { }

  ngOnInit(): void {
  }
}
