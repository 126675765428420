<div class="row" [formGroup]="frequencyUsage">
    <div class="col-lg-4 p-b-20"  *ngIf="!(quoteCustomizedFeature && showOptionAtStepSix)">
        <div class="w-100 d-flex justify-content-center">
            <mat-card class="card-selection text-center radio-selector-wrapper">
                <label class="p-2" for="primary">
                    <p class="radio-selector-title">Primary Residence</p>
    
                    <input type="radio" value="primary" formControlName="usageResidence" id="primary" class="radio-col-light-blue" />
                    <img class="radio-image-selector p-2" src="assets/multiTenantImages/png/quote_property_usage_primary.svg">
                </label>
            </mat-card>
        </div>
    </div>
    <div class="col-lg-4 p-b-20" *ngIf="!(quoteCustomizedFeature && showOptionAtStepSix)">
        <div class="w-100 d-flex justify-content-center">
            <mat-card class="card-selection text-center radio-selector-wrapper">
                <label class="p-2" for="secondary">
                    <p class="radio-selector-title">Secondary / Seasonal</p>
    
                    <input type="radio" value="secondary" formControlName="usageResidence" id="secondary" class="radio-col-light-blue" />
                    <img class="radio-image-selector p-2" src="assets/multiTenantImages/png/quote_property_usage_vacation.svg">
                </label>
            </mat-card>
        </div>
    </div>
    <div class="col-lg-4" *ngIf="!(quoteCustomizedFeature && showOptionAtStepSix)">
        <div class="w-100 d-flex justify-content-center">
            <mat-card class="card-selection text-center radio-selector-wrapper">
                <label class="p-2" for="landlord">
                    <p class="radio-selector-title">Landlord  / Rental Property</p>
    
                    <input type="radio" value="landlord" formControlName="usageResidence" id="landlord" class="radio-col-light-blue" />
                    <img class="radio-image-selector p-2" src="assets/multiTenantImages/png/quote_property_usage_rental.svg">
                </label>
            </mat-card>
        </div>
    </div>
    
    <div class="col-lg-3 p-b-20" *ngIf="quoteCustomizedFeature && showOptionAtStepSix">
        <div class="w-100 d-flex justify-content-center">
            <mat-card class="card-selection text-center radio-selector-wrapper">
                <label class="p-2" for="primary">
                    <p class="radio-selector-title">Primary Residence</p>
    
                    <input type="radio" value="primary" formControlName="usageResidence" id="primary" class="radio-col-light-blue" />
                    <img class="radio-image-selector p-2" src="assets/multiTenantImages/png/quote_property_usage_primary.svg">
                </label>
            </mat-card>
        </div>
    </div>
    <div class="col-lg-3 p-b-20" *ngIf="quoteCustomizedFeature && showOptionAtStepSix">
        <div class="w-100 d-flex justify-content-center">
            <mat-card class="card-selection text-center radio-selector-wrapper">
                <label class="p-2" for="secondary">
                    <p class="radio-selector-title">Secondary / Seasonal</p>
    
                    <input type="radio" value="secondary" formControlName="usageResidence" id="secondary" class="radio-col-light-blue" />
                    <img class="radio-image-selector p-2" src="assets/multiTenantImages/png/quote_property_usage_vacation.svg">
                </label>
            </mat-card>
        </div>
    </div>
    <div class="col-lg-3 p-b-20" *ngIf="quoteCustomizedFeature && showOptionAtStepSix">
        <div class="w-100 d-flex justify-content-center">
            <mat-card class="card-selection text-center radio-selector-wrapper">
                <label class="p-2" for="landlord">
                    <p class="radio-selector-title">Landlord  / Rental Property</p>
    
                    <input type="radio" value="landlord" formControlName="usageResidence" id="landlord" class="radio-col-light-blue" />
                    <img class="radio-image-selector p-2" src="assets/multiTenantImages/png/quote_property_usage_rental.svg">
                </label>
            </mat-card>
        </div>
    </div>
    <div class="col-lg-3" *ngIf="quoteCustomizedFeature && showOptionAtStepSix">
        <div class="w-100 d-flex justify-content-center">
            <mat-card class="card-selection text-center radio-selector-wrapper">
                <label class="p-2" for="vacant">
                    <p class="radio-selector-title">Vacant / Unoccupied</p>
    
                    <input type="radio" value="vacant" formControlName="usageResidence" id="vacant" class="radio-col-light-blue" />
                    <img class="radio-image-selector p-2" src="assets/multiTenantImages/png/quote_property_usage_vacant.svg">
                </label>
            </mat-card>
        </div>
    </div>
</div>