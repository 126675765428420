<div class="card h-100">
    <div class="card-body">
       <!-- <div class="header-font custom-title" 
       style="font-family:Helvetica; font-size:26px; margin-top:-10px; margin-bottom:7px; margin-left: 6px;">
       {{'WELCOME' | translate}}
       </div> -->
       
        <!-- <div class="row">
            <app-info-card class="col-lg-6 p-b-30" [infoCardDetails]="cardInfo"></app-info-card> 
            <app-policies-card class="col-lg-6 p-b-30" [policiesDetails]="policyInfo" (policyIndexChanges)="getPolicyIndex($event)"></app-policies-card>
            </div> -->
        
        <!-- <div class="row">
            get a quote
            </div> -->
            
        <app-get-a-quote></app-get-a-quote> 
            

    </div>

</div>