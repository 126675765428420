/*Toast service for displaying messages*/

import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Injectable()
export class NotifytoastService {
    public config: any;
    constructor(
        private toastService: ToastrService,
    ) { }

    /*Showing suceess message*/
    public success(message: string): void {
        this.toastService.success(message);
    }

    /*Showing error message*/
    public error(message: string): void {
        this.toastService.error(message);
    }

    /*Showing info message*/
    public info(message: string): void {
        this.toastService.info(message);
    }

    /*Showing warning message*/
    public warning(message: string): void {
        this.toastService.warning(message);
    }

}
