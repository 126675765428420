<footer class="footer-wrapper footer-container-wrapper" *ngIf="isSfi; else sfiFooterContainer">
    <div class="col-md-12">
        <div class="row">
            <div class="col-md-4 text-center" style="margin: auto;">
                <div [innerHTML]="footerContent?.footer_left_message | safeHtml"></div>
            </div>
            <div class="col-md-4 customer-care-block">
                <div [innerHTML]="footerContent?.footer_center_message | safeHtml"></div>
            </div>
            <div class="col-md-4 privacy-policy-block">
                <div [innerHTML]="footerContent?.footer_right_message | safeHtml"></div>
                <h6><span class="version-text" style="font-family:Calibri">{{'FOOTER_VERSION' | translate: {date: date, version:version} }}</span></h6> 
            </div>
        </div>
    </div>
</footer>

<ng-template #sfiFooterContainer>
    <footer class="footer" style="font-size: x-small;">
        {{'FOOTER_TEXT' | translate}}<span class="version-text" style="font-family:Calibri">{{'FOOTER_VERSION' | translate: {date: date, version:version} }}</span>
    </footer>
</ng-template>