import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatStepper } from '@angular/material/stepper';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-demo-get-aquote',
  templateUrl: './demo-get-aquote.component.html',
  styleUrls: ['./demo-get-aquote.component.scss']
})
export class DemoGetAQuoteComponent implements OnInit {
  demoGetAQuoteForm1: any;
  demoGetAQuoteForm2: any;
  demoGetAQuoteForm3: any;
  selectedPackage = '';
  availableStoreys = ["1", "2", "3"];
  availableBathrooms = ["1", "2", "3", "4", "5"];
  availableBedrooms = ["1", "2", "3", "4", "5", "6", "7"];
  messageHeading = ''
  constructor(
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router
    ) { }

  ngOnInit(): void {
    this.displayMessage(0)
    this.demoGetAQuoteForm1 = this.fb.group({
      address: ['', Validators.required]
    })
    this.demoGetAQuoteForm2 = this.fb.group({
      first_name: ['', Validators.required],
      last_name: ['', Validators.required],
      phone_number: ['', [Validators.required, Validators.pattern(/^\(\d{3}\)\s\d{3}-\d{4}$/), Validators.maxLength(14)]]
    })
    this.demoGetAQuoteForm3 = this.fb.group({
      noOfBedrooms: [null],
      total_sqft: [null, Validators.required],
      noOfBathrooms: [null],
      noOfStoreys: [null]
    })
  }

  goBack(stepper: MatStepper) {
    stepper.previous();
    this.displayMessage(stepper.selectedIndex)
  }

  selectPackage(pack: string){
    this.selectedPackage = pack
    this.messageHeading = `Great Choice ${this.demoGetAQuoteForm2.value.first_name}. You are about to finalize the ${pack} product.`
  }

  goForward(stepper: MatStepper) {
    if(stepper.selectedIndex === 0 && this.demoGetAQuoteForm1.valid){
      stepper.next();
    } else if(stepper.selectedIndex === 1 && this.demoGetAQuoteForm2.valid){
      stepper.next();
    } else if(stepper.selectedIndex === 2 && this.demoGetAQuoteForm3.valid){
      stepper.next();
    } else if(stepper.selectedIndex === 3 && this.selectedPackage){
      stepper.next();
    } else{
      stepper.next();
    }
    this.displayMessage(stepper.selectedIndex)
  }

  saveQuote(){

  }

  displayMessage(page: Number){
    if(page === 0)
    this.messageHeading = 'Good afternoon,  My name is Bot, I am here to help you in choosing the right insurance.  Now,  first please tell me the location of the property.'
    if(page === 1)
    this.messageHeading = 'Ok!  I will now need some information about you. '
    if(page === 2)
    this.messageHeading = 'We are almost done.  I will need some basic information about the property.'
    if(page === 3)
    this.messageHeading = 'Based on information that you provided,  I think these three options will be a best fit for you.  You could choose our best value Gold package or you could either Choose less or more coverage.  Let me know what you prefer.'
    if(page === 4)
    this.messageHeading = 'Congratulations!  You have made the most important to protect your investment.  Our agent will contact you shortly to finalize the quote.  Thank you for visiting us.'
  }

  navLocation(page){
    const tenant = this.route.parent.snapshot.params.tenant
    this.router.navigate([`${tenant}/${page}`])
  }

}
