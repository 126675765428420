import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { UtilsService } from '../../../shared/services/utils.service';

@Component({
  selector: 'app-select-premium',
  templateUrl: './select-premium.component.html',
  styleUrls: ['./select-premium.component.scss']
})
export class SelectPremiumComponent implements OnInit {
  styling = this.utilsService.getItemfromSession('themeObject');
  @Input() selectedPremium;
  @Output() clickedPremium = new EventEmitter();
  constructor(
    private utilsService: UtilsService,
  ) { }

  ngOnInit(): void {}

  selectPremium(eve) {
    this.selectedPremium = eve;
    this.clickedPremium.emit(eve);
  }
}
