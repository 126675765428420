import { Component, OnInit, HostListener } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ConfirmModalComponent } from '../confirm-modal/confirm-modal.component';
import { BsModalService } from 'ngx-bootstrap/modal';
import { UtilsService } from '../services/utils.service';
import { Store, select } from '@ngrx/store';
import { switchState, toggleOn, toggleOff } from '../sidebar/sidebar_toggle_action';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  providers: [BsModalService]
})
export class HeaderComponent implements OnInit {
  companyObj = this.utilsService.getItemfromSession('companybackground')
  localVar = JSON.parse(sessionStorage.getItem('colorSchema'))
  styling = this.utilsService.getItemfromSession('themeObject')
  notificationObj = [];
  countryCode = 'in'
  tenant = this.route.snapshot.params.tenant
  companyLogo = (window.innerWidth <= 768) ? this.companyObj.company_logo_mobile : this.companyObj.company_logo;
  private sidebarState: Observable<boolean>;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private modalService: BsModalService,
    private utilsService: UtilsService,
    private store: Store<{ sidebarState: boolean }>
  ) {
    this.sidebarState = store.pipe(select('sidebarState'))
  }

  ngOnInit(): void {
    if (window.innerWidth < 768) {
      this.store.dispatch(toggleOn())
    }
    this.sidebarState.subscribe((state) => {
      if (state) {
        document.getElementById('main-container-adjust').classList.add('mini-sidebar')
      } else {
        document.getElementById('main-container-adjust').classList.remove('mini-sidebar')
      }
    })
  }
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.companyLogo = (window.innerWidth <= 768) ? this.companyObj.company_logo_mobile : this.companyObj.company_logo;
  }

  toggleSideBar() {
    this.store.dispatch(switchState())

  }

  showModal(dataObj) {
    console.log(dataObj)
  }

  navLogin() {
    sessionStorage.clear();
    this.router.navigate([`${this.tenant}/login`]).then(() => { window.location.reload() })
  }

  navigateTo(page) {
    this.router.navigate([`${this.tenant}/${page}`])
  }

  confirmModalPopup() {
    const modal = this.modalService.show(ConfirmModalComponent, { backdrop: 'static' });
    (<ConfirmModalComponent>modal.content).showConfirmationModal('<b>Log Out</b>', `Are you sure you want to logout?`);
    (<ConfirmModalComponent>modal.content).onClose.subscribe(resp => {
      if (resp === true) {
        this.navLogin()
      }
    })
  }

}
