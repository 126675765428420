import { NgModule, Component, Type} from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DashboardComponent } from './dashboard/dashboard.component';
import { PrivateComponent } from './private.component';
import { AuthGuard} from '../shared/services/auth.service';
import { ViewPoliciesComponent } from './view-policies/view-policies.component';
import { ViewPolicyCoveragesComponent } from './view-policies/view-policy-coverages/view-policy-coverages.component';
import { MyAccountViewComponent } from './my-account-view/my-account-view.component';
import { MyAccountEditComponent } from './my-account-edit/my-account-edit.component';
import { StylingConfigurationComponent } from './styling-configuration/styling-configuration.component';
import { ViewPolicyDocumentsComponent } from './view-policies/view-policy-documents/view-policy-documents.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { MyBillingViewComponent } from './my-billing-view/my-billing-view.component';
import {PaymentComponent} from './my-billing-view/payment/payment.component';
import { MyClaimListComponent } from './my-claim-list/my-claim-list.component';
import { MyClaimNewComponent } from './my-claim-new/my-claim-new.component';
import { AddPolicyComponent } from './add-policy/add-policy.component';
import {GetQuoteComponent} from './get-quote/get-quote.component';
import { PrivateResolver } from './private.resolver';
import { AuthGuardMaintenanceLogout } from '../shared/services/auth-guard-logout.service';
import { PolicyDocUploadCompComponent } from './policy-doc-upload-comp/policy-doc-upload-comp.component';
import { MakeTcPaymentComponent } from './my-billing-view/make-tc-payment/make-tc-payment.component';
import { DemoPageComponent } from '../tenants/demo-page/demo-page.component';
import { MakeTcPaymentResolver } from './my-billing-view/make-tc-payment/make-tc-payment.resolver';
const permissionKeys = require("../shared/services/permission-keys.json")

const routes: Routes = [
    {
        path: ':tenant/home',
        redirectTo: ':tenant/home/dashboard',
        pathMatch: 'full'
    },
    {
        path: ':tenant/home',
        component: PrivateComponent,
        resolve: {policyObj: PrivateResolver},
        canActivate: [ AuthGuardMaintenanceLogout],
        
        children: [
            {
                path: 'dashboard',
                component: DashboardComponent,
                canActivate: [AuthGuard, AuthGuardMaintenanceLogout]
                
            }, {
                path: 'view-policies',
                component: ViewPoliciesComponent,
                canActivate: [AuthGuard, AuthGuardMaintenanceLogout],
                data: {
                    key: [permissionKeys.POLICY_VIEW]
                }
            }, {
                path: 'view-policies/view-policy-coverages',
                component: ViewPolicyCoveragesComponent,
                canActivate: [AuthGuard, AuthGuardMaintenanceLogout],
                data: {
                    key: [permissionKeys.POLICY_VIEW, permissionKeys.POLICY_COVERAGES]
                }
            }, {
                path: 'my-account-view',
                component: MyAccountViewComponent,
                canActivate: [AuthGuard, AuthGuardMaintenanceLogout]
            }, {
                path: 'my-account-edit',
                component: MyAccountEditComponent,
                canActivate: [AuthGuard, AuthGuardMaintenanceLogout]
            }, {
                path: 'style-configuration',
                component: StylingConfigurationComponent,
                canActivate: [AuthGuard, AuthGuardMaintenanceLogout],
                data: {
                    key: [permissionKeys.STYLE_CONFIGURATOR]
                }
            }, {
                path: 'view-policies/view-policy-documents',
                component: ViewPolicyDocumentsComponent,
                canActivate:[AuthGuard, AuthGuardMaintenanceLogout],
                data: {
                    key: [permissionKeys.POLICY_DOCUMENTS]
                }
            }, {
                path: 'change-password',
                component: ChangePasswordComponent,
                canActivate: [AuthGuard, AuthGuardMaintenanceLogout]
            }, {
                path: 'my-billing-view',
                component: MyBillingViewComponent,
                canActivate: [AuthGuard, AuthGuardMaintenanceLogout],
                data: {
                    key: [permissionKeys.BILLING_VIEW]
                }
            },
            {
                path: 'my-billing-view/payment',
                // component: PaymentComponent,
                component: MakeTcPaymentComponent,
                resolve: {configObj: MakeTcPaymentResolver},
                canActivate: [AuthGuard, AuthGuardMaintenanceLogout],
                data: {
                    key: [permissionKeys.BILLING_VIEW, permissionKeys.MAKE_PAYMENT]
                }
            },
            {
                path: 'my-claims/list',
                component: MyClaimListComponent,
                canActivate: [AuthGuard, AuthGuardMaintenanceLogout],
                data: {
                    key: [permissionKeys.CLAIM_VIEW]
                }
            },
            {
                path: 'my-claims/new',
                component: MyClaimNewComponent,
                canActivate: [AuthGuard, AuthGuardMaintenanceLogout],
                data: {
                    key: [permissionKeys.CLAIM_VIEW, permissionKeys.FILE_A_CLAIM]
                }
            },
            {
                path:'add-policy',
                component: AddPolicyComponent,
                canActivate: [AuthGuard, AuthGuardMaintenanceLogout],
                data: {
                    key: [permissionKeys.ADD_POLICY]
                }
            },
            {
                path:'upload-policy',
                component: PolicyDocUploadCompComponent,
                canActivate: [AuthGuard, AuthGuardMaintenanceLogout],
                data: {
                    key: [permissionKeys.POLICY_DOCUMENT_UPLOAD]
                }
            }, {
                path: 'dashboard-demo',
                component: DemoPageComponent,
                canActivate: [AuthGuard, AuthGuardMaintenanceLogout],
            }
            // {
            //     path:'get-quote',
            //     component: GetQuoteComponent,
            //     canActivate: [AuthGuard, AuthGuardMaintenanceLogout],
            //     data: {
            //         key: [permissionKeys.GET_A_QUOTE]
            //     }
            // }
        ]
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {useHash: true, scrollPositionRestoration: 'top'})],
    exports: [RouterModule]
})
export class PrivateRoutingModule { }