import { Component, OnInit, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-is-rent-form',
  templateUrl: './is-rent-form.component.html',
  styleUrls: ['./is-rent-form.component.scss']
})
export class IsRentFormComponent implements OnInit {
  @Input() isRentForm: FormGroup;
  constructor() { }

  ngOnInit(): void {
  }

  onClick(eve) {
      this.isRentForm.patchValue({
        checkRental : eve
      });
  }

}
