import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { UtilsService } from '../services/utils.service';

@Component({
  selector: 'app-installation-banner',
  templateUrl: './installation-banner.component.html',
  styleUrls: ['./installation-banner.component.scss']
})
export class InstallationBannerComponent implements OnInit, OnChanges {
  @Input() bannerObj: any
  device = ''
  showContainer = false;
  constructor(private utilsService: UtilsService) { }

  ngOnInit(): void {
  }
  
  ngOnChanges(changes: any){
    if (changes.hasOwnProperty('bannerObj')) {
      if (changes.bannerObj.currentValue) {
        let storedBanner = this.utilsService.getItemfromSession('showBanner', true)
        let isStoredBanner: boolean;
        if(storedBanner == null || storedBanner == true)isStoredBanner = true
        else isStoredBanner = false
        this.checkDevice()
        this.showContainer = this.device && this.bannerObj.enable && isStoredBanner
      }
    }
  }

  closeBanner(){
    this.showContainer = false
    localStorage.setItem('showBanner', 'false')
  }

  checkDevice(){
    let deviceUsed = window.navigator.userAgent || navigator.vendor;
    let iosList = ['iPad Simulator', 'iPhone Simulator', 'iPod Simulator','iPad', 'iPhone', 'iPod']
    if(/android/i.test(deviceUsed)){
      this.device = 'android'
    } else if(iosList.includes(navigator.platform) || navigator.userAgent.includes("Mac") && "ontouchend" in document){
    // } else if(/(Mac|iPhone|iPod|iPad)/i.test(navigator.platform)){
      this.device = 'ios'
    }
  }

  installNav(){
    if(this.device == 'android'){
      window.location.href = this.bannerObj.playStoreLink
    } else if(this.device == 'ios'){
      window.location.href = this.bannerObj.appStoreLink
    }
  }
}
