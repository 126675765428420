import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';

@Component({
  selector: 'app-site-not-found',
  templateUrl: './site-not-found.component.html',
  styleUrls: ['./site-not-found.component.scss']
})
export class SiteNotFoundComponent implements OnInit {

  constructor(private location: Location) { }

  ngOnInit(): void {
  }

}
