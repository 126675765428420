<div class=""  *ngIf="!showBodyInBelow">
    <div class="modal-header">
        <h4 class="modal-title pull-left header-four-s" [ngStyle]="styling?.paragraphTitle" [innerHTML]='title'></h4>
    </div>
    <div class="modal-body">
        <p [ngStyle]="styling?.paragraphText" [innerHTML]='body'></p>
    </div>
    <div class="modal-footer">
        <div class="text-right">
            <button type="button" (click)="onCancel()" class="btn btn-danger buttons m-r-10"
            [ngStyle]="styling?.button?.buttonNegative"
            *ngIf="showRejectOption"
            >{{rejectBtnTxt}}</button>
            <button type="button" (click)="onConfirm()" class="btn btn-info buttons"
            [ngStyle]="styling?.button?.buttonPositive"
            >{{acceptBtnTxt}}</button>
        </div>
    </div>
</div>

<div class=""  *ngIf="showBodyInBelow">
    <div style="text-align: center; padding-top: 5%; padding-bottom: 5%; margin-top: 3%;">
        <h4 class="modal-title header-four-s" [ngStyle]="styling?.paragraphTitle" [innerHTML]='title'></h4>  
    </div>
    <div class="modal-body">
        <div class="text-center">
            <button type="button" (click)="onCancel()" class="btn btn-danger buttons m-r-10"
            [ngStyle]="styling?.button?.buttonNegative"
            *ngIf="showRejectOption"
            >{{rejectBtnTxt}}</button>
            <button type="button" (click)="onConfirm()" class="btn btn-info buttons"
            [ngStyle]="styling?.button?.buttonPositive"
            >{{acceptBtnTxt}}</button>
        </div>
    </div>
    <div class="modal-body">
        <p [ngStyle]="styling?.paragraphText" [innerHTML]='body'></p>
    </div>
</div>