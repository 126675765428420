<div class="col-12" [formGroup]="personalInfoForm">
    <h3 class="card-title text-center custom-title p-b-20"></h3>
    <div class="row form-group">
        <div class="col-md-4 offset-md-4">
            <mat-form-field style="width: 100%">
                <input matInput formControlName="firstName" type="text" placeholder="{{'FIRST_NAME' | translate}} *" />
            </mat-form-field>
            <p class="error-class mat-angular-error-text"
                *ngIf="!personalInfoForm.get('firstName').valid && isSubmitted">
                {{'REQUIRED_FIELD' | translate: {value:'First Name'} }}</p>
        </div>

    </div>
    <div class="row form-group">
        <div class="col-md-4 offset-md-4">
            <mat-form-field style="width: 100%">
                <input matInput formControlName="lastName" type="text" placeholder="{{'LAST_NAME' | translate}} *" />
            </mat-form-field>
            <p class="error-class mat-angular-error-text"
                *ngIf="!personalInfoForm.get('lastName').valid && isSubmitted">
                {{'REQUIRED_FIELD' | translate: {value:'Last Name'} }}</p>
        </div>
    </div>
    <div class="row form-group">
        <div class="col-md-4 offset-md-4">
            <mat-form-field style="width: 100%">
                <input matInput formControlName="email" style="padding-right: 0px;" (input)="keyEmail()" type="text"
                    placeholder="Your Email Address*" />
            </mat-form-field>
            <p class="error-class mat-angular-error-text" *ngIf="!personalInfoForm.get('email').valid">{{emailError}}
            </p>
            <p class="error-class mat-angular-error-text"
                *ngIf="personalInfoForm.get('email').errors?.required && isSubmitted">
                {{'REQUIRED_FIELD' | translate: {value:'Email'} }}</p>
        </div>
    </div>
</div>