<div class="card h-100">
    <div class="card-body">
        <!-- Loader Component start-->
        <div class="spinner-adjust" *ngIf="isLoading">
            <!-- <div class="spinner-grow text-primary" style="width: 3rem; height: 3rem;" role="status">
                <span class="sr-only">Loading...</span>
            </div> -->
            <div class="spinner">
                <div class="circle1"></div>
                <div class="circle2"></div>
            </div>
        </div>
        <!-- Loader Component end-->
        <div *ngIf="agencyObject?.claim_posting_info && !isLoading">
            <div [innerHTML]="agencyObject?.claim_posting_info | safeHtml">
            </div>
        </div>
        <div *ngIf="agencyObject?.agencyInfo && displayCard.agentInfoCard && !isLoading">
            <h4 class="header-font custom-title" [ngStyle]="styling?.pageTitle">
                {{'AGENT_INFO' | translate}}
            </h4>
            <div class="custom-description p-t-20 p-b-30" [ngStyle]="styling?.paragraphText">
                <span id="agency_name">{{agencyObject?.agencyInfo?.agencyName}} </span><br>
                <span id="agency_full_name">{{agencyObject?.agencyInfo?.agentFirstName | titlecase}} {{agencyObject?.agencyInfo?.agentLastName | titlecase}}</span><br>
                <div *ngIf="agencyObject?.agencyInfo?.agencyLocationAddress"> <!--Earlier mapped to agentInfo later changed to agencyInfo-->
                <span id="agency_address1">{{agencyObject?.agencyInfo?.agencyLocationAddress.addressLine1 | titlecase}},</span><br>
                <span id="agency_address2">{{agencyObject?.agencyInfo?.agencyLocationAddress.city| titlecase}}, {{agencyObject?.agencyInfo?.agencyLocationAddress.stateCode}} {{agencyObject?.agencyInfo?.agencyLocationAddress.zipCode}}</span><br>
                </div>
                <a href="tel:{{agencyObject?.agencyInfo?.agencyPhoneNumbers.PhoneNumber[0].number}}" [ngStyle]="styling?.hyperlink">{{this.formatNumber(agencyObject?.agencyInfo?.agencyPhoneNumbers.PhoneNumber[0].number)}}</a>
            </div>
        </div>
        <div *ngIf="agencyObject?.needHelp && !isLoading">
            <div [innerHTML]="agencyObject?.needHelp | safeHtml">
            </div>
        </div>
    </div>
</div>