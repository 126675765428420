import { Component, OnInit, Input } from '@angular/core';
import { environment } from '../../../environments/environment';
import { UtilsService } from '../../shared/services/utils.service';
const permissionKeys = require("../../shared/services/permission-keys.json");

@Component({
  selector: 'app-large-agent-info',
  templateUrl: './large-agent-info.component.html',
  styleUrls: ['./large-agent-info.component.scss'],
})
export class LargeAgentInfoComponent implements OnInit {
  @Input() agencyObject: any;
  @Input() isLoading: boolean;
  showInfo: any;
  styling = this.utilsService.getItemfromSession('themeObject');
  displayCard = {agentInfoCard: true}
  constructor(private utilsService: UtilsService) {}
  ngOnInit(): void {
    // this.getAgentInfo()
  }
 
  getAgentInfo() {
    this.agencyObject = this.utilsService.getItemfromSession('agentInfoObject');
  }

  formatNumber(number) {
    return (
      '(' +
      number.slice(0, 3) +
      ') ' +
      number.slice(3, 6) +
      '-' +
      number.slice(6)
    );
  }

  ngOnChanges(changes: any) {
    if (changes.hasOwnProperty('agencyObject')) {
      if (changes.agencyObject.currentValue) {
        let keysAvailable = this.utilsService.getItemfromSession('permissionKeys') ? this.utilsService.getItemfromSession('permissionKeys') : []
        this.displayCard = {
          agentInfoCard: keysAvailable.includes(permissionKeys.VIEW_AGENT_INFO_CARD),
        }
        this.showInfo = changes.agencyObject.currentValue;
      }
    }
  }
}
