

<!-- <div class="progress">
    <div class="progress-bar progress-bar-success progress-bar-striped" role="progressbar"
    aria-valuenow="40" aria-valuemin="0" aria-valuemax="100" style="width:40%">
    40% Complete (success)
    </div>
</div> -->

<!-- <mat-progress-bar mode="indeterminate"></mat-progress-bar> -->

<div class="row" style="margin-top: 3%; display: flex; justify-content: center; align-items: center;">
    <mat-card class="col-lg-6 p-b-30 card-premium-selection">
        <mat-card-content style="text-align: left;">
            Calculating coverages
        </mat-card-content>
   
    </mat-card>
</div>
