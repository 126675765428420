<div class="card get-quote-container zero-margin">
    <div class="text-left">
        <h4 class="text-center p-t-20 header-four-l" [ngStyle]="styling?.pageTitle">Recover Username </h4>
        <div class="col-lg-12 outerbox-padding">
            <mat-horizontal-stepper #stepper class="zero-padding">

                <mat-step [stepControl]="forgotUsernameForm">
                    <form [formGroup]="forgotUsernameForm" autocomplete="false" class="form-horizontal form-material"
                        id="forgotUsernameform">
                        <div class="form-group m-t-20">
                            <p class="col-md-4 offset-md-4 m-b-10 body-paragraph-s"
                                [ngStyle]="styling?.paragraphText">Fill out the info below, and we'll send your username
                                to the email on file</p>
                            <div class="col-md-4 offset-md-4">
                                <mat-form-field style="width: 100%">
                                    <input matInput formControlName="policyNumber" type="text"
                                        placeholder="{{'POLICY_NUMBER' | translate}} *"
                                        [ngStyle]="styling?.fieldValueInput" />
                                </mat-form-field>
                                <p class="error-class mat-angular-error-text"
                                    *ngIf="!forgotUsernameForm.get('policyNumber').valid && isSubmitted">
                                    {{'REQUIRED_FIELD' | translate: {value:'Policy Number'} }}</p>
                            </div>
                            <div class="col-md-4 offset-md-4">
                                <mat-form-field style="width: 100%">
                                    <input matInput formControlName="zipCodeInsuredProperty" type="text"
                                        placeholder="{{'PROP_ZIP_CODE' | translate}} *"
                                        [ngStyle]="styling?.fieldValueInput" />
                                </mat-form-field>
                                <p class="error-class mat-angular-error-text"
                                    *ngIf="!forgotUsernameForm.get('zipCodeInsuredProperty').valid && isSubmitted">
                                    {{'REQUIRED_FIELD' | translate: {value:'Zip Code'} }}</p>
                            </div>
                            <!-- <div class="col-md-4 offset-md-4">
                                <mat-form-field style="width: 100%">
                                    <input matInput formControlName="emailAddress" (input)="keyEmail()" type="text"
                                        placeholder="{{'EMAIL_ADDRESS' | translate}} *"
                                        [ngStyle]="styling?.fieldValueInput" />
                                    <span matSuffix>
                                        <a class="fas fa-question-circle"
                                            [popover]="'RECOVER_USERNAME_EMAIL' | translate" container="body"
                                            [ngStyle]="styling?.iconStyling" triggers="mouseenter:mouseleave"
                                            containerClass="customClass"></a>
                                    </span>
                                </mat-form-field>
                                <p class="error-class mat-angular-error-text"
                                    *ngIf="!forgotUsernameForm.get('emailAddress').valid && isSubmitted">{{emailError}}
                                </p>
                            </div> -->
                        </div>
                    </form>
                </mat-step>
                <mat-step>
                    <form class="form-horizontal form-material" id="otpForm">
                        <div class="form-group m-t-20">
                            <div class="col-md-12" [ngStyle]="styling?.paragraphText">
                                <p class="m-t-10 body-paragraph-s text-center">Great news, we've found your
                                    username! An email is on its way to
                                    {{forgotUsernameForm.get('emailAddress')?.value}} with your username.</p>
                                <p class="m-t-10 body-paragraph-s text-center">Didn't receive it? Be sure to check
                                    your spam folder, just in case.</p>
                            </div>
                        </div>
                    </form>
                </mat-step>
            </mat-horizontal-stepper>
            <!-- <div class="text-center p-b-40">
                <button *ngIf="stepper.selectedIndex == 0" class="btn btn-danger custom-button-info buttons m-r-20" type="button"
                    (click)="navLocation('login')"
                    [ngStyle]="styling?.button?.buttonNegative">{{('CANCEL' | translate) }}</button>
                <button class="btn btn-info custom-button-info buttons" type="button"
                    (click)="goForward(stepper)"
                    [ngStyle]="styling?.button?.buttonPositive">{{stepper.selectedIndex == 1 ? 'Go to Log In': 'Submit'}}</button>
            </div> -->

            <div class="col-md-4 offset-md-4">
                <div class="row" style="margin:0 0 30px 0; justify-content: center;">
                    <button *ngIf="stepper.selectedIndex == 0"
                        class="btn btn-danger custom-button-info buttons col-lg-3" type="button"
                        (click)="navLocation('login')" [ngStyle]="styling?.button?.buttonNegative">{{('CANCEL' |
                        translate) }}</button>
                    <span class="m-r-20 m-b-20"></span>
                    <button class="btn btn-info custom-button-info buttons col-lg-4" type="button"
                        (click)="goForward(stepper)" [ngStyle]="styling?.button?.buttonPositive">{{stepper.selectedIndex
                        == 1 ? 'Go to Log In': 'Submit'}}</button>
                </div>
            </div>
        </div>
    </div>
</div>