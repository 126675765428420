<div class="card custom-card-border h-100" [ngStyle]="styling?.card?.bodyStyling">
    <div class="card-body">
        <h4 class="card-title header-font custom-title" [ngStyle]="styling?.helpTitle">
            {{'AGENT_INFO' | translate}}
        </h4>
        <div class="row" [ngStyle]="styling?.paragraphText">
            <div class="col-md-12">
                <p class="form-control-static custom-description">
                    {{agencyObject?.agencyInfo?.agencyName}} <br>
                    {{agencyObject?.agentInfo?.agentFirstName | titlecase}} {{agencyObject?.agentInfo?.agentLastName | titlecase}}<br>
                    {{agencyObject?.agentInfo?.agencyLocationAddress.street | titlecase}},<br>
                    {{agencyObject?.agentInfo?.agencyLocationAddress.city| titlecase}}, {{agencyObject?.agentInfo?.agencyLocationAddress.stateCode}} {{agencyObject?.agentInfo?.agencyLocationAddress.zipCode}}<br>
                    <a href="tel:{{agencyObject?.agencyInfo?.agencyPhoneNumberFormatted}}" [ngStyle]="styling?.hyperlink">{{agencyObject?.agencyInfo?.agencyPhoneNumberFormatted}}</a>
                </p>
            </div>
        </div>

    </div>
</div>