import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UtilsService } from '../../../shared/services/utils.service';

@Component({
  selector: 'app-upload-policy-doc',
  templateUrl: './upload-policy-doc.component.html',
  styleUrls: ['./upload-policy-doc.component.scss']
})
export class UploadPolicyDocComponent implements OnInit {
  @Input() uploadCardInfo: any;
  styling = this.utilsService.getItemfromSession('themeObject')
  constructor(
    private utilsService: UtilsService,
    private route: ActivatedRoute,
    private router: Router
  ) { }

  ngOnInit(): void {
  }

  navToLink(page){
    const tenant = this.route.parent.snapshot.params.tenant
    this.router.navigate([`${tenant}/${page}`])
  }

}
