import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UtilsService } from '../../shared/services/utils.service';

@Component({
  selector: 'app-under-construction',
  templateUrl: './under-construction.component.html',
  styleUrls: ['./under-construction.component.css']
})
export class UnderConstructionComponent implements OnInit {
  styling =this.utilsService.getItemfromSession('themeObject')
  constructor(private router: Router, private utilsService: UtilsService) { }

  ngOnInit() {
  }

  navigateBack(){
    const tenant = this.utilsService.getTenantFromWindow();
    this.router.navigate([`${tenant}`])
  }

}
