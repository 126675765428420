import { Component, OnInit, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-home-age-size',
  templateUrl: './home-age-size.component.html',
  styleUrls: ['./home-age-size.component.scss']
})
export class HomeAgeSizeComponent implements OnInit {
  @Input() homeAgeAndSizeForm: FormGroup;
  @Input() stories: any;
  @Input() isSubmitted: boolean;
  
  constructor() { }

  ngOnInit(): void {
  }

}
