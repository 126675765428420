<div class="card-body" style=" box-shadow:none;"
    [ngStyle]="styling?.card?.attributeValue">
    <div class="row">
        <mat-card class="col-lg-3 p-b-30 card-premium-selection"
        [ngClass]="selectedPremium ==='basic' ? 'card-premium-blue' : 'card-premium-grey'"
        style="margin-top: 3%; margin-left: 5%; border-radius: 5px;" 
        (click)="selectPremium('basic')"
        >
            <mat-card-title class="text-center">
                <mat-icon *ngIf="selectedPremium =='basic'" [ngClass]="{'icon-circle-green': selectedPremium =='basic'}">
                    check_circle
                </mat-icon> 
                Basic 
            </mat-card-title>
            <mat-card-subtitle>
                Best choice for most homes. Finishes would include builder-quality cabinets, composite countertops, carpet flooring, and standard appliances.
            </mat-card-subtitle>
        </mat-card>

        <mat-card class="col-lg-3 p-b-30 card-premium-selection"
        [ngClass]="selectedPremium ==='upgraded' ? 'card-premium-blue' : 'card-premium-grey'"
        style="margin-top: 3%; margin-left: 5%; border-radius: 5px;" 
        (click)="selectPremium('upgraded')"
        >
            <mat-card-title class="text-center">
                <mat-icon *ngIf="selectedPremium =='upgraded'" [ngClass]="{'icon-circle-green': selectedPremium =='upgraded'}">
                    check_circle
                </mat-icon> 
                Upgraded 
            </mat-card-title>
            <mat-card-subtitle>
                Best choice for homes with upgraded finishes. Finishes would include solid wood cabinets, stone countertops, and wood flooring.
            </mat-card-subtitle>
        </mat-card>

        <mat-card class="col-lg-3 p-b-30 card-premium-selection"
        [ngClass]="selectedPremium ==='highEnd' ? 'card-premium-blue' : 'card-premium-grey'"
        style="margin-top: 3%; margin-left: 5%;  border-radius: 5px;" 
        (click)="selectPremium('highEnd')"
        >
            <mat-card-title class="text-center">
                <mat-icon *ngIf="selectedPremium =='highEnd'" [ngClass]="{'icon-circle-green': selectedPremium =='highEnd'}">
                    check_circle
                </mat-icon> 
                High-End
            </mat-card-title>
            <mat-card-subtitle>
                Best choice for homes with custom finishes. Finishes would include custom or built-in cabinets, marble countertops, and rich wood flooring.
            </mat-card-subtitle>
        </mat-card>
    </div>
</div> 

   