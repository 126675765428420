import { Injectable } from "@angular/core";
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { NotifytoastService } from './notificationToast.service';
import { SpinnerVisibilityService } from 'ng-http-loader';


import { HttpService } from './base.service';
import { environment } from '../../../environments/environment';

@Injectable()
export class CommonApiService extends HttpService {
    constructor(private _http: HttpClient, private _loader: SpinnerVisibilityService, private _router: Router, private _notifyToast: NotifytoastService ){
        super(_http, _loader, environment.custPortalUrl,_router, _notifyToast)
    }
}