import { Directive, OnInit, HostListener } from '@angular/core';
import { NgControl } from '@angular/forms';


@Directive({
    selector: '[numbersOnly]'
})

export class NumbersOnlyDirective implements OnInit{
    constructor(private ngControl: NgControl){
    }

    ngOnInit(){}
    @HostListener('input', ['$event.target.value'])
    onKeyDown(value){
        this.ngControl.control.patchValue(value.replace(/[^0-9]/g, ''))
    }

}