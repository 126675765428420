import {
  Component,
  OnInit,
  ViewChild,
  HostListener,
  Input,
} from '@angular/core';
// var CryptoJS = require("crypto-js");
import { MatStepper } from '@angular/material/stepper';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { DasboardService } from '../../dashboard/dasboard.service';
import { CommonApiService } from '../../../shared/services/common-api.service';
import { UtilsService } from '../../../shared/services/utils.service';
import { ActivatedRoute, Router } from '@angular/router';
import { forkJoin, Subject } from 'rxjs';
import { debounceTime, map } from 'rxjs/operators';
import * as moment from 'moment';
import { PrivateDataShare } from '../../../shared/services/privateDataShare.service';
import { NotifytoastService } from '../../../shared/services/notificationToast.service';
import { SpinnerVisibilityService } from 'ng-http-loader';
import { environment } from '../../../../environments/environment';
const CryptoJS = require("crypto-js");

@Component({
  selector: 'app-make-tc-payment',
  templateUrl: './make-tc-payment.component.html',
  styleUrls: ['./make-tc-payment.component.scss'],
  providers: [CommonApiService, DasboardService],
})
export class MakeTcPaymentComponent implements OnInit {
  @ViewChild('stepper') stepper: MatStepper
  termsLength: any;
  renewalTermPaymentDetails: any;
  currentTermPaymentDetails: any;
  termChange = false;
  termType = 'Current';
  hasBalanceDue: any;
  billTo: any;
  // stepper: MatStepper;
  policyIndex = sessionStorage.getItem('CurPolicySelection');
  policyInfo = this.utilsService.getItemfromSession('policyDetails')
    .PolicySummary;
  allowedCardTypes: any;
  paymentAuthorization: any;
  cardType = '';
  paymentAccount: any;
  lastFourDigits: any;
  status: any;
  type = '';
  paymentStatus: any;
  transactionId: any;
  confirmationNumber: any;
  isSubmitted = false;
  tenant: any;
  message: any;
  nextPaymentDue: any;
  notClickable = false;
  customAmount: any;
  remainingBalance: any;
  myDate = new Date().toLocaleDateString('en-US');
  policyNumber = this.utilsService.getPolicyNumber();
  policiesForm: any;
  amount: any;
  lastfour: any;
  listAddress = [];
  addressObj = [];
  policyStatus: any;
  selectedAddressObj = [];
  paymentMethodsList: any;
  isCreditCardPayment: any;
  private subject: Subject<string> = new Subject();
  styling = this.utilsService.getItemfromSession('themeObject');
  policySummary: any;
  isSmallWindow: boolean;
  isLargeWindow: boolean;
  NextAmountDueControl: boolean = true;
  RemainingBalanceContol: boolean = true;
  CustomAmountControl: boolean = true;
  key: any;
  mortgageeAlertMessage1: any;
  mortgageeAlertMessage2: any;
  cancelledPolicyMessage: any;
  cardStatusInTC: string;
  acceptedBillingcodes: any;
  recoopPaymentAlertFilter: any;
  customAmountControlProperty: boolean = false;
  allowCustomAmount: boolean = false;
  allowMakePayment: boolean =false;
  paymentOptionList = {
    creditCardList: [],
    bankAcntList: []
  }
  billingAccountId:string;
  policyId:string;
  selectedExistingPaymentMethod:boolean = false;
  paymentMethodId: any;

  constructor(
    private http: CommonApiService,
    public utilsService: UtilsService,
    private formBuilder: FormBuilder,
    private dashboardService: DasboardService,
    private route: ActivatedRoute,
    private privateDataShare: PrivateDataShare,
    private router: Router,
    private notifyToast: NotifytoastService,
    private loader: SpinnerVisibilityService
  ) { }
  paymentForm: FormGroup;
  paymentForm1: FormGroup;
  paymentForm2: FormGroup;
  ngOnInit(): void {
    this.isLargeWindow = window.innerWidth >= 900;
    this.isSmallWindow = window.innerWidth < 540;
    this.tenant = this.route.parent.snapshot.params.tenant;
    this.acceptedBillingcodes = this.privateDataShare.getBillingCodes()
    this.subject.pipe(debounceTime(800)).subscribe((resp) => {
      this.validateCard(resp);
    });
    // this.getPaymentToken()
    // this.getPaymentURL()
    // this.getPaymentTokenAndURL()
    this.route.data.subscribe(resp => {
      if(resp.configObj.customer_portal.customizationItems?.recoopPaymentAlertFilter){
        this.recoopPaymentAlertFilter = true;
      }
      if(resp.configObj.showBillingInfoForCancelledPolicy){
        this.allowMakePayment = true;
      }
    })

    this.getSupportedPaymentDetails();
    this.initForm();
    // this.getPolicyDetails();
    this.getPaymentResponseMessages();
    // this.getEncryptionToken()
    this.getPaymentMortgageeAlertMessages();
    this.getCancelledPolicyMessage()
  }
  showPaymentPlanOption(paymentPlanControl) {
    if (this.billTo == 'Mortgage Bill' || this.billTo == 'MortgageBill') {
      if (paymentPlanControl) {
        return true;
      } else {
        return false;
      }
    }
    return true;
  }

  showCustomAmountPaymentPlanOption(paymentPlanControl) {
    if (this.billTo == 'Mortgage Bill' || this.billTo == 'MortgageBill') {
      if (paymentPlanControl) {
        return true;
      } else {
        return false;
      }
    }
    if(this.customAmountControlProperty) {
      if (this.allowCustomAmount) {
        return true;
      } else {
        return false;
      }
    }
    return true;
  }

  getPaymentResponseMessages() {
    var infoType = 'payments_message';
    this.http
      .get(
        `getCardInfo?accountCode=${this.tenant}&infoType=${infoType}`,
        null,
        true
      )
      .subscribe(
        (resp) => {
          if (resp) {
            this.message = resp.out;
          }
        },
        (error) => {
          const errorText = `Please Contact System Administrator.`;
          this.utilsService.confirmModalPopup(errorText);
        }
      );
  }

  // getPaymentToken(){
  //   let data = {
  //     policyNumber: this.policyNumber,
  //     accountCode: this.tenant
  //   }
  //   this.http.post(`getPaymentGatewayToken`, data, true, true).subscribe((resp) => {
  //     console.log(resp, "token recd")
  //   })
  // }

  getPaymentTokenAndURL() {
    this.loader.show()
    const key = {
      payload: atob(environment.securityCode.policyNumber.inputKey)
    }
    let data = {
      policyNumber: this.policyNumber.replace(/\d/g, "X"),
      accountCode: this.tenant
    }
    // const ciphertext = CryptoJS.AES.encrypt(JSON.stringify(data), key.payload).toString();
    // const encryptedData = {
    //   data: ciphertext
    // }
    forkJoin([
      this.http.post(`getPaymentGatewayToken`, data, false, true),
      this.http.post(`paymentURL`, data, false, true)
    ]).subscribe(resp => {
      let token, paymentObj;
      if (resp[0] && resp[0].statusCode == 200) {
        token = resp[0].body
      }
      if (resp[1]) {
        paymentObj = resp[1]
      }
      if (token && paymentObj) this.callPaymentGateway(token, paymentObj)
      else {
        this.notifyToast.error("Please Contact System Administrator")
        this.loader.hide() // loader is written seperately to avoid loader(after getting response) lag between API calls
      }
    }, error => {
      this.loader.hide()
      this.notifyToast.error("Unable to retrieve data. Please contact System Administrator.")
    })
  }

  callPaymentGateway(token, paymentObj: { url: string, action: string, returnurl: string }) {
    // let requestURL = paymentObj.url + '?' + 'token=' + token + '&action=' + paymentObj.action + '&returnurl=' + paymentObj.returnurl
    let requestURL = paymentObj.url
    let payloadData;
    if (this.paymentForm.value.payment_method == 'Bank Account') {
      // requestURL = requestURL + this.bankAccountPaymentData();
      payloadData = this.formDataBankAcnt({token, action: paymentObj.action, returnurl: paymentObj.returnurl})
      console.log("second part of request URL: " + requestURL)
    } else {
      // requestURL = requestURL + this.creditCardPaymentData();
      payloadData = this.formDataCreditCard({token, action: paymentObj.action, returnurl: paymentObj.returnurl})
      console.log("second part of request URL: " + requestURL)
    }
    this.http.transactionPost(requestURL, payloadData).subscribe(resp => {
      if (resp != undefined) {
        // let newToken = resp.token ? resp.token : token
        let isActualTrustCommerce = requestURL.indexOf("https://vault.trustcommerce.com/") > -1
        let tokenFromXML = this.utilsService.getStringValueFromXML(resp, 'token')
        // let newToken = isActualTrustCommerce ? token : tokenFromXML ? tokenFromXML: resp.token //use only when resp is returning in JSON
        let newToken = isActualTrustCommerce ? token : tokenFromXML
        this.callCompletePaymentGateway(newToken)
      } else {
        this.loader.hide()
        this.notifyToast.error("Payment Gateway Unsuccessful")
      }
    }, error => {
      // console.log("Handle CORS temp fix")
      this.callCompletePaymentGateway(token)
    })
  }

  formDataCreditCard(paymentObj: { token: string, action: string, returnurl: string}){
    const formData = new FormData()
    let shortExpYear = parseInt(this.paymentForm.value.expiration_year) % 100
    let card = this.paymentForm.value.card_number.trim().replaceAll(" ", "");
    // let address: any;
    // address =
    //   `&address1= ${this.paymentForm.value.billing_address}, ${this.paymentForm.value.city}, ${this.paymentForm.value.state}` +
    //   "&verify=y" +
    //   `&${environment.demoPayment}` +
    //   `&name=${this.paymentForm.value.name}` +
    //   "&zip=" + this.paymentForm.value.zip
    // address = address.replaceAll(" ", "%20");
    // let requestedURLSecond = '&cc=' + card +
    //   '&exp=' + this.paymentForm.value.expiration_month + shortExpYear +
    //   '&cvv=' + this.paymentForm.value.cvv + address
    // return requestedURLSecond;
    formData.append('address1', `${this.paymentForm.value.billing_address}, ${this.paymentForm.value.city}, ${this.paymentForm.value.state}`)
    formData.append('verify', 'y')
    formData.append('demo', environment.demoPayment.substr(environment.demoPayment.length - 1))
    formData.append('name', this.paymentForm.value.name)
    formData.append('zip', this.paymentForm.value.zip)
    formData.append('cc', card)
    formData.append('exp', this.paymentForm.value.expiration_month + shortExpYear)
    formData.append('cvv', this.paymentForm.value.cvv)
    formData.append('token', paymentObj.token)
    formData.append('action', paymentObj.action)
    formData.append('returnurl', paymentObj.returnurl)
    return formData
  }

  formDataBankAcnt(paymentObj: { token: string, action: string, returnurl: string}){
    const formData = new FormData()
    formData.append('address1', `${this.paymentForm.value.billing_address}, ${this.paymentForm.value.city}, ${this.paymentForm.value.state}`)
    formData.append('verify', 'y')
    formData.append('demo', environment.demoPayment.substr(environment.demoPayment.length - 1))
    formData.append('name', this.paymentForm.value.name)
    formData.append('zip', this.paymentForm.value.zip)
    formData.append('media', "ach")
    formData.append('routing', this.paymentForm.value.routing_number)
    formData.append('account', this.paymentForm.value.account_number)
    formData.append('token', paymentObj.token)
    formData.append('action', paymentObj.action)
    formData.append('returnurl', paymentObj.returnurl)
    return formData
  }

  callCompletePaymentGateway(token) {
    this.completePaymentGateway(token).subscribe(resp => {
      if (resp && resp.statusCode == 200 && resp.body) {
        this.afterPaymentStatus(resp.body)
      } else {
        this.loader.hide()
        this.notifyToast.error("Payment Unsuccessful")
      }
    }, error => {
      this.loader.hide()
      this.notifyToast.error("Payment Unsuccessful")
    })
  }

  afterPaymentStatus(stringifiedPaymentStatus) {
    let stringifiedPaymentArr = stringifiedPaymentStatus.split("\n") //splitting the response body and assigning to obj
    let stringifiedPaymentObj: Object = {};
    if (stringifiedPaymentArr.length) {
      stringifiedPaymentArr.forEach(ele => {
        let objKey, objValue;
        objKey = ele.substring(0, ele.indexOf("="))
        objValue = ele.substring(ele.indexOf("=") + 1)
        if (objKey) stringifiedPaymentObj[objKey] = objValue
      });
      this.cardStatusInTC = stringifiedPaymentObj['status']
      var billingId = stringifiedPaymentObj['billingid']

      // not that necessary start
      this.paymentInfoDetails()
      // not that necessary end

      if (this.cardStatusInTC == 'accepted' || this.cardStatusInTC == 'approved') {
        // console.log("Success")
        let avsResponseFromTC = stringifiedPaymentObj['avs']
        let conditionCC = this.paymentForm.value.payment_method == 'Credit Card'
          && avsResponseFromTC
          && this.acceptedBillingcodes
          && this.acceptedBillingcodes.length
          && !this.acceptedBillingcodes.some(e => e.toLowerCase() == avsResponseFromTC.toLowerCase())

        if (conditionCC) {
          this.cardStatusInTC = 'declined'
          this.loader.hide()
          this.stepper.next()
          this.type = this.message.decline.avs
          this.notifyToast.error("Payment not processed")
        } else {
            var resource = this.getCreditCardOrBankAccountResource(billingId);
            var data = {
              "accountCode": this.tenant,
              resource
            }
            if (this.paymentForm.value.payment_method == 'Credit Card') {
              this.saveCreditCardAndMakePayment(data); 
            } 
            else if (this.paymentForm.value.payment_method == 'Bank Account') {
              this.saveBankAccountAndMakePayment(data); 
            }       
        }
      } else {
        this.loader.hide()
        this.stepper.next()
        this.notifyToast.error("Payment not processed")
        var offendersinTC = stringifiedPaymentObj['offenders']
        var declineTypeinTC = stringifiedPaymentObj['declinetype']
        if (this.cardStatusInTC == 'baddata' && offendersinTC) {
          if (this.message) {
            var msgDisplay = this.message.baddata[offendersinTC] ? this.message.baddata[offendersinTC] : this.message.other
            this.type = msgDisplay
          }
        } else if (this.cardStatusInTC == 'decline' && declineTypeinTC) {
          var msgDisplay = this.message.decline[declineTypeinTC] ? this.message.decline[declineTypeinTC] : this.message.other
          this.type = msgDisplay
        } else {
          this.type = this.message.other
        }
      }
    }
    // temp.filter(resp => resp.match("status")) 
  }

  saveCreditCardAndMakePayment(data) {
    this.loader.show();
    this.dashboardService.setSurePCBRestJWTToken().then(() => {
      this.http.privateBillingRestPost('saveCreditCard', data, false).subscribe(
        (resp) => {
          if (resp) {
            if((resp.statusCode == 201 || resp.statusCode == 409) && resp.body) {
              if (resp.statusCode == 201) {
                var ccId = resp.body.data.id;
              }
              else if (resp.statusCode == 409) {
                var ccId = resp.body.id;
              }
              var paymentResource = this.getPaymentResource(ccId);
              this.makeBillingPolicyPayment(paymentResource);
            }
            else{
              this.loader.hide();
            }
          } 
        },
        (error) => {
          this.loader.hide();
          this.utilsService.confirmModalPopup(error);
        });
    }).catch(error => {
      this.loader.hide();
      var err = {
        statusCode: error.statusCode,
        error: JSON.parse(error.body).error
      }
      throw err;
    });
  }

  saveBankAccountAndMakePayment(data) {
    this.loader.show();
    this.dashboardService.setSurePCBRestJWTToken().then(() => {
      this.http.privateBillingRestPost('saveBankAccount', data, false).subscribe(
        (resp) => {
          if (resp) {
            if((resp.statusCode == 201 || resp.statusCode == 409) && resp.body) {
              if (resp.statusCode == 201) {
                var baId = resp.body.data.id;
              }
              else if (resp.statusCode == 409) {
                var baId = resp.body.id;
              }
              var paymentResource = this.getPaymentResource(baId);
              this.makeBillingPolicyPayment(paymentResource);
            }
            else{
              this.loader.hide();
            }
          } 
        },
        (error) => {
          this.loader.hide();
          this.utilsService.confirmModalPopup(error);
        });
    }).catch(error => {
      this.loader.hide();
      var err = {
        statusCode: error.statusCode,
        error: JSON.parse(error.body).error
      }
      throw err;
    });
  }

  makeBillingPolicyPayment(paymentResource) {
    this.dashboardService.setSurePCBRestJWTToken().then(() => {
      var data = {
        "accountCode": this.tenant,
        paymentResource
      }
      this.http.privateBillingRestPost('makeBillingPolicyPayment', data, false).subscribe(
        (resp) => {
          if (resp.statusCode == 201 && resp.body?.confirmationNumber) {
            this.status = 'approved'
            this.notifyToast.success("Success")
            this.confirmationNumber = resp.body.confirmationNumber
          } else {
            this.notifyToast.error("Payment Declined")
            this.cardStatusInTC = 'declined'
            this.type = this.message.other
          }
          this.stepper.next();
          this.loader.hide();
        });
    }).catch(error => {
      this.loader.hide();
      var err = {
        statusCode: error.statusCode,
        error: JSON.parse(error.body).error
      }
      throw err;
    });
  }

  paymentInfoDetails() {
    var cardNumber = this.paymentForm.value.payment_method == 'Bank Account'
      ? this.paymentForm.value.account_number.replace(/\s/g, '') : this.paymentForm.value.card_number.replace(/\s/g, '');
    if (cardNumber != null) {
      var len = cardNumber.length;
      this.lastFourDigits = len > 4 ? cardNumber.substr(len - 4, len) : cardNumber;
      this.paymentAccount = '**** '+ this.lastFourDigits;
    }
  }
  getPaymentResource(paymentMethodId) {
    let paymentResource: any;
    let data: any;
    data = {
      type : "payments",     
    }
    data.attributes = {
      paymentAmount: this.paymentForm.value.amount,
    }
    if(this.paymentForm.value.payment_method == 'Credit Card')
    {
      data.relationships = {
        policies: {
          data: {
            type: "policies",
            id: this.policyId
          }
        },
        creditCardAccounts: {
          data: {
            type: "creditCardAccounts",
            id: paymentMethodId
          }
        }
      }
    }
    else if(this.paymentForm.value.payment_method == 'Bank Account')
    {
      data.relationships = {
        policies: {
          data: {
            type: "policies",
            id: this.policyId
          }
        },
        bankAccounts: {
          data: {
            type: "bankAccounts",
            id: paymentMethodId
          }
        }
      }
    }
    paymentResource = {
      data
    }
    return paymentResource;
  }
  getCreditCardOrBankAccountResource(billingId) {
    const cardsList = [
      { name: 'Visa', ccType: 'Visa' },
      { name: 'Discover', ccType: 'Discover' },
      { name: 'Mastercard', ccType: 'Mastercard' },
      { name: 'American Express', ccType: 'AmericanExpress' },
      { name: 'DinersCC', ccType: 'DinersCC' }
    ]
    let card = cardsList.find(card => card.name == this.cardType);
    let resource: any;
    let billingAddress = {
      addressLine1: this.paymentForm.value.billing_address,
      addressLine2: this.paymentForm.value.billing_address2,
      city: this.paymentForm.value.city,
      country: 'US',
      state: this.paymentForm.value.state,
      zipCode: this.paymentForm.value.zip
    }
    let data: any;
    if (this.paymentForm.value.payment_method == 'Credit Card') {
      data = {
        type : "creditCardAccounts",     
      }
      data.attributes = {
        cardExpirationMonth: this.paymentForm.value.expiration_month,
        cardExpirationYear: this.paymentForm.value.expiration_year,
        cardHolderName: this.paymentForm.value.name,
        cardTokenValue: billingId,
        cardType: card && card.ccType,
        lastFourDigits: this.lastfour,
        billingAddress
      }
    }
    else if (this.paymentForm.value.payment_method == 'Bank Account') {
      data = {
        type : "bankAccounts",     
      }
      data.attributes = {
        accountOwnerName: this.paymentForm.value.name,
        accountType: "Checking",
        achTokenValue: billingId,
        lastFourDigits: this.lastfour,
        routingNumber: this.paymentForm.value.routing_number,
        billingAddress
      }
    }
    data.relationships = {
      accounts: {
        data: {
          type: "accounts",
          id: this.billingAccountId
        }
      }
    }
    resource = {
      data
    }
    return resource;
  }

  getNewBankOrCreditData(billingId) {
    const cardsList = [
      { name: 'Visa', ccType: 'Visa' },
      { name: 'Discover', ccType: 'Discover' },
      { name: 'Mastercard', ccType: 'Mastercard' },
      { name: 'American Express', ccType: 'AmericanExpress' },
      { name: 'DinersCC', ccType: 'DinersCC' }
    ]
    let card = cardsList.find(card => card.name == this.cardType);
    let data: any;
    let billingAddress = {
      addressLine1: this.paymentForm.value.billing_address,
      addressLine2: this.paymentForm.value.billing_address2,
      city: this.paymentForm.value.city,
      country: 'USA',
      state: this.paymentForm.value.state,
      zipCode: this.paymentForm.value.zip
    }
    data = {
      payerType: 'Insured', // not available X
      paymentAmount: this.paymentForm.value.amount,
      policyNumber: this.policyNumber,
      subPaymentType: this.paymentForm.value.payment_method == 'Bank Account' ? "BankAccount" : "CreditCard",
      accountCode: this.tenant,
      termStartDate: this.paymentForm.value.effectiveDate
    }

    if (this.paymentForm.value.payment_method == 'Bank Account') {
      data.achAccountInfo = {
        // accountOwnerEmailAddress: "",
        accountOwnerName: this.paymentForm.value.name,
        // accountOwnerPhoneNumber: "",
        accountType: "Checking",
        achTokenValue: billingId,
        billingAddress,
        lastFourDigits: this.lastfour,
        routingNumber: this.paymentForm.value.routing_number,
      }
    } else {
      data.creditCardInfo = {
        billingAddress,
        cardExpirationMonth: this.paymentForm.value.expiration_month,
        cardExpirationYear: this.paymentForm.value.expiration_year,
        cardHolderName: this.paymentForm.value.name,
        cardTokenValue: billingId,
        cardType: card && card.ccType,
        lastFourDigits: this.lastfour
      }
    }
    return data
  }

  getBankOrCreditData(billingId) {
    const cardsList = [
      { name: 'Visa', ccType: 'Visa' },
      { name: 'Discover', ccType: 'Discover' },
      { name: 'Mastercard', ccType: 'MasterCard' },
      { name: 'American Express', ccType: 'Amex' },
      { name: 'DinersCC', ccType: 'DinersCC' }
    ]
    let card = cardsList.find(card => card.name == this.cardType);
    let data: any;
    data = {
      accountCode: this.tenant,
      formattedPolicyNumber: this.policyNumber,
      paymentAmount: this.paymentForm.value.amount,
      paymentType: this.paymentForm.value.payment_method == 'Bank Account' ? "ACH" : this.paymentForm.value.payment_method,
      token: billingId,
      // bankInfo: {
      //   accountType: "Savings",
      //   lastDigitsOfAccountNumber: "5678",
      //   routingNumber: "121122676"
      // },
      // creditCardInfo: {
      //   "ccType": this.cardType,
      //   "expMonth": this.paymentForm.value.expiration_month,
      //   "expYear": this.paymentForm.value.expiration_year,
      //   "lastDigitsOfCardNumber": this.lastfour
      // }
    }
    if (this.paymentForm.value.payment_method == 'Bank Account') {
      data.bankInfo = {
        "accountType": "Checking",
        "lastDigitsOfAccountNumber": this.lastfour,
        "routingNumber": this.paymentForm.value.routing_number
      }
    } else {
      data.creditCardInfo = {
        "ccType": card && card.ccType,
        "expMonth": this.paymentForm.value.expiration_month,
        "expYear": this.paymentForm.value.expiration_year,
        "lastDigitsOfCardNumber": this.lastfour
      }
    }

    return data

  }

  makePaymentGateWay(requestURL) {
    return window.fetch(requestURL, {
      method: 'POST',
      // mode: 'no-cors',
      credentials: 'include',
      //body: JSON.stringify({token: "", b: 2}), 
      //credentials: 'include', 
      headers: {
        'Content-Type': 'application/json',
        // 'Access-Control-Allow-Origin': '*',
        // 'Content-Type': 'application/x-www-form-urlencoded',
        // 'Access-Control-Allow-Origin': '*',
        // 'Content-Type': 'application/xml',
        // 'Content-Type': 'text/html',
        // 'responseType': 'text'
      },
    })
      .then(response => {
        console.log("response:", response);
        return response
      })
      .catch(error => {
        console.log(error)
        throw error
      })
  }

  completePaymentGateway(token) {
    const data = {
      accountCode: this.tenant,
      token
    }
    return this.http.post('completePaymentGateway', data, false, true).pipe(
      map((resp) => {
        if (resp === undefined || resp === null || resp.statusCode != 200) {
          this.notifyToast.error('Please Contact System Administrator.' + '\n' + resp.root.Envelope.Body.Fault.faultstring);
        } else {
          console.log('return value from getpaymentgatewaytoken' + JSON.stringify(resp));
          return resp;
        }
      })
    );
  }

  bankAccountPaymentData() {
    let address: any;
    address =
      `&address1= ${this.paymentForm.value.billing_address}, ${this.paymentForm.value.city}, ${this.paymentForm.value.state}` +
      "&verify=y" +
      `&${environment.demoPayment}` +
      `&name=${this.paymentForm.value.name}` +
      "&zip=" + this.paymentForm.value.zip;
    address = address.replaceAll(" ", "%20");
    let requestedURLSecond = '&routing=' + this.paymentForm.value.routing_number +
      '&account=' + this.paymentForm.value.account_number +
      "&media=ach" + address
    return requestedURLSecond;
  }

  // creditCardPaymentData() {
  //   let card = this.paymentForm.value.card_number.trim().replaceAll(" ", "");
  //   let address: any;
  //   address = "&verify=y" +
  //     "&address1=" + this.paymentForm.value.billing_address +
  //     "&city=" + this.paymentForm.value.city +
  //     "&state=" + this.paymentForm.value.state +
  //     "&demo=y" + 
  //     "&name=George"
  //     "&zip=" + this.paymentForm.value.zip;
  //     address = address.replaceAll(" ", "%20");
  //   let requestedURLSecond = '&cc=' + card +
  //     '&exp=' + this.paymentForm.value.expiration_month + this.paymentForm.value.expiration_year +
  //     '&cvv=' + this.paymentForm.value.cvv + address
  //   return requestedURLSecond;
  // }

  creditCardPaymentData() {
    let shortExpYear = parseInt(this.paymentForm.value.expiration_year) % 100
    let card = this.paymentForm.value.card_number.trim().replaceAll(" ", "");
    let address: any;
    address =
      `&address1= ${this.paymentForm.value.billing_address}, ${this.paymentForm.value.city}, ${this.paymentForm.value.state}` +
      "&verify=y" +
      `&${environment.demoPayment}` +
      `&name=${this.paymentForm.value.name}` +
      "&zip=" + this.paymentForm.value.zip;
    address = address.replaceAll(" ", "%20");
    let requestedURLSecond = '&cc=' + card +
      '&exp=' + this.paymentForm.value.expiration_month + shortExpYear +
      '&cvv=' + this.paymentForm.value.cvv + address
    return requestedURLSecond;
  }

  // getPaymentURL(){
  //   let data = {
  //     policyNumber: this.policyNumber,
  //     accountCode: this.tenant
  //   }
  //   this.http.post(`paymentURL`, data, true, true).subscribe((resp) => {
  //     console.log(resp, "token recd")
  //   })
  // }

  // getEncryptionToken() {
  //   this.http
  //     .get(
  //       `getEncryptionToken?accountCode=${this.tenant}`,
  //       null,
  //       true
  //     )
  //     .subscribe(
  //       (resp) => {
  //         if (resp) {
  //           this.key = atob(resp.out.encryptionKey.default);
  //         }
  //       },
  //       (error) => {
  //         const errorText = `Please Contact System Administrator.`;
  //         this.utilsService.confirmModalPopup(errorText);
  //       }
  //     );
  // }

  getCancelledPolicyMessage() {
    var infoType = 'payments_cancelled_message';
    this.http
      .get(
        `getCardInfo?accountCode=${this.tenant}&infoType=${infoType}`,
        null,
        true
      )
      .subscribe(
        (resp) => {
          if (resp) {
            if (resp.out != null) {
              this.cancelledPolicyMessage = resp.out;
            }
          }
        },
        (error) => {
          const errorText = `Please Contact System Administrator.`;
          this.utilsService.confirmModalPopup(errorText);
        }
      );
  }

  getPaymentMortgageeAlertMessages() {
    var infoType = 'payment_Mortgagee_alert_message';
    this.http
      .get(
        `getCardInfo?accountCode=${this.tenant}&infoType=${infoType}`,
        null,
        true
      )
      .subscribe(
        (resp) => {
          if (resp) {
            if (resp.out != null) {
              this.mortgageeAlertMessage1 = resp.out.message1;
              this.mortgageeAlertMessage2 = resp.out.message2;
            }
          }
        },
        (error) => {
          const errorText = `Please Contact System Administrator.`;
          this.utilsService.confirmModalPopup(errorText);
        }
      );
  }

  getPolicyDetails(curPolicyNo) {
    let policySumary = []
    policySumary = this.privateDataShare.getPolicyDetails().PolicySummary
    this.selectedAddressObj = policySumary.find(resp => resp.policyDetails.policyNumber == curPolicyNo).policyDetails
    // this.addressObj = JSON.parse(
    //   sessionStorage.getItem('policyDetails')
    // ).PolicySummary;
    // this.selectedAddressObj = this.addressObj[this.policyIndex].policyDetails;
    this.policyStatus = this.selectedAddressObj['status']
    if (this.policyStatus == 'Cancelled' && !this.allowMakePayment) // check if status is cancelled and make payment is not allowed
    { this.paymentForm.disable() }
    else {
      this.paymentForm.enable()
    }
    this.paymentForm.patchValue({
      name: this.selectedAddressObj['primaryInsured']['fullName'],
      billing_name: this.selectedAddressObj['primaryInsured']['fullName'],
      effectiveDate: this.selectedAddressObj['effectiveDate']
    });
  }

  // getBillingDetails() {
  //   this.dashboardService.getPolicyInfoByPolicyNumber().subscribe((resp) => {
  //     if(resp.billingDetails)
  //     this.paymentForm.patchValue({
  //       billing_address: resp.billingDetails.billingAddress.addressLine1,
  //       city: resp.billingDetails.billingAddress.city,
  //       zip: resp.billingDetails.billingAddress.zipCode,
  //       state: resp.billingDetails.billingAddress.stateCode,
  //     });
  //   });
  // }
  initForm() {
    this.policiesForm = this.formBuilder.group({
      selectedAddress: [],
    });
    this.paymentForm = this.formBuilder.group({
      customAmount: [],
      selectedAmount: ['amountDue'],
      selectPaymentOption: ['selectNew'],
      amount: [null, [Validators.required]],
      name: [null, [Validators.required]],
      card_number: [
        '',
        [
          Validators.required,
          Validators.minLength(12),
          Validators.maxLength(23),
        ],
      ],
      expiration_month: [
        '',
        [
          Validators.required,
          Validators.pattern('[0-9]*'),
          Validators.minLength(2),
          Validators.maxLength(2),
        ],
      ],
      expiration_year: [
        '',
        [
          Validators.required,
          Validators.pattern('^[0-9]{4}'),
          // Validators.minLength(4),
          // Validators.maxLength(4),
        ],
      ],
      routing_number: [null],
      account_number: [null],
      billing_name: [null, [Validators.required]],
      billing_address: [null, [Validators.required]],
      city: [null, [Validators.required]],
      zip: [null, [Validators.required]],
      cvv: ['', [Validators.required, Validators.pattern(/^[0-9]{3}$/)]],
      billing_address2: [''],
      state: [null, [Validators.required]],
      payment_method: ['Credit Card', [Validators.required]],
      effectiveDate: []
    });
    this.paymentForm.value.expiration =
      this.paymentForm.value.expiration_month +
      this.paymentForm.value.expiration_year;
    // if (this.billTo == 'Mortgage Bill') {
    //   this.paymentForm.patchValue({
    //     selectedAmount: 'remainingBalance'
    //   })
    // }
  }

  addCreditCardControls(){
    this.paymentForm.addControl('card_number', this.formBuilder.control('', [
      Validators.required,
      Validators.minLength(12),
      Validators.maxLength(23),
    ]));
    this.paymentForm.addControl('expiration_month', this.formBuilder.control('',[
        Validators.required,
        Validators.pattern('[0-9]*'),
        Validators.minLength(2),
        Validators.maxLength(2)
    ]));
    this.paymentForm.addControl('expiration_year', this.formBuilder.control('',[
      Validators.required,
      Validators.pattern('^[0-9]{4}')
    ]));
    this.paymentForm.addControl('cvv', this.formBuilder.control('',[
      Validators.required, Validators.pattern(/^[0-9]{3}$/)
    ]));
  }

  addBankAccountControls(){
    this.paymentForm.addControl('routing_number', this.formBuilder.control('',[
      Validators.required, Validators.pattern('^[0-9]{9}$')
    ]));
    this.paymentForm.addControl('account_number', this.formBuilder.control('',[
      Validators.required
    ]));
  }

  removeCreditCardControls(){
    this.paymentForm.removeControl('card_number'); 
    this.paymentForm.removeControl('expiration_month');
    this.paymentForm.removeControl('expiration_year');
    this.paymentForm.removeControl('cvv');
  }

  removeBankAccountControls() {
    this.paymentForm.removeControl('routing_number');
    this.paymentForm.removeControl('account_number');
  }

  determinePaymentMethod(selectedMethod) {   
    if (selectedMethod) {
      this.paymentForm.patchValue({
        selectPaymentOption: 'selectNew'
      })
    }
    if (selectedMethod == 'Bank Account') {
      this.removeCreditCardControls();
      this.addBankAccountControls();
    } else if (selectedMethod == 'Credit Card') {
      this.removeBankAccountControls();
      this.addCreditCardControls();
    }
  }
  stepperSelected(selectedTab) {
    // this.stepper['selectedIndex'] = selectedTab.selectedIndex
    // console.log("slected tab",this.stepper.selectedIndex)
    return selectedTab;
  }
  resetStepper(stepper: MatStepper) {
    this.notClickable = false;
    this.type = ''
    stepper.selectedIndex = 0;
    this.isSubmitted = false;
  }
  goBack(stepper: MatStepper) {
    stepper.previous();
  }
  goForward(stepper: MatStepper) {
    this.isSubmitted = true;
    if (stepper.selectedIndex === 0) {
      this.notClickable = true;
      this.validateAmount();
      if (this.paymentForm.valid || this.selectedExistingPaymentMethod) {
        this.getPaymentAuthorization();
        this.status = null;
        this.amount = this.paymentForm.value.amount;
        if (this.paymentForm.value.payment_method == 'Credit Card') {
          if(this.selectedExistingPaymentMethod) {
            this.paymentOptionList.creditCardList.forEach(cc => {
              if(cc.cardId === this.paymentMethodId) {
                this.lastfour = cc.creditCardNo.substr(cc.creditCardNo.length - 4);          
              }
            })           
          }
          else {
            let cardNumber = this.paymentForm.value.card_number.replace(/ /g, '');
            this.lastfour = cardNumber.substr(cardNumber.length - 4);
          }
        } else if (this.paymentForm.value.payment_method == 'Bank Account') {
          if(this.selectedExistingPaymentMethod) {
            this.paymentOptionList.bankAcntList.forEach(ba => {
              if(ba.id === this.paymentMethodId) {
                this.lastfour = ba.accountNo.substr(ba.accountNo.length - 4);          
              }
            })           
          } else {
            this.lastfour =
            this.paymentForm.value.account_number.length < 4
              ? this.paymentForm.value.account_number
              : this.paymentForm.value.account_number.substr(
                this.paymentForm.value.account_number.length - 4
              );
          }
        }
        stepper.next()
      }
    }
    else if (stepper.selectedIndex === 1) {
      this.notClickable = true;
      if (this.paymentForm.valid && !this.selectedExistingPaymentMethod) {
        this.getPaymentTokenAndURL()
      }
      else if(this.selectedExistingPaymentMethod) {
        var paymentResource = this.getPaymentResource(this.paymentMethodId);
        this.cardStatusInTC = 'accepted';
        this.paymentAccount = '**** '+this.lastfour;
        this.loader.show();
        this.makeBillingPolicyPayment(paymentResource);
      }
      // if (this.paymentForm.value.payment_method == 'Credit Card') {
      //   const data = {
      //     cardNumber: this.paymentForm.value.card_number.replace(/\s/g, ''),
      //     expirationDate:
      //       this.paymentForm.value.expiration_month +
      //       this.paymentForm.value.expiration_year,
      //     securityCode: this.paymentForm.value.cvv,
      //     amount: this.paymentForm.value.amount,
      //     referenceId: this.policyNumber,
      //     address1: this.paymentForm.value.billing_address,
      //     zip: this.paymentForm.value.zip,
      //     cardType: this.cardType,
      //   };
      //   const ciphertext = CryptoJS.AES.encrypt(JSON.stringify(data), this.key).toString();
      //   const creditCardData = {
      //     data: ciphertext
      //   }
      //   this.makeCreditCardPayment(creditCardData);
      // } else if (this.paymentForm.value.payment_method == 'Bank Account') {
      //   const data = {
      //     accountNumber: this.paymentForm.value.account_number.replace(
      //       /\s/g,
      //       ''
      //     ),
      //     routingNumber: this.paymentForm.value.routing_number.replace(
      //       /\s/g,
      //       ''
      //     ),
      //     accountOwner: this.paymentForm.value.name,
      //     amount: this.paymentForm.value.amount,
      //     referenceId: this.policyNumber,
      //   };
      //   const ciphertext = CryptoJS.AES.encrypt(JSON.stringify(data), this.key).toString();
      //   const bankData = {
      //     data: ciphertext
      //   }
      //   this.makeBankAccountPayment(bankData);
      // }
    }
  }

  navigateToLink(page) {
    const tenant = this.route.parent.snapshot.params.tenant;
    this.router.navigate([`${tenant}/${page}`]);
  }

  amountSelection(type) {
    if (type != 'customAmount') {
      this.paymentForm.get('customAmount').setErrors(null);
      this.paymentForm.patchValue({
        customAmount: null,
      });
    }
  }

  paymentMethodSelection(paymentId) {
    if(paymentId == 'selectNew') {
      this.selectedExistingPaymentMethod = false;
      if (this.paymentForm.value.payment_method == 'Credit Card') {
        this.addCreditCardControls();
      } else {
        this.addBankAccountControls();
      }

    } else {
      this.selectedExistingPaymentMethod = true;
        this.removeCreditCardControls();
        this.removeBankAccountControls();
      this.paymentMethodId = paymentId;
    }
  }
  // makeBankAccountPayment(data) {
  //   this.dashboardService.makeBankAccountPayment(data).subscribe(
  //     (resp) => {
  //       this.status = resp.status;
  //       this.transactionId = resp.transid;
  //       var cardNumber = this.paymentForm.value.account_number.replace(
  //         /\s/g,
  //         ''
  //       );
  //       if (cardNumber != null) {
  //         var len = cardNumber.length;
  //         this.lastFourDigits =
  //           len > 4 ? cardNumber.substr(len - 4, len) : cardNumber;
  //         var pre = Array(len - 4)
  //           .fill('X')
  //           .join('');
  //         this.paymentAccount = pre + this.lastFourDigits;
  //       }
  //       if ('declinetype' in resp) {
  //         this.type = resp.declinetype;
  //       } else if ('offenders' in resp) {
  //         this.type = resp.offenders;
  //       }
  //       // console.log("Response from bank account payment:", this.paymentStatus);
  //     },
  //     (error) => {
  //       const errorText = `Please Contact System Administrator.`;
  //       this.utilsService.confirmModalPopup(errorText);
  //     }
  //   );
  // }
  // makeCreditCardPayment(data) {
  //   this.dashboardService.makeCreditCardPayment(data).subscribe(
  //     (resp) => {
  //       this.status = resp.status;
  //       this.transactionId = resp.transid;
  //       var cardNumber = this.paymentForm.value.card_number;
  //       if (cardNumber != null) {
  //         var len = cardNumber.length;
  //         this.lastFourDigits =
  //           len > 4 ? cardNumber.substr(len - 4, len) : cardNumber;
  //         var pre = Array(len - 4)
  //           .fill('X')
  //           .join('');
  //         this.paymentAccount = pre + this.lastFourDigits;
  //       }
  //       if (resp.status !== 'accepted' || resp.status !== 'approved') {
  //         this.type = resp.message;
  //       }
  //     },
  //     (error) => {
  //       const errorText = `Please Contact System Administrator.`;
  //       this.utilsService.confirmModalPopup(errorText);
  //     }
  //   );
  // }

  autoTab(eve) {
    if (eve.length > 1) {
      if (eve > 12) {
        this.paymentForm.patchValue({
          expiration_month: `0${eve % 10}`,
        });
      }
      if (this.paymentForm.value.expiration_month == 0) {
        this.paymentForm.patchValue({ expiration_month: '01' });
      }
      document.getElementById('yearInput').focus();
      this.checkPastYear();
    }
  }

  checkPastYear() {
    var ex_year = this.paymentForm.value.expiration_year;
    var ex_month = this.paymentForm.value.expiration_month;
    if (ex_month.length == 2 && ex_year.length == 4) {
      // var curYear = (new Date().getFullYear() / 100).toFixed();
      var expiredDate = moment(`${ex_year}-${ex_month}-01 00:00:00`)
        .endOf('month')
        .toDate();
      console.log(expiredDate);
      if (new Date() < expiredDate) {
        this.paymentForm.get('expiration_year').setErrors(null);
        // this.paymentForm.get('expiration_year').updateValueAndValidity()
      } else {
        this.paymentForm.get('expiration_year').setErrors({ pastDate: true });
      }
    }
  }

  cardNumberInput(value) {
    this.subject.next(value);
  }

  validateCard(resp) {
    this.paymentForm.patchValue({ cvv: '' });
    let tempValue = resp.split(' ').join('');
    if (tempValue.match(/^4[0-9]{12}(?:[0-9]{6})?/)) this.cardType = 'Visa';
    else if (tempValue.match(/^6(?:011|5[0-9]{2})[0-9]{12}/))
      this.cardType = 'Discover';
    else if (
      tempValue.match(
        /^(5[1-5][0-9]{14}|2(22[1-9][0-9]{12}|2[3-9][0-9]{13}|[3-6][0-9]{14}|7[0-1][0-9]{13}|720[0-9]{12}))/
      )
    )
      this.cardType = 'Mastercard';
    else if (tempValue.match(/^3[47][0-9]{13}$/))
      this.cardType = 'American Express';
    else if (tempValue.match(/^3(?:0[0-5]|[68][0-9])[0-9]{11}$/))
      this.cardType = 'DinersCC';
    else this.cardType = '';
    this.checkErrors(tempValue);
    this.setCVV();
  }

  setCVV() {
    if (this.cardType == 'American Express') {
      this.paymentForm
        .get('cvv')
        .setValidators([Validators.required, Validators.pattern(/^[0-9]{4}$/)]);
      this.paymentForm.get('cvv').updateValueAndValidity();
    } else if (this.cardType) {
      this.paymentForm
        .get('cvv')
        .setValidators([Validators.required, Validators.pattern(/^[0-9]{3}$/)]);
      this.paymentForm.get('cvv').updateValueAndValidity();
    } else {
      this.paymentForm
        .get('cvv')
        .setValidators([Validators.required, Validators.pattern(/^[0-9]{3}$/)]);
      this.paymentForm.get('cvv').updateValueAndValidity();
    }
  }

  checkErrors(number) {
    if (number) {
      if (!this.luhnValidator(number)) {
        this.paymentForm.get('card_number').setErrors(null);
        this.paymentForm.get('card_number').setErrors({ luhnError: true });
      } else if (this.cardType == '') {
        this.paymentForm.get('card_number').setErrors(null);
        this.paymentForm.get('card_number').setErrors({ cardTypeNull: true });
      } else if (!this.allowedCardTypes.includes(this.cardType)) {
        this.paymentForm.get('card_number').setErrors(null);
        this.paymentForm.get('card_number').setErrors({ cardType: true });
      } else {
        this.paymentForm.get('card_number').setErrors(null);
      }
    }
  }

  luhnValidator(cardNo) {
    let cardNumber = cardNo.split(' ').join('');
    return (
      /^\d+$/.test(cardNumber) &&
      cardNumber
        .split('')
        .reverse()
        .reduce((sum, d, n) => {
          return +sum + (n % 2 ? [0, 2, 4, 6, 8, 1, 3, 5, 7, 9][+d] : +d);
        }, 0) %
      10 ==
      0
    );
  }

  printReceipt() {
    window.print();
  }

  getInstallmentDetails() {
    this.loader.show()
    this.dashboardService.getBillingPolicyInfo(false).subscribe((resp) => {
      this.currentTermPaymentDetails = null
      this.renewalTermPaymentDetails = null
      this.termsLength = resp.length;
      for (var paymentInfo of resp) {
        if (paymentInfo.termType == 'Current') {
          this.currentTermPaymentDetails = paymentInfo;
        } else if (paymentInfo.termType == 'Future') {
          this.renewalTermPaymentDetails = paymentInfo;
        }
      }
      this.termType = ((!this.currentTermPaymentDetails) && (this.renewalTermPaymentDetails)) ? "Future" : "Current";
      this.getPaymentSummary();
      this.getPaymentMethods();
    });
  }

  getBillingAddress(termPaymentDetails) {
    this.paymentForm.patchValue({
      billing_address: termPaymentDetails.addressLine1,
      city: termPaymentDetails.city,
      zip: termPaymentDetails.zipCode,
      state: termPaymentDetails.state
    })
  }

  getPaymentSummary() {
    var paymentList = [];
    //this.paymentList=[]
    this.remainingBalance = '0.00';
    this.nextPaymentDue = '0.00';
    if (this.policyStatus != 'Cancelled' || (this.policyStatus == 'Cancelled' && this.allowMakePayment)) {
      if (this.termType == 'Current') {
        paymentList = this.currentTermPaymentDetails.policy.policyInstallments.InstallmentInfo;
        this.billTo = this.currentTermPaymentDetails.policy.billPlan.billType;
        this.nextPaymentDue = this.currentTermPaymentDetails.policy.nextAmountDue;
        this.remainingBalance = this.currentTermPaymentDetails.policy.payOffBalance;
        this.billingAccountId = this.currentTermPaymentDetails.policy.billingAccountInfo.accountId;
        this.policyId = this.currentTermPaymentDetails.policy.policyId;
        this.getBillingAddress(this.currentTermPaymentDetails.billingAddress)
      } else if (this.termType == 'Future') {
        paymentList = this.renewalTermPaymentDetails.policy.policyInstallments.InstallmentInfo;
        this.billTo = this.renewalTermPaymentDetails.policy.billPlan.billType;
        this.nextPaymentDue = this.renewalTermPaymentDetails.policy.nextAmountDue;
        this.remainingBalance = this.renewalTermPaymentDetails.policy.payOffBalance;
        this.billingAccountId = this.renewalTermPaymentDetails.policy.billingAccountInfo.accountId;
        this.policyId = this.renewalTermPaymentDetails.policy.policyId;
        this.getBillingAddress(this.renewalTermPaymentDetails.billingAddress)
      }
      if (this.billTo == 'Mortgage Bill' || this.billTo == 'MortgageBill') {
        this.paymentForm.patchValue({
          selectedAmount: 'remainingBalance',
        });
      }
      for (var i = 0, j = 0; i < paymentList.length; i++) {
        paymentList[i]['status'] =
          paymentList[i]['status'] == 'Open'
            ? 'Billed'
            : paymentList[i]['status'];
        if (paymentList[i]['status'] != 'Failed') {
      //     paymentList[i]['fees'] = (
      //       parseFloat(paymentList[i]['installmentFee']) +
      //       parseFloat(paymentList[i]['lateFee']) +
      //       parseFloat(paymentList[i]['penaltyFee']) +
      //       parseFloat(paymentList[i]['policyFee']) +
      //       parseFloat(paymentList[i]['premiumTax'])
      //     ).toFixed(2);
      //     paymentList[i]['premium'] = (
      //       parseFloat(paymentList[i]['dueAmount']) -
      //       parseFloat(paymentList[i]['fees'])
      //     ).toFixed(2);

      //     if (j == 0 && paymentList[i]['balanceDue'] != '0.00') {
      //       this.nextPaymentDue = paymentList[i]['balanceDue'];
      //       j++;
      //     }
      //     this.remainingBalance = (
      //       parseFloat(this.remainingBalance) +
      //       parseFloat(paymentList[i]['balanceDue'])
      //     ).toFixed(2);
          if (
            this.termType == 'Current' &&
            this.remainingBalance == 0.0 &&
            this.termsLength > 1 &&
            !this.termChange
          ) {
            this.changeTerm();
          }
        }
      }
    } else {
      this.remainingBalance = '0.00';
      this.nextPaymentDue = '0.00';
      this.billTo = ''
    }
    this.hasBalanceDue = !(this.nextPaymentDue === '0.00');
  }

  getPaymentMethods() {
    var paymentMethods = [];
    this.paymentOptionList.creditCardList = [];
    this.paymentOptionList.bankAcntList = [];
    this.dashboardService.setSurePCBRestJWTToken().then(() => {
      let accountId;
      if (this.policyStatus != 'Cancelled' || (this.policyStatus == 'Cancelled' && this.allowMakePayment)) {
        if (this.termType == 'Current') {
          accountId = this.currentTermPaymentDetails.policy.billingAccountInfo.accountId;
        } else if (this.termType == 'Future') {
          accountId = this.renewalTermPaymentDetails.policy.billingAccountInfo.accountId;
        }
      }
      this.http.privateBillingRestGet(`getPaymentMethods?accountCode=${this.tenant}&accountId=` + encodeURIComponent(accountId), null, false).subscribe(
        (resp) => {
          this.loader.hide();
          if (resp) {
            paymentMethods = resp;
            if (paymentMethods.length > 0) {
              paymentMethods.forEach(paymentMethod => {
                if (paymentMethod.type == 'creditCardAccounts') {
                  var expMonth = paymentMethod.attributes.cardExpirationMonth;
                  var expYear = paymentMethod.attributes.cardExpirationYear;
                  var cardExpiry = expMonth.toString().length === 1 ? "0" + expMonth + "/" + expYear : expMonth + "/" + expYear;
                  var ccType = paymentMethod.attributes.cardType === "AmericanExpress"?"American Express":paymentMethod.attributes.cardType;
                  var creditCard = {
                    cardHolderName: paymentMethod.attributes.cardHolderName,
                    creditCardNo: "**** " + paymentMethod.attributes.lastFourDigits,
                    creditCardExpiry: cardExpiry,
                    creditCardType: ccType,
                    cardId: paymentMethod.id
                  };
                  this.paymentOptionList.creditCardList.push(creditCard);
                } else {
                  var bankAccount = {               
                    accountOwnerName: paymentMethod.attributes.accountOwnerName,
                    accountNo: "**** " + paymentMethod.attributes.lastFourDigits,
                    routingNumber: paymentMethod.attributes.routingNumber,
                    accountType: paymentMethod.attributes.accountType,
                    id: paymentMethod.id
                  }
                  this.paymentOptionList.bankAcntList.push(bankAccount);
                }
              });
            }
          }
        },
        (error) => {
          this.loader.hide();
          this.utilsService.confirmModalPopup(error);
        }
        );
    }).catch(error => {
      this.loader.hide();
      var err = {
        statusCode: error.statusCode,
        error: JSON.parse(error.body).error
      }
      throw err;
    });
  }

  changeTerm() {
    this.termType == 'Current'
      ? (this.termType = 'Future')
      : (this.termType = 'Current');
    this.termChange = true;
    this.getPaymentSummary();
  }

  validateAmount() {
    var paymentAmountType = this.paymentForm.get('selectedAmount').value;

    if (paymentAmountType == 'amountDue') {
      this.paymentForm.patchValue({
        amount: this.nextPaymentDue,
      });
      this.paymentForm.get('customAmount').setErrors(null);
    } else if (paymentAmountType == 'remainingBalance') {
      this.paymentForm.patchValue({
        amount: this.remainingBalance,
      });
      this.paymentForm.get('customAmount').setErrors(null);
    } else if (paymentAmountType == 'customAmount') {
      if (
        this.paymentForm.get('customAmount').value == null ||
        parseFloat(this.paymentForm.get('customAmount').value) <
        parseFloat(this.nextPaymentDue)
      ) {
        this.paymentForm
          .get('customAmount')
          .setErrors({ noMinimumAmount: true });
        this.paymentForm.get('amount').setErrors({ required: false });
      } else {
        this.paymentForm.get('customAmount').setErrors(null);
        this.paymentForm.get('amount').setErrors({ required: true });
        this.paymentForm.patchValue({
          amount: parseFloat(
            this.paymentForm.get('customAmount').value
          ).toFixed(2),
        });
      }
    }
  }

  getSupportedPaymentDetails() {
    const data = {
      referenceId: this.policyNumber[0] + '0'.repeat(this.policyNumber.length - 1),
      accountCode: this.tenant
    };
    this.http.privatePost(`supportedPayments?accountCode=${this.tenant}`, data, true).subscribe(
      (resp) => {
        this.paymentMethodsList = resp.supportedPaymentMethods;
        this.allowedCardTypes = resp.supportedCardTypes;
        let paymentPlanControlWhenBillToIsMortgagee =
          resp.paymentPlanControlWhenBillToIsMortgagee;   
        if(resp.allowCustomAmountPayment != null) {
          this.customAmountControlProperty = true;
          this.allowCustomAmount = resp.allowCustomAmountPayment;
        }    
        if (paymentPlanControlWhenBillToIsMortgagee != null) {
          this.NextAmountDueControl =
            paymentPlanControlWhenBillToIsMortgagee.NextAmountDue;
          this.RemainingBalanceContol =
            paymentPlanControlWhenBillToIsMortgagee.RemainingBalance;
          this.CustomAmountControl =
            paymentPlanControlWhenBillToIsMortgagee.CustomAmount;
        } 
      },
      (error) => {
        const errorText = `Payment method or card types aren't available.`;
        this.utilsService.confirmModalPopup(errorText);
      }
    );
  }

  getPolicyIndex(policyNo) {
    this.isSubmitted = false;
    this.termChange = false;
    this.termType = 'Current';
    this.paymentForm.patchValue({
      selectPaymentOption: 'selectNew'
    });
    this.addCreditCardControls();
    // this.policyIndex = index;
    this.policyNumber = policyNo;
    this.getPolicyDetails(policyNo);
    // this.getBillingDetails();
    this.getInstallmentDetails();
  }

  getPaymentAuthorization() {
    this.dashboardService
      .getCardInfo('payment_authorization')
      .subscribe((resp) => {
        if (resp) {
          this.paymentAuthorization = resp;
        }
      });
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.isLargeWindow = window.innerWidth >= 900;
    this.isSmallWindow = window.innerWidth < 540;
  }
}
