<div>
    <ng-container *ngIf="checkObject(selectedAddressObj); else showEmptyPolicyForm">

        <div class="row form-group">
            <div class="col-lg-6 p-b-5">
                <div class="custom-title-info account-title" [ngStyle]="styling?.attributeKey">{{'POLICY_TYPE' | translate}}</div>
                <div class="custom-title-infoDetail account-description" [ngStyle]="styling?.attributeValue">{{policyType}}</div>
            </div>
            <div class="col-lg-6">
                <div class="custom-title-info account-title" [ngStyle]="styling?.attributeKey">{{'POLICY_TERM' | translate}}</div>
                <div class="custom-title-infoDetail account-description" [ngStyle]="styling?.attributeValue">{{selectedAddressObj?.effectiveDate  | date: 'MM/dd/yyyy'}} - {{selectedAddressObj?.expirationDate  | date: 'MM/dd/yyyy'}}</div>
            </div>
        </div>

        <div class="row form-group">
            <div class="col-lg-6 p-b-5">
                <div class="custom-title-info account-title" [ngStyle]="styling?.attributeKey">{{'PRIMARY_INSURED' | translate}}</div>
                <div class="custom-title-infoDetail account-description" [ngStyle]="styling?.attributeValue">{{selectedAddressObj?.primaryInsured?.fullName}}</div>
            </div>
            <div class="col-lg-6">
                <div class="custom-title-info account-title" [ngStyle]="styling?.attributeKey">{{'CO_INSURED' | translate}}</div>
                <div class="custom-title-infoDetail account-description" [ngStyle]="styling?.attributeValue">
                    <ng-container *ngIf="selectedAddressObj.coInsured === undefined
                    || selectedAddressObj.coInsured.Insured === undefined
                    ; else coInsuredNames"> {{"N/A"}}     
                    </ng-container>
                    <ng-template #coInsuredNames>
                        <div *ngFor="let coInsuredPerson of selectedAddressObj?.coInsured?.Insured"> 
                            {{coInsuredPerson?.fullName}}  
                        </div>
                    </ng-template>
                </div>
            </div>
        </div>
        <div class="row form-group">
            <div class="col-lg-6 p-b-5">
                <div class="custom-title-info account-title" [ngStyle]="styling?.attributeKey">{{'EMAIL_ON_POLICY' | translate}}</div>
                <div class="custom-title-infoDetail account-description" [ngStyle]="styling?.attributeValue">{{selectedAddressObj?.primaryInsured?.email}}</div>
            </div>
            <div class="col-lg-6">
                <div class="custom-title-info account-title" [ngStyle]="styling?.attributeKey">{{'MAILING_ADDRESS' | translate}}</div>
                <div class="custom-title-infoDetail account-description" [ngStyle]="styling?.attributeValue">
                    {{selectedAddressObj?.primaryInsured?.mailingAddress?.addressLine1}}, 
                    {{selectedAddressObj?.primaryInsured?.mailingAddress?.city}}, 
                    {{selectedAddressObj?.primaryInsured?.mailingAddress?.stateCode}} {{selectedAddressObj?.primaryInsured?.mailingAddress?.zipCode}}</div>
            </div>
        </div>
        <div class="row form-group">
            <div class="col-lg-6 p-b-5">
                <div class="custom-title-info account-title" [ngStyle]="styling?.attributeKey">{{'MORTGAGE_COMPANY' | translate}}</div>
                <div class="custom-title-infoDetail account-description" [ngStyle]="styling?.attributeValue">{{mortgagee}}</div>
            </div>
            <!-- <div class="col-lg-6">
                <div class="custom-title-info account-title" [ngStyle]="styling?.attributeKey">{{'BILLING_ADDRESS' | translate}}</div>
                <div class="custom-title-infoDetail custom-description account-description" [ngStyle]="styling?.attributeValue">{{billingAddress}}</div>
            </div> -->
        </div>
    </ng-container>
    <ng-template #showEmptyPolicyForm>
        <div class="card custom-empty-card">
            <div class="card-body">
                <h4 class="card-title header-font text-center custom-description" [ngStyle]="styling?.paragraphText">
                    {{'NO_POLICY_MSG' | translate}}
                </h4>
            </div>
        </div>
    </ng-template>

    <div class="row form-group">
        <div class="col-lg-6 p-b-10" *ngIf="displayButton.policyCoverages">
            <button class="btn btn-info btn-full-width-mob custom-button-info buttons" 
            (click)="navToLink('home/view-policies/view-policy-coverages')"
            [ngStyle]="styling?.button?.buttonNavigation">{{'VIEW_POLICY_COVERAGES' | translate}}</button>
        </div>
        <div class="col-lg-6" *ngIf="displayButton.policyDocuments">
            <button class="btn btn-info btn-full-width-mob custom-button-info buttons" 
            (click)="navToLink('home/view-policies/view-policy-documents')"
            [ngStyle]="styling?.button?.buttonNavigation"
            >{{'VIEW_POLICY_DOCUMENTS' | translate}}</button>
        </div>
    </div>
</div>