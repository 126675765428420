<div class="modal-header">
    <h4 class="modal-title pull-left" [ngStyle]="styling?.paragraphTitle">{{title}}</h4>
    <button type="button" class="btn-close close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
        <span aria-hidden="true" class="visually-hidden">&times;</span>
    </button>
</div>
<div class="modal-body">
    <!-- <ul *ngIf="list.length">
        <li *ngFor="let item of list">{{item}}</li>
    </ul> -->
    <div class="table-responsive" style="max-height: 300px;">
        <table class="table table-striped">
            <thead style="background-color: #A9A9A9;">
                <tr>
                    <th [ngStyle]="styling?.tableHeader">FileName</th>
                </tr>
            </thead>
            <tbody>
                <ng-container *ngIf="fileList?.length; else noFilesContainer">
                    <ng-container *ngFor="let file of fileList">
                        <tr [ngStyle]="styling?.tableValue">
                            <td><a (click)="showFileInNewTab(file?.id)" [ngStyle]="styling?.tableValue" class="fileLink">{{file.fileName}}</a></td>
                        </tr>
                    </ng-container>
                </ng-container>
                <ng-template #noFilesContainer>
                    <tr>
                        <td colspan="2" class="text-center" [ngStyle]="styling?.paragraphText">No Data available.</td>
                    </tr>
                </ng-template>
            </tbody>
        </table>
    </div>
    <!-- <ng-container *ngIf="list.length">
        <p class="list-hyperlink" *ngFor="let item of list" (click)="showFileInNewTab(item)">{{item}}</p>
    </ng-container> -->
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-info buttons" (click)="bsModalRef.hide()">{{closeBtnName}}</button>
</div>