import { Component, OnInit, HostListener } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { DasboardService } from '../../dashboard/dasboard.service';
import { CommonApiService } from '../../../shared/services/common-api.service';
import { UtilsService } from '../../../shared/services/utils.service';
import { ActivatedRoute, Router } from '@angular/router';
import { formatCurrency } from '@angular/common';
import { formatNumber } from '@angular/common';
import { PrivateDataShare } from '../../../shared/services/privateDataShare.service';

@Component({
  selector: 'app-view-policy-coverages',
  templateUrl: './view-policy-coverages.component.html',
  styleUrls: ['./view-policy-coverages.component.scss'],
  providers: [CommonApiService, DasboardService]
})
export class ViewPolicyCoveragesComponent implements OnInit {
  // policiesForm: any;
  policyNumber: any;
  selectedAddressObj: any;
  addressObj = [];
  listAddress = [];
  coverageList = [];
  coverageOnlyList = [];
  noCoverageList = [];
  styling = this.utilsService.getItemfromSession('themeObject')
  isSmallWindow: boolean;
  constructor(
    private formBuilder: FormBuilder,
    private dashboardService: DasboardService,
    private utilsService: UtilsService,
    private route: ActivatedRoute,
    private router: Router,
    private privateDataShare: PrivateDataShare
  ) { }

  ngOnInit(): void {
    this.isSmallWindow = window.innerWidth <= 991;
    console.log('small window: ' + this.isSmallWindow)
    this.getPolicyDetails()
    this.getCoverages()
  }


  // getPolicyDetails(){
  //   let selectedPolicy = this.utilsService.selectedPolicy()
  //   if(sessionStorage.getItem('policyDetails') ){
  //     this.addressObj = JSON.parse(sessionStorage.getItem('policyDetails')).PolicySummary;
  //     this.listAddress =  this.addressObj.map(resp => resp.policyDetails.riskAddress.addressLine1 + ', ' +  resp.policyDetails.riskAddress.city + ', ' +  resp.policyDetails.riskAddress.stateCode+ ' ' + resp.policyDetails.riskAddress.zipCode)

  //     this.selectedAddressObj = this.listAddress[selectedPolicy]
  //     this.policyNumber =  this.utilsService.getPolicyNumber();
  //   } else{
  //     return null
  //   }
  // }

  getPolicyDetails() {
    let policyDetails = this.privateDataShare.getPolicyDetails()
    if (policyDetails) {
      this.policyNumber = this.privateDataShare.getPolicyNumber()
      let selectedPolicyDetails = policyDetails.PolicySummary.find(resp => resp.policyDetails.policyNumber == this.policyNumber).policyDetails
      let curAddressArray = [
        selectedPolicyDetails.riskAddress.addressLine1,
        selectedPolicyDetails.riskAddress.city,
        selectedPolicyDetails.riskAddress.stateCode,
        selectedPolicyDetails.riskAddress.zipCode]
      this.selectedAddressObj = curAddressArray.join(" ,")
    } else {
      return null
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.isSmallWindow = window.innerWidth <= 991;
    console.log('small window: ' + this.isSmallWindow)
  }

  getCoverages() {
    this.dashboardService.getPolicyInfoByPolicyNumber().subscribe(resp => {
      this.coverageList = resp.policy.risks.Risk[0].coverages.Coverage;
      console.log("all the coverages: " + JSON.stringify(this.coverageList))
      for (let coverage of this.coverageList) {
        if (coverage.value === "$0" || coverage.value === "false" || coverage.value === "None" || coverage.value === "none") {
          coverage.value = "Excluded";
        } else if (coverage.value === "true") {
          coverage.value = "Included";
        }

        // SFI specific: Convert the currency value without formatting or only with $ formatting to the currency formatting with both $ and comma.
        if (this.route.parent.snapshot.params.tenant.indexOf('sfi') > -1 && coverage.value !== null && coverage.value.length > 0) {
          if (coverage.value.substring(0, 1) !== "$"
            && Number.isFinite(Number(coverage.value))) {
            coverage.value = formatCurrency(coverage.value, "en_US", "$", "USD", '1.0-0');
          } else if (coverage.value.substring(0, 1) === "$"
            && coverage.value.length > 1
            && Number.isFinite(Number(coverage.value.substring(1, coverage.value.length)))) {
            coverage.value = formatCurrency(coverage.value.substring(1, coverage.value.length), "en_US", "$", "USD", '1.0-0');
          }
        }

        if (coverage.name.substring(0, 8) == "Coverage") {
          this.coverageOnlyList.push(coverage);
        } else {
          this.noCoverageList.push(coverage);
        }
      }
      console.log("coverage begin with coverage" + this.coverageOnlyList)
      console.log("coverage not begin with coverage" + this.noCoverageList)
      this.coverageOnlyList.sort((a, b) => a.name.localeCompare(b.name));
      this.noCoverageList.sort((a, b) => a.name.localeCompare(b.name));
    });
  }

  navToLink(page) {
    const tenant = this.route.parent.snapshot.params.tenant
    this.router.navigate([`${tenant}/${page}`])
  }
}
