import { Component, OnInit, Input } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { UtilsService } from '../../../shared/services/utils.service';
import { CommonApiService } from '../../../shared/services/common-api.service';
import { NotifytoastService } from '../../../shared/services/notificationToast.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-email-agent-confirmation',
  templateUrl: './email-agent-confirmation.component.html',
  styleUrls: ['./email-agent-confirmation.component.scss'],
  providers: [CommonApiService],
})
export class EmailAgentConfirmationComponent implements OnInit {
  @Input() agentDetails: any;
  @Input() quoteNumber: any;
  quoteID: any;
  agentFirstName: any; //="agentFirstName";
  agentLastName: any; //= "agentLastName";
  styling = this.utilsService.getItemfromSession('themeObject');
  tenant: any;
  customerResource: any;

  constructor(
    private http: CommonApiService,
    private route: ActivatedRoute,
    private utilsService: UtilsService,
    private sanitizer: DomSanitizer,
    private notifyToast: NotifytoastService
  ) {}

  ngOnInit(): void {
    this.getCustomerResource();
    // this.customerResource = "<p class=\"form-control-static custom-description\" style=\"text-align:center;\"><a href=\"http://www.acme-insure.com\" target=\"_blank\" style=\"font-size: 16; font-family: Helvetica; \">ACME Insurance Customer Resources</a></p>"
    // this.customerResource = this.sanitizer.bypassSecurityTrustHtml(
    //   this.customerResource
    // );
    // this.setAgentDetails();
  }

  ngOnChanges(changes: any) {
    if ('agentDetails' in changes && changes.agentDetails.currentValue) {
      this.setAgentDetails();
    }
    // if(changes.quoteNumber.currentValue){
    //   this.quoteID = this.quoteNumber
    //   console.log("change in quote number to:", this.quoteNumber)
    // }
  }

  setAgentDetails() {
    this.agentFirstName = this.agentDetails.agentFirstName;
    this.agentLastName = this.agentDetails.agentLastName;
  }

  getCustomerResource() {
    //
    this.tenant = this.route.parent.snapshot.params.tenant;
    console.log('get customer resource tenant ' + this.tenant);
    var infoType = 'agent_email_confirmation_resource';
    this.http
      .get(
        `getCardInfo?accountCode=${this.tenant}&infoType=${infoType}`,
        null,
        true
      )
      .subscribe(
        (resp) => {
          if (resp === undefined) {
            this.notifyToast.error(
              'Please Contact System Administrator.' +
                '\nError: ' +
                resp.root.Envelope.Body.Fault.faultstring
            );
          } else {
            this.customerResource = resp.out;
            this.customerResource = this.sanitizer.bypassSecurityTrustHtml(
              this.customerResource
            );
            console.log(
              'Customer resources after sending email:',
              this.customerResource
            );
          }
        },
        (error) => {
          this.notifyToast.error('Please Contact System Administrator.');
        }
      );
  }

  navigateToLink(page) {
    if (page === 'get-quote') {
      window.location.reload();
    }
  }
}
