<div [formGroup]="additionalHomeDetailsForm">
    <div class="form-group row">
        <div class="col-md-4 offset-md-4">
            <mat-label class="custom-title-info account-title">Building Material *</mat-label>
            <ng-select id="buildingMaterials_5" [items]="buildingMaterials" appendTo="body" [hideSelected]="true" placeholder="Building Material"
                formControlName="buildingMaterial">
            </ng-select>
            <p class="error-class" *ngIf="!additionalHomeDetailsForm.get('buildingMaterial').valid && isSubmitted">
                {{'REQUIRED_FIELD' | translate: {value:'Building Materials'} }}</p>
        </div>

    </div>
    <div class="form-group row">
        <div class="col-md-4 offset-md-4">
            <mat-label class="custom-title-info account-title">Building Type *</mat-label>
            <ng-select id="buildingTypes_5" [items]="buildingTypes" appendTo="body" [hideSelected]="true" placeholder="Building Type"
                formControlName="buildingType">
            </ng-select>
            <p class="error-class" *ngIf="!additionalHomeDetailsForm.get('buildingType').valid && isSubmitted">
                {{'REQUIRED_FIELD' | translate: {value:'Building Type'} }}</p>
        </div>

    </div>
    <!-- <div class="form-group row">
        <div class="col-md-4 offset-md-4">
            <mat-label class="custom-title-info account-title">Foundation Type</mat-label>
            <ng-select id="foundationTypes_5" [items]="foundationTypes" appendTo="body" [hideSelected]="true" placeholder="Foundation Type"
                formControlName="foundationType">
            </ng-select>
            <p class="error-class" *ngIf="!additionalHomeDetailsForm.get('foundationType').valid && isSubmitted">
                {{'REQUIRED_FIELD' | translate: {value:'Foundation Type'} }}</p>
        </div>

    </div> -->

    <div class="form-group row">

        <div class="col-md-4 offset-md-4">
            <div class="custom-title-info account-title">Within 1000ft. of fire hydrant? *</div>
            <div class="d-flex justify-content-center">
                <div style="width: 100px;">
                    <input type="radio" [value]="true" formControlName="nearFireHydrant" id="true"
                        class="radio-col-light-blue" />
                    <label [for]="true">Yes</label>
                </div>
                <div style="width: 100px;">
                    <input type="radio" [value]="false" formControlName="nearFireHydrant" id="false"
                        class="radio-col-light-blue" />
                    <label [for]="false">No</label>
                </div>
            </div>
            <p class="error-class" *ngIf="!additionalHomeDetailsForm.get('nearFireHydrant').valid && isSubmitted" style="text-align: center;">
                Mention whether your home is within 1000ft. of fire hydrant</p>

        </div>
    </div>

</div>