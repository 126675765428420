<div class="card custom-card-border h-100">
    <div class="card-body body-mobile">

        <h2 [ngStyle]="styling?.pageTitle" class="text-center header-two-l">
            Upload Policy Documents
        </h2>
        <div class="row">
            <app-policies-card class="col-lg-12 p-b-30" [policiesDetails]="policyInfo"
                (policyIndexChanges)="getPolicyIndex($event)"></app-policies-card>
        </div>

        <!-- <div class="row">
            <div class="table-responsive">
                <table class="table table-striped">
                    <thead style="background-color: #A9A9A9;">
                        <tr>
                            <th [ngStyle]="styling?.tableHeader">Picture Side </th>
                            <th [ngStyle]="styling?.tableHeader">Upload Doc</th>
                            <th [ngStyle]="styling?.tableHeader">Uploaded Status</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>bl</td>
                            <td>bl</td>
                        </tr>
                    </tbody>
                </table>
            </div>  
        </div> -->

        <div class="table-responsive" style="max-height: 300px;">
            <table class="table table-striped">
                <thead style="background-color: #A9A9A9;">
                    <tr>
                        <th>Picture Side</th>
                        <th style="width: 200px;">Upload Doc</th>
                        <th class="text-center">Status</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <th scope="row">Front Right Picture</th>
                        <td>
                            <label for="file-upload-front-right" class="file-upload-btn-custom"
                                style="cursor: pointer;"><span class="fas"
                                    [ngClass]="loader ? 'fa-spinner fa-spin': 'fa-upload'"></span> Upload </label>
                            <input type="file" [disabled]="loader" id="file-upload-front-right" style="display: none;"
                                (click)="fileInputCCP.value = null" #fileInputCCP
                                (change)="fileChange($event, 'Property Front Right')" />
                        </td>
                        <td class="row" style="justify-content: center;">
                            <!-- <div *ngIf="frontRightCollection.length">
                                <span class="fas fa-check-circle success-icon-table" 
                                triggers="mouseenter:mouseleave"  
                                [popover]="frontRightTemplate"
                                container="body"
                                ></span>
                            </div> -->
                            <div *ngIf="frontRightCollection.length">
                                <span class="fas fa-check-circle success-icon-table"></span>
                            </div>
                            <!-- <div *ngIf="errfrontRightCollection.length">
                                <span class="m-l-10 fas fa-times-circle error-icon-table" 
                                (click)="openModal(errfrontRightCollection, 'Files not uploaded', 'error')"
                                ></span>
                            </div>  -->
                        </td>
                    </tr>
                    <tr>
                        <th scope="row">Back Left Picture</th>
                        <td>
                            <label for="file-upload-back-left" class="file-upload-btn-custom"
                                style="cursor: pointer;"><span class="fas"
                                    [ngClass]="loader ? 'fa-spinner fa-spin': 'fa-upload'"></span> Upload </label>
                            <input type="file" [disabled]="loader" id="file-upload-back-left" style="display: none;"
                                (click)="fileInputCCP.value = null" #fileInputCCP
                                (change)="fileChange($event, 'Property Back Left')" />
                        </td>
                        <td class="row" style="justify-content: center;">
                            <div *ngIf="backLeftCollection.length">
                                <span class="fas fa-check-circle success-icon-table"></span>
                            </div>
                            <!-- <div *ngIf="backLeftCollection.length">
                                <span class="fas fa-check-circle success-icon-table" 
                                triggers="mouseenter:mouseleave"  
                                [popover]="backLeftemplate"
                                container="body"
                                ></span>
                            </div>
                            <div *ngIf="errbackLeftCollection.length">
                                <span class="m-l-10 fas fa-times-circle error-icon-table" 
                                triggers="mouseenter:mouseleave"  
                                [popover]="errbackLeftemplate"
                                container="body"
                                ></span>
                            </div> -->
                        </td>
                    </tr>
                    <tr>
                        <th scope="row">Upload Current Declarations</th>
                        <td>
                            <label for="file-upload-current-declarations" class="file-upload-btn-custom"
                                style="cursor: pointer;"><span class="fas"
                                    [ngClass]="loader ? 'fa-spinner fa-spin': 'fa-upload'"></span> Upload </label>
                            <input type="file" [disabled]="loader" id="file-upload-current-declarations"
                                style="display: none;" (click)="fileInputCCP.value = null" #fileInputCCP
                                (change)="fileChange($event, 'Declarations')" />
                        </td>
                        <td class="row" style="justify-content: center;">
                            <div *ngIf="declarationCollection.length">
                                <span class="fas fa-check-circle success-icon-table"></span>
                            </div>
                            <!-- <div *ngIf="declarationCollection.length">
                                <span class="fas fa-check-circle success-icon-table" 
                                triggers="mouseenter:mouseleave"  
                                [popover]="declarationstemplate"
                                container="body"
                                ></span>
                            </div>
                            <div *ngIf="errdeclarationCollection.length">
                                <span class="m-l-10 fas fa-times-circle error-icon-table" 
                                triggers="mouseenter:mouseleave"  
                                [popover]="errdeclarationstemplate"
                                container="body"
                                ></span>
                            </div> -->
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>

    </div>
</div>
<!-- 
<ng-template #frontRightTemplate>
    <ul>
        <li *ngFor="let item of frontRightCollection">{{item}}</li>
    </ul>
</ng-template>

<ng-template #backLeftemplate>
    <p *ngFor="let item of backLeftCollection">
        <span class="fas fa-chevron-right m-r-10"></span> <span> {{item}}</span>
    </p>
</ng-template>

<ng-template #declarationstemplate>
    <ul>
        <li *ngFor="let item of declarationCollection">{{item}}</li>
    </ul>
</ng-template>


<ng-template #errfrontRightTemplate>
    <p *ngFor="let item of errfrontRightCollection">
        <span class="fas fa-chevron-right m-r-10"></span> <span> {{item}}</span>
    </p>
</ng-template>

<ng-template #errbackLeftemplate>
    <p *ngFor="let item of errbackLeftCollection">
        <span class="fas fa-chevron-right m-r-10"></span> <span> {{item}}</span>
    </p>
</ng-template>

<ng-template #errdeclarationstemplate>
    <p *ngFor="let item of errdeclarationCollection">
        <span class="fas fa-chevron-right m-r-10"></span> <span> {{item}}</span>
    </p>
</ng-template> -->