<div [formGroup]="homeAgeAndSizeForm">
    <div class="form-group row">

        <div class="col-md-4 offset-md-4">
            <mat-form-field>
                <input matInput formControlName="yearBuilt" type="text" placeholder="Year Built *" numbersOnly maxlength="4" />
            </mat-form-field>
            <p class="error-class mat-angular-error-text text-center" *ngIf="homeAgeAndSizeForm.get('yearBuilt').errors?.required && isSubmitted" style="text-align: center;">
                {{'REQUIRED_FIELD' | translate: {value:'Year Built'} }}</p>
            <p class="error-class mat-angular-error-text text-center" *ngIf="homeAgeAndSizeForm.get('yearBuilt').errors?.min && isSubmitted">Year Built should be greater than 1650</p>
            <p class="error-class mat-angular-error-text text-center" *ngIf="homeAgeAndSizeForm.get('yearBuilt').errors?.max && isSubmitted">Year Built should be less than Roof Year</p>

        </div>
    </div>
    <div class="form-group row">

        <div class="col-md-4 offset-md-4">
            <mat-form-field>
                <input matInput formControlName="squareFeet" type="text" placeholder="Square Feet *" numbersOnly maxlength="6" />
            </mat-form-field>
            <p class="error-class mat-angular-error-text text-center" *ngIf="homeAgeAndSizeForm.get('squareFeet').errors?.required && isSubmitted">
                {{'REQUIRED_FIELD' | translate: {value:'Square Feet'} }}</p>
            <p class="error-class mat-angular-error-text text-center" *ngIf="homeAgeAndSizeForm.get('squareFeet').errors?.max && isSubmitted">Square Feet should be less than 100,000</p>
            <p class="error-class mat-angular-error-text text-center" *ngIf="homeAgeAndSizeForm.get('squareFeet').errors?.min && isSubmitted">Square Feet should be greater than 0</p>

        </div>
    </div>
    <div class="form-group row">
        <div class="w-100 d-flex justify-content-center">
            <div style="width: 150px;">
                <mat-label class="custom-title-info account-title">Number of Stories * </mat-label>
                <ng-select id="stories_7" [items]="stories" appendTo="body" [hideSelected]="true"
                    placeholder="Number of Stories" formControlName="story">
                </ng-select>
                <p class="error-class" *ngIf="!homeAgeAndSizeForm.get('story').valid && isSubmitted">
                    Number of stories is required</p>
            </div>
        </div>

    </div>
    
</div>