import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UtilsService } from '../services/utils.service';

@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss']
})
export class NotFoundComponent implements OnInit {

  constructor(private router: Router, private utilsService: UtilsService) { }

  ngOnInit(): void {
    
  }

  navigateBack(){
    const tenant = this.utilsService.getTenantFromWindow();
    this.router.navigate([`/${tenant}`])
  }

}
