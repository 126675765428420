<div class="card custom-card-border h-100">
  <div class="card-body body-mobile" >
    <div class="row">
      <div class="col-lg-4 text-right m-b-10" *ngIf="isSmallWindow">
          <button class="btn btn-info btn-full-width-mob custom-button-info buttons" 
          (click)="navToLink('home/view-policies')"
          [ngStyle]="styling?.button?.buttonNegative">
          {{'BACK_TO_POLICY' | translate}}</button>
      </div>
      <div class="col-md-8">
        <p class="custom-title-info account-title" [ngStyle]="styling?.attributeKey">
          {{'POLICY_DOCUMENT_FOR' | translate}}: 
          <span class="custom-title-infoDetail account-description" 
          [ngStyle]="styling?.attributeValue">{{this.selectedAddressObj}}</span>
        </p>
      </div>
      <div class="col-lg-4 text-right" *ngIf="!isSmallWindow">
        <button class="btn btn-info btn-full-width-mob custom-button-info buttons" 
        (click)="navToLink('home/view-policies')"
        [ngStyle]="styling?.button?.buttonNegative">
        {{'BACK_TO_POLICY' | translate}}</button>
      </div>
      <div class="col-md-12">
        <p class="custom-title-info account-title" [ngStyle]="styling?.attributeKey">
          {{'POLICY_NUMBER' | translate}}: 
          <span class="custom-title-infoDetail account-description" 
          [ngStyle]="styling?.attributeValue">{{policyNumber}}</span>
        </p>
      </div>
    </div>
    <hr>
    <div class="row">
      <div class="col-md-12">
        <label class="control-label col-mob-6 header-font custom-title" 
        [ngStyle]="styling?.attributeKey">
          {{'SELECT_POLICY_TERM_FOR_DOCUMENTS' | translate}}
        </label>

        <form [formGroup]="policiesTermForm">
          <div class="form-group custom-description">
              <ng-select id="policyTermList_4" [items]="policyTermList" [ngStyle]="styling?.content" appendTo="body" [hideSelected]="true"
                placeholder="Select Policy Term" formControlName="selectedPolicyTerm"
                bindLabel="policyTerm" [clearable]="false" (change)="selectChangePolicyTerm($event)"
                [searchable]="false" [ngStyle]="styling?.fieldDropDown">
              </ng-select>
          </div>
        </form>
      </div>
    </div>
       
    <div class="p-t-20">
      <div class="table">
        <table class="table table-striped">
          <thead class="custom-description" style="background-color: #80808085;">
            <tr>
              <th style="width: 70%;" [ngStyle]="styling?.tableHeader" class="hideContent">{{'DOCUMENT' | translate}}</th>
              <!-- <th style="font-weight: 500; width: 200px;" class="hideContent">{{'DATE' | translate}}</th> -->
              <th style="width: 30%; text-align: right;" [ngStyle]="styling?.tableHeader">{{'ACTION' | translate}}</th>
            </tr>
          </thead>
          <tbody class="custom-description">
            <ng-container *ngIf="map.get(selectedPolicyTermObj) && map.get(selectedPolicyTermObj).length; else alternateContent">
              <ng-container *ngFor="let document of map.get(selectedPolicyTermObj) | paginate: { itemsPerPage: count, currentPage: page }">
                <tr *ngIf="document.value !== 'false'" [ngStyle]="styling?.tableValue">
                  <td class="text-nowrap hideContent">
                    <a class="view-policy-button" 
                    (click)="previewDocument(document.id)"
                    [popover]="'PREVIEW_DOCUMENT' | translate" container="body" triggers="mouseenter:mouseleave"
                    appendToBody=true>
                      {{document.fileName}}
                    </a>
                  </td>
                  <!-- <td class="text-nowrap hideContent">{{document.uploadedDate}}</td> -->
                  <td class="text-nowrap" style="text-align: right; ">
                      <ng-container *ngIf="!(isSmallWindow && currentBrowser === 'firefox')">
                        <a (click)="previewDocument(document.id)" [ngStyle]="styling?.iconStyling"
                          class="view-policy-button  tags fas fa-file-alt m-r-15"
                          [popover]="'PREVIEW_DOCUMENT' | translate" container="body" triggers="mouseenter:mouseleave"  
                          container="body"> 
                        </a>
                      </ng-container>
                      <ng-container>
                        <a class="view-policy-button tags fas fa-download m-r-15" [ngStyle]="styling?.iconStyling"
                        (click)="downloadDocument(document.id)"
                        [popover]="'DOWNLOAD_DOCUMENT' | translate" container="body" triggers="mouseenter:mouseleave">
                        </a>
                      </ng-container>
                  </td>
                </tr>
              </ng-container>
            </ng-container>
            <ng-template #alternateContent>
              <tr>
                <td colspan="2" class="text-center" [ngStyle]="styling?.paragraphText">{{'NO_DOCUMENT_AVAILABLE' | translate}}</td>
              </tr>
            </ng-template>
          </tbody>
        </table>
      </div>
      <div class="text-right">
        <pagination-controls (pageChange)="page = $event"></pagination-controls>
      </div>
    </div>
      <div class="col-lg-12 text-right">
        <button class="btn btn-info btn-full-width-mob custom-button-info buttons" 
        (click)="navToLink('home/view-policies')"
        [ngStyle]="styling?.button?.buttonNegative">
        {{'BACK_TO_POLICY' | translate}}</button>
      </div>
  </div>
</div>