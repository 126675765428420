import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators, FormControl } from '@angular/forms';
import { NotifytoastService } from '../../shared/services/notificationToast.service';
import { Router, ActivatedRoute } from '@angular/router';
import { CommonApiService } from '../../shared/services/common-api.service';
import { TranslateService } from '@ngx-translate/core';
import { UtilsService } from '../../shared/services/utils.service';
import { DasboardService } from '../../private/dashboard/dasboard.service';
import { ErrorModalComponent } from '../../shared/error-modal/error-modal.component';
import { BsModalService } from 'ngx-bootstrap/modal';
import { DatePipe } from '@angular/common';
import { PrivateDataShare } from '../../shared/services/privateDataShare.service';
import { environment } from '../../../environments/environment';
const permissionKeys = require('../../shared/services/permission-keys.json');
const CryptoJS = require("crypto-js");

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  providers: [CommonApiService, DasboardService],
})
export class LoginComponent implements OnInit {
  keysAvailable = this.utilsService.getItemfromSession('permissionKeys')
    ? this.utilsService.getItemfromSession('permissionKeys')
    : [];
  displayButton: { language: any; getQuote: any, expresspay: any };
  loginForm: any;
  isSubmitted: boolean;
  showPassword = true;
  pageName: any;
  styling = this.utilsService.getItemfromSession('themeObject');
  languageList = [
    { language: 'English', langCode: 'en' },
    { language: 'Spanish', langCode: 'es' },
  ];
  expressPayUrl = '';
  loginErrorMessages: any;
  constructor(
    private notifyToast: NotifytoastService,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private http: CommonApiService,
    private router: Router,
    private translate: TranslateService,
    private utilsService: UtilsService,
    private dashboardService: DasboardService,
    private modalService: BsModalService,
    private datepipe: DatePipe,
    private privateDataShare: PrivateDataShare
  ) {}

  ngOnInit() {
    this.pageName = this.route.parent.snapshot.params.tenant;
    this.getPermissionKey();

    // const hasToken = this.utilsService.getItemfromSession('token') ? JSON.parse(sessionStorage.getItem('token')).token : null
    // if(hasToken){
    //   this.router.navigate([`${this.pageName}/home`])
    // }
    this.initForm();
  }

  getPermissionKey() {
    this.http
      .get(`accountConfiguration?accountCode=${this.pageName}`, null, true)
      .subscribe((resp) => {
        if (resp.expresspay && resp.expresspay.url)
          this.expressPayUrl = resp.expresspay.url;
        if (
          resp.content_management &&
          resp.content_management.login_error_messages
        ) {
          this.loginErrorMessages =
            resp.content_management.login_error_messages;
        }
        if (resp) {
          this.keysAvailable = resp['AccessControl']
            ? resp['AccessControl']
            : [];
          this.displayButton = {
            language: this.keysAvailable.includes(
              permissionKeys.LANGUAGE_SUPPORT
            ),
            getQuote: this.keysAvailable.includes(
              permissionKeys.GET_A_QUOTE_BUTTON
            ),
            expresspay: this.keysAvailable.includes(
              permissionKeys.EXPRESSPAY_BUTTON
            )
          };
        }
      });
  }

  initForm() {
    const u_name = localStorage.getItem('previousUser')
      ? localStorage.getItem('previousUser')
      : '';
    this.loginForm = this.formBuilder.group({
      username: [u_name, [Validators.required]],
      password: ['', [Validators.required]],
      selectedLanguage: [{ language: 'English', langCode: 'en' }],
      rememberMe: [false],
    });

    if (u_name) this.loginForm.patchValue({ rememberMe: true });
  }

  togglePassword() {
    this.showPassword = !this.showPassword;
  }

  saveLocal() {
    localStorage.setItem('previousUser', this.loginForm.value.username);
  }

  removeLocal() {
    localStorage.removeItem('previousUser');
  }

  navHome() {
    this.isSubmitted = true;
    if (this.loginForm.valid) {
      if (this.loginForm.value.rememberMe) this.saveLocal();
      else this.removeLocal();
      const data = {
        "username": this.loginForm.value.username,
        "password": this.loginForm.value.password
      }
      const key = {
        payload: atob(environment.securityCode.forgot_password.inputKey)
      }
      const ciphertext = CryptoJS.AES.encrypt(JSON.stringify(data), key.payload).toString();
      const encryptedData = {
        data: ciphertext
      }
      this.http
        .post(
          `authenticateUser?accountCode=${this.pageName}`,
          encryptedData,
          true
        )
        .subscribe(
          (resp) => {
            if (resp.out) {
              const data = {
                tenant: this.pageName,
                token: resp.out,
                username: this.loginForm.value.username,
              };
              sessionStorage.setItem('token', JSON.stringify(data));
              sessionStorage.setItem(
                'language',
                JSON.stringify(this.loginForm.value.selectedLanguage)
              );
              this.getPoliciesOfUser();
            } else {
              //const errorText = `Please Contact System Administrator.
              //Error: ${resp.root.Envelope.Body.Fault.faultstring}`
              const errorText = `Sorry, the username and/or password you entered is incorrect. Please try again.`;
              const title = `Login Error`;
              const buttonConten = `Try Again`;
              //this.utilsService.confirmModalPopup(errorText)
              this.utilsService.confirmModalPopupWithConfig(
                title,
                errorText,
                buttonConten
              );
            }
          },
          (error) => {
            this.utilsService.confirmModalPopup(
              'Either the tenant isnt registered or the credentials entered are wrong. Please verify.'
            );
          }
        );
    }
  }

  getPoliciesOfUser() {
    this.http.privateGet(`getPoliciesByUser?accountCode=${this.pageName}`, null, true).subscribe((resp) => {
      if ('out' in resp) {
          this.privateDataShare.setPolicyDetails(resp.out)
          sessionStorage.setItem('policyDetails', JSON.stringify(resp.out));
          let num = '0';
          sessionStorage.setItem('CurPolicySelection', num);
          this.navLocation('home');
      } else {
        //interim until future dated policies are handled
          const errorFromApi = resp.root.Envelope.Body.Fault.faultstring;
          const extractedDate = /\d{4}([.\-/ ])\d{2}\1\d{2}/;
          const extractedPolicy = /[A-Za-z]{1}\d{9}/;
          if (extractedDate.test(errorFromApi) && extractedPolicy.test(errorFromApi)) {
            var policy = errorFromApi.match(extractedPolicy)[0];
            this.http.privateGet(`lookupPolicyTerms?accountCode=${this.pageName}&policyNumber=${policy}`,null,true).subscribe((resp) => {
              if (resp.out && resp.out.termType) {
                if(resp.out.termType == 'Future'){
                  var effectiveDate =  this.datepipe.transform(resp.out.effectiveDate, 'MM/dd/yyyy')
                  this.popErrorMessage(this.loginErrorMessages['Future'].replace(/MM-DD-YYYY/gi,effectiveDate))
                } else if(resp.out.termType == 'Past'){
                  var expirationDate = this.datepipe.transform(resp.out.expirationDate, 'MM/dd/yyyy')
                  this.popErrorMessage(this.loginErrorMessages['Past'].replace(/MM-DD-YYYY/gi,expirationDate))
                }else{
                  this.popErrorMessage(this.loginErrorMessages['Other'])
                }
              } else{
                this.popErrorMessage(this.loginErrorMessages['Other'])
              }
            });
          } else {
            this.popErrorMessage(this.loginErrorMessages['Other'])
          }
        }
      });
  }
  popErrorMessage(errormessage){
    sessionStorage.clear();
    const modal = this.modalService.show(ErrorModalComponent, {backdrop: 'static',});
          (<ErrorModalComponent>modal.content).showConfirmationModal('Error',errormessage);
          (<ErrorModalComponent>modal.content).onClose.subscribe((resp) => {
            if (resp === true) {
              const tenant = this.route.parent.snapshot.params.tenant;
              this.router.navigate([`${tenant}/login`]).then(() => {window.location.reload()})
            }
          });

  }
  selectChange(eve) {
    let lang = this.utilsService.selLangFile(this.pageName, eve.langCode);
    this.translate.use(lang);
  }

  navLocation(page) {
    this.router.navigate([`${this.pageName}/${page}`]);
  }

  navLink() {
    window.open(this.expressPayUrl, '_blank');
  }
}
