<div class="fix-header fix-sidebar card-no-border" id="main-container-adjust">
    <div id="main-wrapper">
        <app-header></app-header>

        <app-sidebar></app-sidebar>

        <div class="page-wrapper" style="min-height: calc(100vh - 153px)">
            <div class="container-fluid">
                 <!-- <div class="page-titles">
                        <div class="col-md-5 col-8 align-self-center">
                            <h3 class="text-themecolor">Dashboard</h3>
                            <ol class="breadcrumb">
                                <li class="breadcrumb-item active">
                                    <a>Home</a>
                                </li>
                            </ol>
                        </div>
                    </div> -->
                <div class="row-mobile" *ngIf="!isMaxWidth; else largerDiv">
                    <div class="col-lg-9 mobile-spacing m-b-20">
                        <router-outlet></router-outlet>
                    </div>
                    <div class="col-lg-3 mobile-spacing m-b-20">
                        <app-large-agent-info [agencyObject]="agencyObject" [isLoading]="isLoading"></app-large-agent-info>
                    </div>
                </div>
                <ng-template #largerDiv>
                    <!-- <div class="row"> -->
                        <!-- <div class="col-md-12"> -->
                            <router-outlet></router-outlet>
                        <!-- </div> -->
                    <!-- </div> -->
                </ng-template>
            </div>
        </div>
        <app-footer [footerContent]="footer_content"></app-footer>

    </div>
</div>