import { Component, OnInit } from '@angular/core';
import { ThemeChangerService } from '../shared/services/themeChanger.service';
import { DasboardService } from "./dashboard/dasboard.service";
import { CommonApiService } from '../shared/services/common-api.service';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { IdleTimerService } from '../shared/services/idleTimer.service';
import { NotifytoastService } from '../shared/services/notificationToast.service';
import { environment } from '../../environments/environment';
import { DataTransferService } from '../shared/services/dataTransfer.service';
import { Subscription } from 'rxjs';
import { UtilsService } from '../shared/services/utils.service';
import { PrivateDataShare } from '../shared/services/privateDataShare.service';
import { PrivateService } from './private.service';
// import { GoogleTagManagerService } from 'angular-google-tag-manager';

declare var gtag;

@Component({
  selector: 'app-private',
  templateUrl: './private.component.html',
  styleUrls: ['./private.component.scss'],
  providers: [DasboardService, CommonApiService, IdleTimerService, PrivateService]
})
export class PrivateComponent implements OnInit {
  subscription: Subscription;
  isLoading = true;
  availableThemes: any;
  isMaxWidth = false;
  agencyObject: any;
  largerSpacingURls = [
    '/home/my-billing-view/payment',
    '/home/style-configuration',
    '/home/my-claims/list',
    '/home/my-claims/new',
    '/home/find-agents'
  ]
  needHelpObject: any;
  footer_content = {
    footer_left_message: '',
    footer_center_message: '',
    footer_right_message: ''
  }
  constructor(
    private themeService: ThemeChangerService,
    private http: DasboardService,
    private route: ActivatedRoute,
    private router: Router,
    private idleService: IdleTimerService,
    private notifyToast: NotifytoastService,
    private dataTransferService: DataTransferService,
    private utilsService: UtilsService,
    // private gtmService: GoogleTagManagerService
    private privateDataShare: PrivateDataShare,
    private privateService: PrivateService
  ) {
    this.route.url.subscribe(resp => {
      const temp = this.router.url
      if (temp) {
        let currentPath = temp.substring(temp.indexOf('/', temp.indexOf('/') + 1))
        this.isMaxWidth = this.largerSpacingURls.indexOf(currentPath) > -1

        // const gtmTag = {
        //   event: 'page',
        //   pageName: temp
        // }
        // console.log(gtmTag,'reached');
        // let id = this.utilsService.getTagId()
        // console.log('UA-185070269-1', id)
        // this.gtmService.pushTag(gtmTag)
        // console.log(this.gtmService.getDataLayer())
        // gtag('config', id, {
        //   'page_path': temp
        // })
      }

    })
  }

  ngOnInit(): void {
    this.invokeSubscription()
    this.idleChecker()
    // this.getAgentInfo()
    this.availableThemes = this.themeService.availableThemes
    this.getConfiguration()

    this.route.data.subscribe(resp => {
      console.log(resp.policyObj)
      // this.policiesOfUser(resp.policyObj[3])
      const pageConfig = resp.policyObj[2].page_configuration
      const fileUploadSize = resp.policyObj[2].claim_configuration && resp.policyObj[2].claim_configuration.fileUploadLimit
      if (fileUploadSize) this.privateDataShare.setFileUploadSize(fileUploadSize)
      let acceptedBillingcodes = resp.policyObj[2].customer_portal && resp.policyObj[2].customer_portal.accepted_billing_codes
      let documentPreference = resp.policyObj[2].customer_portal && resp.policyObj[2].customer_portal.mapping && resp.policyObj[2].customer_portal.mapping.documentPreference;

      if (acceptedBillingcodes) this.privateDataShare.setBillingCodes(acceptedBillingcodes)
      if (documentPreference) this.privateDataShare.setDoucumentPreferenceMapping(documentPreference);// if(pageConfig.styling)
      // this.agencyObject = this.privateService.getNeedHelp(resp.policyObj[1], pageConfig.styling, resp.policyObj[0].policyDetails.agentDetails)
      // this.pageValid(resp.accountConfiguration)
    }, error => {
      console.log(error)
      this.notifyToast.error("Unable to retrieve data")
      // this.router.navigate(['/site-not-found'])
    })



    console.log(this.privateDataShare.getPolicyNumber())
  }

  invokeSubscription() {
    this.subscription = this.dataTransferService.recievedObj.subscribe(
      resp => {
        this.getAgentInfo()
      });
  }

  idleChecker() {
    this.idleService.startSession(environment.idleTime).subscribe((res) => {
      if (res) {
        this.idleService.stopTimer()
        sessionStorage.clear();
        const tenant = this.route.snapshot.params.tenant
        this.notifyToast.error('Session Expired due to inactivity')
        this.router.navigate([`${tenant}/login`]).then(() => { window.location.reload() })
      }
    })
  }

  getAgentInfo() {
    let agencyObject;
    this.isLoading = true
    this.http.getPolicyInfoByPolicyNumber().subscribe(resp => {
      if (resp && resp.policy.agent) {
        agencyObject = {
          agencyInfo: resp.policy.agent
        }
        this.http.getCardInfo("needHelp").subscribe(needHelp => {

          const data = {
            ...agencyObject,
            needHelp
          }
          this.agencyObject = data

          this.isLoading = false
          // sessionStorage.setItem('agentInfoObject', JSON.stringify(data))
        })
      } else {
        this.isLoading = false;
        this.notifyToast.error("Please Contact System Administrator")
      }
      // if(resp.policyDetails) this.findAgents(resp.policyDetails.agentDetails)
    }, error => {
      this.isLoading = false;
      this.notifyToast.error("Please Contact System Administrator")
    })
  }

  findAgents(agencyInfo) {
    let needHelp;
    let agencyObject;
    this.isLoading = true
    this.http.findAgents(agencyInfo.agencyName).subscribe(resp => {
      agencyObject = {
        agencyInfo,
        agentInfo: resp ? resp.AgentDetails[0] : null
      }
      this.http.getCardInfo("needHelp").subscribe(needHelp => {
        this.needHelpObject = needHelp
        const data = {
          ...agencyObject,
          needHelp
        }
        this.agencyObject = data
        this.isLoading = false
        // sessionStorage.setItem('agentInfoObject', JSON.stringify(data))
      })
    }, error => {
      this.isLoading = false
      this.notifyToast.error('No Agents Found')

    })
  }

  getConfiguration() {
    this.http.getAccountConfiguration().subscribe(resp => {
      let data = { ...resp.page_configuration }
      delete data.cardStyling
      this.utilsService.setTabTitleAndIcon(data)
      let permissionKey = resp['AccessControl'] ? resp['AccessControl'] : []
      sessionStorage.setItem('permissionKeys', JSON.stringify(permissionKey))
      sessionStorage.setItem('companybackground', JSON.stringify(data))
      if (resp.page_configuration.styling) {
        sessionStorage.setItem('themeObject', JSON.stringify(resp.page_configuration.styling))
      }
      if (resp.content_management) {
        this.footer_content = {
          footer_left_message: resp.content_management.footer_left_message,
          footer_center_message: resp.content_management.footer_center_message,
          footer_right_message: resp.content_management.footer_right_message
        }
      }
      const theme = resp.page_configuration.themeName ? resp.page_configuration.themeName : 'Default'
      const setATheme = this.availableThemes.find(response => response.name === theme)
      this.themeService.setTheme(setATheme)
    })
  }

}
