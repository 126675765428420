import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-error-modal',
  templateUrl: './error-modal.component.html',
  styleUrls: ['./error-modal.component.scss']
})
export class ErrorModalComponent implements OnInit {
  public active = false;
  public body: string;
  public title: string;
  styling: any;
  public onClose: Subject<boolean>;
  public buttonContent = 'OK';
  constructor(private _bsModal: BsModalRef) { }

  ngOnInit() {
    this.onClose = new Subject()
    this.styling = this.getItemfromSession('themeObject');
  
  }

  public showConfirmationModal(title, body){
    this.title = title
    this.body = body
    this.active = true
  }

  public showConfirmationModalWithConfigOfButton(title, body, buttonContent){
    this.title = title
    this.body = body
    this.active = true
    this.buttonContent = buttonContent;
  }

  public hideConfirmationModal(){
    this.active = false
    this.onClose.next(true)
    this._bsModal.hide()
  }
  getItemfromSession(key, isLocal?){
    let storage = isLocal ? localStorage : sessionStorage
    if(storage.getItem(key) && storage.getItem(key) != 'undefined'){
      return JSON.parse(storage.getItem(key))
    }else{
      return null
    }
  }
}
