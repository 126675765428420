import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatStepper } from '@angular/material/stepper';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonApiService } from '../../shared/services/common-api.service';
import { NotifytoastService } from '../../shared/services/notificationToast.service';
import { ValidationService } from '../../shared/services/validation.service';
import { UtilsService } from '../../shared/services/utils.service';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-forgot-username',
  templateUrl: './forgot-username.component.html',
  styleUrls: ['./forgot-username.component.scss'],
  providers: [CommonApiService]
})
export class ForgotUsernameComponent implements OnInit {
  forgotUsernameForm: any;
  isSubmitted = false;
  tenant: any;
  emailError = '';
  styling = this.utilsService.getItemfromSession('themeObject')
  constructor(
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private http: CommonApiService,
    private notifyToast: NotifytoastService,
    private utilsService: UtilsService
  ) { }

  ngOnInit(): void {
    this.tenant = this.route.parent.snapshot.params.tenant

    this.initForm()
    // this.keyEmail()
  }

  initForm() {
    this.forgotUsernameForm = this.fb.group({
      accountCode: [this.tenant],
      policyNumber: ['', [Validators.required]],
      // emailAddress: ['', [Validators.required, ValidationService.emailValidator]],
      zipCodeInsuredProperty: ['', [Validators.required]],
      sendEmail: [true]
    })
  }

  getCustUsername(stepper: MatStepper) {
    this.isSubmitted = true
    if (this.forgotUsernameForm.valid) {
      this.http.get(`getCustomerUsername`, this.forgotUsernameForm.value, true).subscribe(resp => {
        if (resp.out) {
          stepper.next()
        } else {
          const errorText = `
          ${resp.errorMessage}`
          this.utilsService.confirmModalPopup(errorText)
          // Please Contact System Administrator.
          // this.utilsService.confirmModalPopup('Wrong data entered. Please try again later.')
        }
      }, (error: HttpErrorResponse) => {
        console.log(error)
        this.utilsService.confirmModalPopup(error.error.message)
      })
    }
  }

  navLocation(page) {
    this.router.navigate([`${this.tenant}/${page}`])
  }

  goForward(stepper: MatStepper) {
    if (stepper.selectedIndex == 0) {
      this.getCustUsername(stepper)
    } else {
      this.navLocation('login')
    }
  }

  keyEmail() {
    const temp = this.forgotUsernameForm.get('emailAddress')
    if (temp.errors) {
      if (temp.errors.required) {
        this.emailError = 'Email is required'
      } else if (temp.errors.invalidEmailAddress) {
        this.emailError = 'Email is not valid.'
      }
    }
  }

}
