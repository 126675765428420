<div class="card get-quote-container zero-margin">
    <div *ngIf="stepper.selectedIndex == 0" style="margin-top: 30px; position: absolute; right: 10px; z-index: 9;"><button class="btn btn-info" (click)="langSwitcher()" [ngStyle]="styling?.button?.buttonMajorAction">{{isEnglish ? 'Español': 'English'}}</button></div>
    <div class="text-left">
        <app-message-card [message]="messageBot" [loading]="loading" [custName]="personalInfoForm_0?.value?.firstName" [custMail]="personalInfoForm_0?.value?.email"
            [indexStepper]="stepper.selectedIndex.toString()" *ngIf="showMessage"></app-message-card>
        <div class="col-lg-12 outerbox-padding">
            <mat-horizontal-stepper #stepper class="zero-padding">
                <mat-step [stepControl]="personalInfoForm_0">
                    <form [formGroup]="personalInfoForm_0">
                        <app-personal-info-form [personalInfoForm]="personalInfoForm_0" [isSubmitted]="isSubmitted"></app-personal-info-form>
                    </form>
                </mat-step>
                <mat-step [stepControl]="addressForm_1">
                    <form [formGroup]="addressForm_1">
                        <div class="row p-20">
                        <div class="col-md-4 offset-md-4">
                                <app-google-autocomplete 
                                    (setAddress)="getAddress($event)"
                                placeHolder="Address of the Property *"></app-google-autocomplete>
                            <p class="error-class mat-angular-error-text" *ngIf="addressForm_1.get('address').errors?.required && isSubmitted">
                                Address is required</p>
                        </div>
                            <div class="col-md-4">
                                <mat-form-field style="width: 50%">
                                    <input matInput type="text" formControlName="unitNumber" placeholder="APT/Unit #" />
                                </mat-form-field>
                                <p class="error-class mat-angular-error-text" *ngIf="!addressForm_1.get('unitNumber').valid && isSubmitted">
                                    APT/Unit # is not valid, please enter 'apt XXX' or 'unit XXX'</p>
                            </div>
                        </div>
                    </form>
                </mat-step>
                <mat-step [stepControl]="startDateForm_2">
                    <form [formGroup]="startDateForm_2">
                        <div class="col-md-4 offset-md-4">
                            <div class="form-group row">
                                <input type="text" placeholder="Date of Loss *" class="form-control custom" bsDatepicker
                                    [bsConfig]="datePickerSettings" formControlName="startDate" [minDate]="minDate" [maxDate]="maxDate">
                                <p class="error-class" *ngIf="startDateForm_2.get('startDate').errors?.required && isSubmitted">Start
                                    date is required</p>
                            </div>
                        </div>
                    </form>
                </mat-step>
                <mat-step [stepControl]="isRentForm_3">
                    <form [formGroup]="isRentForm_3">
                        <app-is-rent-form [isRentForm]="isRentForm_3"></app-is-rent-form>
                    </form>
                </mat-step>
                <mat-step [stepControl]="isHouseForm_4">
                    <form [formGroup]="isHouseForm_4">
                        <app-is-house-form [isHouseForm]="isHouseForm_4"></app-is-house-form>
                    </form>
                </mat-step>
                <mat-step [stepControl]="frequencyUsage_5">
                    <form [formGroup]="frequencyUsage_5">
                        <app-is-residence-form [frequencyUsage]="frequencyUsage_5"
                        [quoteCustomizedFeature]="quoteCustomizedFeature"
                        [showOptionAtStepSix]="showOptionAtStepSix" >
                        </app-is-residence-form>
                    </form>
                </mat-step>
                <mat-step [stepControl]="roofForm_6">
                    <form [formGroup]="roofForm_6">
                        <app-select-roof-form [roofForm]="roofForm_6"></app-select-roof-form>
                    </form>
                </mat-step>
                <mat-step [stepControl]="additionalRoofInfoForm_7">
                    <div class="col-lg-12 text-center">
                        <form [formGroup]="additionalRoofInfoForm_7">
                            <app-additional-roof-info-form [roofForm]="additionalRoofInfoForm_7"
                                [roofMaterials]="roofMaterials" [isSubmitted]="isSubmitted">
                            </app-additional-roof-info-form>
                        </form>
                    </div>
                </mat-step>
                <mat-step [stepControl]="homeAgeAndSizeForm">
                    <div class="col-lg-12 text-center">
                        <form [formGroup]="homeAgeAndSizeForm">
                            <app-home-age-size [homeAgeAndSizeForm]="homeAgeAndSizeForm" [stories]="stories" [isSubmitted]="isSubmitted">
                            </app-home-age-size>
                        </form>
                    </div>
                </mat-step>
                <mat-step [stepControl]="homeAgeAndSizeForm">
                    <div class="col-lg-12 text-center">
                        <form [formGroup]="homeAgeAndSizeForm">
                            <app-additional-home-details [additionalHomeDetailsForm]="additionalHomeDetailsForm"
                                [buildingMaterials]="buildingMaterials" [buildingTypes]="buildingTypes"
                                [foundationTypes]="foundationTypes" [isSubmitted]="isSubmitted">
                            </app-additional-home-details>
                        </form>
                    </div>
                </mat-step>


                <mat-step>
                    <ng-container *ngIf="!loading">
                        <app-select-premium (clickedPremium)="clickedPremium($event)"
                            [selectedPremium]="selectedPremium_10"></app-select-premium>
                    </ng-container>

                </mat-step>

                <mat-step>
                    <app-show-premium [estimatedPremium]="estimatedPremium"
                        (navigateToCoverageDetails)="navigateToCoverageDetails($event)"
                        [stepper]="stepper"
                        [address]="currentSelectedAddress"
                        ></app-show-premium>
                </mat-step>

                <mat-step [stepControl]="coverageValues_13">
                    <ng-container *ngIf="!loading">
                    <form [formGroup]="coverageValues_13">
                        <app-coverage-details [coverageValues]="coverageValues_13"
                        [coverageForms]="coverageForms"
                        [curPolicyType]="policyType"
                        [curCoverageNames]="curCoverageNames"
                            [curDeductibleNames]="curDeductibleNames"></app-coverage-details>
                    </form>
                    </ng-container>
                </mat-step>
                <mat-step [stepControl]="emailQuoteForm_14">
                    <!-- <form [formGroup]="emailQuoteForm_14"> -->
                        <app-email-quote [emailQuoteForm]="emailQuoteForm_14"></app-email-quote>
                    <!-- </form> -->
                </mat-step>

                <mat-step>
                    <div [innerHTML]="customerResource"
                    class="text-center"></div>
                    <div class = "text-center">
                        <button class="btn btn-info "
                        (click)="navigateToLink('get-quote')"
                        [ngStyle]="styling?.button?.buttonMajorAction">Get Another Quote</button>
                    </div>  
                </mat-step>

                <mat-step>
                    <div class="row col-md-12" style="padding: 10px 40px;">
                        <button class="btn btn-info" type="button" style="margin: auto;" (click)="navigateToPremium()" [ngStyle]="styling?.button?.buttonBack">Back to Quote Details</button>
                    </div>
                    <app-find-agents-in-gmap (agentDetails)="agentDetails($event, stepper)" [address]="riskAddress" [findAgent]="findAgent">
                    </app-find-agents-in-gmap>
                </mat-step>
                <mat-step>
                    <form [formGroup]="emailQuoteAgentForm">
                        <app-send-quote-agent [emailQuoteAgentForm]="emailQuoteAgentForm" [agentDetails]="selectedAgentDetails" [isSubmitted]="isSubmitted" ></app-send-quote-agent>
                
                    </form>
                </mat-step>
                <mat-step>
                    <app-email-agent-confirmation [agentDetails]="selectedAgentDetails" [quoteNumber]="quoteID"> </app-email-agent-confirmation>
                </mat-step>

            </mat-horizontal-stepper>

            <div class="text-center p-b-40" *ngIf="stepper.selectedIndex < 11 && !loading">
                <button *ngIf="stepper.selectedIndex > 0" class="btn btn-info custom-button-info m-r-20" type="button"
                    (click)="goBack(stepper)"
                    [ngStyle]="styling?.button?.buttonBack">{{'BACK' | translate}}</button>
                <!-- <button class="btn btn-danger custom-button-info m-r-20" type="button"
                    (click)="navigateToLink('login')"
                    [ngStyle]="styling?.button?.buttonBack">{{('CANCEL' | translate) }}</button> -->
                <button class="btn btn-info custom-button-info" type="button"
                    (click)="goForward(stepper)"
                    [ngStyle]="styling?.button?.buttonNext">{{('NEXT' | translate)}}</button>
            </div>


            <div class="p-b-40 text-center" *ngIf="stepper.selectedIndex === 12">
                <ng-container *ngIf="!loading">
                    <button class="btn btn-info custom-button-info m-r-40" type="button"
                    (click)="goBack(stepper)"
                    [ngStyle]="styling?.button?.buttonBack">{{'BACK' | translate}}</button>
                    <button  class="btn btn-info custom-button-info" type="button"
                    (click)="reCalculatePremium(stepper)"
                    [ngStyle]="styling?.button?.buttonNext">Update Quote</button>
                </ng-container>
            </div>

            <div class="row p-20 text-center" *ngIf="stepper.selectedIndex === 11">
                <div class="col-lg-4 ">
                    <button class="btn customized-btn" (click)="navigateToAStep(stepper)">
                        <mat-icon class="w-100" style="font-size: 60px;">account_box</mat-icon>
                    </button>
                    <p>Find an Agent</p>
                </div>
                <div class="col-lg-4 ">
                    <mat-icon class="w-100" style="font-size: 60px;">shopping_cart</mat-icon>
                    <p>Purchase Now</p>
                </div>
                <div class="col-lg-4 ">
                    <button class="btn customized-btn" (click)="navigateToEmailQuote(stepper)">
                        <mat-icon class="w-100" style="font-size: 60px;">email</mat-icon>
                    </button>
                    <p>Email Quote</p>
                </div>
            </div>

            <div class="p-b-40 text-center" *ngIf="stepper.selectedIndex === 13">
                    <button class="btn btn-info custom-button-info m-r-40" type="button"
                        (click)="navigateToShowPremium(stepper)"
                        [ngStyle]="styling?.button?.buttonBack">Back</button>

                    <button class="btn btn-info custom-button-info" type="button" (click)="goForward(stepper)"
                    [ngStyle]="styling?.button?.buttonNext">Email Quote</button>
            </div>

            <div class="row p-b-20" *ngIf="stepper.selectedIndex === 16">
                <div class="col-mob-6 d-flex justify-content-center">
                    <button class="btn btn-info custom-button-info" type="button"
                        (click)="goBack(stepper)"
                        [ngStyle]="styling?.button?.buttonBack">Back to Search</button>
                </div>

                <div class="col-mob-6 d-flex justify-content-center">
                    <button class="btn btn-info custom-button-info" type="button" (click)="goForward(stepper)"
                    [ngStyle]="styling?.button?.buttonNext">Email Quote</button>
                </div>
            </div>


        </div>
    </div>
</div>