import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-files-upload-popup',
  templateUrl: './files-upload-popup.component.html',
  styleUrls: ['./files-upload-popup.component.scss']
})
export class FilesUploadPopupComponent implements OnInit {
  filesList?: any = []
  title?: string = "";
  status?: string = "";
  containerText? : string = "";
  styling: any;
  // public onClose: Subject<boolean>;
  public buttonContent = 'OK';
  constructor(private _bsModal: BsModalRef) { }

  ngOnInit() {
    // this.onClose = new Subject()
    this.styling = this.getItemfromSession('themeObject');
  }

  public hideConfirmationModal(){
    this._bsModal.hide()
  }

  getItemfromSession(key, isLocal?){
    let storage = isLocal ? localStorage : sessionStorage
    if(storage.getItem(key) && storage.getItem(key) != 'undefined'){
      return JSON.parse(storage.getItem(key))
    }else{
      return null
    }
  }

}
