import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UtilsService } from '../../shared/services/utils.service';
import { FormBuilder, Validators } from '@angular/forms';
import { DasboardService } from '../dashboard/dasboard.service';
import { NotifytoastService } from '../../shared/services/notificationToast.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CommonApiService } from '../../shared/services/common-api.service';
import { DatePipe } from '@angular/common';
import { ErrorModalComponent } from '../../shared/error-modal/error-modal.component';
import { BsModalService } from 'ngx-bootstrap/modal';
import { PrivateDataShare } from '../../shared/services/privateDataShare.service';


@Component({
  selector: 'app-add-policy',
  templateUrl: './add-policy.component.html',
  styleUrls: ['./add-policy.component.scss'],
})
export class AddPolicyComponent implements OnInit {
  policyObj = [];
  styling = this.utilsService.getItemfromSession('themeObject');
  isSubmitted = false;
  policyForm: any;
  loginErrorMessages: any;
  policyNumber: any;
  message = {
    approved: 'The transaction was successfully authorized.',
    already_associated:
      'This policy has already been associated with another user account. Please contact customer support for assistance. ',
    error:
      'Your policy could not be found. Please verify the information you entered and try again.',
    already_associated_from_server:
      'Policy is already associated to a policyholder portal user.',
  };
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private utilsService: UtilsService,
    private formBuilder: FormBuilder,
    private http: CommonApiService,
    private dashboardService: DasboardService,
    private notifyToast: NotifytoastService,
    private datepipe: DatePipe,
    private modalService: BsModalService,
    private privateDataShare: PrivateDataShare
  ) { }

  ngOnInit(): void {
    this.initForm();
    this.getConfiguration();
  }

  getConfiguration() {
    this.http
      .get(
        `accountConfiguration?accountCode=${this.route.parent.snapshot.params.tenant}`,
        null,
        true
      )
      .subscribe((resp) => {
        if (
          resp.content_management &&
          resp.content_management.login_error_messages
        ) {
          this.loginErrorMessages =
            resp.content_management.add_policy_noCurrentTerm_error_messages;
        }
      });
  }
  popErrorMessage(errormessage) {
    const modal = this.modalService.show(ErrorModalComponent, {
      backdrop: 'static',
    });
    (<ErrorModalComponent>modal.content).showConfirmationModal(
      'Error',
      errormessage
    );
  }

  saveNewPolicyInfo() {
    const data = {
      policyNumber: this.policyForm.value.policyNumber,
      // pniDateOfBirth:
      //   this.policyForm.value.dateOfBirth_month +
      //   '/' +
      //   this.policyForm.value.dateOfBirth_date +
      //   '/' +
      //   this.policyForm.value.dateOfBirth_year,
      zipCode: this.policyForm.value.zipCode,
    };
    this.isSubmitted = true;
    if (this.policyForm.valid) {
      this.http
        .privateGet(
          `lookupPolicyTerms?accountCode=${this.route.parent.snapshot.params.tenant}&policyNumber=${this.policyForm.value.policyNumber}`,
          null,
          true
        )
        .subscribe((resp) => {
          console.log("policy terms resp:", resp);
          if (resp.out && resp.out.termType) {
            // if (resp.out.termType == 'Future') {
            //   var effectiveDate = this.datepipe.transform(
            //     resp.out.effectiveDate,
            //     'MM/dd/yyyy'
            //   );
            //   this.popErrorMessage(
            //     this.loginErrorMessages['Future'].replace(
            //       /MM-DD-YYYY/gi,
            //       effectiveDate
            //     )
            //   );
            // } else 
            if (resp.out.termType == 'Past') {
              var expirationDate = this.datepipe.transform(
                resp.out.expirationDate,
                'MM/dd/yyyy'
              );
              this.popErrorMessage(
                this.loginErrorMessages['Past'].replace(
                  /MM-DD-YYYY/gi,
                  expirationDate
                )
              );
            } else {
              this.dashboardService.addPolicyToUserAccount(data).subscribe(
                (resp) => {
                  if (resp != null && resp.statusCode === 200) {
                    this.getPoliciesOfUser().subscribe((getPoliciesResp) => {
                      if (getPoliciesResp === undefined) {
                        console.log('No response to updating all the policies');
                        this.notifyToast.error('Policy update failed.');
                      } else {
                        this.notifyToast.success('Successfully updated.');
                        this.navToLink('home/my-account-view');
                      }
                    });
                  } else if (resp != null && resp.root != null) {
                    console.log('error message' + JSON.stringify(resp));
                    var faultString = resp.root.Envelope.Body.Fault.faultstring;
                    if (
                      faultString ===
                      this.message.already_associated_from_server
                    ) {
                      this.utilsService.confirmModalPopup(
                        this.message.already_associated
                      );
                    } else {
                      this.utilsService.confirmModalPopup(this.message.error);
                    }
                  } else {
                    this.utilsService.confirmModalPopup(this.message.error);
                  }
                },
                (error) => {
                  this.utilsService.confirmModalPopup(
                    'Please contact System Administrator'
                  );
                }
              );
            }
          } else {
            this.popErrorMessage(resp.root.Envelope.Body.Fault.faultstring);
          }
        });
    }
  }

  navToLink(url) {
    const tenant = this.route.parent.snapshot.params.tenant;
    this.router.navigate([`${tenant}/${url}`]);
  }

  initForm() {
    this.policyForm = this.formBuilder.group({
      policyNumber: ['', [Validators.required]],
      zipCode: [
        '',
        [
          Validators.required,
          Validators.pattern('[0-9]*'),
          Validators.minLength(3),
        ],
      ],
      // dateOfBirth_month: [
      //   '',
      //   [
      //     Validators.required,
      //     Validators.pattern('[0-9]*'),
      //     Validators.minLength(1),
      //     Validators.maxLength(2),
      //   ],
      // ],
      // dateOfBirth_date: [
      //   '',
      //   [
      //     Validators.required,
      //     Validators.pattern('[0-9]*'),
      //     Validators.minLength(1),
      //     Validators.maxLength(2),
      //   ],
      // ],
      // dateOfBirth_year: [
      //   '',
      //   [
      //     Validators.required,
      //     Validators.pattern('[0-9]*'),
      //     Validators.minLength(4),
      //     Validators.maxLength(4),
      //   ],
      // ],
    });
  }

  getPoliciesOfUser() {
    var preSelectedPolicy = this.utilsService.getPolicyNumber();
    return this.dashboardService.getPoliciesByUser().pipe(
      map((resp) => {
        console.log('getPoliciesByUserResponse:', resp);
        console.log('update policies response: ' + JSON.stringify(resp));
        if (resp != null) {
          this.privateDataShare.setPolicyDetails(resp)
          sessionStorage.setItem('policyDetails', JSON.stringify(resp));
          this.policyObj = JSON.parse(
            sessionStorage.getItem('policyDetails')
          ).PolicySummary;
          for (let index in this.policyObj) {
            if (
              this.policyObj[index].policyDetails.policyNumber ==
              preSelectedPolicy
            ) {
              sessionStorage.setItem('CurPolicySelection', index);
            }
          }
          return true;
        }
      })
    );
  }
}
