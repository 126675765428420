import { Component, OnInit, HostListener } from '@angular/core';
import { DasboardService } from '../../dashboard/dasboard.service';
import { FormBuilder } from '@angular/forms';
import { CommonApiService } from '../../../shared/services/common-api.service';
import { NotifytoastService } from '../../../shared/services/notificationToast.service';
import { UtilsService } from '../../../shared/services/utils.service';
import { formatDate } from '@angular/common';
import { format } from 'path';
import { ActivatedRoute, Router } from '@angular/router';
import { DeviceDetectorService } from 'ngx-device-detector';
import { PrivateDataShare } from '../../../shared/services/privateDataShare.service';

@Component({
  selector: 'app-view-policy-documents',
  templateUrl: './view-policy-documents.component.html',
  styleUrls: ['./view-policy-documents.component.scss'],
  providers: [CommonApiService, DasboardService]
})
export class ViewPolicyDocumentsComponent implements OnInit {
  documentList = [];
  policyTermList = [];
  map = new Map();

  policyNumber: any;
  policiesTermForm: any;
  selectedAddressObj: any;
  addressObj=[];
  listAddress=[];

  selectedPolicyTermObj: any;
  http: any;
  // Pagination parameters.
  page = 1;
  count= 10;
  styling =this.utilsService.getItemfromSession('themeObject')
  currentBrowser: any;
  currentDevice: any;
  deviceInfo: any;
  notShowPreview = false;
  isSmallWindow: boolean;


  constructor(
    private formBuilder: FormBuilder,
    private dashboardService: DasboardService,
    private toastr: NotifytoastService,
    private utilsService: UtilsService,  
    private route: ActivatedRoute,
    private router: Router,
    private deviceService: DeviceDetectorService,
    private privateDataShare: PrivateDataShare
  ) { }

  ngOnInit(): void {
    this.initForm();
    this.getPolicyDetails();
    this.getViewPolicyDocuments();
    this.isSmallWindow = window.innerWidth <= 991;
    console.log('small window: '+ this.isSmallWindow)
    this.currentBrowser = this.getCurrentBrowser();
    console.log("current browser: " + this.currentBrowser);
    this.currentDevice = this.getCurrentDevice();
    console.log("current device: " + this.currentDevice);
    this.hidePreview();
  }
  
  hidePreview() {
    if (this.currentBrowser === 'firefox' && this.isSmallWindow) {
      this.notShowPreview = true;
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.isSmallWindow = window.innerWidth <= 991;
    console.log('small window: '+ this.isSmallWindow)
  }

  getCurrentBrowser() {
    this.deviceInfo = this.deviceService.getDeviceInfo();
    // get current browser
    const browser = this.deviceInfo.browser.toLowerCase();
    console.log("find the browser: " + browser);
    switch (true) {
      case browser.indexOf('edge') > -1:
        return 'edge';
      case browser.indexOf('chrome') > -1:
        return'chrome';
      case browser.indexOf('trident') > -1:
        return 'ie';
      case browser.indexOf('firefox') > -1:
        return 'firefox';
      case browser.indexOf('safari') > -1:
        return 'safari';
      default:
        return 'other';
    }
  }

  getCurrentDevice(){
    const isMobile = this.deviceService.isMobile();
    const isTablet = this.deviceService.isTablet();
    const isDesktopDevice = this.deviceService.isDesktop();
    console.log("deviceService details: " + JSON.stringify(this.deviceService));
    console.log("device info: " + JSON.stringify(this.deviceInfo));
    console.log("isMobile: " + isMobile);  // returns if the device is a mobile device (android / iPhone / windows-phone etc)
    console.log("isTablet: " + isTablet);  // returns if the device us a tablet (iPad etc)
    console.log("isDesktop: " + isDesktopDevice); // returns if the app is running on a Desktop browser.

  }


  initForm(){
    this.policiesTermForm = this.formBuilder.group({
      selectedPolicyTerm: []
    })
  }


  getPolicyDetails(){
    // let selectedPolicy = this.utilsService.selectedPolicy()
    let policyDetails = this.privateDataShare.getPolicyDetails()
    if(policyDetails){
      // this.addressObj = JSON.parse(sessionStorage.getItem('policyDetails')).PolicySummary;
      // this.listAddress =  this.addressObj.map(resp => resp.policyDetails.riskAddress.addressLine1 + ', ' +  resp.policyDetails.riskAddress.city + ', ' +  resp.policyDetails.riskAddress.stateCode+ ' ' + resp.policyDetails.riskAddress.zipCode)

      // this.selectedAddressObj = this.listAddress[selectedPolicy]
      // this.policyNumber =  this.utilsService.getPolicyNumber();
      this.policyNumber = this.privateDataShare.getPolicyNumber()
      let selectedPolicyDetails = policyDetails.PolicySummary.find(resp => resp.policyDetails.policyNumber == this.policyNumber).policyDetails
      let curAddressArray = [
        selectedPolicyDetails.riskAddress.addressLine1, 
        selectedPolicyDetails.riskAddress.city,
        selectedPolicyDetails.riskAddress.stateCode,
        selectedPolicyDetails.riskAddress.zipCode]
      this.selectedAddressObj = curAddressArray.join(" ,")
      this.policiesTermForm.patchValue({
        selectedPolicyTerm: this.policyTermList[0]
      })
      this.selectChangePolicyTerm(this.policyTermList[0])
    } else{
      return null
    }
  }


  private base64ToArrayBuffer(base64: string) {
    var binary_string = window.atob(base64);
    var len = binary_string.length;
    var bytes = new Uint8Array(len);
    for (var i = 0; i < len; i++) {
        bytes[i] = binary_string.charCodeAt(i);
    }
    return bytes.buffer;
  }

  private downloadFile(data) {
    const blob = new Blob([this.base64ToArrayBuffer(data.contents)], { type: data.contentType });
    const downloadURL = window.URL.createObjectURL(blob);
    var link = document.createElement('a');
    link.href = downloadURL;
    link.download = data.fileName;
    link.click();
  }

  private previewFile(data) {
  
    if (this.currentBrowser === 'safari') {
      const blob = new Blob([this.base64ToArrayBuffer(data.contents)], { type: 'application/pdf' });
      const url= window.URL.createObjectURL(blob);
      window.location.href = url;
      window.URL = window.URL || window.webkitURL;
      // var reader = new FileReader();
      // reader.onloadend = function (){};
      // reader.readAsDataURL(blob);
    } else {
      const blob = new Blob([this.base64ToArrayBuffer(data.contents)], { type: data.contentType });
      const url= window.URL.createObjectURL(blob);
      const printWindow = window.open(url);
    }
  }

  downloadDocument(id: string) {
    this.dashboardService.downloadDocument(id).subscribe(resp => {
      console.log("downloadDocument: " + resp)
      if (resp == null) {
        this.toastr.error('Cannot download the file');
      } else {
        this.downloadFile(resp)
      } 
    })
  }

  previewDocument(id: string) {
    this.dashboardService.downloadDocument(id).subscribe(resp => {
      console.log("Preview document: " + JSON.stringify(resp)) 
      if (resp == null) {
        this.toastr.error('Cannot preview the file');
      } else {
        this.previewFile(resp)
      }
    })
  }
  getViewPolicyDocuments(){
    this.dashboardService.getPolicyDocumentsMetadata().subscribe(resp => {
      // 1. process the document date, put docs to the map based on policy term 
      console.log("get policy documents" + JSON.stringify(resp))
      this.documentList = resp.PolicyDocumentMetadata;
      var hasNullPolicyTerm = false;
      var docWithoutPolicyTerm = [];
      for(let doc of this.documentList) {
        var docListOfCurTerm = [];
        if (doc != null) {
          let policyTerm = doc.policyTermText == null ? null : this.formatPolicyTerm(doc.policyTermText)
          if (policyTerm == null) {
            // the case is that there is no policy term for some documents
            console.log("NO POLICY TERM   " + JSON.stringify(doc))
            docWithoutPolicyTerm.push(doc)
            // hasNullPolicyTerm = true;
          } else if  (this.map.has(policyTerm)) {
            docListOfCurTerm = this.map.get(policyTerm);
            docListOfCurTerm.push(doc)
            this.map.set(policyTerm, docListOfCurTerm)
          } else{
            docListOfCurTerm.push(doc)
            this.map.set(policyTerm, docListOfCurTerm)
            this.policyTermList = [...this.policyTermList, policyTerm]
          }
        }
      }
      
      this.policyTermList.sort();
      this.policyTermList.reverse();
      // 2. Add the docs without policy term to the cur term
      if (hasNullPolicyTerm) {
        // this.policyTermList.push('other');
        if (this.policyTermList.length > 0) {
          var cur = this.map.get(this.policyTermList[this.policyTermList.length - 1])
          for (let doc of docWithoutPolicyTerm) {
            cur.push(doc)
          }
          this.map.set(this.policyTermList[this.policyTermList.length - 1], cur)
        } else {
          console.log("there is no policy term")
        }
      }
      // 3. sort docs in  map
      for(let value of this.map.values()) {
        value.sort((a,b) => a.fileName.localeCompare(b.fileName));
      }
      // this.documentList.sort((a,b) => a.fileName.localeCompare(b.fileName));
      // this.documentList.sort((a,b) => a.uploadedDate.localeCompare(b.uploadedDate));
      this.policiesTermForm.patchValue({
        selectedPolicyTerm: this.policyTermList[0]
      })
      this.selectChangePolicyTerm(this.policyTermList[0])
      console.log("policy terms:  " + this.policyTermList)
      // console.log(resp)
    })
  }

  formatPolicyTerm(policyTermRange) {
    if (policyTermRange == null) {
      console.log("Policy term is null")
      return null;
    }
    let splitPolicyTerm = policyTermRange.split('-');
    return  formatDate(new Date(splitPolicyTerm[0]), 'MM/dd/yyyy', 'en') 
    + ' - ' + formatDate(new Date(splitPolicyTerm[1]), 'MM/dd/yyyy', 'en');
    //MMM d, y
  }

  // selectChange(eve){
  //   let address = eve.split(', ')
  //   for (let index in this.addressObj) {
  //     if(this.addressObj[index].riskAddress.addressLine1 === address[0] && this.addressObj[index].riskAddress.city === address[1] && this.addressObj[index].riskAddress.stateCode === address[2]){
  //       sessionStorage.setItem('CurPolicySelection', index)
  //       this.selectedAddressObj = this.addressObj[index]
  //       this.documentList = [];
  //       this.policyTermList = []; 
  //       this.map.clear();
  //       this.getViewPolicyDocuments()
  //       return true
  //     }
  //   }
  // }

  selectChangePolicyTerm(eve) {
    if (eve != null) {
      let policyTerm = eve.split('-')
      this.selectedPolicyTermObj = this.policyTermList.find(resp => {
        let cur = resp.split('-')
        if (cur[0] == policyTerm[0] && cur[1] == policyTerm[1]){
          return true
        }
      })
    }
  }

  navToLink(page){
    const tenant = this.route.parent.snapshot.params.tenant
    this.router.navigate([`${tenant}/${page}`])
  }
}
