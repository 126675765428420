import { Component, OnInit, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { UtilsService } from '../../../shared/services/utils.service';
import { map } from 'rxjs/operators';
@Component({
  selector: 'app-coverage-details',
  templateUrl: './coverage-details.component.html',
  styleUrls: ['./coverage-details.component.scss']
})
export class CoverageDetailsComponent implements OnInit {
  styling = this.utilsService.getItemfromSession('themeObject');
  @Input() coverageValues: FormGroup;
  @Input() coverageForms: any;
  @Input() curCoverageNames: any;
  @Input() curDeductibleNames: any;
  @Input() curPolicyType: any;
  constructor(
    private utilsService: UtilsService,  
  ) { }

  ngOnInit(): void {
  }

  hasCuCoverage(name) {
    console.log(this.curCoverageNames)
    return this.curCoverageNames.has(name);
  }
}
