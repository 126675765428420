<div class="col-12 banner-container" [ngClass]="showContainer ? 'show' : 'hide'">
    <div class="row" style="background-color: black;">
        <div class="banner-left">
            <div class="banner-close">
                <i class="fa fa-times" style="cursor: pointer;" (click)="closeBanner()"></i>
            </div>
            <div class="banner-img">
                <img [src]="bannerObj?.bannerLogo" alt="Banner Icon" />
            </div>
            <div class="banner-text">
                <span class="info-text">{{bannerObj?.displayInfo}}</span>
            </div>
        </div>
        <div class="banner-right" (click)="installNav()">
            <span class="info-text">Open</span>
        </div>
    </div>
</div>