<div class="col-lg-12">
    <div class="row">
        <div class="col-lg-6 user-actions-order">
            <div class="login-box card m-10-right" style="height: 100%; ">
                <div class="card-body" style="background-color: #eef5f9;">
                    <div class="col-lg-12" style="padding: 20px 0px;">
                        <h5 class="login-button-title-right m-b-10 header-five-l">{{"NEW_USER" | translate}}</h5>
                        <!-- label class="action-info-text m-b-5">(Create an account and manage your policies online)</label> -->
                        <button type="button" class="btn btn-info btn-block buttons" (click)="navLocation('sign-up')"
                            [ngStyle]="styling?.button?.buttonNavigation">{{"CREATE_ACCOUNT" | translate}}</button>
                    </div>
                    <div class="col-lg-12" style="padding:0px;">
                        <div *ngIf="displayButton?.expresspay">
                            <h5 class="login-button-title-right m-b-10 header-five-l" style="margin-top: 36px;">
                                {{'QUICK_ACTIONS' | translate}}</h5>
                            <!-- <label class="action-info-text">(Express Pay allows you to make a payment without logging in)</label> -->
                            <button type="button" class="btn btn-info btn-block m-b-10 buttons" (click)="navLink()"
                                [ngStyle]="styling?.button?.buttonNavigation">{{'EXPRESS_PAY' | translate}}</button>
                        </div>
                        <!-- <div *ngIf ="isSfi" class="login-button-title-right m-b-10" style="margin-top: 36px;">{{'QUICK_ACTIONS_SFI' | translate}}</div> -->
                        <!-- <button *ngIf ="isSfi" type="button" class="btn btn-info btn-block m-b-10 buttons"
                        [ngStyle]="styling?.button?.buttonNavigation">{{'EXPRESS_PAY_SFI' | translate}}</button> -->
                        <!-- <label class="action-info-text"  *ngIf ="displayButton?.getQuote">(Shopping for insurance? Get a hassle-free quote now!)</label> -->
                        <button *ngIf="displayButton?.getQuote" type="button" class="btn btn-info btn-block buttons"
                            (click)="navLocation('get-quote')"
                            [ngStyle]="styling?.button?.buttonNavigation">{{'GET_A_QUOTE' | translate}}</button>
                        <!-- <button type="button" class="btn btn-info btn-block buttons" style = "visibility: hidden;" (click)="navLocation('demo-get-a-quote')">Get a Quote Demo</button> -->
                    </div>
                </div>
            </div>
        </div>
        <div class="col-lg-6 login-box-order">
            <div class="login-box card m-10-left" style="height: 100%;">
                <div class="card-body" style="background-color: #eef5f9;">
                    <form [formGroup]="loginForm" autocomplete="false" (ngSubmit)="navHome()"
                        class="form-horizontal form-material" id="loginform">
                        <input [hidden]="true" type="password" name="fakepasswordremembered"
                            id="please_ignore_for_autocomplete" />
                        <div class="m-t-20">
                            <div class="col-lg-12">
                                <mat-form-field style="width: 100%">
                                    <input matInput formControlName="username" type="text"
                                        [ngStyle]="styling?.fieldValueInput" placeholder="{{'USERNAME' | translate}}" />
                                    <!-- <span matSuffix>
                                        <a class="fas fa-question-circle" popoverTitle="Username Format" [popover]="'TOOLTIP_USERNAME' | translate" container="body" triggers="mouseenter:mouseleave"></a>
                                    </span> -->
                                </mat-form-field>
                                <p class="error-class mat-angular-error-text"
                                    *ngIf="!loginForm.get('username').valid && isSubmitted">{{'REQUIRED_FIELD' |
                                    translate: {value:'Username'} }}</p>
                            </div>
                        </div>
                        <div class="p-b-5">
                            <div class="col-lg-12">
                                <mat-form-field style="width: 100%">
                                    <input matInput autocomplete="new-password" formControlName="password"
                                        [type]="showPassword ? 'password': 'text'"
                                        placeholder="{{'PASSWORD' | translate}}" [ngStyle]="styling?.fieldValueInput" />
                                    <span matSuffix>
                                        <a id='login_togglePassword' class='show-password-data m-r-10'
                                            (click)=togglePassword()>
                                            <i [ngStyle]="styling?.toolTip"
                                                [ngClass]="showPassword?'fa fa-eye': 'fa fa-eye-slash'"></i>
                                        </a>
                                    </span>
                                    <!-- <span matSuffix>
                                        <a class="fas fa-question-circle" popoverTitle="Password Format" [popover]="'TOOLTIP_PASSWORD' | translate" container="body" triggers="mouseenter:mouseleave"
                                            containerClass="customClass"></a>
                                    </span> -->
                                </mat-form-field>
                                <p class="error-class mat-angular-error-text p-b-20"
                                    *ngIf="!loginForm.get('password').valid && isSubmitted">{{'REQUIRED_FIELD' |
                                    translate: {value:'Password'} }}</p>
                            </div>
                        </div>
                        <div class="" *ngIf="displayButton?.language">
                            <div class="col-lg-12">
                                <ng-select id="languageList_7" [items]="languageList" appendTo="body"
                                    [hideSelected]="true" placeholder="Select Language"
                                    formControlName="selectedLanguage" bindLabel="language" [clearable]="false"
                                    (change)="selectChange($event)" [ngStyle]="styling?.fieldDropDown">
                                </ng-select>
                            </div>
                        </div>
                        <div class="col-lg-12 m-t-10">
                            <p class="checkbox checkbox-primary body-paragraph-s" style="padding-top:15px;">
                                <input id="checkbox-signup" type="checkbox" formControlName="rememberMe">
                                <label for="checkbox-signup" [ngStyle]="styling?.fieldValueCheckBox"> {{'REMEMBER_ME' |
                                    translate}} </label>
                            </p>
                        </div>
                        <div class="form-group text-center m-t-20">
                            <div class="col-lg-12">
                                <button class="btn btn-info btn-block waves-effect waves-light buttons" type="submit"
                                    [ngStyle]="styling?.button?.buttonNavigation">{{'LOG_IN' | translate}}</button>
                            </div>
                        </div>
                        <div class="form-group col-lg-12">
                            <div class="d-flex" style="justify-content: space-between;">
                                <!-- <div class="hyperlink-button hyperlinks" (click)="navLocation('forgot-password')"> -->
                                <div class="hyperlink-button" [ngStyle]="styling?.footerHyperlink"
                                    (click)="navLocation('forgot-password')">
                                    {{'FORGOT_PASSWORD' | translate}}
                                </div>
                                <span class="vertical-line" [ngStyle]="styling?.verticalSeparator"></span>
                                <!-- <div class="hyperlink-button hyperlinks text-right" (click)="navLocation('forgot-username')"> -->
                                <div class="hyperlink-button text-right" [ngStyle]="styling?.footerHyperlink"
                                    (click)="navLocation('forgot-username')">
                                    {{'FORGOT_USERNAME' | translate}}
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>