import { Component, OnInit, Input } from '@angular/core';
import { UtilsService } from '../../../shared/services/utils.service';

@Component({
  selector: 'app-info-card',
  templateUrl: './info-card.component.html',
  styleUrls: ['./info-card.component.scss']
})
export class InfoCardComponent implements OnInit {
  @Input() infoCardDetails: any
  styling =this.utilsService.getItemfromSession('themeObject')
  constructor(private utilsService: UtilsService) { }

  ngOnInit(): void {
  }

}
