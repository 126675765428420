<div class="row">
    <div class="col-md-12">
        <div class="card">
            <div class="card-body">
                <!-- <h3 class="p-b-20">Configurations</h3> -->
                <form [formGroup]="stylingForm">
                    <div class="">
                        <h4 class="font-weight-bold">Select a color theme</h4>
                        <div class="row col-md-12">
                            <div class="col-md-4 p-t-10" *ngFor="let theme of availableThemes">
                                <div style="width: 100px;">
                                    <input type="radio" [value]="theme.name" formControlName="selectedTheme" (change)="selectChange($event)" [id]="theme.name" class="radio-col-light-blue"/>
                                    <label [for]="theme.name">{{theme.name}}</label>
                                </div>
                            </div>
                            <!-- <ng-select [items]="availableThemes"
                            appendTo="body"
                            [hideSelected]="true"
                            placeholder="Select a Theme"
                            formControlName="selectedTheme"
                            bindLabel="name"
                            [clearable]="false"
                            (change)="selectChange($event)"
                            >
                            </ng-select> -->
                            <app-inline-page-styling class="row col-md-12 page-styling-custom" 
                            [cardStylingObj]="tempConfiguration?.page_configuration?.cardStyling"></app-inline-page-styling>
                        </div>
                    </div>
                    <div class="form-group col-md-12 text-right">
                        <button class="btn btn-danger m-r-10" (click)="cancelTheme()"
                        [ngStyle]="styling?.button?.buttonBack">Cancel Theme</button>
                        <button class="btn btn-success" (click)="saveTheme()"
                        [ngStyle]="styling?.button?.buttonNext">Save Theme</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>