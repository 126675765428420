<div>
    <h3 class="col-md-12 text-center header-four-s title-spacing-warning" [ngStyle]="styling?.cardTitle" [innerHTML]='header'></h3>
    <p class="col-md-12 text-center" [ngStyle]="styling?.fieldValueCheckBox" [innerHTML]='content'></p>
    <div class="col-md-12 title-spacing-warning" style="text-align: center">
        <button class="btn btn-info custom-button-info buttons" type="button" (click)="hideUserWarning()"
            [ngStyle]="styling?.button?.buttonNegative" style="margin: 10px;"> {{'GO_BACK' | translate }}
        </button>
        <button class="btn btn-info custom-button-info buttons" type="button" (click)="navigateToPage(page)"
            [ngStyle]="styling?.button?.buttonPositive" style="margin: 10px;">{{'CANCEL_CLAIM' | translate }}
        </button>
    </div>
</div>
<!-- 
<div>
    <div class="modal-header">
        <h4 class="modal-title pull-left header-four-s" [ngStyle]="styling?.cardTitle" [innerHTML]='header'></h4>
    </div>
    <div class="modal-body">
        <p [ngStyle]="styling?.fieldValueCheckBox" [innerHTML]='content'></p>
    </div>
    <div class="modal-footer">
        <div class="text-right">
            <button type="button" (click)="hideUserWarning()" class="btn btn-danger buttons m-r-10"
            [ngStyle]="styling?.button?.buttonNegative"
            >{{'GO_BACK' | translate }}</button>
            <button type="button" (click)="navigateToPage(page)" class="btn btn-info buttons"
            [ngStyle]="styling?.button?.buttonPositive"
            >{{'CANCEL_CLAIM' | translate }}</button>
        </div>
    </div>
</div> -->