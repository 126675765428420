import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { DasboardService } from '../dashboard/dasboard.service';
import { CommonApiService } from '../../shared/services/common-api.service';
import { Router, ActivatedRoute } from '@angular/router';
import { NotifytoastService } from '../../shared/services/notificationToast.service';
import { ValidationService } from '../../shared/services/validation.service';
import { UtilsService } from '../../shared/services/utils.service';

@Component({
  selector: 'app-my-account-edit',
  templateUrl: './my-account-edit.component.html',
  styleUrls: ['./my-account-edit.component.scss'],
  providers: [DasboardService, CommonApiService]
})
export class MyAccountEditComponent implements OnInit {
  accountForm: any
  accountDetails: any;
  isSubmitted = false;
  emailError = '';
  styling =this.utilsService.getItemfromSession('themeObject')
  constructor(
    private formBuilder: FormBuilder,
    private dashboardService: DasboardService,
    private router: Router,
    private route: ActivatedRoute,
    private notifyToast: NotifytoastService,
    private utilsService: UtilsService
    ) { }

  ngOnInit(): void {
    this.initForm()
    this.getAccountInfo()
    this.keyEmail()
  }

  initForm(){
    this.accountForm = this.formBuilder.group({
      email: ['', [Validators.required, ValidationService.emailValidator]],
      firstName: ['', [Validators.required]],
      lastName: ['', [Validators.required]],
      passwordLastChangedDate: [''],
      status: [''],
      userName: [''],
      zipCode: [''],
      dob: ['']
    })
  }

  getAccountInfo(){
    this.dashboardService.getCustomerByID().subscribe(resp => {
      this.accountForm.patchValue(resp)
      this.accountDetails = resp
      console.log(this.accountForm)
    })
  }

  navToLink(url){
    const tenant =this.route.parent.snapshot.params.tenant
    this.router.navigate([`${tenant}/${url}`])
  }

  saveAcntInfo(){
    this.isSubmitted = true
    if(this.accountForm.valid){
      console.log('meaow', this.accountForm.value)
      this.dashboardService.updateCustomerUser(this.accountForm.value).subscribe(resp => {
        this.notifyToast.success('Successfully updated')
        this.navToLink('home/my-account-view')
      }, error => {
          const errorText = `Please Contact System Administrator.`
          this.utilsService.confirmModalPopup(errorText)
      })
    }
  }

  keyEmail(){
    const temp = this.accountForm.get('email')
    if(temp.errors){
      if (temp.errors.required) {
        this.emailError =  'Email is required'
      } else if (temp.errors.invalidEmailAddress) {
        this.emailError =  'Email is not valid.'
      }
    }
  }

}
