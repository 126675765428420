<div class="card h-100">
    <div class="card-body body-mobile">
        <h2 class="custom-title text-center p-b-10 header-two-l" [ngStyle]="styling?.pageTitle" style="padding-bottom:20px;">{{'MY_ACCOUNT_PROFILE_EDIT' | translate}}</h2>
        <form [formGroup]="accountForm" (ngSubmit)="saveAcntInfo()">
            <div class="row form-group">
                <div class="col-lg-6">
                    <mat-form-field style="width: 100%">
                        <input matInput class="body-paragraph-s" formControlName="firstName" [ngStyle]="styling?.fieldValueInput" type="text" placeholder="{{'FIRST_NAME' | translate}} *" />
                    </mat-form-field>
                    <p class="error-class mat-angular-error-text" *ngIf="!accountForm.get('firstName').valid && isSubmitted">{{'REQUIRED_FIELD' | translate: {value:'First Name'} }}</p>
                </div>
                <div class="col-lg-6">
                    <mat-form-field style="width: 100%">
                        <input matInput class="body-paragraph-s" formControlName="lastName" [ngStyle]="styling?.fieldValueInput" type="text" placeholder="{{'LAST_NAME' | translate}} *" />
                    </mat-form-field>
                    <p class="error-class mat-angular-error-text" *ngIf="!accountForm.get('lastName').valid && isSubmitted">{{'REQUIRED_FIELD' | translate: {value:'Last Name'} }}</p>
                </div>
            </div>
            <div class="row form-group">
                <div class="col-lg-6">
                    <div class="custom-title-info account-title" [ngStyle]="styling?.attributeKey">{{'USERNAME' | translate}}</div>
                    <div class="custom-title-infoDetail account-description body-paragraph-s" [ngStyle]="styling?.attributeValue">{{accountDetails?.userName }}</div>
                </div>
                <div class="col-lg-3">
                    <div class="custom-title-info account-title" [ngStyle]="styling?.attributeKey">{{'PASSWORD_LAST_CHANGED' | translate}}</div>
                    <div class="custom-title-infoDetail account-description body-paragraph-s" [ngStyle]="styling?.attributeValue">{{accountDetails?.passwordLastChangedDate  | date: 'MM/dd/yyyy'}}</div>
                </div>
                <div class="col-lg-3">
                    <button class="btn btn-info w-100 buttons" style="padding: 5px;" (click)="navToLink('home/change-password')"
                    [ngStyle]="styling?.button?.buttonNavigation">{{'CHANGE_PASSWORD' | translate}}</button>
                </div>
            </div>
            <div class="row form-group">
                <div class="col-lg-6">
                    <mat-form-field style="width: 100%">
                        <input matInput class="body-paragraph-s" formControlName="email" [ngStyle]="styling?.fieldValueInput" type="text" (input)="keyEmail()" placeholder="{{'EMAIL' | translate}} *" />
                    </mat-form-field>
                    <p class="error-class mat-angular-error-text" *ngIf="!accountForm.get('email').valid && isSubmitted">{{emailError}}</p>
                </div>
                <!-- <div class="col-lg-6">
                    <mat-form-field style="width: 100%">
                        <input matInput class="body-paragraph-s" formControlName="dob" type="text" placeholder="{{'DOB' | translate}}" />
                    </mat-form-field>
                    <p class="error-class" *ngIf="!accountForm.get('dob').valid && isSubmitted">{{'REQUIRED_FIELD' | translate: {value:'DOB'} }}</p>
                </div> -->
            </div>
            <!-- <hr />
            <h3 class="custom-title text-center p-b-10" [ngStyle]="styling?.header">{{'MANAGE_POLICIES' | translate}}</h3>
            <div class="row form-group">
                <div class="col-lg-6">
                    <div class="custom-title-info account-title" [ngStyle]="styling?.attributeKey">{{'POLICY_NUMBER' | translate}}</div>
                    <div class="custom-title-infoDetail account-description" [ngStyle]="styling?.content">{{accountDetails?.policyNumber}}</div>
                </div>
                <div class="col-lg-6">
                    <div class="custom-title-info account-title" [ngStyle]="styling?.attributeKey">{{'POLICY_TYPE' | translate}}</div>
                    <div class="custom-title-infoDetail account-description" [ngStyle]="styling?.content">{{accountDetails?.zipCode}}</div>
                </div>
            </div>
            <div class="row form-group">
                <div class="col-lg-6">
                    <div class="custom-title-info account-title" [ngStyle]="styling?.attributeKey">{{'ZIP_CODE' | translate}}</div>
                    <div class="custom-title-infoDetail account-description" [ngStyle]="styling?.content">{{accountDetails?.zipCode}}</div>
                </div>
                <div class="col-lg-6">
                    <div class="custom-title-info account-title" [ngStyle]="styling?.attributeKey">{{'PAPERLESS_ENROLLED' | translate}}</div>
                    <div class="d-flex">
                        <div style="width: 100px;">
                            <input type="radio" name="radio_enroll" id="yes" class="radio-col-light-blue" />
                            <label for="yes">Yes</label>
                        </div>
                        <div style="width: 100px;">
                            <input type="radio" name="radio_enroll" id="no" class="radio-col-light-blue" checked/>
                            <label for="no">No</label>
                        </div>
                    </div>
                    
                </div>
            </div>
            <div class="row form-group">
                <div class="col-lg-6">
                    <div class="custom-title-info account-title" [ngStyle]="styling?.attributeKey">{{'STATUS' | translate}}</div>
                    <div class="custom-title-infoDetail account-description" [ngStyle]="styling?.content">{{accountDetails?.status}}</div>
                </div>
                <div class="col-lg-6">
                    <div class="custom-title-info account-title" [ngStyle]="styling?.attributeKey">{{'MAILING_ADDRESS' | translate}}</div>
                    <div class="custom-title-infoDetail account-description" [ngStyle]="styling?.content">{{accountDetails?.mailingAddress}}</div>
                </div>
            </div> -->

            <div class="col-md-8 offset-md-2" >
                <div class="row" style="margin: 40px 0 0 0; justify-content: center;">
                    <button class="btn btn-danger col-lg-4 buttons" type="button" (click)="navToLink('home/my-account-view')"
                    [ngStyle]="styling?.button?.buttonNegative">{{'CANCEL' | translate}}</button>
                    <span class="m-r-20 m-b-20"></span>
                    <button class="btn btn-info custom-button-info col-lg-4 buttons" type="submit"
                    [ngStyle]="styling?.button?.buttonPositive">{{'SAVE' | translate}}</button>
                </div>
            </div>

            <!-- <div class="row form-group">
                <div class="col-lg-12 text-right">
                    <button class="btn btn-danger m-r-10" type="button" (click)="navToLink('home/my-account-view')"
                    [ngStyle]="styling?.button?.buttonBack">{{'CANCEL' | translate}}</button>
                    <button class="btn btn-info custom-button-info" type="submit"
                    [ngStyle]="styling?.button?.buttonNext">{{'SAVE' | translate}}</button>
                </div>
            </div> -->
        </form>
    </div>
</div>