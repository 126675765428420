<!-- <div class="card custom-card-border h-100" [ngStyle]="styling?.card?.bodyStyling">
    <div class="card-body p-b-0" style="margin-bottom: -16px;">
        <div class="row custom-color" *ngIf="policiesDetails">
            <div class="col-md-5" style="margin-bottom:5px;">
                <form [formGroup]="policiesForm">
                    <div class="form-group m-b-0">
                        
                        <span [ngStyle]="styling?.attributeValue">
                            <ng-select id="policy_address" [readonly]="(listAddress.length == 1 || notclickable)" [items]="listAddress" (change)="selectCurrrentPolicy($event)"
                                appendTo="body" [hideSelected]="true" placeholder="Select Address"
                                formControlName="selectedAddress" [ngStyle]="styling?.fieldDropDown" bindLabel="address"
                                [clearable]="false" [searchable]="false">
                            </ng-select>
                        </span>
                    </div>
                </form>
            </div>
            <div class="col-md-4" style="margin-bottom:-10px; padding: 0px;">
                <p class="form-control-static custom-description">
                    <span class="account-description" [ngStyle]="styling?.attributeKey">
                        {{'HOME_POLICY' | translate}}:
                    </span>
                    <span class="account-title" [ngStyle]="styling?.attributeValue" *ngIf="isPolicyDetailsPage; else showHyperlink">
                        {{policiesDetails[policyIndex]?.policyDetails.policyNumber}}
                    </span>
                    <ng-template #showHyperlink>
                        <span class="custom-link account-title hyperlinks" (click)="navigateToClaim()"
                            [ngStyle]="styling?.hyperlink">
                            {{policiesDetails[policyIndex]?.policyDetails.policyNumber}}
                        </span>
                    </ng-template>
                </p>
            </div>
            <div class="col-md-3" style="margin-bottom:-16px;padding: 0px;">
                <p class="form-control-static custom-description">
                    <span class="account-description" [ngStyle]="styling?.attributeKey">
                        {{'STATUS' | translate}}:
                    </span>

                    <span class="account-title" [ngStyle]="styling?.attributeValue">
                        {{policiesDetails[policyIndex]?.policyDetails.status}}
                    </span>
                </p>
            </div>

        </div>


    </div>
</div> -->

<div class="card m-auto h-100 d-flex" [ngStyle]="styling?.card?.bodyStyling">
    <div class="card-body">
        <div class="row custom-color" *ngIf="policiesDetails">
            <div class="col-md-5">
                <form [formGroup]="policiesForm">
                    <div class="form-group m-b-0">           
                        <span [ngStyle]="styling?.attributeValue">
                            <ng-select id="policy_address" [readonly]="(listAddress.length == 1 || notclickable)" [items]="listAddress" (change)="selectCurrrentPolicy($event)"
                                appendTo="body" [hideSelected]="true" placeholder="Select Address" bindValue="policyNo"
                                formControlName="selectedPolicyNo" [ngStyle]="styling?.fieldDropDown" bindLabel="address"
                                [clearable]="false" [searchable]="false">
                            </ng-select>
                        </span>
                    </div>
                </form>
            </div>
            <div class="col-md-4 spacing-cols">
                <div class="form-control-static custom-description">
                    <span class="account-description" [ngStyle]="styling?.attributeKey">
                        {{'HOME_POLICY' | translate}}:
                    </span>
                    <span class="account-title" [ngStyle]="styling?.attributeValue" *ngIf="isPolicyDetailsPage; else showHyperlink">
                        <!-- {{policiesDetails[policyIndex]?.policyDetails.policyNumber}} -->
                        {{policiesForm?.value?.selectedPolicyNo}}
                    </span>
                    <ng-template #showHyperlink>
                        <span class="custom-link account-title hyperlinks" (click)="navigateToClaim()"
                            [ngStyle]="styling?.hyperlink">
                            <!-- {{policiesDetails[policyIndex]?.policyDetails.policyNumber}} -->
                            {{policiesForm?.value?.selectedPolicyNo}}
                        </span>
                    </ng-template>
                </div>
            </div>
            <div class="col-md-3 spacing-cols">
                <div class="form-control-static custom-description">
                    <span class="account-description" [ngStyle]="styling?.attributeKey">
                        {{'STATUS' | translate}}:
                    </span>

                    <span class="account-title" [ngStyle]="styling?.attributeValue">
                        <!-- {{policiesDetails[policyIndex]?.policyDetails.status}} -->
                        {{currentStatus}}
                    </span>
                </div>
            </div>

        </div>


    </div>
</div>