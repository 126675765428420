import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { NotFoundComponent } from './shared/not-found/not-found.component';
import { CommonApiService } from './shared/services/common-api.service';
import { AuthService } from './shared/services/auth.service';

const routes: Routes = [
  { path: '**', component: NotFoundComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {useHash: true, scrollPositionRestoration: 'top'})],
  exports: [RouterModule],
  providers: [ CommonApiService, AuthService],
})
export class AppRoutingModule { }
