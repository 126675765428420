import {
  Component,
  OnInit,
  ViewChild,
  HostListener,
  Input,
} from '@angular/core';
var CryptoJS = require("crypto-js");
import { MatStepper } from '@angular/material/stepper';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { DasboardService } from '../../dashboard/dasboard.service';
import { CommonApiService } from '../../../shared/services/common-api.service';
import { UtilsService } from '../../../shared/services/utils.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import * as moment from 'moment';
import { PrivateDataShare } from '../../../shared/services/privateDataShare.service';

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.scss'],
  providers: [CommonApiService, DasboardService],
})
export class PaymentComponent implements OnInit {
  termsLength: any;
  renewalTermPaymentDetails: any;
  currentTermPaymentDetails: any;
  termChange = false;
  termType = 'Current';
  hasBalanceDue: any;
  billTo: any;
  stepper: MatStepper;
  policyIndex = sessionStorage.getItem('CurPolicySelection');
  policyInfo = this.utilsService.getItemfromSession('policyDetails')
    .PolicySummary;
  allowedCardTypes: any;
  paymentAuthorization: any;
  cardType = '';
  paymentAccount: any;
  lastFourDigits: any;
  status: any;
  type = '';
  paymentStatus: any;
  transactionId: any;
  isSubmitted = false;
  tenant: any;
  message: any;
  nextPaymentDue: any;
  notClickable = false;
  customAmount: any;
  remainingBalance: any;
  myDate = new Date().toLocaleDateString('en-US');
  policyNumber = this.utilsService.getPolicyNumber();
  policiesForm: any;
  amount: any;
  lastfour: any;
  listAddress = [];
  addressObj = [];
  policyStatus: any;
  selectedAddressObj = [];
  paymentMethodsList: any;
  isCreditCardPayment: any;
  private subject: Subject<string> = new Subject();
  styling = this.utilsService.getItemfromSession('themeObject');
  policySummary: any;
  isSmallWindow: boolean;
  isLargeWindow: boolean;
  NextAmountDueControl: true;
  RemainingBalanceContol: true;
  CustomAmountControl: true;
  key: any;
  mortgageeAlertMessage1: any;
  mortgageeAlertMessage2: any;
  cancelledPolicyMessage: any;

  constructor(
    private http: CommonApiService,
    public utilsService: UtilsService,
    private formBuilder: FormBuilder,
    private dashboardService: DasboardService,
    private route: ActivatedRoute,
    private privateDataShare: PrivateDataShare,
    private router: Router
  ) { }
  paymentForm: FormGroup;
  paymentForm1: FormGroup;
  paymentForm2: FormGroup;
  ngOnInit(): void {
    this.isLargeWindow = window.innerWidth >= 900;
    this.isSmallWindow = window.innerWidth < 540;
    this.tenant = this.route.parent.snapshot.params.tenant;
    this.subject.pipe(debounceTime(800)).subscribe((resp) => {
      this.validateCard(resp);
    });
    this.getSupportedPaymentDetails();
    this.initForm();
    // this.getPolicyDetails();
    this.getPaymentResponseMessages();
    this.getEncryptionToken()
    this.getPaymentMortgageeAlertMessages();
    this.getCancelledPolicyMessage()
  }
  showPaymentPlanOption(paymentPlanControl) {
    if (this.billTo == 'Mortgage Bill') {
      if (paymentPlanControl) {
        return true;
      } else {
        return false;
      }
    }
    return true;
  }
  getPaymentResponseMessages() {
    var infoType = 'payments_message';
    this.http
      .get(
        `getCardInfo?accountCode=${this.tenant}&infoType=${infoType}`,
        null,
        true
      )
      .subscribe(
        (resp) => {
          if (resp) {
            this.message = resp.out;
          }
        },
        (error) => {
          const errorText = `Please Contact System Administrator.`;
          this.utilsService.confirmModalPopup(errorText);
        }
      );
  }

  getEncryptionToken() {
    this.http
      .get(
        `getEncryptionToken?accountCode=${this.tenant}`,
        null,
        true
      )
      .subscribe(
        (resp) => {
          if (resp) {
            this.key = atob(resp.out.encryptionKey.default);
          }
        },
        (error) => {
          const errorText = `Please Contact System Administrator.`;
          this.utilsService.confirmModalPopup(errorText);
        }
      );
  }

  getCancelledPolicyMessage() {
    var infoType = 'payments_cancelled_message';
    this.http
      .get(
        `getCardInfo?accountCode=${this.tenant}&infoType=${infoType}`,
        null,
        true
      )
      .subscribe(
        (resp) => {
          if (resp) {
            if (resp.out != null) {
              this.cancelledPolicyMessage = resp.out;
            }
          }
        },
        (error) => {
          const errorText = `Please Contact System Administrator.`;
          this.utilsService.confirmModalPopup(errorText);
        }
      );
  }

  getPaymentMortgageeAlertMessages() {
    var infoType = 'payment_Mortgagee_alert_message';
    this.http
      .get(
        `getCardInfo?accountCode=${this.tenant}&infoType=${infoType}`,
        null,
        true
      )
      .subscribe(
        (resp) => {
          if (resp) {
            if (resp.out != null) {
              this.mortgageeAlertMessage1 = resp.out.message1;
              this.mortgageeAlertMessage2 = resp.out.message2;
            }
          }
        },
        (error) => {
          const errorText = `Please Contact System Administrator.`;
          this.utilsService.confirmModalPopup(errorText);
        }
      );
  }

  getPolicyDetails(curPolicyNo) {
    let policySumary = []
    policySumary = this.privateDataShare.getPolicyDetails().PolicySummary
    this.selectedAddressObj = policySumary.find(resp => resp.policyDetails.policyNumber == curPolicyNo).policyDetails
    // this.addressObj = JSON.parse(
    //   sessionStorage.getItem('policyDetails')
    // ).PolicySummary;
    // this.selectedAddressObj = this.addressObj[this.policyIndex].policyDetails;
    this.policyStatus = this.selectedAddressObj['status']
    if (this.policyStatus == 'Cancelled') // check if status is cancelled
    { this.paymentForm.disable() }
    else {
      this.paymentForm.enable()
    }
    this.paymentForm.patchValue({
      name: this.selectedAddressObj['primaryInsured'][
        'fullName'
      ],
      billing_name: this.selectedAddressObj['primaryInsured'][
        'fullName'
      ],
    });

  }

  getBillingDetails() {
    this.dashboardService.getPolicyInfoByPolicyNumber().subscribe((resp) => {
      this.paymentForm.patchValue({
        billing_address: resp.billingDetails.billingAddress.addressLine1,
        city: resp.billingDetails.billingAddress.city,
        zip: resp.billingDetails.billingAddress.zipCode,
        state: resp.billingDetails.billingAddress.stateCode,
      });
    });
  }
  initForm() {
    this.policiesForm = this.formBuilder.group({
      selectedAddress: [],
    });
    this.paymentForm = this.formBuilder.group({
      customAmount: [],
      selectedAmount: ['amountDue'],
      amount: [null, [Validators.required]],
      name: [null, [Validators.required]],
      card_number: [
        '',
        [
          Validators.required,
          Validators.minLength(12),
          Validators.maxLength(23),
        ],
      ],
      expiration_month: [
        '',
        [
          Validators.required,
          Validators.pattern('[0-9]*'),
          Validators.minLength(2),
          Validators.maxLength(2),
        ],
      ],
      expiration_year: [
        '',
        [
          Validators.required,
          Validators.pattern('[0-9]*'),
          Validators.minLength(2),
          Validators.maxLength(2),
        ],
      ],
      routing_number: [null],
      account_number: [null],
      billing_name: [null, [Validators.required]],
      billing_address: [null, [Validators.required]],
      city: [null, [Validators.required]],
      zip: [null, [Validators.required]],
      cvv: ['', [Validators.required, Validators.pattern(/^[0-9]{3}$/)]],
      billing_address2: [''],
      state: [null, [Validators.required]],
      payment_method: ['Credit Card', [Validators.required]],
    });
    this.paymentForm.value.expiration =
      this.paymentForm.value.expiration_month +
      this.paymentForm.value.expiration_year;
    // if (this.billTo == 'Mortgage Bill') {
    //   this.paymentForm.patchValue({
    //     selectedAmount: 'remainingBalance'
    //   })
    // }
  }

  determinePaymentMethod(selectedMethod) {
    if (selectedMethod == 'Bank Account') {
      this.paymentForm.get('card_number').clearValidators();
      this.paymentForm.get('card_number').updateValueAndValidity();

      this.paymentForm.get('expiration_month').clearValidators();
      this.paymentForm.get('expiration_month').updateValueAndValidity();

      this.paymentForm.get('expiration_year').clearValidators();
      this.paymentForm.get('expiration_year').updateValueAndValidity();

      this.paymentForm.get('cvv').clearValidators();
      this.paymentForm.get('cvv').updateValueAndValidity();

      this.paymentForm
        .get('routing_number')
        .setValidators([Validators.required, Validators.pattern('^[0-9]{9}$')]); //, Validators.minLength(9), Validators.maxLength(9)]);
      this.paymentForm.get('routing_number').updateValueAndValidity();

      this.paymentForm
        .get('account_number')
        .setValidators([Validators.required]);
      this.paymentForm.get('account_number').updateValueAndValidity();
    } else if (selectedMethod == 'Credit Card') {
      console.log('Selected Credit Card');
      this.paymentForm.get('routing_number').clearValidators();
      this.paymentForm.get('routing_number').updateValueAndValidity();

      this.paymentForm.get('account_number').clearValidators();
      this.paymentForm.get('account_number').updateValueAndValidity();

      this.paymentForm
        .get('card_number')
        .setValidators([
          Validators.required,
          Validators.minLength(12),
          Validators.maxLength(23),
        ]);
      this.paymentForm.get('card_number').updateValueAndValidity();

      this.paymentForm
        .get('expiration_month')
        .setValidators([
          Validators.required,
          Validators.pattern('[0-9]*'),
          Validators.minLength(2),
          Validators.maxLength(2),
        ]);
      this.paymentForm.get('expiration_month').updateValueAndValidity();

      this.paymentForm
        .get('expiration_year')
        .setValidators([
          Validators.required,
          Validators.pattern('[0-9]*'),
          Validators.minLength(2),
          Validators.maxLength(2),
        ]);
      this.paymentForm.get('expiration_year').updateValueAndValidity();
    }
  }
  stepperSelected(selectedTab) {
    // this.stepper['selectedIndex'] = selectedTab.selectedIndex
    // console.log("slected tab",this.stepper.selectedIndex)
    return selectedTab;
  }
  resetStepper(stepper: MatStepper) {
    this.notClickable = false;
    this.type = ''
    stepper.selectedIndex = 0;
  }
  goBack(stepper: MatStepper) {
    stepper.previous();
  }
  goForward(stepper: MatStepper) {
    this.isSubmitted = true;
    if (stepper.selectedIndex === 0) {
      this.notClickable = true;
      this.getPaymentAuthorization();
      this.validateAmount();
      this.status = null;
      this.amount = this.paymentForm.value.amount;
      if (this.paymentForm.value.payment_method == 'Credit Card') {
        let cardNumber = this.paymentForm.value.card_number.replace(/ /g, '');
        this.lastfour = cardNumber.substr(cardNumber.length - 4);
      } else if (this.paymentForm.value.payment_method == 'Bank Account') {
        this.lastfour =
          this.paymentForm.value.account_number.length < 4
            ? this.paymentForm.value.account_number
            : this.paymentForm.value.account_number.substr(
              this.paymentForm.value.account_number.length - 4
            );
      }
    }
    if (stepper.selectedIndex === 1) {
      this.notClickable = true;
      if (this.paymentForm.value.payment_method == 'Credit Card') {
        const data = {
          cardNumber: this.paymentForm.value.card_number.replace(/\s/g, ''),
          expirationDate:
            this.paymentForm.value.expiration_month +
            this.paymentForm.value.expiration_year,
          securityCode: this.paymentForm.value.cvv,
          amount: this.paymentForm.value.amount,
          referenceId: this.policyNumber,
          address1: this.paymentForm.value.billing_address,
          zip: this.paymentForm.value.zip,
          cardType: this.cardType,
        };
        const ciphertext = CryptoJS.AES.encrypt(JSON.stringify(data), this.key).toString();
        const creditCardData = {
          data: ciphertext
        }
        this.makeCreditCardPayment(creditCardData);
      } else if (this.paymentForm.value.payment_method == 'Bank Account') {
        const data = {
          accountNumber: this.paymentForm.value.account_number.replace(
            /\s/g,
            ''
          ),
          routingNumber: this.paymentForm.value.routing_number.replace(
            /\s/g,
            ''
          ),
          accountOwner: this.paymentForm.value.name,
          amount: this.paymentForm.value.amount,
          referenceId: this.policyNumber,
        };
        const ciphertext = CryptoJS.AES.encrypt(JSON.stringify(data), this.key).toString();
        const bankData = {
          data: ciphertext
        }
        this.makeBankAccountPayment(bankData);
      }
    }
    stepper.next();
  }

  navigateToLink(page) {
    const tenant = this.route.parent.snapshot.params.tenant;
    this.router.navigate([`${tenant}/${page}`]);
  }

  amountSelection(type) {
    if (type != 'customAmount') {
      this.paymentForm.get('customAmount').setErrors(null);
      this.paymentForm.patchValue({
        customAmount: null,
      });
    }
  }
  makeBankAccountPayment(data) {
    this.dashboardService.makeBankAccountPayment(data).subscribe(
      (resp) => {
        this.status = resp.status;
        this.transactionId = resp.transid;
        var cardNumber = this.paymentForm.value.account_number.replace(
          /\s/g,
          ''
        );
        if (cardNumber != null) {
          var len = cardNumber.length;
          this.lastFourDigits =
            len > 4 ? cardNumber.substr(len - 4, len) : cardNumber;
          var pre = Array(len - 4)
            .fill('X')
            .join('');
          this.paymentAccount = pre + this.lastFourDigits;
        }
        if ('declinetype' in resp) {
          this.type = resp.declinetype;
        } else if ('offenders' in resp) {
          this.type = resp.offenders;
        }
        // console.log("Response from bank account payment:", this.paymentStatus);
      },
      (error) => {
        const errorText = `Please Contact System Administrator.`;
        this.utilsService.confirmModalPopup(errorText);
      }
    );
  }
  makeCreditCardPayment(data) {
    this.dashboardService.makeCreditCardPayment(data).subscribe(
      (resp) => {
        this.status = resp.status;
        this.transactionId = resp.transid;
        var cardNumber = this.paymentForm.value.card_number;
        if (cardNumber != null) {
          var len = cardNumber.length;
          this.lastFourDigits =
            len > 4 ? cardNumber.substr(len - 4, len) : cardNumber;
          var pre = Array(len - 4)
            .fill('X')
            .join('');
          this.paymentAccount = pre + this.lastFourDigits;
        }
        if (resp.status !== 'accepted' || resp.status !== 'approved') {
          this.type = resp.message;
        }
      },
      (error) => {
        const errorText = `Please Contact System Administrator.`;
        this.utilsService.confirmModalPopup(errorText);
      }
    );
  }

  autoTab(eve) {
    if (eve.length > 1) {
      if (eve > 12) {
        this.paymentForm.patchValue({
          expiration_month: `0${eve % 10}`,
        });
      }
      if (this.paymentForm.value.expiration_month == 0) {
        this.paymentForm.patchValue({ expiration_month: '01' });
      }
      document.getElementById('yearInput').focus();
      this.checkPastYear();
    }
  }

  checkPastYear() {
    var ex_year = this.paymentForm.value.expiration_year;
    var ex_month = this.paymentForm.value.expiration_month;
    if (ex_month.length == 2 && ex_year.length == 2) {
      var curYear = (new Date().getFullYear() / 100).toFixed();
      var expiredDate = moment(`${curYear}${ex_year}-${ex_month}-01 00:00:00`)
        .endOf('month')
        .toDate();
      console.log(expiredDate);
      if (new Date() < expiredDate) {
        this.paymentForm.get('expiration_year').setErrors(null);
        // this.paymentForm.get('expiration_year').updateValueAndValidity()
      } else {
        this.paymentForm.get('expiration_year').setErrors({ pastDate: true });
      }
    }
  }

  cardNumberInput(value) {
    this.subject.next(value);
  }

  validateCard(resp) {
    this.paymentForm.patchValue({ cvv: '' });
    let tempValue = resp.split(' ').join('');
    if (tempValue.match(/^4[0-9]{12}(?:[0-9]{6})?/)) this.cardType = 'Visa';
    else if (tempValue.match(/^6(?:011|5[0-9]{2})[0-9]{12}/))
      this.cardType = 'Discover';
    else if (
      tempValue.match(
        /^(5[1-5][0-9]{14}|2(22[1-9][0-9]{12}|2[3-9][0-9]{13}|[3-6][0-9]{14}|7[0-1][0-9]{13}|720[0-9]{12}))/
      )
    )
      this.cardType = 'Mastercard';
    else if (tempValue.match(/^3[47][0-9]{13}$/))
      this.cardType = 'American Express';
    else if (tempValue.match(/^3(?:0[0-5]|[68][0-9])[0-9]{11}$/))
      this.cardType = 'DinersCC';
    else this.cardType = '';
    this.checkErrors(tempValue);
    this.setCVV();
  }

  setCVV() {
    if (this.cardType == 'American Express') {
      this.paymentForm
        .get('cvv')
        .setValidators([Validators.required, Validators.pattern(/^[0-9]{4}$/)]);
      this.paymentForm.get('cvv').updateValueAndValidity();
    } else if (this.cardType) {
      this.paymentForm
        .get('cvv')
        .setValidators([Validators.required, Validators.pattern(/^[0-9]{3}$/)]);
      this.paymentForm.get('cvv').updateValueAndValidity();
    } else {
      this.paymentForm
        .get('cvv')
        .setValidators([Validators.required, Validators.pattern(/^[0-9]{3}$/)]);
      this.paymentForm.get('cvv').updateValueAndValidity();
    }
  }

  checkErrors(number) {
    if (number) {
      if (!this.luhnValidator(number)) {
        this.paymentForm.get('card_number').setErrors(null);
        this.paymentForm.get('card_number').setErrors({ luhnError: true });
      } else if (this.cardType == '') {
        this.paymentForm.get('card_number').setErrors(null);
        this.paymentForm.get('card_number').setErrors({ cardTypeNull: true });
      } else if (!this.allowedCardTypes.includes(this.cardType)) {
        this.paymentForm.get('card_number').setErrors(null);
        this.paymentForm.get('card_number').setErrors({ cardType: true });
      } else {
        this.paymentForm.get('card_number').setErrors(null);
      }
    }
  }

  luhnValidator(cardNo) {
    let cardNumber = cardNo.split(' ').join('');
    return (
      /^\d+$/.test(cardNumber) &&
      cardNumber
        .split('')
        .reverse()
        .reduce((sum, d, n) => {
          return +sum + (n % 2 ? [0, 2, 4, 6, 8, 1, 3, 5, 7, 9][+d] : +d);
        }, 0) %
      10 ==
      0
    );
  }

  printReceipt() {
    window.print();
  }

  getInstallmentDetails() {
    this.dashboardService.getPaymentSummary().subscribe((resp) => {
      this.termsLength = resp.length;
      for (var paymentInfo of resp) {
        if (paymentInfo.termType == 'Current') {
          this.currentTermPaymentDetails = paymentInfo;
        } else if (paymentInfo.termType == 'Future') {
          this.renewalTermPaymentDetails = paymentInfo;
        }
      }
      this.getPaymentSummary();
    });
  }
  getPaymentSummary() {
    var paymentList = [];
    //this.paymentList=[]
    if (this.policyStatus != 'Cancelled') {
      if (this.termType == 'Current') {
        paymentList = this.currentTermPaymentDetails.installments.Installment;
        this.billTo = this.currentTermPaymentDetails.billTo;
      } else if (this.termType == 'Future') {
        paymentList = this.renewalTermPaymentDetails.installments.Installment;
        this.billTo = this.renewalTermPaymentDetails.billTo;
      }
      if (this.billTo == 'Mortgage Bill') {
        this.paymentForm.patchValue({
          selectedAmount: 'remainingBalance',
        });
      }
      this.remainingBalance = '0.00';
      this.nextPaymentDue = '0.00';
      for (var i = 0, j = 0; i < paymentList.length; i++) {
        paymentList[i]['status'] =
          paymentList[i]['status'] == 'Open'
            ? 'Billed'
            : paymentList[i]['status'];
        if (paymentList[i]['status'] != 'Failed') {
          paymentList[i]['fees'] = (
            parseFloat(paymentList[i]['installmentFee']) +
            parseFloat(paymentList[i]['lateFee']) +
            parseFloat(paymentList[i]['penaltyFee']) +
            parseFloat(paymentList[i]['policyFee']) +
            parseFloat(paymentList[i]['premiumTax'])
          ).toFixed(2);
          paymentList[i]['premium'] = (
            parseFloat(paymentList[i]['dueAmount']) -
            parseFloat(paymentList[i]['fees'])
          ).toFixed(2);

          if (j == 0 && paymentList[i]['balanceDue'] != '0.00') {
            this.nextPaymentDue = paymentList[i]['balanceDue'];
            j++;
          }
          this.remainingBalance = (
            parseFloat(this.remainingBalance) +
            parseFloat(paymentList[i]['balanceDue'])
          ).toFixed(2);
          if (
            this.termType == 'Current' &&
            this.remainingBalance == 0.0 &&
            this.termsLength > 1 &&
            !this.termChange
          ) {
            this.changeTerm();
          }
        }
      }
    } else {
      this.remainingBalance = '0.00';
      this.nextPaymentDue = '0.00';
      this.billTo = ''
    }
    this.hasBalanceDue = !(this.nextPaymentDue === '0.00');
  }

  changeTerm() {
    this.termType == 'Current'
      ? (this.termType = 'Future')
      : (this.termType = 'Current');
    this.termChange = true;
    this.getPaymentSummary();
  }

  validateAmount() {
    var paymentAmountType = this.paymentForm.get('selectedAmount').value;

    if (paymentAmountType == 'amountDue') {
      this.paymentForm.patchValue({
        amount: this.nextPaymentDue,
      });
      this.paymentForm.get('customAmount').setErrors(null);
    } else if (paymentAmountType == 'remainingBalance') {
      this.paymentForm.patchValue({
        amount: this.remainingBalance,
      });
      this.paymentForm.get('customAmount').setErrors(null);
    } else if (paymentAmountType == 'customAmount') {
      if (
        this.paymentForm.get('customAmount').value == null ||
        parseFloat(this.paymentForm.get('customAmount').value) <
        parseFloat(this.nextPaymentDue)
      ) {
        this.paymentForm
          .get('customAmount')
          .setErrors({ noMinimumAmount: true });
        this.paymentForm.get('amount').setErrors({ required: false });
      } else {
        this.paymentForm.get('customAmount').setErrors(null);
        this.paymentForm.get('amount').setErrors({ required: true });
        this.paymentForm.patchValue({
          amount: parseFloat(
            this.paymentForm.get('customAmount').value
          ).toFixed(2),
        });
      }
    }
  }

  getSupportedPaymentDetails() {
    const data = {
      referenceId: this.policyNumber[0] + '0'.repeat(this.policyNumber.length - 1),
      accountCode: this.tenant
    };
    this.http.privatePost(`supportedPayments?accountCode=${this.tenant}`, data, true).subscribe(
      (resp) => {
        this.paymentMethodsList = resp.supportedPaymentMethods;
        this.allowedCardTypes = resp.supportedCardTypes;
        let paymentPlanControlWhenBillToIsMortgagee =
          resp.paymentPlanControlWhenBillToIsMortgagee;
        if (paymentPlanControlWhenBillToIsMortgagee != null) {
          this.NextAmountDueControl =
            paymentPlanControlWhenBillToIsMortgagee.NextAmountDue;
          this.RemainingBalanceContol =
            paymentPlanControlWhenBillToIsMortgagee.RemainingBalance;
          this.CustomAmountControl =
            paymentPlanControlWhenBillToIsMortgagee.CustomAmount;
        }
      },
      (error) => {
        const errorText = `Payment method or card types aren't available.`;
        this.utilsService.confirmModalPopup(errorText);
      }
    );
  }
  getPolicyIndex(policyNo) {
    this.isSubmitted = false;
    this.termChange = false;
    this.termType = 'Current';
    // this.policyIndex = index;
    this.policyNumber = policyNo;
    this.getPolicyDetails(policyNo);
    this.getBillingDetails();
    this.getInstallmentDetails();
  }

  getPaymentAuthorization() {
    this.dashboardService
      .getCardInfo('payment_authorization')
      .subscribe((resp) => {
        if (resp) {
          this.paymentAuthorization = resp;
        }
      });
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.isLargeWindow = window.innerWidth >= 900;
    this.isSmallWindow = window.innerWidth < 540;
  }
}
