import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { map } from 'rxjs/operators';
import { CommonApiService } from '../../../shared/services/common-api.service';
import { NotifytoastService } from '../../../shared/services/notificationToast.service';
import { UtilsService } from '../../../shared/services/utils.service';


@Component({
  selector: 'app-show-uploaded-files-modal',
  templateUrl: './show-uploaded-files-modal.component.html',
  styleUrls: ['./show-uploaded-files-modal.component.scss'],
  providers: [ CommonApiService ]
})
export class ShowUploadedFilesModalComponent implements OnInit {
  // title = 'Show Documents'
  // list = ['Show 1', 'Show 2']
  // closeBtnName = 'Close'
  title?: string
  fileList = ['Show 1', 'Show 2']
  closeBtnName?: string
  currentBrowser?: string
  public styling =this.utilsService.getItemfromSession('themeObject')

  constructor( 
    private httpService: CommonApiService,
    public bsModalRef: BsModalRef,
    private utilsService: UtilsService,
    private route: ActivatedRoute,
    private notifyToast: NotifytoastService
  ) { }

  ngOnInit(): void {
  }

  showFileInNewTab(item){
    const tenant = this.utilsService.getTenantFromWindow()
    console.log(item, tenant, this.route)
    return this.httpService
      .privateGet(`downloadDocument?documentId=${item}&accountCode=${tenant}`, null, true)
      .pipe( map((data) => { return data.out; }) ).subscribe(resp => {
        console.log(resp)
        this.previewFile(resp)
      })
  }

  private previewFile(data) {
  
    // if (this.currentBrowser === 'safari') {
    //   const blob = new Blob([this.base64ToArrayBuffer(data.contents)], { type: data.contentType });
    //   const url= window.URL.createObjectURL(blob);
    //   window.location.href = url;
    //   window.URL = window.URL || window.webkitURL;
    // } else {
    //   const blob = new Blob([this.base64ToArrayBuffer(data.contents)], { type: data.contentType });
    //   const url= window.URL.createObjectURL(blob);
    //   const printWindow = window.open(url);
    // }
    if(data.contentType) {
      const blob = new Blob([this.base64ToArrayBuffer(data.contents)], { type: data.contentType });
      const url= window.URL.createObjectURL(blob);
      if(this.currentBrowser == 'safari'){
        window.location.href = url;
        window.URL = window.URL || window.webkitURL;
      } else {
        window.open(url, "_blank");
      }
      setTimeout(() => {
        window.URL.revokeObjectURL(url)
      }, 1000);
    } else {
      this.notifyToast.error("Unable to retrieve the file. Please contact System Admin.")
    }
  }

  private base64ToArrayBuffer(base64: string) {
    var binary_string = window.atob(base64);
    var len = binary_string.length;
    var bytes = new Uint8Array(len);
    for (var i = 0; i < len; i++) {
        bytes[i] = binary_string.charCodeAt(i);
    }
    return bytes.buffer;
  }

}
