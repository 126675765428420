import { Injectable } from "@angular/core";
import { Observable } from 'rxjs';

@Injectable()
export class LocateMeService {
    coOrds: any

    getMyLocation(): Observable<Position> {
        return Observable.create((resp) => {
            if(navigator.geolocation){
                navigator.geolocation.watchPosition((position: Position) => {
                    console.log(position)
                    if(position) resp.next(position)
                }), (err) => {
                    console.log('Unable to get position', err)
                }, { enableHighAccuracy: true }
            } else {
                console.log('Disabled')
            }
            }
        )
    }
}