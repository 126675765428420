import { Component, OnInit, Input } from '@angular/core';
import { UtilsService } from '../../../shared/services/utils.service';
import { Router, ActivatedRoute } from '@angular/router';
import { CarouselConfig } from 'ngx-bootstrap/carousel';

@Component({
  selector: 'app-claim-card',
  templateUrl: './claim-card.component.html',
  styleUrls: ['./claim-card.component.scss']
})
export class ClaimCardComponent implements OnInit {
  @Input() claimDetails: any;
  @Input() policyNumber: any;
  claimDetailsObj: any;

  styling =this.utilsService.getItemfromSession('themeObject')
  constructor(
    private utilsService: UtilsService,
    private router: Router,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
  }

  navToLink(page){
    const tenant = this.route.parent.snapshot.params.tenant
    this.router.navigate([`${tenant}/${page}`])
  }

  ngOnChanges() {
    this.claimDetailsObj = this.claimDetails.find(resp => resp.policyNumber == this.policyNumber)
  }

}
