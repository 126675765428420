import { Component, OnInit, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-select-roof-form',
  templateUrl: './select-roof-form.component.html',
  styleUrls: ['./select-roof-form.component.scss']
})
export class SelectRoofFormComponent implements OnInit {
  @Input() roofForm: FormGroup;
  constructor() { }

  ngOnInit(): void {
  }

}
