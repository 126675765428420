import { Injectable } from '@angular/core';
import { Theme, greenTheme, yellowTheme, greyTheme, redTheme, defaultTheme, darkTheme, darkBlueTheme} from './theme'

@Injectable()
export class ThemeChangerService {
    availableThemes: Theme[]
    constructor(){ 
        this.availableThemes = [greenTheme, yellowTheme, greyTheme, redTheme, darkTheme, defaultTheme, darkBlueTheme]

    }

    getAvailableThemes(): Theme[]{
        return this.availableThemes;
    }

    setDefaultTheme(){
        Object.keys(defaultTheme.properties).forEach(property => {
            document.documentElement.style.setProperty(property,defaultTheme.properties[property])
        })
    }

    setTheme(theme: Theme){
        Object.keys(theme.properties).forEach(property => {
            document.documentElement.style.setProperty(property,theme.properties[property])
        })
    }
    
}