<div class="card custom-card-border h-100" [ngStyle]="styling?.card?.bodyStyling">
    <div class="card-body">
        <div style="display: flex; justify-content: space-between;">
            <h4 class="card-title header-font custom-title header-four-l" [ngStyle]="styling?.cardTitle">
                Upload Policy Documents
            </h4>
        </div>
        <div class="row p-t-20">
            <div class="offset-2 col-8 p-b-20">
                <label for="file-upload-back-right" 
                    class="file-upload-btn-custom"
                    style="cursor: pointer;"><span class="fas" [ngClass]="loader ? 'fa-spinner fa-spin': 'fa-upload'"></span> Upload Front Right Picture </label>
                <input type="file" [disabled]="loader" id="file-upload-back-right"  style="display: none;"
                    (click)="fileInputCCPFront.value = null" #fileInputCCPFront (change)="fileChange($event, 'Property Front Right')"  />
            </div>
            <div *ngIf="frontRightCollection.length" class="col-2">
                <span class="fas fa-check-circle success-icon-table" 
                ></span>
            </div> 
            <div class="offset-2 col-8 p-b-20">
                <label for="file-upload-back-left" 
                    class="file-upload-btn-custom"
                    style="cursor: pointer;"><span class="fas" [ngClass]="loader ? 'fa-spinner fa-spin': 'fa-upload'"></span> Upload Back Left Picture </label>
                <input type="file" [disabled]="loader" id="file-upload-back-left"  style="display: none;"
                    (click)="fileInputCCPBack.value = null" #fileInputCCPBack (change)="fileChange($event, 'Property Back Left')"  />
            </div>
            <div *ngIf="backLeftCollection.length" class="col-2">
                <span class="fas fa-check-circle success-icon-table" 
                ></span>
            </div> 
            <div class="offset-2 col-8 p-b-20">
                <label for="file-upload-back-dec" 
                    class="file-upload-btn-custom"
                    style="cursor: pointer;"><span class="fas" [ngClass]="loader ? 'fa-spinner fa-spin': 'fa-upload'"></span> Upload Current Declarations </label>
                <input type="file" [disabled]="loader" id="file-upload-back-dec"  style="display: none;"
                    (click)="fileInputCCPDec.value = null" #fileInputCCPDec (change)="fileChange($event, 'Declarations')"  />
            </div>
            <div *ngIf="declarationCollection.length" class="col-2">
                <span class="fas fa-check-circle success-icon-table" 
                ></span>
            </div> 
        </div>
    </div>
</div>