<div class="card find-agent-card-margin" style="font-family: Helvetica;">
    <div class="card-header blue-background" style=" border: 1px solid black;">Send Your Quote to {{agentFirstName}}
        {{agentLastName}}</div>
    <div class="card-body">

        <div class="col-12">
            <div class="row">
                <div class="col-md-12 text-center">
                    What is the best method for {{agentFirstName}} to get in touch with you?
                </div>
            </div>
            <div class="d-flex justify-content-center m-t-15">
                <div class="row col-md-4">
                    <div class="col-mob-6">

                        <button mat-stroked-button class="btn btn-block"
                            [class.button-blue]="emailQuoteAgentForm.get('preferredCommunicationType').value==='email'"
                            type="button" (click)="setCommunicationType('email')" style="align-content: center;"
                            [ngStyle]="styling?.button?.buttonNext">
                            Email
                        </button>
                    </div>
                    <div class="col-mob-6">
                        <button mat-stroked-button class="btn btn-block"
                            [class.button-blue]="emailQuoteAgentForm.get('preferredCommunicationType').value==='phone'"
                            type="button" (click)="setCommunicationType('phone')" style="align-content: center;"
                            [ngStyle]="styling?.button?.buttonNext">
                            Phone
                        </button>
                    </div>
                </div>
            </div>
            <div class="d-flex justify-content-center">
                <div class="col-md-4">
                    <form [formGroup]="emailQuoteAgentForm" class="w-100">
                        <div class="row m-t-15">
                            <div class="col-mob-2">
                                <mat-form-field style="width: 100%">
                                    <input matInput formControlName="countryCode" maxlength="3" />
                                    <span matPrefix>+&nbsp;</span>
                                </mat-form-field>
                                <div style="margin-top: -20px">
                                    <p class="error-class mat-angular-error-text"
                                        *ngIf="emailQuoteAgentForm.get('countryCode').errors?.required && isSubmitted">
                                        Country Code is required</p>
                                    <p class="error-class mat-angular-error-text"
                                        *ngIf="emailQuoteAgentForm.get('countryCode').errors?.pattern && isSubmitted">
                                        Numbers only </p>
                                </div>
                            </div>
                            <div class="col-mob-10">
                                <mat-form-field style="width: 100%"
                                    *ngIf="emailQuoteAgentForm.get('preferredCommunicationType').value==='phone'">
                                    <input matInput phoneDisplay [preValue]="emailQuoteAgentForm.value.ph_number" style="padding-right: 0px;" placeholder="Phone Number*"
                                        formControlName="ph_number" maxlength="14" />
                                        <!-- <input matInput phoneDisplay [preValue]="newClaimForm.value.ph_number" placeholder="Phone Number *" formControlName="ph_number" maxlength="14" /> -->
                                        
                                </mat-form-field>
                                <mat-form-field style="width: 100%"
                                    *ngIf="emailQuoteAgentForm.get('preferredCommunicationType').value==='email'">
                                    <input matInput phoneDisplay style="padding-right: 0px;" placeholder="Phone Number"
                                        formControlName="ph_number" maxlength="14" />
                                </mat-form-field>
                                <p class="error-class mat-angular-error-text" *ngIf="emailQuoteAgentForm.get('ph_number').errors?.required && isSubmitted">Phone Number is required</p>
                                            <p class="error-class mat-angular-error-text" *ngIf="emailQuoteAgentForm.get('ph_number').errors?.pattern && isSubmitted">Invalid Phone Number</p>
                                        
                                <!-- <p class="error-class mat-angular-error-text"
                                    *ngIf="emailQuoteAgentForm.get('ph_number').errors?.required && isSubmitted">
                                    {{'REQUIRED_FIELD' | translate: {value:'Phone number'} }}</p> -->
                            </div>
                        </div>
                        <div class="row m-t-15">
                            <mat-form-field style="width: 100%"
                                *ngIf="emailQuoteAgentForm.get('preferredCommunicationType').value==='email'">
                                <input matInput formControlName="email" style="padding-right: 0px;" (input)="keyEmail()"
                                    type="text" placeholder="Your Email Address*" />
                            </mat-form-field>
                            <mat-form-field style="width: 100%"
                                *ngIf="emailQuoteAgentForm.get('preferredCommunicationType').value==='phone'">
                                <input matInput formControlName="email" style="padding-right: 0px;" (input)="keyEmail()"
                                    type="text" placeholder="Your Email Address" />
                            </mat-form-field>
                            <p class="error-class mat-angular-error-text"
                                *ngIf="!emailQuoteAgentForm.get('email').valid">{{emailError}}</p>
                            <!-- <p class="error-class mat-angular-error-text" *ngIf="emailQuoteAgentForm.get('email').errors?.required && isSubmitted"> {{'REQUIRED_FIELD' | translate: {value:'Email'} }}</p> -->
                        </div>
                        <div class="row m-t-15">
                            <mat-form-field style="width: 100%;">
                                <div class="custom-title-info account-title" style="border: 2px #808080;">Your Message:
                                    <textarea matInput rows="4" formControlName="message"></textarea>
                                </div>
                            </mat-form-field>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>

</div>