import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { UtilsService } from '../../../shared/services/utils.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-show-premium',
  templateUrl: './show-premium.component.html',
  styleUrls: ['./show-premium.component.scss']
})
export class ShowPremiumComponent implements OnInit {
  styling = this.utilsService.getItemfromSession('themeObject');
  @Input() estimatedPremium: any;
  @Input() stepper: any;
  @Input() address:any;
  @Output() navigateToCoverageDetails = new EventEmitter();
  displayValue:any;
  constructor(
    private utilsService: UtilsService,
    private route: ActivatedRoute,
    private router: Router
  ) { }

  ngOnInit(): void {
  }

  onCoverageDetails() {
    console.log("click")
    console.log("stepper" + this.stepper)
    this.navigateToCoverageDetails.emit(this.stepper);
  }


  navigateToLink(page){
    const tenant = this.route.parent.snapshot.params.tenant
    // var address = {
    //   "city": "Kissimmee",
    //   "street": "3053 Ashland Ln N",
    //   "zipCode": "34741"
    // };
    var addressFormatted = {
      "city": this.address.city.short_name,
      "street":this.address.street_number.short_name +" "+ this.address.route.long_name,
      "zipCode": this.address.postal_code.long_name,
      "stateCode":this.address.state.short_name
    }
    console.log("address formatted", addressFormatted)
    // plan: set a variable when clicked and call that component with ngif condition
    this.router.navigate([`${tenant}/${page}`],{state: {data: addressFormatted}})
  }

  ngOnChanges(changes: any){
    if (changes.hasOwnProperty('estimatedPremium')) {
      if (changes.estimatedPremium.currentValue) {
        this.displayValue = this.utilsService.convertNumberToIS(this.estimatedPremium)
      }
    }
  }
}
