import { Component, OnInit, Input } from '@angular/core';
import { UtilsService } from '../../../shared/services/utils.service';
import { FormGroup, Validators } from '@angular/forms';
import { ValidationService } from '../../../shared/services/validation.service';

@Component({
  selector: 'app-send-quote-agent',
  templateUrl: './send-quote-agent.component.html',
  styleUrls: ['./send-quote-agent.component.scss']
})
export class SendQuoteAgentComponent implements OnInit {
  styling = this.utilsService.getItemfromSession('themeObject');
  emailError: any;
  @Input()  emailQuoteAgentForm: FormGroup;
  @Input() agentDetails:any;
  @Input() isSubmitted:boolean;
  agentFirstName:any;
  agentLastName: any;
  constructor(
    private utilsService: UtilsService
  ) { }

  ngOnInit(): void {
  }

  ngOnChanges(changes: any) {
    if (
      'agentDetails' in changes && 'currentValue' in changes.agentDetails && changes.agentDetails.currentValue
    ) {
      this.setAgentDetails();
      
    }
  }


  setAgentDetails(){
    this.agentFirstName = this.agentDetails.agentFirstName;
    this.agentLastName = this.agentDetails.agentLastName;
    
  }


  setPreferredContact(type){
    
    
    console.log(type);

  }

  setCommunicationType(type){
    this.emailQuoteAgentForm.patchValue({
      preferredCommunicationType: type  
    });
    this.emailQuoteAgentForm.updateValueAndValidity();
    if(type =="email"){
      this.emailQuoteAgentForm.get('email').setValidators([Validators.required, ValidationService.emailValidator])
      this.emailQuoteAgentForm.get('email').updateValueAndValidity();
      this.emailQuoteAgentForm.get('ph_number').clearValidators()
      this.emailQuoteAgentForm.get('ph_number').updateValueAndValidity();
      
    }
    else if(type =="phone"){
      this.emailQuoteAgentForm.get('ph_number').setValidators([Validators.required,Validators.pattern(/^\(\d{3}\)\s\d{3}-\d{4}$/), Validators.maxLength(14)])
      this.emailQuoteAgentForm.get('ph_number').updateValueAndValidity();
      this.emailQuoteAgentForm.get('email').clearValidators()
      this.emailQuoteAgentForm.get('email').updateValueAndValidity();
      
    }
    // this.emailQuoteAgentForm.updateValueAndValidity();
    console.log("communication type is set to",type)
  }

  keyEmail(){
    const temp = this.emailQuoteAgentForm.get('email')
    if(temp.errors){
      if (temp.errors.required) {
        this.emailError =  'Email is required'
      } else if (temp.errors.invalidEmailAddress) {
        this.emailError =  'Email is not valid.'
      }
    }
  }

}
