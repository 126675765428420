<ng-container>
    <h4 class="row col-lg-12 font-weight-bold p-b-10">General Configuration</h4>
    <div class="col-lg-12 p-t-20">
        <h5 style="font-weight: 600;">Page Title</h5>
        <div class="row p-b-10">
            <div class="col-lg-4">
                <small>Font Size</small>
                <ng-select id="availableLengths_1" [items]="availableLengths"
                    appendTo="body"
                    [hideSelected]="true"
                    placeholder="Set Length"
                    [clearable]="false"
                    [(ngModel)]="configuration.pageTitle['font-size']"
                    >
                </ng-select>
            </div>
            <div class="col-lg-4">
                <small>Font Family</small>
                <ng-select id="availableFontFamily_1" [items]="availableFontFamily"
                    appendTo="body"
                    [hideSelected]="true"
                    placeholder="Set Font Family"
                    [clearable]="false"
                    [(ngModel)]="configuration.pageTitle['font-family']"
                    >
                    <ng-template ng-label-tmp let-item="item"><span [ngStyle]="{'font-family': item}">{{item}}</span></ng-template>
                    <ng-template ng-option-tmp let-item="item"><span [ngStyle]="{'font-family': item}">{{item}}</span></ng-template>
                </ng-select>
            </div>
            <div class="col-lg-4">
                <small>Font Weight</small>
                <ng-select id="availableFontWeight_1" [items]="availableFontWeight"
                    appendTo="body"
                    [hideSelected]="true"
                    placeholder="Set Font Weight"
                    [clearable]="false"
                    [(ngModel)]="configuration.pageTitle['font-weight']"
                    >
                </ng-select>
            </div>
        </div>
    </div>

    <div class="col-lg-12 p-t-20">
        <h5 style="font-weight: 600;">Page Sub Title</h5>
        <div class="row p-b-10">
            <div class="col-lg-4">
                <small>Font Size</small>
                <ng-select id="availableLengths_23" [items]="availableLengths"
                    appendTo="body"
                    [hideSelected]="true"
                    placeholder="Set Length"
                    [clearable]="false"
                    [(ngModel)]="configuration.pageSubTitle['font-size']"
                    >
                </ng-select>
            </div>
            <div class="col-lg-4">
                <small>Font Family</small>
                <ng-select id="availableFontFamily_23" [items]="availableFontFamily"
                    appendTo="body"
                    [hideSelected]="true"
                    placeholder="Set Font Family"
                    [clearable]="false"
                    [(ngModel)]="configuration.pageSubTitle['font-family']"
                    >
                    <ng-template ng-label-tmp let-item="item"><span [ngStyle]="{'font-family': item}">{{item}}</span></ng-template>
                    <ng-template ng-option-tmp let-item="item"><span [ngStyle]="{'font-family': item}">{{item}}</span></ng-template>
                </ng-select>
            </div>
            <div class="col-lg-4">
                <small>Font Weight</small>
                <ng-select id="availableFontWeight_23" [items]="availableFontWeight"
                    appendTo="body"
                    [hideSelected]="true"
                    placeholder="Set Font Weight"
                    [clearable]="false"
                    [(ngModel)]="configuration.pageSubTitle['font-weight']"
                    >
                </ng-select>
            </div>
        </div>
    </div>


    <div class="col-lg-12 p-t-20">
        <h5 style="font-weight: 600;">Sidebar Text</h5>
        <div class="row p-b-10">
            <div class="col-lg-4">
                <small>Font Size</small>
                <ng-select id="availableLengths_2" [items]="availableLengths"
                    appendTo="body"
                    [hideSelected]="true"
                    placeholder="Set Length"
                    [clearable]="false"
                    [(ngModel)]="configuration.sidebarText['font-size']"
                    >
                </ng-select>
            </div>
            <div class="col-lg-4">
                <small>Font Family</small>
                <ng-select id="availableFontFamily_2" [items]="availableFontFamily"
                    appendTo="body"
                    [hideSelected]="true"
                    placeholder="Set Font Family"
                    [clearable]="false"
                    [(ngModel)]="configuration.sidebarText['font-family']"
                    >
                    <ng-template ng-label-tmp let-item="item"><span [ngStyle]="{'font-family': item}">{{item}}</span></ng-template>
                    <ng-template ng-option-tmp let-item="item"><span [ngStyle]="{'font-family': item}">{{item}}</span></ng-template>
                </ng-select>
            </div>
            <div class="col-lg-4">
                <small>Font Weight</small>
                <ng-select id="availableFontWeight_2" [items]="availableFontWeight"
                    appendTo="body"
                    [hideSelected]="true"
                    placeholder="Set Font Weight"
                    [clearable]="false"
                    [(ngModel)]="configuration.sidebarText['font-weight']"
                    >
                </ng-select>
            </div>
        </div>
    </div>


    <div class="col-lg-12 p-t-20">
        <h5 style="font-weight: 600;">Card Title</h5>
        <div class="row p-b-10">
            <div class="col-lg-4">
                <small>Font Size</small>
                <ng-select id="availableLengths_3" [items]="availableLengths"
                    appendTo="body"
                    [hideSelected]="true"
                    placeholder="Set Length"
                    [clearable]="false"
                    [(ngModel)]="configuration.cardTitle['font-size']"
                    >
                </ng-select>
            </div>
            <div class="col-lg-4">
                <small>Font Family</small>
                <ng-select id="availableFontFamily_3" [items]="availableFontFamily"
                    appendTo="body"
                    [hideSelected]="true"
                    placeholder="Set Font Family"
                    [clearable]="false"
                    [(ngModel)]="configuration.cardTitle['font-family']"
                    >
                    <ng-template ng-label-tmp let-item="item"><span [ngStyle]="{'font-family': item}">{{item}}</span></ng-template>
                    <ng-template ng-option-tmp let-item="item"><span [ngStyle]="{'font-family': item}">{{item}}</span></ng-template>
                </ng-select>
            </div>
            <div class="col-lg-4">
                <small>Font Weight</small>
                <ng-select id="availableFontWeight_3" [items]="availableFontWeight"
                    appendTo="body"
                    [hideSelected]="true"
                    placeholder="Set Font Weight"
                    [clearable]="false"
                    [(ngModel)]="configuration.cardTitle['font-weight']"
                    >
                </ng-select>
            </div>
        </div>
    </div>


    <div class="col-lg-12 p-t-20">
        <h5 style="font-weight: 600;">Help Title</h5>
        <div class="row p-b-10">
            <div class="col-lg-4">
                <small>Font Size</small>
                <ng-select id="availableLengths_4" [items]="availableLengths"
                    appendTo="body"
                    [hideSelected]="true"
                    placeholder="Set Length"
                    [clearable]="false"
                    [(ngModel)]="configuration.helpTitle['font-size']"
                    >
                </ng-select>
            </div>
            <div class="col-lg-4">
                <small>Font Family</small>
                <ng-select id="availableFontFamily_4" [items]="availableFontFamily"
                    appendTo="body"
                    [hideSelected]="true"
                    placeholder="Set Font Family"
                    [clearable]="false"
                    [(ngModel)]="configuration.helpTitle['font-family']"
                    >
                    <ng-template ng-label-tmp let-item="item"><span [ngStyle]="{'font-family': item}">{{item}}</span></ng-template>
                    <ng-template ng-option-tmp let-item="item"><span [ngStyle]="{'font-family': item}">{{item}}</span></ng-template>
                </ng-select>
            </div>
            <div class="col-lg-4">
                <small>Font Weight</small>
                <ng-select id="availableFontWeight_4" [items]="availableFontWeight"
                    appendTo="body"
                    [hideSelected]="true"
                    placeholder="Set Font Weight"
                    [clearable]="false"
                    [(ngModel)]="configuration.helpTitle['font-weight']"
                    >
                </ng-select>
            </div>
        </div>
    </div>


    <div class="col-lg-12 p-t-20">
        <h5 style="font-weight: 600;">Help Text</h5>
        <div class="row p-b-10">
            <div class="col-lg-4">
                <small>Font Size</small>
                <ng-select id="availableLengths_5" [items]="availableLengths"
                    appendTo="body"
                    [hideSelected]="true"
                    placeholder="Set Length"
                    [clearable]="false"
                    [(ngModel)]="configuration.helpText['font-size']"
                    >
                </ng-select>
            </div>
            <div class="col-lg-4">
                <small>Font Family</small>
                <ng-select id="availableFontFamily_5" [items]="availableFontFamily"
                    appendTo="body"
                    [hideSelected]="true"
                    placeholder="Set Font Family"
                    [clearable]="false"
                    [(ngModel)]="configuration.helpText['font-family']"
                    >
                    <ng-template ng-label-tmp let-item="item"><span [ngStyle]="{'font-family': item}">{{item}}</span></ng-template>
                    <ng-template ng-option-tmp let-item="item"><span [ngStyle]="{'font-family': item}">{{item}}</span></ng-template>
                </ng-select>
            </div>
            <div class="col-lg-4">
                <small>Font Weight</small>
                <ng-select id="availableFontWeight_5" [items]="availableFontWeight"
                    appendTo="body"
                    [hideSelected]="true"
                    placeholder="Set Font Weight"
                    [clearable]="false"
                    [(ngModel)]="configuration.helpText['font-weight']"
                    >
                </ng-select>
            </div>
        </div>
    </div>

    <div class="col-lg-12 p-t-20">
        <h5 style="font-weight: 600;">Hyperlink</h5>
        <div class="row p-b-10">
            <div class="col-lg-3">
                <small>Text Color</small>
                <ng-select id="availableColors_6" [items]="availableColors"
                    appendTo="body"
                    [hideSelected]="true"
                    placeholder="Set Color"
                    [clearable]="false"
                    [(ngModel)]="configuration.hyperlink['color']"
                    >
                </ng-select>
            </div>
            <div class="col-lg-3">
                <small>Font Size</small>
                <ng-select id="availableLengths_6" [items]="availableLengths"
                    appendTo="body"
                    [hideSelected]="true"
                    placeholder="Set Length"
                    [clearable]="false"
                    [(ngModel)]="configuration.hyperlink['font-size']"
                    >
                </ng-select>
            </div>
            <div class="col-lg-4">
                <small>Font Family</small>
                <ng-select id="availableFontFamily_6" [items]="availableFontFamily"
                    appendTo="body"
                    [hideSelected]="true"
                    placeholder="Set Font Family"
                    [clearable]="false"
                    [(ngModel)]="configuration.hyperlink['font-family']"
                    >
                    <ng-template ng-label-tmp let-item="item"><span [ngStyle]="{'font-family': item}">{{item}}</span></ng-template>
                    <ng-template ng-option-tmp let-item="item"><span [ngStyle]="{'font-family': item}">{{item}}</span></ng-template>
                </ng-select>
            </div>
        </div>
    </div>

    <div class="col-lg-12 p-t-20">
        <h5 style="font-weight: 600;">Field Name/ Attribute Key</h5>
        <div class="row p-b-10">
            <div class="col-lg-4">
                <small>Font Size</small>
                <ng-select id="availableLengths_7" [items]="availableLengths"
                    appendTo="body"
                    [hideSelected]="true"
                    placeholder="Set Length"
                    [clearable]="false"
                    [(ngModel)]="configuration.attributeKey['font-size']"
                    >
                </ng-select>
            </div>
            <div class="col-lg-4">
                <small>Font Family</small>
                <ng-select id="availableFontFamily_7" [items]="availableFontFamily"
                    appendTo="body"
                    [hideSelected]="true"
                    placeholder="Set Font Family"
                    [clearable]="false"
                    [(ngModel)]="configuration.attributeKey['font-family']"
                    >
                    <ng-template ng-label-tmp let-item="item"><span [ngStyle]="{'font-family': item}">{{item}}</span></ng-template>
                    <ng-template ng-option-tmp let-item="item"><span [ngStyle]="{'font-family': item}">{{item}}</span></ng-template>
                </ng-select>
            </div>
            <div class="col-lg-4">
                <small>Font Weight</small>
                <ng-select id="availableFontWeight_7" [items]="availableFontWeight"
                    appendTo="body"
                    [hideSelected]="true"
                    placeholder="Set Font Weight"
                    [clearable]="false"
                    [(ngModel)]="configuration.attributeKey['font-weight']"
                    >
                </ng-select>
            </div>
        </div>
    </div>

    <div class="col-lg-12 p-t-20">
        <h5 style="font-weight: 600;">Field PlaceHolder</h5>
        <div class="row p-b-10">
            <div class="col-lg-4">
                <small>Font Size</small>
                <ng-select id="availableLengths_8" [items]="availableLengths"
                    appendTo="body"
                    [hideSelected]="true"
                    placeholder="Set Length"
                    [clearable]="false"
                    [(ngModel)]="configuration.fieldPlaceHolder['font-size']"
                    >
                </ng-select>
            </div>
            <div class="col-lg-4">
                <small>Font Family</small>
                <ng-select id="availableFontFamily_8" [items]="availableFontFamily"
                    appendTo="body"
                    [hideSelected]="true"
                    placeholder="Set Font Family"
                    [clearable]="false"
                    [(ngModel)]="configuration.fieldPlaceHolder['font-family']"
                    >
                    <ng-template ng-label-tmp let-item="item"><span [ngStyle]="{'font-family': item}">{{item}}</span></ng-template>
                    <ng-template ng-option-tmp let-item="item"><span [ngStyle]="{'font-family': item}">{{item}}</span></ng-template>
                </ng-select>
            </div>
            <div class="col-lg-4">
                <small>Font Weight</small>
                <ng-select id="availableFontWeight_8" [items]="availableFontWeight"
                    appendTo="body"
                    [hideSelected]="true"
                    placeholder="Set Font Weight"
                    [clearable]="false"
                    [(ngModel)]="configuration.fieldPlaceHolder['font-weight']"
                    >
                </ng-select>
            </div>
        </div>
    </div>


    <div class="col-lg-12 p-t-20">
        <h5 style="font-weight: 600;">Field value Description/ Attribute Value</h5>
        <div class="row p-b-10">
            <div class="col-lg-4">
                <small>Font Size</small>
                <ng-select id="availableLengths_9" [items]="availableLengths"
                    appendTo="body"
                    [hideSelected]="true"
                    placeholder="Set Length"
                    [clearable]="false"
                    [(ngModel)]="configuration.attributeValue['font-size']"
                    >
                </ng-select>
            </div>
            <div class="col-lg-4">
                <small>Font Family</small>
                <ng-select id="availableFontFamily_9" [items]="availableFontFamily"
                    appendTo="body"
                    [hideSelected]="true"
                    placeholder="Set Font Family"
                    [clearable]="false"
                    [(ngModel)]="configuration.attributeValue['font-family']"
                    >
                    <ng-template ng-label-tmp let-item="item"><span [ngStyle]="{'font-family': item}">{{item}}</span></ng-template>
                    <ng-template ng-option-tmp let-item="item"><span [ngStyle]="{'font-family': item}">{{item}}</span></ng-template>
                </ng-select>
            </div>
            <div class="col-lg-4">
                <small>Font Weight</small>
                <ng-select id="availableFontWeight_9" [items]="availableFontWeight"
                    appendTo="body"
                    [hideSelected]="true"
                    placeholder="Set Font Weight"
                    [clearable]="false"
                    [(ngModel)]="configuration.attributeValue['font-weight']"
                    >
                </ng-select>
            </div>
        </div>
    </div>


    <div class="col-lg-12 p-t-20">
        <h5 style="font-weight: 600;">Field Value Input</h5>
        <div class="row p-b-10">
            <div class="col-lg-4">
                <small>Font Size</small>
                <ng-select id="availableLengths_10" [items]="availableLengths"
                    appendTo="body"
                    [hideSelected]="true"
                    placeholder="Set Length"
                    [clearable]="false"
                    [(ngModel)]="configuration.fieldValueInput['font-size']"
                    >
                </ng-select>
            </div>
            <div class="col-lg-4">
                <small>Font Family</small>
                <ng-select id="availableFontFamily_10" [items]="availableFontFamily"
                    appendTo="body"
                    [hideSelected]="true"
                    placeholder="Set Font Family"
                    [clearable]="false"
                    [(ngModel)]="configuration.fieldValueInput['font-family']"
                    >
                    <ng-template ng-label-tmp let-item="item"><span [ngStyle]="{'font-family': item}">{{item}}</span></ng-template>
                    <ng-template ng-option-tmp let-item="item"><span [ngStyle]="{'font-family': item}">{{item}}</span></ng-template>
                </ng-select>
            </div>
            <div class="col-lg-4">
                <small>Font Weight</small>
                <ng-select id="availableFontWeight_10" [items]="availableFontWeight"
                    appendTo="body"
                    [hideSelected]="true"
                    placeholder="Set Font Weight"
                    [clearable]="false"
                    [(ngModel)]="configuration.fieldValueInput['font-weight']"
                    >
                </ng-select>
            </div>
        </div>
    </div>


    <div class="col-lg-12 p-t-20">
        <h5 style="font-weight: 600;">Field Value CheckBox/ RadioBox</h5>
        <div class="row p-b-10">
            <div class="col-lg-4">
                <small>Font Size</small>
                <ng-select id="availableLengths_11" [items]="availableLengths"
                    appendTo="body"
                    [hideSelected]="true"
                    placeholder="Set Length"
                    [clearable]="false"
                    [(ngModel)]="configuration.fieldValueCheckBox['font-size']"
                    >
                </ng-select>
            </div>
            <div class="col-lg-4">
                <small>Font Family</small>
                <ng-select id="availableFontFamily_11" [items]="availableFontFamily"
                    appendTo="body"
                    [hideSelected]="true"
                    placeholder="Set Font Family"
                    [clearable]="false"
                    [(ngModel)]="configuration.fieldValueCheckBox['font-family']"
                    >
                    <ng-template ng-label-tmp let-item="item"><span [ngStyle]="{'font-family': item}">{{item}}</span></ng-template>
                    <ng-template ng-option-tmp let-item="item"><span [ngStyle]="{'font-family': item}">{{item}}</span></ng-template>
                </ng-select>
            </div>
            <div class="col-lg-4">
                <small>Font Weight</small>
                <ng-select id="availableFontWeight_11" [items]="availableFontWeight"
                    appendTo="body"
                    [hideSelected]="true"
                    placeholder="Set Font Weight"
                    [clearable]="false"
                    [(ngModel)]="configuration.fieldValueCheckBox['font-weight']"
                    >
                </ng-select>
            </div>
        </div>
    </div>


    <div class="col-lg-12 p-t-20">
        <h5 style="font-weight: 600;">Field Dropdown</h5>
        <div class="row p-b-10">
            <div class="col-lg-4">
                <small>Font Size</small>
                <ng-select id="availableLengths_12" [items]="availableLengths"
                    appendTo="body"
                    [hideSelected]="true"
                    placeholder="Set Length"
                    [clearable]="false"
                    [(ngModel)]="configuration.fieldDropDown['font-size']"
                    >
                </ng-select>
            </div>
            <div class="col-lg-4">
                <small>Font Family</small>
                <ng-select id="availableFontFamily_12" [items]="availableFontFamily"
                    appendTo="body"
                    [hideSelected]="true"
                    placeholder="Set Font Family"
                    [clearable]="false"
                    [(ngModel)]="configuration.fieldDropDown['font-family']"
                    >
                    <ng-template ng-label-tmp let-item="item"><span [ngStyle]="{'font-family': item}">{{item}}</span></ng-template>
                    <ng-template ng-option-tmp let-item="item"><span [ngStyle]="{'font-family': item}">{{item}}</span></ng-template>
                </ng-select>
            </div>
            <div class="col-lg-4">
                <small>Font Weight</small>
                <ng-select id="availableFontWeight_12" [items]="availableFontWeight"
                    appendTo="body"
                    [hideSelected]="true"
                    placeholder="Set Font Weight"
                    [clearable]="false"
                    [(ngModel)]="configuration.fieldDropDown['font-weight']"
                    >
                </ng-select>
            </div>
        </div>
    </div>

    <div class="col-lg-12 p-t-20">
        <h5 style="font-weight: 600;">Footer HyperLink</h5>
        <div class="row p-b-10">
            <div class="col-lg-4">
                <small>Font Size</small>
                <ng-select id="availableLengths_13" [items]="availableLengths"
                    appendTo="body"
                    [hideSelected]="true"
                    placeholder="Set Length"
                    [clearable]="false"
                    [(ngModel)]="configuration.footerHyperlink['font-size']"
                    >
                </ng-select>
            </div>
            <div class="col-lg-4">
                <small>Font Family</small>
                <ng-select id="availableFontFamily_13" [items]="availableFontFamily"
                    appendTo="body"
                    [hideSelected]="true"
                    placeholder="Set Font Family"
                    [clearable]="false"
                    [(ngModel)]="configuration.footerHyperlink['font-family']"
                    >
                    <ng-template ng-label-tmp let-item="item"><span [ngStyle]="{'font-family': item}">{{item}}</span></ng-template>
                    <ng-template ng-option-tmp let-item="item"><span [ngStyle]="{'font-family': item}">{{item}}</span></ng-template>
                </ng-select>
            </div>
            <div class="col-lg-4">
                <small>Font Weight</small>
                <ng-select id="availableFontWeight_13" [items]="availableFontWeight"
                    appendTo="body"
                    [hideSelected]="true"
                    placeholder="Set Font Weight"
                    [clearable]="false"
                    [(ngModel)]="configuration.footerHyperlink['font-weight']"
                    >
                </ng-select>
            </div>
        </div>
    </div>

    <div class="col-lg-12 p-t-20">
        <h5 style="font-weight: 600;">Field Text</h5>
        <div class="row p-b-10">
            <div class="col-lg-4">
                <small>Font Size</small>
                <ng-select id="availableLengths_14" [items]="availableLengths"
                    appendTo="body"
                    [hideSelected]="true"
                    placeholder="Set Length"
                    [clearable]="false"
                    [(ngModel)]="configuration.footerText['font-size']"
                    >
                </ng-select>
            </div>
            <div class="col-lg-4">
                <small>Font Family</small>
                <ng-select id="availableFontFamily_14" [items]="availableFontFamily"
                    appendTo="body"
                    [hideSelected]="true"
                    placeholder="Set Font Family"
                    [clearable]="false"
                    [(ngModel)]="configuration.footerText['font-family']"
                    >
                    <ng-template ng-label-tmp let-item="item"><span [ngStyle]="{'font-family': item}">{{item}}</span></ng-template>
                    <ng-template ng-option-tmp let-item="item"><span [ngStyle]="{'font-family': item}">{{item}}</span></ng-template>
                </ng-select>
            </div>
            <div class="col-lg-4">
                <small>Font Weight</small>
                <ng-select id="availableFontWeight_14" [items]="availableFontWeight"
                    appendTo="body"
                    [hideSelected]="true"
                    placeholder="Set Font Weight"
                    [clearable]="false"
                    [(ngModel)]="configuration.footerText['font-weight']"
                    >
                </ng-select>
            </div>
        </div>
    </div>

    <div class="col-lg-12 p-t-20">
        <h5 style="font-weight: 600;">Table Header</h5>
        <div class="row p-b-10">
            <div class="col-lg-4">
                <small>Font Size</small>
                <ng-select id="availableLengths_15" [items]="availableLengths"
                    appendTo="body"
                    [hideSelected]="true"
                    placeholder="Set Length"
                    [clearable]="false"
                    [(ngModel)]="configuration.tableHeader['font-size']"
                    >
                </ng-select>
            </div>
            <div class="col-lg-4">
                <small>Font Family</small>
                <ng-select id="availableFontFamily_15" [items]="availableFontFamily"
                    appendTo="body"
                    [hideSelected]="true"
                    placeholder="Set Font Family"
                    [clearable]="false"
                    [(ngModel)]="configuration.tableHeader['font-family']"
                    >
                    <ng-template ng-label-tmp let-item="item"><span [ngStyle]="{'font-family': item}">{{item}}</span></ng-template>
                    <ng-template ng-option-tmp let-item="item"><span [ngStyle]="{'font-family': item}">{{item}}</span></ng-template>
                </ng-select>
            </div>
            <div class="col-lg-4">
                <small>Font Weight</small>
                <ng-select id="availableFontWeight_15" [items]="availableFontWeight"
                    appendTo="body"
                    [hideSelected]="true"
                    placeholder="Set Font Weight"
                    [clearable]="false"
                    [(ngModel)]="configuration.tableHeader['font-weight']"
                    >
                </ng-select>
            </div>
        </div>
    </div>

    <div class="col-lg-12 p-t-20">
        <h5 style="font-weight: 600;">Table Value</h5>
        <div class="row p-b-10">
            <div class="col-lg-4">
                <small>Font Size</small>
                <ng-select id="availableLengths_16" [items]="availableLengths"
                    appendTo="body"
                    [hideSelected]="true"
                    placeholder="Set Length"
                    [clearable]="false"
                    [(ngModel)]="configuration.tableValue['font-size']"
                    >
                </ng-select>
            </div>
            <div class="col-lg-4">
                <small>Font Family</small>
                <ng-select id="availableFontFamily_16" [items]="availableFontFamily"
                    appendTo="body"
                    [hideSelected]="true"
                    placeholder="Set Font Family"
                    [clearable]="false"
                    [(ngModel)]="configuration.tableValue['font-family']"
                    >
                    <ng-template ng-label-tmp let-item="item"><span [ngStyle]="{'font-family': item}">{{item}}</span></ng-template>
                    <ng-template ng-option-tmp let-item="item"><span [ngStyle]="{'font-family': item}">{{item}}</span></ng-template>
                </ng-select>
            </div>
            <div class="col-lg-4">
                <small>Font Weight</small>
                <ng-select id="availableFontWeight_16" [items]="availableFontWeight"
                    appendTo="body"
                    [hideSelected]="true"
                    placeholder="Set Font Weight"
                    [clearable]="false"
                    [(ngModel)]="configuration.tableValue['font-weight']"
                    >
                </ng-select>
            </div>
        </div>
    </div>


    <div class="col-lg-12 p-t-20">
        <h5 style="font-weight: 600;">Button</h5>
        <h6 style="font-weight: 600;">Positive Response Button</h6>
        <div class="row p-b-10">
            <div class="col-lg-3">
                <small>Background Color</small>
                <ng-select id="availableColors_17" [items]="availableColors"
                    appendTo="body"
                    [hideSelected]="true"
                    placeholder="Set Color"
                    [clearable]="false"
                    [(ngModel)]="configuration.button.buttonPositive['background-color']"
                    >
                </ng-select>
            </div>
            <div class="col-lg-3">
                <small>Font Size</small>
                <ng-select id="availableLengths_17" [items]="availableLengths"
                    appendTo="body"
                    [hideSelected]="true"
                    placeholder="Set Length"
                    [clearable]="false"
                    [(ngModel)]="configuration.button.buttonPositive['font-size']"
                    >
                </ng-select>
            </div>
            <div class="col-lg-3">
                <small>Font Family</small>
                <ng-select id="availableFontFamily_17" [items]="availableFontFamily"
                    appendTo="body"
                    [hideSelected]="true"
                    placeholder="Set Font Family"
                    [clearable]="false"
                    [(ngModel)]="configuration.button.buttonPositive['font-family']"
                    >
                    <ng-template ng-label-tmp let-item="item"><span [ngStyle]="{'font-family': item}">{{item}}</span></ng-template>
                    <ng-template ng-option-tmp let-item="item"><span [ngStyle]="{'font-family': item}">{{item}}</span></ng-template>
                </ng-select>
            </div>
            <div class="col-lg-3">
                <small>Font Weight</small>
                <ng-select id="availableFontWeight_17" [items]="availableFontWeight"
                    appendTo="body"
                    [hideSelected]="true"
                    placeholder="Set Font Weight"
                    [clearable]="false"
                    [(ngModel)]="configuration.button.buttonPositive['font-weight']"
                    >
                </ng-select>
            </div>
        </div>
    </div>
    
    
    <div class="col-lg-12 p-t-20">
        <h6 style="font-weight: 600;">Button Negative response</h6>
        <div class="row p-b-10">
            <div class="col-lg-3">
                <small>Background Color</small>
                <ng-select id="availableColors_18" [items]="availableColors"
                    appendTo="body"
                    [hideSelected]="true"
                    placeholder="Set Color"
                    [clearable]="false"
                    [(ngModel)]="configuration.button.buttonNegative['background-color']"
                    >
                </ng-select>
            </div>
            <div class="col-lg-3">
                <small>Font Size</small>
                <ng-select id="availableLengths_18" [items]="availableLengths"
                    appendTo="body"
                    [hideSelected]="true"
                    placeholder="Set Length"
                    [clearable]="false"
                    [(ngModel)]="configuration.button.buttonNegative['font-size']"
                    >
                </ng-select>
            </div>
            <div class="col-lg-3">
                <small>Font Family</small>
                <ng-select id="availableFontFamily_18" [items]="availableFontFamily"
                    appendTo="body"
                    [hideSelected]="true"
                    placeholder="Set Font Family"
                    [clearable]="false"
                    [(ngModel)]="configuration.button.buttonNegative['font-family']"
                    >
                    <ng-template ng-label-tmp let-item="item"><span [ngStyle]="{'font-family': item}">{{item}}</span></ng-template>
                    <ng-template ng-option-tmp let-item="item"><span [ngStyle]="{'font-family': item}">{{item}}</span></ng-template>
                </ng-select>
            </div>
            <div class="col-lg-3">
                <small>Font Weight</small>
                <ng-select id="availableFontWeight_18" [items]="availableFontWeight"
                    appendTo="body"
                    [hideSelected]="true"
                    placeholder="Set Font Weight"
                    [clearable]="false"
                    [(ngModel)]="configuration.button.buttonNegative['font-weight']"
                    >
                </ng-select>
            </div>
        </div>
    </div>
    
    
    <div class="col-lg-12 p-t-20">
        <h6 style="font-weight: 600;">Button Navigate Pages</h6>
        <div class="row p-b-10">
            <div class="col-lg-3">
                <small>Background Color</small>
                <ng-select id="availableColors_19" [items]="availableColors"
                    appendTo="body"
                    [hideSelected]="true"
                    placeholder="Set Color"
                    [clearable]="false"
                    [(ngModel)]="configuration.button.buttonNavigation['background-color']"
                    >
                </ng-select>
            </div>
            <div class="col-lg-3">
                <small>Font Size</small>
                <ng-select id="availableLengths_19" [items]="availableLengths"
                    appendTo="body"
                    [hideSelected]="true"
                    placeholder="Set Length"
                    [clearable]="false"
                    [(ngModel)]="configuration.button.buttonNavigation['font-size']"
                    >
                </ng-select>
            </div>
            <div class="col-lg-3">
                <small>Font Family</small>
                <ng-select id="availableFontFamily_19" [items]="availableFontFamily"
                    appendTo="body"
                    [hideSelected]="true"
                    placeholder="Set Font Family"
                    [clearable]="false"
                    [(ngModel)]="configuration.button.buttonNavigation['font-family']"
                    >
                    <ng-template ng-label-tmp let-item="item"><span [ngStyle]="{'font-family': item}">{{item}}</span></ng-template>
                    <ng-template ng-option-tmp let-item="item"><span [ngStyle]="{'font-family': item}">{{item}}</span></ng-template>
                </ng-select>
            </div>
            <div class="col-lg-3">
                <small>Font Weight</small>
                <ng-select id="availableFontWeight_19" [items]="availableFontWeight"
                    appendTo="body"
                    [hideSelected]="true"
                    placeholder="Set Font Weight"
                    [clearable]="false"
                    [(ngModel)]="configuration.button.buttonNavigation['font-weight']"
                    >
                </ng-select>
            </div>
        </div>
    </div>


    <div class="col-lg-12 p-t-20">
        <h5 style="font-weight: 600;">Paragraph Title</h5>
        <div class="row p-b-10">
            <div class="col-lg-4">
                <small>Font Size</small>
                <ng-select id="availableLengths_20" [items]="availableLengths"
                    appendTo="body"
                    [hideSelected]="true"
                    placeholder="Set Length"
                    [clearable]="false"
                    [(ngModel)]="configuration.paragraphTitle['font-size']"
                    >
                </ng-select>
            </div>
            <div class="col-lg-4">
                <small>Font Family</small>
                <ng-select id="availableFontFamily_20" [items]="availableFontFamily"
                    appendTo="body"
                    [hideSelected]="true"
                    placeholder="Set Font Family"
                    [clearable]="false"
                    [(ngModel)]="configuration.paragraphTitle['font-family']"
                    >
                    <ng-template ng-label-tmp let-item="item"><span [ngStyle]="{'font-family': item}">{{item}}</span></ng-template>
                    <ng-template ng-option-tmp let-item="item"><span [ngStyle]="{'font-family': item}">{{item}}</span></ng-template>
                </ng-select>
            </div>
            <div class="col-lg-4">
                <small>Font Weight</small>
                <ng-select id="availableFontWeight_20" [items]="availableFontWeight"
                    appendTo="body"
                    [hideSelected]="true"
                    placeholder="Set Font Weight"
                    [clearable]="false"
                    [(ngModel)]="configuration.paragraphTitle['font-weight']"
                    >
                </ng-select>
            </div>
        </div>
    </div>
    
    
    <div class="col-lg-12 p-t-20">
        <h5 style="font-weight: 600;">Paragraph Text</h5>
        <div class="row p-b-10">
            <div class="col-lg-4">
                <small>Font Size</small>
                <ng-select id="availableLengths_21" [items]="availableLengths"
                    appendTo="body"
                    [hideSelected]="true"
                    placeholder="Set Length"
                    [clearable]="false"
                    [(ngModel)]="configuration.paragraphText['font-size']"
                    >
                </ng-select>
            </div>
            <div class="col-lg-4">
                <small>Font Family</small>
                <ng-select id="availableFontFamily_21" [items]="availableFontFamily"
                    appendTo="body"
                    [hideSelected]="true"
                    placeholder="Set Font Family"
                    [clearable]="false"
                    [(ngModel)]="configuration.paragraphText['font-family']"
                    >
                    <ng-template ng-label-tmp let-item="item"><span [ngStyle]="{'font-family': item}">{{item}}</span></ng-template>
                    <ng-template ng-option-tmp let-item="item"><span [ngStyle]="{'font-family': item}">{{item}}</span></ng-template>
                </ng-select>
            </div>
            <div class="col-lg-4">
                <small>Font Weight</small>
                <ng-select id="availableFontWeight_21" [items]="availableFontWeight"
                    appendTo="body"
                    [hideSelected]="true"
                    placeholder="Set Font Weight"
                    [clearable]="false"
                    [(ngModel)]="configuration.paragraphText['font-weight']"
                    >
                </ng-select>
            </div>
        </div>
    </div>
    
    
    <div class="col-lg-12 p-t-20">
        <h5 style="font-weight: 600;">Icon Styling</h5>
        <div class="row p-b-10">
            <div class="col-lg-4">
                <small>Font Size</small>
                <ng-select id="availableColors_22" [items]="availableColors"
                    appendTo="body"
                    [hideSelected]="true"
                    placeholder="Set Length"
                    [clearable]="false"
                    [(ngModel)]="configuration.iconStyling['color']"
                    >
                </ng-select>
            </div>
        </div>
    </div>

    <div class="col-lg-12 p-t-20">
        <h5 style="font-weight: 600;">Card Body Styling</h5>
        <div class="row p-b-10">
            <div class="col-lg-4">
                <small>Border Color</small>
                <ng-select id="availableColors_4" [items]="availableColors"
                    appendTo="body"
                    [hideSelected]="true"
                    placeholder="Set Length"
                    [clearable]="false"
                    [(ngModel)]="cardColor"
                    (change)="changesColor()"
                    >
                </ng-select>
            </div>
            <div class="col-lg-4">
                <small>Border Thickness</small>
                <ng-select id="availableThickness_4" [items]="availableThickness"
                    appendTo="body"
                    [hideSelected]="true"
                    placeholder="Set Font Family"
                    [clearable]="false"
                    [(ngModel)]="cardThickness"
                    (change)="changesColor()"
                    >
                </ng-select>
            </div>
            <div class="col-lg-4">
                <small>Border Radius</small>
                <ng-select id="availableRadius_4" [items]="availableRadius"
                    appendTo="body"
                    [hideSelected]="true"
                    placeholder="Set Font Family"
                    [clearable]="false"
                    [(ngModel)]="cardRadius"
                    >
                </ng-select>
            </div>
        </div>
    </div>


</ng-container>