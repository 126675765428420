<div class="card custom-card-border h-100" [ngStyle]="styling?.card?.bodyStyling">
    <div class="card-body">
        <div style="display: flex; justify-content: space-between;">
            <h4 class="card-title header-font custom-title header-four-l" [ngStyle]="styling?.cardTitle">
                Upload Policy Documents
            </h4>
        </div>
        <div class="row p-t-20">
            <div class="col-md-12">
                <div class="form-control-static custom-description p-b-5">
                    <p class="account-title" [ngStyle]="styling?.attributeValue">
                        Did you forget to upload policy documents. Head over to the <span class="custom-link" (click)="navToLink('home/upload-policy')">upload policy documents</span> page to upload.
                    </p>
                </div>
            </div>
        </div>
    </div>
</div>