import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { MatStepper } from '@angular/material/stepper';
import { CommonApiService } from '../../shared/services/common-api.service';
import { NotifytoastService } from '../../shared/services/notificationToast.service';
import { UtilsService } from '../../shared/services/utils.service';
import { TranslateService } from '@ngx-translate/core';
import { ValidationService } from '../../shared/services/validation.service';
import { CountdownComponent } from 'ngx-countdown';
import { environment } from '../../../../default/environments/environment';

const CryptoJS = require("crypto-js");
@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {
  @ViewChild('stepper') stepper: MatStepper
  @ViewChild('cd', { static: false }) private countdown: CountdownComponent;
  forgotPasswordForm: any;
  isSubmitted = false;
  pageName: any;
  otpForm: any;
  passwordForm: any;
  showPassword = true;
  passwordError = '';
  // mfaServiceSid: any;
  mfaDeliverEmail: any;
  characterPassword = '~ ` ! @ # $ % ^ & * ( ) \/ < > " \' { } [ ] . _ + = ?';
  styling = this.utilsService.getItemfromSession('themeObject')
  titleHeader = ['Forgot Password?', 'Your code is on the way!', 'Forgot Password?', 'Password Reset Successfully']
  buttonTxt = ['Request Code', 'Next', 'Submit']
  countDownBegin = true;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private http: CommonApiService,
    private notifyToast: NotifytoastService,
    private translate: TranslateService,
    private utilsService: UtilsService
  ) { }

  ngOnInit(): void {
    this.pageName = this.route.parent.snapshot.params.tenant
    this.initForm()
  }

  initForm() {
    this.forgotPasswordForm = this.formBuilder.group({
      username: ['', Validators.required],
      communication: ['email']
    })
    this.otpForm = this.formBuilder.group({
      otp: ['', Validators.required]
    })
    this.passwordForm = this.formBuilder.group({
      NewPassword: ['', [Validators.required, Validators.minLength(8), Validators.maxLength(20),
      // Validators.pattern('^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[~`!@#$%^&*()<>\"\'\/\._+=?{}\\[\\]])[A-Za-z\\d~`!@#$%^&*()<>\"\'\/\._+=?{}\\[\\]]+$'),
      ValidationService.passwordTenantValidator
      ]],
      confirmPassword: ['', Validators.required]
    })
  }

  navLogin() {
    this.navLocation('login')
  }

  navLocation(page) {
    this.router.navigate([`${this.pageName}/${page}`])
  }

  goForward(stepper: MatStepper) {
    this.isSubmitted = true
    if (stepper.selectedIndex === 0 && this.forgotPasswordForm.valid) {
      this.submitOtp()
      // stepper.next()
      this.isSubmitted = false
    } else if (stepper.selectedIndex === 1 && this.otpForm.valid) {
      this.verifyOtp()
      // stepper.next()
      this.isSubmitted = false
    } else if ((stepper.selectedIndex === 2 && this.passwordForm.valid)) {
      this.isSubmitted = false
      this.finalizePassword()
    }
  }

  reSubmitOtp(isLinkClicked) {
    if (!this.countDownBegin) {
      this.submitOtp(isLinkClicked);
    } else {
      this.utilsService.confirmModalPopupWithConfig("Your code is on the way!", "We’ve sent an email to " + this.mfaDeliverEmail + " with your code. It may take a few minutes to reach your inbox. If you don’t see it, please check your junk/spam folders or request a new one after 2 minutes.", "Ok")
    }
  }

  handleEvent(event) {
    if (event.action === 'notify') {
      this.countDownBegin = false;
    }
  }

  submitOtp(isLinkClicked?) {
    const data = {
      "method": this.forgotPasswordForm.value.communication,
      "accountCode": this.pageName,
      "customerID": this.forgotPasswordForm.value.username
    }
    const key = {
      payload: atob(environment.securityCode.send_customer_portal_2fa_Code.inputKey),
      response: atob(environment.securityCode.send_customer_portal_2fa_Code.outputKey)
    }
    const ciphertext = CryptoJS.AES.encrypt(JSON.stringify(data), key.payload).toString();
    const encryptedData = {
      data: ciphertext
    }
    this.http.post(`send_customer_portal_2fa_Code`, encryptedData, true, true).subscribe(resp => {
      resp = JSON.parse(CryptoJS.AES.decrypt(resp?.out, key.response).toString(CryptoJS.enc.Utf8));
      if (resp?.statusCode == 200 && resp?.messageKey === 'cp_2fa_code_sent') {
        this.mfaDeliverEmail = resp.to;
        if (!isLinkClicked) {
          this.stepper.next()
        } else {
          this.notifyToast.success("OTP has been sent to your mail.")
        }
        this.countDownBegin = true;
        this.countdown.restart();
      } else {
        this.translate.get(resp.messageKey).subscribe(text => {
          this.utilsService.confirmModalPopup(text)
        })
      }
    }, error => {
      this.notifyToast.error("Please Contact System Administrator.")
    })
  }

  verifyOtp() {
    const data = {
      "method": "email",
      "accountCode": this.pageName,
      "codeReceived": this.otpForm.value.otp,
      "email": this.mfaDeliverEmail
    }
    const key = {
      payload: atob(environment.securityCode.verify_2fa_Code.inputKey),
      response: atob(environment.securityCode.verify_2fa_Code.outputKey)
    }

    const ciphertext = CryptoJS.AES.encrypt(JSON.stringify(data), key.payload).toString();
    const inputData = {
      data: ciphertext
    }

    this.http.post(`verify2faCode`, inputData, true, true).subscribe(resp => {
      resp = JSON.parse(CryptoJS.AES.decrypt(resp?.out, key.response).toString(CryptoJS.enc.Utf8));
      if (resp?.statusCode == 200 && resp?.status === "approved" && resp?.messageKey === "cp_2fa_code_verified") {
        this.stepper.next()
      } else {
        this.translate.get(resp?.messageKey).subscribe(text => {
          this.utilsService.confirmModalPopup(text)
        })
      }
    })
  }

  finalizePassword() {
    const data = {
      "UserName": this.forgotPasswordForm.value.username,
      "NewPassword": btoa(this.passwordForm.value.NewPassword),
      "accountCode": this.pageName
    }
    const key = {
      payload: atob(environment.securityCode.forgot_password.inputKey),
      response: atob(environment.securityCode.forgot_password.outputKey)
    }
    const ciphertext = CryptoJS.AES.encrypt(JSON.stringify(data), key.payload).toString();
    const encryptedData = {
      data: ciphertext
    }
    this.http.post(`forgotPassword`, encryptedData, true, true).subscribe(resp => {
      resp = (JSON.parse(CryptoJS.AES.decrypt(resp?.out, key.response).toString(CryptoJS.enc.Utf8)));
      if (resp && resp?.statusCode == 200 && resp?.messageKey === "password_updated_sucessfully" && resp?.status === "approved") {
        this.notifyToast.success('Password successfully changed.')
        this.stepper.next()
        // this.navLogin()
      } else {
        this.notifyToast.error('Please Contact System Administrator.')
      }
    })
  }

  viewPassword() {
    this.showPassword = !this.showPassword;
  }

  inputChange(field) {
    this.displayError()
    if (this.passwordForm.get('NewPassword').value !== this.passwordForm.get('confirmPassword').value) {
      this.passwordForm.get('confirmPassword').setErrors({ MatchPassword: true });
    } else {
      this.passwordForm.get('confirmPassword').setErrors({ 'MatchPassword': null });
      this.passwordForm.get('confirmPassword').updateValueAndValidity()
    }
  }

  displayError() {
    const temp = this.passwordForm.get('NewPassword');
    if (temp.errors) {
      if (temp.errors.required) {
        this.passwordError = 'Account Code is required'
      } else if (temp.errors.invalidPassword) {
        this.passwordError = 'Password does not match the password requirements.'
      } else if (temp.errors.minlength) {
        this.passwordError = 'Account Code should be at least 8 characters'
      } else if (temp.errors.maxlength) {
        this.passwordError = 'Account Code should be no more than 20 characters'
      }
    }
  }

}
