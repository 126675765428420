import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { UtilsService } from '../../../shared/services/utils.service';
import { DataTransferService } from '../../../shared/services/dataTransfer.service';
import { FormBuilder } from '@angular/forms';
import * as _ from 'lodash'
import { PrivateDataShare } from '../../../shared/services/privateDataShare.service';

@Component({
  selector: 'app-policies-card',
  templateUrl: './policies-card.component.html',
  styleUrls: ['./policies-card.component.scss']
})
export class PoliciesCardComponent implements OnInit {
  @Output() policyIndexChanges = new EventEmitter()
  policyIndex = 0
  @Input() notclickable:any
  @Input() policiesDetails: any
  policyObj = []
  listAddress = []
  policiesForm: any;
  styling =this.utilsService.getItemfromSession('themeObject')
  isPolicyDetailsPage = false
  currentStatus: any;
  constructor(
      private router: Router,
      private formBuilder: FormBuilder,
      private route: ActivatedRoute,
      private  utilsService: UtilsService,
      private dataTransferService: DataTransferService,
      private privateDataShare: PrivateDataShare
      ) { }

  ngOnInit(): void {
    this.initForm();
    this.getAllPolicies();
    this.getCurrentPage()
    
   }

   getCurrentPage(){
    let currentLocn = this.route.snapshot.url[0].path
    if(currentLocn == 'view-policies') this.isPolicyDetailsPage = true
   }

   initForm() {
    this.policiesForm = this.formBuilder.group({
      selectedPolicyNo: [],
      });
  }

  navigateToClaim(){
    const tenant = this.route.parent.snapshot.params.tenant
    this.router.navigate([`${tenant}/home/view-policies`])
  }

  getAllPolicies(){
    // let selectedPolicyNo = this.privateDataShare.getPolicyNumber();
    let policyDetails = this.privateDataShare.getPolicyDetails()
    if(policyDetails){
      let selectedPolicyNo = this.getPolicyNo(policyDetails.PolicySummary)
      this.policyObj = policyDetails.PolicySummary;
      let listOfAddresses = this.policyObj.map(
        (resp) =>({
          address:
          // `${resp.policyDetails.riskAddress.addressLine1},  ${resp.policyDetails.riskAddress.city}, ${resp.policyDetails.riskAddress.stateCode} ${resp.policyDetails.riskAddress.zipCode} - ${resp.policyDetails.policyNumber}`,
          `${resp.policyDetails.riskAddress.addressLine1},  ${resp.policyDetails.riskAddress.city}, ${resp.policyDetails.riskAddress.stateCode} ${resp.policyDetails.riskAddress.zipCode}`,
          policyNo: resp.policyDetails.policyNumber
        })
      );
      this.listAddress = this.modifyDuplicates(listOfAddresses)
      this.policiesForm.patchValue({
        selectedPolicyNo: selectedPolicyNo,
      });
      this.selectCurrrentPolicy({policyNo: selectedPolicyNo})
      } else{
      return null
    }
  }

  getPolicyNo(policySummary){
    let selectedPolicyNo = this.privateDataShare.getPolicyNumber();
    if(!policySummary.length) return selectedPolicyNo
    else{
      let hasPolicyNo = policySummary.some(resp => resp.policyDetails.policyNumber == selectedPolicyNo)
      if(hasPolicyNo){
        return selectedPolicyNo
      } else{
        return policySummary[0].policyDetails.policyNumber
      }
    }
  }

  modifyDuplicates(listOfAddresses){
    let temp = []
    let onlyAddress = listOfAddresses.map(res => res.address)
    temp = onlyAddress.filter((element, index, array) => array.indexOf(element) != index)
    let duplicateAddress = [...new Set(temp)]
    let finalizedAddress = []
    listOfAddresses.forEach(element => {
      if(duplicateAddress.includes(element.address)) {
        let number = finalizedAddress.filter(curElement => curElement ? curElement.address.match(element.address) : curElement.match(element.address)).length + 1
        finalizedAddress.push({address: `${number} - ${element.address}`, policyNo: element.policyNo})
      }
      else finalizedAddress.push({address: element.address, policyNo: element.policyNo})
    });
    return finalizedAddress
  }

  selectCurrrentPolicy(curPolicyObj?){
    let curPolicyNo = curPolicyObj.policyNo
    console.log(curPolicyNo,'gege')
    this.currentStatus = this.policyObj.find(resp => resp.policyDetails.policyNumber == curPolicyNo).policyDetails.status
    if(curPolicyNo){
      this.privateDataShare.setPolicyNumber(curPolicyNo)
      this.policyIndexChanges.emit(curPolicyNo)
      this.dataTransferService.sendData(true)
      return true
    }
    
    
    // let address = eve.split(', ')

    // for (let index in this.policyObj) {
    //   if(this.policyObj[index].policyDetails.riskAddress.addressLine1 === address[0] && this.policyObj[index].policyDetails.riskAddress.city === address[1] 
    //     && this.policyObj[index].policyDetails.riskAddress.stateCode +  ' ' + this.policyObj[index].policyDetails.riskAddress.zipCode === address[2]){
    //       this.policyIndex = Number(index);
    //       sessionStorage.setItem('CurPolicySelection', index)
    //       this.policyIndexChanges.emit(index)
    //       this.dataTransferService.sendData(true)
    //       return true
    //   }
    // }
  }

  // agetAllPolicies(){
  //   let selectedPolicy = this.utilsService.selectedPolicy();
  //   if(sessionStorage.getItem('policyDetails') ){
  //     const currentPolicy = this.utilsService.selectedPolicy()
  //     this.policyIndex = currentPolicy ? parseInt(currentPolicy): this.policyIndex
  //     this.policyObj = JSON.parse(sessionStorage.getItem('policyDetails')).PolicySummary;
  //     let listOfAddresses = this.policyObj.map(
  //       (resp) =>({
  //         address:
  //         // `${resp.policyDetails.riskAddress.addressLine1},  ${resp.policyDetails.riskAddress.city}, ${resp.policyDetails.riskAddress.stateCode} ${resp.policyDetails.riskAddress.zipCode} - ${resp.policyDetails.policyNumber}`,
  //         `${resp.policyDetails.riskAddress.addressLine1},  ${resp.policyDetails.riskAddress.city}, ${resp.policyDetails.riskAddress.stateCode} ${resp.policyDetails.riskAddress.zipCode}`,
  //         policyNo: resp.policyDetails.policyNumber
  //       })
  //     );
  //     // this.listAddress = listOfAddresses
  //     this.listAddress = this.modifyDuplicates(listOfAddresses)
  //     // this.policiesForm.patchValue({
  //     //   selectedAddress: this.listAddress[selectedPolicy],
  //     // });
  //     this.selectCurrrentPolicy(this.listAddress[selectedPolicy])
  //     } else{
  //     return null
  //   }
  // }
  
}
