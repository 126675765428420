import { BrowserModule, Title } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { ToastrModule } from 'ngx-toastr';
import { StoreModule } from '@ngrx/store';
import { AgmCoreModule } from "@agm/core";

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { PrivateModule } from './private/private.module';
import { PublicModule } from './public/public.module';
import { SharedModule } from './shared/shared.module';
import { NotifytoastService } from './shared/services/notificationToast.service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AuthGuard } from './shared/services/auth.service';
import { ThemeChangerService } from './shared/services/themeChanger.service';
import { NgxPaginationModule } from 'ngx-pagination';
import { sidebarToggleReducer } from './shared/sidebar/sidebar_reducer';
import { AuthGuardMaintenanceLogout } from './shared/services/auth-guard-logout.service';
import { ExternalWebchatScriptComponent } from './external-webchat-script/external-webchat-script.component';

@NgModule({
  declarations: [
    AppComponent,
    ExternalWebchatScriptComponent
  ],
  imports: [
    BrowserModule,
    ToastrModule.forRoot({
      timeOut: 3000,
      preventDuplicates: true,
    }),
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyBXaK96lS5Q6vs4IQPx4fZgpLLmfK_F44k'
    }),
    
    PrivateModule,
    PublicModule,
    SharedModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    NgxPaginationModule,
    StoreModule.forRoot({ sidebarState: sidebarToggleReducer }),
  ],
  providers:[
    NotifytoastService,
    AuthGuard,
    ThemeChangerService,
    Title,
    AuthGuardMaintenanceLogout
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
