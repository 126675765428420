import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Resolve} from "@angular/router";
import { throwError as observableThrowError, Observable } from "rxjs";

import { catchError, map } from 'rxjs/operators'
import { CommonApiService } from "../../../shared/services/common-api.service";
import { UtilsService } from "../../../shared/services/utils.service";

@Injectable()
export class MakeTcPaymentResolver implements Resolve<any>{

    constructor(
        private http: CommonApiService,
        private utilsService: UtilsService
      ) { }

    resolve(route: ActivatedRouteSnapshot): Observable<any>{
        const tenant = this.utilsService.getTenantFromWindow()       
        return this.http.privateGet( `accountConfiguration?accountCode=${tenant}`, null, true).pipe(
            map(res => { return res }),
            catchError(error => this.handleError(error))
        )
    }

    handleError(error){
        return observableThrowError(error)
    }
}