
<div class="row " style="margin-top: 3%; display: flex; justify-content: center; align-items: center;padding-bottom: 40px;"
    [ngStyle]="styling?.card?.attributeValue">
    <mat-card class="col-lg-3 p-b-30 card-premium-selection">
        <mat-card-title class="text-center" style="font-size: 35px;">
            $ {{displayValue}}
        </mat-card-title>
        <mat-card-subtitle class="text-center">
            Estimated annual premium
        </mat-card-subtitle>
        <div class = "text-center">
            <button class="btn btn-info "
            (click)="onCoverageDetails()"
            [ngStyle]="styling?.button?.buttonMajorAction">Edit Coverages</button>
        </div>  
    </mat-card>
</div>



      
