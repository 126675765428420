import { Component, OnInit, Input } from '@angular/core';
import { CommonApiService } from '../../../shared/services/common-api.service';
import { DasboardService } from '../dasboard.service';
import { UtilsService } from '../../../shared/services/utils.service';


@Component({
  selector: 'app-agentinfo-card',
  templateUrl: './agentinfo-card.component.html',
  styleUrls: ['./agentinfo-card.component.scss'],
  providers: [CommonApiService, DasboardService]
})
export class AgentinfoCardComponent implements OnInit {
  @Input() agencyObject: any
  styling =this.utilsService.getItemfromSession('themeObject')
  constructor(private utilsService: UtilsService) { }

  ngOnInit(): void { }

}
