<div class="row" [formGroup]="roofForm">
    <div class="col-lg-4 p-b-20">
        <div class="w-100 d-flex justify-content-center">
            <mat-card class="card-selection text-center radio-selector-wrapper">
                <label class="p-2" for="gable">
                    <p class="radio-selector-title">Gable</p>
                    <input type="radio" value="gable" formControlName="roofType" id="gable" class="radio-col-light-blue" />
                    <img class="radio-image-selector p-2" src="assets/multiTenantImages/png/quote_roof_gable.svg">
                </label>
            </mat-card>
        </div>
    </div>
    <div class="col-lg-4 p-b-20">
        <div class="w-100 d-flex justify-content-center">
            <mat-card class="card-selection text-center radio-selector-wrapper">
                <label class="p-2" for="hip">
                    <p class="radio-selector-title">Hip</p>
                    <input type="radio" value="hip" formControlName="roofType" id="hip" class="radio-col-light-blue" />
                    <img class="radio-image-selector p-2" src="assets/multiTenantImages/png/quote_roof_hip.svg">
                </label>
            </mat-card>
        </div>
    </div>
    <div class="col-lg-4 p-b-20">
        <div class="w-100 d-flex justify-content-center">
            <mat-card class="card-selection text-center radio-selector-wrapper">
                <label class="p-2" for="flat">
                    <p class="radio-selector-title">Flat</p>
                    <input type="radio" value="flat" formControlName="roofType" id="flat" class="radio-col-light-blue" />
                    <img class="radio-image-selector p-2" src="assets/multiTenantImages/png/quote_roof_flat.svg">
                </label>
            </mat-card>
        </div>
    </div>
</div>