import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, Validators, FormArray } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonApiService } from '../../shared/services/common-api.service';
import { NotifytoastService } from '../../shared/services/notificationToast.service';
import { ValidationService } from '../../shared/services/validation.service';
import { UtilsService } from '../../shared/services/utils.service';
import { MatStepper } from '@angular/material/stepper';
import { TranslateService } from '@ngx-translate/core';
import { CountdownComponent } from 'ngx-countdown';
import { getFirstDayOfMonth } from 'ngx-bootstrap/chronos';
import { environment } from '../../../../default/environments/environment';
import { SearchCountryField, CountryISO, PhoneNumberFormat } from 'ngx-intl-tel-input';

const CryptoJS = require("crypto-js");
@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.css']
})
export class SignUpComponent implements OnInit {
  @ViewChild('cd', { static: false }) private countdown: CountdownComponent;
  separateDialCode = true;
  phoneNumberRequired = false;
  SearchCountryField = SearchCountryField;
  CountryISO = CountryISO;
  PhoneNumberFormat = PhoneNumberFormat;
  supportedCountryCodesPhoneNumber: any;
  signUpForm: any;
  policyNumberHint: any;
  showPassword = true;
  isSubmitted = false;
  passwordError = '';
  emailError = '';
  phoneNumberError = '';
  otpForm: any;
  tenant = this.route.parent.snapshot.params.tenant;
  characterPassword = '~ ` ! @ # $ % ^ & * ( ) \/ < > " \' { } [ ] . _ + = ?';
  usernameError = 'Username is required'
  styling = this.utilsService.getItemfromSession('themeObject')
  // serviceSid = "";
  policyNumberError = 'Policy Number is required';
  zipCodeError = 'Zip Code is required';
  pageName: any;
  countDownBegin = true;
  methodValue: any;
  methodMessage: any;

  constructor(
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private http: CommonApiService,
    private notifyToast: NotifytoastService,
    private utilsService: UtilsService,
    private translate: TranslateService
  ) { }

  ngOnInit(): void {
    this.pageName = this.route.parent.snapshot.params.tenant;
    this.initForm()
    this.keyEmail()
    this.keyPhoneNumber()
    this.displayError()
    this.getConfiguration();
    this.getHints()
  }

  getHints() {
    // account_creation_policy_number_hint
    var infoType = 'account_creation_policy_number_hint';
    const tenant = this.route.parent.snapshot.params.tenant;
    this.http
      .get(
        `getCardInfo?accountCode=${tenant}&infoType=${infoType}`,
        null,
        true
      )
      .subscribe(
        (resp) => {
          if (resp) {
            this.policyNumberHint = resp.out
          }
        },
        (error) => {
          console.log(error);
        }
      );
  }

  initForm() {
    this.signUpForm = this.fb.group({
      desiredUserID: ['', [Validators.required, Validators.minLength(5), Validators.maxLength(25), Validators.pattern('^(?=.*[a-zA-Z])[A-Za-z\\d\.~_-]+$')]],
      policyNumber: ['', Validators.required],
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      zipCodeInsuredProperty: ['', Validators.required],
      password: ['', [Validators.required, Validators.minLength(8), Validators.maxLength(20),
      ValidationService.passwordTenantValidator]],
      // Validators.pattern('^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[~`!@#$%^&*()<>\"\'\/\._+=?{}\\[\\]])[A-Za-z\\d~`!@#$%^&*()<>\"\'\/\._+=?{}\\[\\]]+$')]],
      confirmPassword: ['', [Validators.required]],
      email: ['', [Validators.required, ValidationService.emailValidator]],
      phoneNumber: [null],
      chooseMethod: ['email']
    })
    this.otpForm = this.fb.group({
      otp: ['', Validators.required]
    })
  }

  getConfiguration() {
    this.http.get(`accountConfiguration?accountCode=${this.pageName}`, null, true).subscribe(resp => {
      if (resp) {
        let data = { ...resp.page_configuration }
        if (data && data.formatValidations && data.formatValidations.policyNumberMaxLength) {
          this.signUpForm.get('policyNumber').setValidators([Validators.required, Validators.maxLength(data.formatValidations.policyNumberMaxLength)]);
        }
        if (data && data.formatValidations && data.formatValidations.policyNumberPattern) {
          this.signUpForm.get('policyNumber').setValidators([Validators.required, Validators.pattern(data.formatValidations.policyNumberPattern)]);
        }
        if (data && data.formatValidations && data.formatValidations.zipCodePattern) {
          this.signUpForm.get('zipCodeInsuredProperty').setValidators([Validators.required, Validators.pattern(data.formatValidations.zipCodePattern)]);
        }
        if (resp?.customer_portal?.supported_country_codes_for_phonenumber) {
          this.supportedCountryCodesPhoneNumber = resp.customer_portal.supported_country_codes_for_phonenumber;
        }
      } else {
        console.log("Error: Can not get configuration file.")
      }
    }, error => {
      console.log("Error: Can not get configuration file.")
    })
  }

  inputChange(field) {
    if (field === 'password' || field === 'confirmPassword') {
      this.displayError()
      if (this.signUpForm.get('password').value !== this.signUpForm.get('confirmPassword').value) {
        this.signUpForm.get('confirmPassword').setErrors({ MatchPassword: true });
      } else {
        this.signUpForm.get('confirmPassword').setErrors(null);
      }
    }
    if (field === 'policyNumber') {
      this.keyPolicyNumber();
    }
    if (field === 'zipCodeInsuredProperty') {
      this.keyZipCode();
    }
    if (field === 'sms' || field === 'call') {
      this.phoneNumberRequired = true;
      this.keyPhoneNumber()
    }
    if (field === 'email') {
      this.phoneNumberRequired = false;
      this.keyPhoneNumber()
    }
  }

  togglePassword() {
    this.showPassword = !this.showPassword;
  }

  navLocation(page) {
    this.router.navigate([`${this.tenant}/${page}`])
  }

  saveAcntInfo(stepper: MatStepper) {
    this.isSubmitted = true;
    if (stepper.selectedIndex < 2
      && this.signUpForm.get('firstName').valid
      && this.signUpForm.get('lastName').valid
      && this.signUpForm.get('email').valid
      && this.signUpForm.get('phoneNumber').valid) {
      if (stepper.selectedIndex === 0) {
        //Revert the new method call and message of 2FA
        this.methodValue = this.signUpForm.value.chooseMethod
        // this.methodValue = 'email'
        this.setMethodMessage();
        this.sendOtp(stepper, false)
      } else if (stepper.selectedIndex === 1 && this.otpForm.valid) {
        //"serviceSid": "VA959d8f687f65cb49a808bcedb7ff3b87",
        // sms call
        let data = {
          "method": this.methodValue,
          "codeReceived": this.otpForm.value.otp,
          "accountCode": this.tenant,
          "email": this.signUpForm.value.email,
          "phoneNumber": this.signUpForm.value.phoneNumber?.e164Number
        }
        const key = {
          payload: atob(environment.securityCode.verify_2fa_Code.inputKey),
          response: atob(environment.securityCode.verify_2fa_Code.outputKey)
        }

        const ciphertext = CryptoJS.AES.encrypt(JSON.stringify(data), key.payload).toString();
        const inputData = {
          data: ciphertext
        }
        this.http.post('verify2faCode', inputData, true, true).subscribe(resp => {
          resp = JSON.parse(CryptoJS.AES.decrypt(resp?.out, key.response).toString(CryptoJS.enc.Utf8));
          if (resp?.statusCode == 200 && resp?.status === "approved" && resp?.messageKey === "cp_2fa_code_verified") {
            this.isSubmitted = false;
            stepper.next();
          } else {
            if (resp && resp?.messageKey) {
              this.translate.get(resp.messageKey).subscribe(text => {
                this.utilsService.confirmModalPopup(text)
              })
            } else {
              this.utilsService.confirmModalPopup("Invalid Otp Entered");
            }

          }
        }, error => {
          this.utilsService.confirmModalPopup("Please contact System Administrator")
        })
      }
    }
    if (stepper.selectedIndex === 2 && this.signUpForm.valid) {
      this.signUpPost(stepper);
    }
  }

  sendOtp(stepper: MatStepper, resendLink) {
    let data = {
      method: this.methodValue,
      email: this.signUpForm.value.email,
      accountCode: this.tenant,
      phoneNumber: this.signUpForm.value.phoneNumber?.e164Number
    }
    const key = {
      payload: atob(environment.securityCode.send_customer_portal_create_account_2fa_Code.inputKey),
      response: atob(environment.securityCode.send_customer_portal_create_account_2fa_Code.outputKey)
    }
    const ciphertext = CryptoJS.AES.encrypt(JSON.stringify(data), key.payload).toString();
    const encryptedData = {
      data: ciphertext
    }

    this.http.post('sendcreateaccount2fa', encryptedData, true, true).subscribe(resp => {
      resp = JSON.parse(CryptoJS.AES.decrypt(resp?.out, key.response).toString(CryptoJS.enc.Utf8));
      // console.log("Create 2FA account and send the request: " + JSON.stringify(resp))
      if (resp) {
        if (resp === undefined || resp?.statusCode != 200 || resp?.messageKey != "cp_2fa_code_sent") {
          this.utilsService.confirmModalPopup("Can not send the verification code successfully.");
        }
        // else {
        //   this.serviceSid = resp.serviceSid;
        // }
        if (!resendLink) {
          this.isSubmitted = false;
          stepper.next()
        }
        this.countDownBegin = true;
        this.countdown.restart();

      } else {
        this.translate.get(resp?.messageKey).subscribe(text => {
          this.utilsService.confirmModalPopup(text)
        })
        // stepper.next()
      }
    })
  }

  reSendOtp(stepper: MatStepper, resendLink) {
    if (!this.countDownBegin) {
      this.sendOtp(stepper, resendLink);
    } else {
      if (this.methodValue === 'email') {
        this.utilsService.confirmModalPopupWithConfig("Your code is on the way!", "We’ve sent an email to " + this.signUpForm.value.email + " with your code. It may take a few minutes to reach your inbox. If you don’t see it, please check your junk/spam folders or request a new one after 2 minutes.", "Ok")
      } else {
        this.utilsService.confirmModalPopupWithConfig("Your code is on the way!", "We’ve sent a code to " + this.signUpForm.value.phoneNumber?.e164Number + ". It may take a few minutes to reach your phone.", "Ok")
      }
    }
  }

  setMethodMessage() {
    if (this.methodValue === 'email') {
      this.methodMessage = "We've sent you an email with your verification code. This email might take a few minutes to reach your inbox.";
    } else {
      this.methodMessage = "We've sent your verification code. It might take a few minutes to reach you.";
    }
  }
  handleEvent(event) {
    if (event.action === 'notify') {
      this.countDownBegin = false;
    }
  }

  navBack(stepper: MatStepper) {
    stepper.previous()
  }

  keyUsername() {
    const temp = this.signUpForm.get('desiredUserID')
    if (temp.errors) {
      if (temp.errors.required) {
        this.usernameError = 'Username is required'
      } else if (temp.errors.minlength) {
        this.usernameError = 'Username must be at least 5 characters long'
      } else if (temp.errors.pattern) {
        this.usernameError = 'Entered Username does not match Username criteria.'
      }
    }
  }

  keyPolicyNumber() {
    // To Do: Enhancement for the whole component, put the error message to the confuration file
    const temp = this.signUpForm.get('policyNumber');
    if (temp.errors) {
      if (temp.errors.required) {
        this.policyNumberError = 'Policy Number is required'
      } else if (temp.errors.maxlength) {
        this.policyNumberError = 'Policy number should be no more than 11 characters'
      } else if (temp.errors.pattern) {
        this.policyNumberError = 'Entered policy number does not match policy number criteria.'
      }
    }
  }

  keyZipCode() {
    // To Do: Enhancement for the whole component, put the error message to the confuration file
    const temp = this.signUpForm.get('zipCodeInsuredProperty');
    if (temp.errors) {
      if (temp.errors.required) {
        this.zipCodeError = 'Zip Code is required'
      } else if (temp.errors.pattern) {
        this.zipCodeError = 'Entered zip code does not match zip code criteria.'
      }
    }
  }

  signUpPost(stepper: MatStepper) {
    const data = { ...this.signUpForm.value }
    data.phoneNumber = (data.phoneNumber && data.phoneNumber.e164Number) ? data.phoneNumber.e164Number.slice(-10) : null;
    delete data.confirmPassword
    data.password = btoa(this.signUpForm.value.password)
    data.accountCode = this.tenant;
    console.log(data);
    this.http.post('createCustomerUser', data, true, true).subscribe(resp => {
      if (resp.out === undefined) {
        const errorText = `${resp.errorMessage}`
        this.utilsService.confirmModalPopup(errorText)
      } else {
        stepper.next()
        // this.notifyToast.success('Account Successfully Created.');
        // this.navLocation('login')
      }
    }, error => {
      const errorText = `We were unable to create your account. Please verify the information entered and try again.`
      this.utilsService.confirmModalPopup(errorText)
    })
  }

  displayError() {
    const temp = this.signUpForm.get('password');
    if (temp.errors) {
      if (temp.errors.required) {
        this.passwordError = 'Password is required'
      } else if (temp.errors.invalidPassword) {
        this.passwordError = 'Password does not match the password requirements.'
      } else if (temp.errors.minlength) {
        this.passwordError = 'Password should be at least 8 characters'
      } else if (temp.errors.maxlength) {
        this.passwordError = 'Password should be no more than 20 characters'
      }
    }
  }

  keyEmail() {
    const temp = this.signUpForm.get('email')
    if (temp.errors) {
      if (temp.errors.required) {
        this.emailError = 'Email is required'
      } else if (temp.errors.invalidEmailAddress) {
        this.emailError = 'Email is not valid.'
      }
    }
  }

  keyPhoneNumber() {
    const temp = this.signUpForm.get('phoneNumber');
    let number = (temp.value) ? temp.value?.nationalNumber : null;
    if (number) {
      this.signUpForm.get('phoneNumber').patchValue(temp.value?.nationalNumber);
    }

    if (temp.errors) {
      if (temp.errors.required) {
        this.phoneNumberError = 'Phone number is required'
      } else if (temp.errors.validatePhoneNumber) {
        this.phoneNumberError = 'The phone number is incorrect.'
      }
    }
  }

}
