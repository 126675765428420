<div class="card custom-card-border">
    <div class="card-body body-mobile">
        <mat-horizontal-stepper (selectionChange)="stepperSelected($event)" linear #stepper>
            {{stepper.selectedIndex}}
            <mat-step [stepControl]="quoteForm">
                <form [formGroup]="quoteForm" class="form-material m-t-20">
                    <ng-template matStepLabel>
                        <div class="custom-title-info account-title"
                            [ngClass]="{'selected-tab': stepper.selectedIndex === 0}">{{'GET_QUOTE_STEP1' | translate}}
                        </div>
                    </ng-template>
                    <div class="form-group row" style="margin: 0px;">
                        <mat-label class="custom-title-info account-title" style="padding-top: 5px">
                            What type of policy are you looking for?</mat-label>
                        <button mat-stroked-button class="waves-effect waves-dark" 
                        [class.button-blue]="quoteForm.get('policyType').value==='HO3'"
                        
                        
                        type="button" (click)="setPolicyType('HO3')" style="margin-left: 10px;">
                            Home
                        </button>
                        <button mat-stroked-button class="waves-effect waves-dark" type="button" 
                        [class.button-blue]="quoteForm.get('policyType').value==='HO6'"
                        (click)="setPolicyType('HO6')" style="margin-left: 10px;">
                            Condo
                        </button>
                        <button mat-stroked-button class="waves-effect waves-dark" type="button" 
                        [class.button-blue]="quoteForm.get('policyType').value==='HO4'"
                        (click)="setPolicyType('HO4')" style="margin-left: 10px;">
                            Renters
                        </button>
                        <p class="error-class" *ngIf="quoteForm.get('policyType').errors?.required && isSubmitted">Type of Policy is required</p>
                    </div>
                    <div class="form-group row" style="margin-top: 10px; margin-left:0px;">
                        <mat-label class="custom-title-info account-title" style="padding-top: 15px;">When should your
                            policy start? </mat-label>
                        <!-- <mat-form-field appearance="outline" style="border:0px; padding: 0px;"> -->
                        <input matInput type="text" placeholder="Start date" bsDatepicker
                            [bsConfig]="datePickerSettings" class="form-control custom col-lg-2"
                            formControlName="startDate" style="border:0px; padding-top: 15px; margin-left: 10px;">

                        <!-- </mat-form-field> -->
                        <p class="error-class" *ngIf="quoteForm.get('startDate').errors?.required && isSubmitted">Start
                            date is required</p>

                    </div>
                    <div class="form-group row" style="margin: 0px;">

                        <!-- <mat-label class="custom-title-info account-title" style="padding-top: 15px;">Enter your
                            address: </mat-label>

                        <input type="text" class="form-control custom col-lg-6" (change)="addressChange($event)"
                            formControlName="address" style="padding-top: 10px; margin-left: 10px;">
                        <p class="error-class" *ngIf="quoteForm.get('address').errors?.required && isSubmitted">Address
                            is required</p> -->
                        <div class="col-lg-6" style="padding-top:5px; margin-left:-15px;">
                            <app-google-autocomplete (setAddress)="getAddress($event)"
                                placeHolder="Address of the Property"></app-google-autocomplete>
                            <p class="error-class" *ngIf="quoteForm.get('address').errors?.required && isSubmitted">
                                Address is required</p>
                        </div>
                    </div>
                    <div class="card" style="margin-top: 30px;">

                        <div class="card-header" style="font-weight: bold;">
                            <i class="fas fa-home text-center"></i>

                            Residence Information
                        </div>
                        <div class="card-body">
                            <div class="form-group row" style="margin-bottom: 10px;">
                                <div class="col-md-4 offset-md-4">
                                    <mat-label class="custom-title-info account-title">Property Usage: </mat-label>
                                    <ng-select id="propertiesList" [items]="propertiesList" appendTo="body" [hideSelected]="true"
                                        bindLabel="name" placeholder="Property" formControlName="property">
                                    </ng-select>
                                    <p class="error-class" *ngIf="!quoteForm.get('property').valid && isSubmitted">
                                        Property is required</p>
                                </div>
                                <div class="col-lg-6">
                                    <mat-form-field appearance="outline" style="padding: 0px;">
                                        <mat-label class="custom-title-info account-title" style="padding: 0px;">Yearb
                                            Built: </mat-label>
                                        <input matInput formControlName="year" style="padding: 0px;">
                                    </mat-form-field>
                                    <p class="error-class" *ngIf="!quoteForm.get('year').valid && isSubmitted">Year
                                        Built is required</p>
                                </div>
                            </div>
                            <div class="form-group row" style="margin-bottom: 10px;">
                                <div class="col-lg-6">
                                    <mat-label class="custom-title-info account-title">Building Material: </mat-label>
                                    <ng-select id="buildingMaterials" [items]="buildingMaterials" appendTo="body" [hideSelected]="true"
                                        bindLabel="name" placeholder="Building Material"
                                        formControlName="buildingMaterial">
                                    </ng-select>
                                    <p class="error-class"
                                        *ngIf="!quoteForm.get('buildingMaterial').valid && isSubmitted">
                                        Building Material is required</p>
                                </div>
                                <div class="col-lg-6">
                                    <mat-form-field appearance="outline">
                                        <mat-label class="custom-title-info account-title">Square Feet: </mat-label>
                                        <input matInput class="custom col-lg-6" style="padding: 0px;"
                                            formControlName="squareFeet">
                                    </mat-form-field>

                                    <p class="error-class" *ngIf="!quoteForm.get('squareFeet').valid && isSubmitted">
                                        Square Feet is required</p>
                                </div>
                            </div>
                            <div class="form-group row" style="margin-bottom: 10px;">
                                <div class="col-lg-6">
                                    <mat-label class="custom-title-info account-title">Building Type: </mat-label>
                                    <ng-select id="buildingTypes" [items]="buildingTypes" appendTo="body" [hideSelected]="true"
                                        bindLabel="name" placeholder="Building Types" formControlName="buildingType">
                                    </ng-select>
                                    <p class="error-class" *ngIf="!quoteForm.get('buildingType').valid && isSubmitted">
                                        Building Type is required</p>
                                </div>
                                <div class="col-lg-6" style="padding-bottom: 22px;">
                                    <mat-label class="custom-title-info account-title">Stories: </mat-label>
                                    <ng-select id="stories" [items]="stories" appendTo="body" [hideSelected]="true" bindLabel="name"
                                        placeholder="Stories" formControlName="story">
                                    </ng-select>
                                    <p class="error-class" *ngIf="!quoteForm.get('story').valid && isSubmitted">
                                        Stories is required</p>
                                </div>
                            </div>
                            <div class="form-group row" style="margin-bottom: 10px;">
                                <div class="col-lg-6">
                                    <mat-label class="custom-title-info account-title">Roof Shape: </mat-label>
                                    <ng-select id="roofShapes" [items]="roofShapes" appendTo="body" [hideSelected]="true"
                                        bindLabel="name" placeholder="Roof Shape" formControlName="roofShape">
                                    </ng-select>
                                    <p class="error-class" *ngIf="!quoteForm.get('roofShape').valid && isSubmitted">
                                        Roof Shape is required</p>
                                </div>
                                <div class="col-lg-6">
                                    <mat-form-field appearance="outline">

                                        <mat-label class="custom-title-info account-title">Year Roof Built/<br>Replaced:
                                        </mat-label>
                                        <input matInput type="text" class="custom" style="padding: 0px;"
                                            formControlName="roofYear">
                                    </mat-form-field>
                                    <p class="error-class" *ngIf="!quoteForm.get('roofYear').valid && isSubmitted">
                                        Year Roof Built/Replaced is required</p>
                                </div>
                            </div>
                            <div class="form-group row" style="margin-bottom: 10px;">
                                <div class="col-lg-6">
                                    <mat-label class="custom-title-info account-title">Foundation Type: </mat-label>
                                    <ng-select id="foundationTypes" [items]="foundationTypes" appendTo="body" [hideSelected]="true"
                                        bindLabel="name" placeholder="Foundation Type" formControlName="foundationType">
                                    </ng-select>
                                    <p class="error-class"
                                        *ngIf="!quoteForm.get('foundationType').valid && isSubmitted">
                                        Foundation Type is required</p>
                                </div>
                                <div class="col-lg-6">
                                    <div class="custom-title-info account-title">Within 1000ft. of fire hydrant?</div>
                                    <div class="d-flex">
                                        <div style="width: 100px;">
                                            <input type="radio" [value]="true" formControlName="nearFireHydrant"
                                                id="true" class="radio-col-light-blue" />
                                            <label [for]="true">Yes</label>
                                        </div>
                                        <div style="width: 100px;">
                                            <input type="radio" [value]="false" formControlName="nearFireHydrant"
                                                id="false" class="radio-col-light-blue" />
                                            <label [for]="false">No</label>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card" style="margin-top: 30px;">

                        <div class="card-header" style="font-weight: bold;">
                            <i class="fas fa-address-book text-center"></i>

                            Contact Information
                        </div>
                        <div class="card-body">
                            <div class="row form-group">
                                <div class="col-lg-3">
                                    <mat-form-field style="width: 100%">
                                        <input matInput formControlName="firstName" type="text"
                                            placeholder="{{'FIRST_NAME' | translate}}" />
                                    </mat-form-field>
                                    <p class="error-class" *ngIf="!quoteForm.get('firstName').valid && isSubmitted">
                                        {{'REQUIRED_FIELD' | translate: {value:'First Name'} }}</p>
                                </div>
                                <div class="col-lg-3">
                                    <mat-form-field style="width: 100%">
                                        <input matInput formControlName="lastName" type="text"
                                            placeholder="{{'LAST_NAME' | translate}}" />
                                    </mat-form-field>
                                    <p class="error-class" *ngIf="!quoteForm.get('lastName').valid && isSubmitted">
                                        {{'REQUIRED_FIELD' | translate: {value:'Last Name'} }}</p>
                                </div>
                                <div class="col-lg-2">
                                    <mat-form-field style="width: 100%">
                                        <input matInput placeholder="Country Code" formControlName="countryCode" maxlength="3" />
                                        <span matPrefix>+&nbsp;</span>
                                    </mat-form-field>
                                    <div style="margin-top: -20px">
                                        <p class="error-class" *ngIf="quoteForm.get('countryCode').errors?.required && isSubmitted">Country Code is required</p>
                                        <p class="error-class" *ngIf="quoteForm.get('countryCode').errors?.pattern && isSubmitted">Numbers only</p>
                                    </div>
                                </div>
                                <div class="col-lg-4">
                                    <mat-form-field style="width: 100%">
                                        <input matInput phoneDisplay [preValue]="quoteForm.value.ph_number" placeholder="Phone Number" formControlName="ph_number" maxlength="14" />
                                    </mat-form-field>
                                    <div style="margin-top: -20px">
                                        <p class="error-class" *ngIf="quoteForm.get('ph_number').errors?.required && isSubmitted">Phone Number is required</p>
                                        <p class="error-class" *ngIf="quoteForm.get('ph_number').errors?.pattern && isSubmitted">Invalid Phone Number</p>
                                    </div>
                                </div>
                            </div>
                            <div class="row form-group">
                                <div class="col-lg-6">
                                    <mat-form-field style="width: 100%">
                                        <input matInput formControlName="email" type="text" (input)="keyEmail()" placeholder="{{'EMAIL' | translate}}" />
                                    </mat-form-field>
                                    <p class="error-class" *ngIf="!quoteForm.get('email').valid && isSubmitted">{{emailError}}</p>
                                    
                                </div>
                            </div>
                            <div class="form-group row">
                                <div class="col-lg-3" style="font-weight: bold;">Mailing Address</div>
                                <div class="checkbox checkbox-info">
                                    <input type="checkbox" id="isMailingSameAsProperty" (change)="mailingChange($event)" formControlName="isMailingSameAsProperty">
                                    <label for="isMailingSameAsProperty" class="">
                                        <span>Same as Property Address</span>
                                    </label>
                                </div>
                            </div>
                            <div class="form-group row">
                                <div class="col-lg-6 ">
                                    <mat-form-field style="width: 100%">
                                        <input matInput formControlName="mailing_street" type="text"
                                            placeholder="Street Address" />
                                    </mat-form-field>
                                    <p class="error-class"  *ngIf="!quoteForm.get('mailing_street').valid && isSubmitted">
                                        Street address is required field</p>
                                </div>
                                <div class="col-lg-6 ">
                                    <mat-form-field style="width: 100%">
                                        <input matInput formControlName="mailing_street2" type="text"
                                            placeholder="Street Address 2" />
                                    </mat-form-field>
                                   
                                </div>
                            </div>
                            <div class="form-group row">
                                <div class="col-lg-6 ">
                                    <mat-form-field style="width: 100%">
                                        <input matInput formControlName="mailing_city" type="text"
                                            placeholder="City" />
                                    </mat-form-field>
                                    <p class="error-class"  *ngIf="!quoteForm.get('mailing_city').valid && isSubmitted">
                                        City is required field</p>
                                </div>
                                <div class="col-lg-6 ">
                                    <mat-form-field style="width: 100%">
                                        <input matInput formControlName="mailing_state" type="text"
                                            placeholder="State" />
                                    </mat-form-field>
                                    <p class="error-class"  *ngIf="!quoteForm.get('mailing_state').valid && isSubmitted">
                                        State is required field</p>
                                </div>
                            </div>
                            <div class="form-group row">
                                <div class="col-lg-6 ">
                                    <mat-form-field style="width: 100%">
                                        <input matInput formControlName="mailing_zip" type="text"
                                            placeholder="Zip Code" />
                                    </mat-form-field>
                                    <p class="error-class"  *ngIf="!quoteForm.get('mailing_zip').valid && isSubmitted">
                                        Zip Code is required field</p>
                                </div>

                            </div>


                        </div>
                    </div>
                </form>
            </mat-step>
            <mat-step>
                <ng-template matStepLabel>
                    <div class="custom-title-info account-title"
                        [ngClass]="{'selected-tab': stepper.selectedIndex === 1}">{{'GET_QUOTE_STEP2' | translate}}
                    </div>
                </ng-template>

                
                    <div class="card-body" style=" box-shadow:none;">
                        <div class="row">
                            <mat-card class="col-lg-3 p-b-30 card-premium-selection"
                            [class.card-premium-blue]="selectedPremium==='silver'"
                            [class.card-premium-grey]="selectedPremium!=='silver'"
                            style="margin-top: 3%; margin-left: 5%;" 
                            (click)="selectPremium(1)"
                            >
                            <!-- <span style="text-align: left;">
                                <mat-icon
                                [class.icon-circle-green]="selectedPremium==='silver'"
                                [hidden]="selectedPremium!=='silver'"
                                 >check_circle</mat-icon>
                            </span> -->
                                <mat-card-title style="text-align: center;">
                                    <mat-icon
                                    [class.icon-premium-yellow]="selectedPremium==='silver'"
                                    [class.icon-premium-grey]="selectedPremium!=='silver'"
                                     >brightness_5</mat-icon> Silver </mat-card-title>
                                <mat-card-subtitle>
                                    Description of silver coverage goes here. Best choice for smaller homes with modest
                                    personal property coverage.
                                </mat-card-subtitle>
                                <mat-card-content style="text-align: center;">
                                    <p>
                                        Estimated monthly premium
                                    </p>
                                    <p>
                                        ${{estimatedPremium.silverEstimatedPremium}} per month
                                    </p>
                                </mat-card-content>
                            </mat-card>
                            <mat-card class="col-lg-3 p-b-30 card-premium-selection"
                            style="margin-top: 3%; margin-left: 5%;" 
                            [class.card-premium-blue]="selectedPremium==='gold'"
                            [class.card-premium-grey]="selectedPremium!=='gold'"
                            (click)="selectPremium(2)"
                            >
                            <!-- <span style="text-align: left;">
                                <mat-icon
                                [class.icon-circle-green]="selectedPremium==='gold'"
                                [hidden]="selectedPremium!=='gold'"
                                >check_circle</mat-icon>
                            </span> -->
                                
                            <mat-card-title style="text-align: center;">
                                <mat-icon
                                [class.icon-premium-yellow]="selectedPremium==='gold'"
                                [class.icon-premium-grey]="selectedPremium!=='gold'"
                                >brightness_5</mat-icon> Gold
                                </mat-card-title>
                                <mat-card-subtitle>
                                    Description of gold coverage goes here. Best choice for average homes with standard
                                    personal property coverage.
                                </mat-card-subtitle>
                                <mat-card-content style="text-align: center;">
                                    <p>
                                        Estimated monthly premium
                                    </p>
                                    <p>
                                        ${{estimatedPremium.goldEstimatedPremium}} per month
                                    </p>
                                </mat-card-content>
                            </mat-card>

                            <mat-card class="col-lg-3 p-b-30 card-premium-selection"
                            style="margin-top: 3%; margin-left: 5%;"
                            [class.card-premium-blue]="selectedPremium==='platinum'"
                            [class.card-premium-grey]="selectedPremium!=='platinum'" 
                            (click)="selectPremium(3)"
                            >
                            <!-- <span style="text-align: left;">
                                <mat-icon
                                [class.icon-circle-green]="selectedPremium==='platinum'"
                                [hidden]="selectedPremium!=='platinum'"
                                >check_circle</mat-icon>
                            </span> -->
                                <mat-card-title style="text-align: center;">
                                    <mat-icon
                                    [class.icon-premium-yellow]="selectedPremium==='platinum'"
                                    [class.icon-premium-grey]="selectedPremium!=='platinum'"
                                    >brightness_5</mat-icon>  Platinum</mat-card-title>
                                <mat-card-subtitle>
                                    Description of platinum coverage goes here. Best choice for larger homes with higher
                                    personal property coverage.
                                </mat-card-subtitle>
                                <mat-card-content style="text-align: center;">
                                    <p>
                                        Estimated monthly premium
                                    </p>
                                    <p>
                                        ${{estimatedPremium.platinumEstimatedPremium}} per month
                                    </p>
                                </mat-card-content>
                            </mat-card>
                        </div>
                        <div class="row" style="padding-top: 40px; padding-bottom: 40px; text-align: center;" class = "p-20">
                            <button class="btn btn-info custom-button-info" style="margin-right: 10px" type="button"
                                [hidden]="">
                                Update Premium Estimate
                            </button>
                        </div>

                       
                    <div class="row">
                        <mat-card class="col-lg-12 p-b-1"
                        style="margin-left: 7%; margin-right: 7%; box-shadow:none; align-content: center;" 
                        >
                            <div class="row col-md-12">
                                <div class="col-md-4">
                                <h4 class="custom-title-info account-title" 
                                style="font-weight:600px; font-size:24px; padding-bottom: 5px; padding-top: 8px;">
                                        Coverages
                                    </h4>
                                </div>
                                <div class="col-md-4">
                                    <h4 class="custom-title-info account-title"
                                        style="font-weight:600px; font-size:24px;">
                                        Limit Amount
                                    </h4>
                                </div>
                                <div class="col-md-4">
                                    <h4 class="custom-title-info account-title"
                                        style="font-weight:600px; font-size:24px;">
                                        Coverage Adjustment
                                    </h4>
                                </div>
                            </div>

                            <div class="row col-md-12" style="margin-top:15px;"
                            [hidden]="curPolicyType === 'HO4'"
                            >
                                <div class="col-md-4">
                                    <p class="custom-title-info account-title" [ngStyle]="styling?.card?.attributeKey">
                                        Coverage A Dwelling :
                                    </p>
                                </div>
                                <div class="col-md-4">
                                    <span class="custom-title-infoDetail account-description"
                                        [ngStyle]="styling?.card?.content">${{ coverageValues.coverageAValue }}</span>
                                </div>
                                <div class="col-md-4">
                                    <mat-slider tickInterval="auto" thumbLabel min="1" max="100000" step="1000"
                                        value="50000">
                                    </mat-slider>
                                </div>
                            </div>

                            <div class="row col-md-12"
                            [hidden]="curPolicyType === 'HO4' || curPolicyType ==='HO6'">
                                <div class="col-md-4">
                                    <p class="custom-title-info account-title" [ngStyle]="styling?.card?.attributeKey">
                                        Coverage B Other Structures :
                                    </p>
                                </div>
                                <div class="col-md-4">
                                    <span class="custom-title-infoDetail account-description"
                                        [ngStyle]="styling?.card?.content">${{ coverageValues.coverageBValue }}</span>
                                </div>
                                <div class="col-md-4">
                                    <mat-slider tickInterval="auto" thumbLabel min="1" max="100000" step="1000"
                                        value="50000">
                                    </mat-slider>
                                </div>
                            </div>


                            <div class="row col-md-12">
                                <div class="col-md-4">
                                    <p class="custom-title-info account-title" [ngStyle]="styling?.card?.attributeKey">
                                        Coverage C Personal Property :
                                    </p>
                                </div>
                                <div class="col-md-4">
                                    <span class="custom-title-infoDetail account-description"
                                        [ngStyle]="styling?.card?.content">${{coverageValues.coverageCValue }}</span>
                                </div>
                                <div class="col-md-4">
                                    <mat-slider tickInterval="auto" thumbLabel min="1" max="100000" step="1000"
                                        value="50000">
                                    </mat-slider>
                                </div>
                            </div>

                            <div class="row col-md-12">
                                <div class="col-md-4">
                                    <p class="custom-title-info account-title" [ngStyle]="styling?.card?.attributeKey">
                                        Coverage D Loss of Use :
                                    </p>
                                </div>
                                <div class="col-md-4">
                                    <span class="custom-title-infoDetail account-description"
                                        [ngStyle]="styling?.card?.content">${{ coverageValues.coverageDValue }}</span>
                                </div>
                                <div class="col-md-4">
                                    <mat-slider tickInterval="auto" thumbLabel min="1" max="100000" step="1000"
                                        value="50000">
                                    </mat-slider>
                                </div>
                            </div>

                            <div class="row col-md-12">
                                <div class="col-md-4">
                                    <p class="custom-title-info account-title" [ngStyle]="styling?.card?.attributeKey">
                                        Coverage E Personal Liability :
                                    </p>
                                </div>
                                <div class="col-md-4">
                                    <span class="custom-title-infoDetail account-description"
                                        [ngStyle]="styling?.card?.content">{{ coverageValues.coverageEValue }}</span>
                                </div>
                                <div class="col-md-4">
                                    <mat-slider tickInterval="auto" thumbLabel min="1" max="100000" step="1000"
                                        value="50000">
                                    </mat-slider>
                                </div>
                            </div>

                            <div class="row col-md-12">
                                <div class="col-md-4">
                                    <p class="custom-title-info account-title" [ngStyle]="styling?.card?.attributeKey">
                                        Coverage F Medical Payments :
                                    </p>
                                </div>
                                <div class="col-md-4">
                                    <span class="custom-title-infoDetail account-description"
                                        [ngStyle]="styling?.card?.content">{{ coverageValues.coverageFValue }}</span>
                                </div>
                                <div class="col-md-4">
                                    <mat-slider tickInterval="auto" thumbLabel min="1" max="100000" step="1000"
                                        value="50000">
                                    </mat-slider>
                                </div>
                            </div>

                            <div class="row col-md-12">
                                <div class="col-md-4">
                                <h4 class="custom-title-info account-title" 
                                style="font-weight:600px; font-size:24px; padding-bottom: 5px; padding-top: 8px;">
                                    Deductibles
                                </h4>
                            </div>
                            
                        </div>

                        <div class="row col-md-12">
                            <div class="col-md-4">
                                    <p class="custom-title-info account-title" [ngStyle]="styling?.card?.attributeKey">
                                        AOP Deductible :
                                    </p>
                                </div>
                                <div class="col-md-4">
                                    <span class="custom-title-infoDetail account-description"
                                        [ngStyle]="styling?.card?.content">{{ coverageValues.aopDeductible }}</span>
                                </div>
                                <div class="col-md-4">
                                    <mat-slider tickInterval="auto" thumbLabel min="1" max="100000" step="1000"
                                        value="50000">
                                    </mat-slider>
                                </div>
                            </div>

                            <div class="row col-md-12">
                                <div class="col-md-4">
                                    <p class="custom-title-info account-title" [ngStyle]="styling?.card?.attributeKey">
                                        Hurricane Deductible :
                                    </p>
                                </div>
                                <div class="col-md-4">
                                    <span class="custom-title-infoDetail account-description"
                                        [ngStyle]="styling?.card?.content">{{ coverageValues.deductibleAppliedForHurricaneCoverage }}</span>
                                </div>
                                <div class="col-md-4">
                                    <mat-slider tickInterval="auto" thumbLabel min="1" max="100000" step="1000"
                                        value="50000">
                                    </mat-slider>
                                </div>
                            </div>

                            <div class="row col-md-12"
                            [hidden]="curPolicyType === 'HO4' || curPolicyType ==='HO6'">
                                <div class="col-md-4">
                                    <p class="custom-title-info account-title" [ngStyle]="styling?.card?.attributeKey">
                                        Water Deductible :
                                    </p>
                                </div>
                                <div class="col-md-4">
                                    <span class="custom-title-infoDetail account-description"
                                        [ngStyle]="styling?.card?.content">{{ coverageValues.waterDeductible }}</span>
                                </div>
                                <div class="col-md-4">
                                    <mat-slider tickInterval="auto" thumbLabel min="1" max="100000" step="1000"
                                        value="50000">
                                    </mat-slider>
                                </div>
                            </div>
                    </mat-card>
                        </div>

                 
            </div> 
            </mat-step>

            <mat-step>
                <ng-template matStepLabel>
                    <div class="custom-title-info account-title"
                        [ngClass]="{'selected-tab': stepper.selectedIndex === 2}">{{'GET_QUOTE_STEP3' | translate}}
                    </div>
                </ng-template>


            </mat-step>

        </mat-horizontal-stepper>
              
        <div style="text-align: center;
            padding: 5%;">

            <button *ngIf="stepper.selectedIndex === 1" 
            class="btn btn-info custom-button-info"
            type="button" 
            style="margin-right: 20px"
            (click)="navigateToLink('login')"
            >{{('CANCEL' | translate) }}</button>

            <button *ngIf="stepper.selectedIndex === 1" 
            class="btn btn-info custom-button-info"
            type="button" 
            style="margin-right: 20px"
            (click)="resetStepper(stepper)"
            >{{'BACK' | translate}}</button>

            <button *ngIf="stepper.selectedIndex !== 2" 
            (click)="goForward(stepper)" 
            class="btn btn-info custom-button-info" style="margin-right: 20px"
                type="button">{{('NEXT' | translate)}}</button>

        </div>
        <!-- <div style="text-align: center;
            padding: 5%;">
            <button (click)="goForward(stepper)" class="btn btn-info custom-button-info" style="margin-right: 10px"
                type="button"
                [hidden]="stepper.selectedIndex === 2">{{stepper.selectedIndex === 1 ? ('CONFIRM' | translate) : ('NEXT' | translate)}}</button>

            <button (click)="navigateToLink('home/my-billing-view/payment')" class="btn btn-info custom-button-info"
                type="button" (click)="navigateToLink('home/my-billing-view')"
                [hidden]="stepper.selectedIndex === 1">{{stepper.selectedIndex === 2 ? ('BACK_TO_MY_BILLING' | translate) : ('CANCEL' | translate) }}</button>

            <button (click)="resetStepper(stepper)" class="btn btn-info custom-button-info" style="margin-right: 10px"
                type="button"
                [hidden]="stepper.selectedIndex === 0 || stepper.selectedIndex === 2">{{'BACK' | translate}}</button>
        </div> -->




    </div>

</div>