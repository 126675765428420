<body class="fix-header fix-sidebar card-no-border">
    <section id="wrapper" class="error-page">
        <div class="error-box">
            <div class="error-body text-center">
                <h1 class="text-info">404</h1>
                <h3 class="text-uppercase" style="color: grey">RESTRICTED ACCESS!</h3>
                <p class="text-muted m-t-30 m-b-30  text-uppercase">LOOKS LIKE YOU DON'T HAVE ACCESS TO THIS PAGE. PLEASE TRY AGAIN LATER.</p>
                <a class="btn btn-info btn-rounded waves-effect waves-light m-b-40" (click)="navigateBack()">Back to home</a>
            </div>
            <!-- <app-footer></app-footer> -->
        </div>
    </section>
</body>