import { Injectable } from "@angular/core";
import { Observable, Subject, merge, fromEvent, timer } from 'rxjs';

@Injectable({
    providedIn: 'root'
})

export class IdleTimerService {
    private idleState: Observable<any>
    private ticker;
    private msTimeOut;
    private idleSubscription;

    public expiryTick: Subject<boolean> = new Subject<boolean>();

    constructor(){}

    public startSession(sec): Observable<any>{
        this.idleState = merge(
            fromEvent(document, 'DOMMouseScroll'),
            fromEvent(document, 'MSPointerMove'),
            fromEvent(document, 'mousemove'),
            fromEvent(document, 'mousedown'),
            fromEvent(document, 'click'),
            fromEvent(document, 'mousewheel'),
            fromEvent(document, 'touchmove'),
            fromEvent(document, 'keypress'),
            fromEvent(document, 'resize')
        );
        this.msTimeOut = sec * 1000;
        this.idleSubscription = this.idleState.subscribe((res) => {
            this.resetTimer();
        })
        this.startTimer();

        return this.expiryTick;
    }

    private startTimer(){
        this.ticker = timer(this.msTimeOut, this.msTimeOut).subscribe((res) => {
            this.expiryTick.next(true);
        })
    }

    public resetTimer(){
        this.ticker.unsubscribe();
        this.startTimer()
    }

    public stopTimer(){
        this.ticker.unsubscribe();
        this.idleSubscription.unsubscribe()
    }
}