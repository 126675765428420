<div class="card custom-card-border h-100" [ngStyle]="styling?.card?.bodyStyling">
    <div class="card-body">
        <div style="display: flex; justify-content: space-between;">
            <h4 class="card-title header-font custom-title header-four-l" [ngStyle]="styling?.cardTitle">
                {{'MY_CLAIMS' | translate}}
            </h4>
            <button class="btn btn-info custom-button-info mini-button buttons"
                (click)="navToLink('home/my-claims/new')"
                [ngStyle]="styling?.button?.buttonNavigation">{{'NEW_CLAIM' | translate}}</button>
        </div>
        <div class="row p-t-20">
            <div class="col-md-12">
                <div class="card" style="margin-bottom: 5px;" *ngIf="claimDetails && claimDetailsObj?.openClaims > 0; else alternateContent">
                    <div class="card-body">
                        <div class="d-flex flex-row"  >
                            <a class="navbar-brand" style="color: black">
                                <img src="assets/multiTenantImages/png/claims_icon.PNG" style="width:50px;height:50px" alt="Claims icon"/>
                            </a>
                            <div class="m-l-10 align-self-center" [ngStyle]="styling?.paragraphText">
                                <h3 class="m-b-0 custom-link account-title"
                                    (click)="navToLink('home/my-claims/list')">{{claimDetailsObj?.openClaims}}</h3>
                                <h5 class="text-muted m-b-0" (click)="navToLink('home/view-policies')">
                                    {{claimDetailsObj?.policyNumber}}</h5>
                            </div>
                        </div>
                    </div>
                </div>
                <ng-template #alternateContent>
                    <div class="card-body">
                        <div class="text-center" [ngStyle]="styling?.paragraphText">{{'NO_OPEN_CLAIM_MSG' | translate}}</div>
                    </div>
                </ng-template>

            </div>
        </div>
    </div>