import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ConfirmModalComponent } from '../confirm-modal/confirm-modal.component';
import { BsModalService } from 'ngx-bootstrap/modal';
import { Observable } from 'rxjs';
import { CommonApiService } from '../../shared/services/common-api.service';
import { Store, select } from '@ngrx/store';
import { toggleOn } from './sidebar_toggle_action';
import { UtilsService } from '../services/utils.service';
import { UserWarningComponent } from '../user-warning/user-warning.component';
const permissionKeys = require("../../shared/services/permission-keys.json");

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
  providers: [CommonApiService, BsModalService]
})
export class SidebarComponent implements OnInit {
  tenant: any;
  homeIcon: any;
  billingIcon: any;
  claimIcon: any;
  policyIcon: any;
  userAdminIcon: any;
  styling = this.utilsService.getItemfromSession('themeObject')
  keysAvailable = this.utilsService.getItemfromSession('permissionKeys') ? this.utilsService.getItemfromSession('permissionKeys') : []
  displayButton: { policyView: any, billingView: any, claimView: any, quoteView: any, styleConfView: any };
  constructor(
    public router: Router,
    private route: ActivatedRoute,
    private modalService: BsModalService,
    private utilsService: UtilsService,
    private http: CommonApiService,
    private store: Store<{ sidebarState: boolean }>) { }

  ngOnInit(): void {
    this.displayButton = {
      policyView: this.keysAvailable.includes(permissionKeys.POLICY_VIEW),
      billingView: this.keysAvailable.includes(permissionKeys.BILLING_VIEW),
      claimView: this.keysAvailable.includes(permissionKeys.CLAIM_VIEW),
      quoteView: this.keysAvailable.includes(permissionKeys.GET_A_QUOTE),
      styleConfView: this.keysAvailable.includes(permissionKeys.STYLE_CONFIGURATOR)
    }
    const icons = this.utilsService.getItemfromSession('companybackground');
    this.homeIcon = icons.home_icon;
    this.billingIcon = icons.billing_icon;
    this.policyIcon = icons.policy_icon;
    this.claimIcon = icons.claim_icon;
    this.userAdminIcon = icons.user_admin_icon;
    this.tenant = this.route.snapshot.params.tenant
  }

  navigateTo(page) {
    this.toggleSideBar()
    var infoType = 'user_warning_cancelled_claim';
    this.http.get(`getCardInfo?accountCode=${this.tenant}&infoType=${infoType}`, null, true)
      .subscribe(
        (resp) => {
          if (resp) {
            var warningMessage = resp.out;
            if ((this.router.url == '/' + this.tenant + '/home/my-claims/new') && (this.utilsService.getItemfromSession('showClaimUserWarning'))) {
              const modal = this.modalService.show(UserWarningComponent, { backdrop: 'static' });
              (<UserWarningComponent>modal.content).showUserWarning(this.tenant, warningMessage, page);
            }
            else {
              this.router.navigate([`${this.tenant}/${page}`])
            }
          }
        }
      );



  }

  navLogin() {
    sessionStorage.clear();
    this.router.navigate([`${this.tenant}/login`]).then(() => { window.location.reload() })
  }

  confirmModalPopup() {
    const modal = this.modalService.show(ConfirmModalComponent, { backdrop: 'static' });
    (<ConfirmModalComponent>modal.content).showConfirmationModal('Logout', `Are you sure you want to logout?`);
    (<ConfirmModalComponent>modal.content).onClose.subscribe(resp => {
      if (resp === true) {
        this.navLogin()
      }
    })
  }

  toggleSideBar() {
    if (window.innerWidth < 768) {
      this.store.dispatch(toggleOn())
    }
  }
}
