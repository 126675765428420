import { Component, OnInit, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-is-house-form',
  templateUrl: './is-house-form.component.html',
  styleUrls: ['./is-house-form.component.scss']
})
export class IsHouseFormComponent implements OnInit {
  @Input() isHouseForm: FormGroup
  constructor() { }

  ngOnInit(): void {
  }

  onClick(eve) {
      this.isHouseForm.patchValue({
        checkHouse: eve
      });
  }
}
