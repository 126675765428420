import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { forkJoin, Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { throwError as observableThrowError } from 'rxjs'

import { CommonApiService } from '../../../shared/services/common-api.service';
import { NotifytoastService } from '../../../shared/services/notificationToast.service';
import { PrivateDataShare } from '../../../shared/services/privateDataShare.service';
import { FilesUploadPopupComponent } from '../../policy-doc-upload-comp/files-upload-popup/files-upload-popup.component';
import { UtilsService } from '../../../shared/services/utils.service';

@Component({
  selector: 'app-upload-policy-doc-new',
  templateUrl: './upload-policy-doc-new.component.html',
  styleUrls: ['./upload-policy-doc-new.component.scss'],
  providers: [ CommonApiService ]
})
export class UploadPolicyDocNewComponent implements OnInit {
  loader: boolean;
  frontRightCollection = [];
  backLeftCollection = [];
  declarationCollection = [];
  @Input() policyNumber: any;
  styling = this.utilsService.getItemfromSession('themeObject')
  constructor(
    private utilsService: UtilsService,
    private privateDataShare: PrivateDataShare,
    private notifytoast: NotifytoastService,
    private modalService: BsModalService,
    private route: ActivatedRoute,
    private httpService: CommonApiService
  ) { }

  ngOnInit(): void {
  }

  fileChange(event, picSide){ // considering only one pic
    this.loader = true;
    const selectedFiles = [...event.target.files];
    this.uploadDocs(selectedFiles, picSide).subscribe(resp => {
      this.loader = false;
      let successfilesSet = resp.filter(resp => resp.uploaded == true).map(e => e.fileName)
      let errorfilesSet = resp.filter(resp => resp.uploaded == false && resp.sizeLimitExceeded == false).map(e => e.fileName)
      let sizeExceededSet = resp.filter(resp => resp.uploaded == false && resp.sizeLimitExceeded == true).map(e => e.fileName)
      if(successfilesSet.length){
        this.notifytoast.success("Upload Completed")
        this.openModal(successfilesSet, `Successfully uploaded`, `Files uploaded to ${picSide}:`, 'success');
        this.setFlag(successfilesSet, picSide)
      } else if(errorfilesSet.length){
        this.openModal(errorfilesSet, 'Error',  `Files not uploaded to ${picSide}: `, 'error');
      } else if(sizeExceededSet.length){
        this.openModal(sizeExceededSet, 'Error', `Files not uploaded to ${picSide} as the file size is large:`, 'error');
      }
    })
  }

  setFlag(file, picSide){
    if(picSide == 'Property Front Right') this.frontRightCollection = file;
    else if(picSide == 'Property Back Left') this.backLeftCollection = file;
    else if(picSide == 'Declarations') this.declarationCollection = file;
  }

  openModal(filesList, title, containerText, status){
    let initialState: ModalOptions = {
      backdrop: 'static',
      // class: 'modal-lg',
      initialState: {
        filesList,
        title,
        containerText,
        status
      }
    } 
    this.modalService.show(FilesUploadPopupComponent, initialState);
  }

  uploadDocs(files, picSide): Observable<any>{
    let fileUploadSize = this.privateDataShare.getFileUploadSize() 
    fileUploadSize = fileUploadSize ? parseInt(fileUploadSize)*1024*1024 : 5242880
    let observables = files.map((file, index) => {
      if(file.size < fileUploadSize){
        let convertedData = this.convertFormData(file, picSide)
        return this.httpService.privateUpload('uploadpolicydocuments', convertedData, true).pipe(
          map((resp) => {
            if(resp.statusCode == 200){
                let tempData = this.getPicRespData(file.name, picSide, true, false)
                return tempData
              } else{
                let tempData = this.getPicRespData(file.name, picSide, false, false)
                return tempData
              }
          }),
          catchError(error => this.errorCase(file.name, picSide))
        )
      } else{
        let tempData = this.getPicRespData(file.name, picSide, false, true)
        return of(tempData)
      }
    })
    return forkJoin(observables)
  }

  convertFormData(file, docType){
    const formData = new FormData()
    const tenant = this.route.parent.snapshot.params.tenant
    formData.append('policyNumber', this.policyNumber)
    // formData.append('claimID', '228837')
    formData.append('documentType', docType)
    formData.append('accountCode', tenant)
    formData.append('file', file)
    return formData
  }

  errorCase(fileName, picSide){
    let tempData = this.getPicRespData(fileName, picSide, false, false)
    return observableThrowError(tempData)
  }

  getPicRespData(fileName, picSide, uploaded, sizeLimitExceeded?){
    let tempData = {fileName, picSide, uploaded, sizeLimitExceeded}
    return tempData
  }

}
