import { Component, OnInit, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-personal-info-form',
  templateUrl: './personal-info-form.component.html',
  styleUrls: ['./personal-info-form.component.scss']
})
export class PersonalInfoFormComponent implements OnInit {
  @Input() personalInfoForm: FormGroup;
  @Input() isSubmitted: boolean;
  emailError: any;
  constructor() { }

  ngOnInit(): void {}

  keyEmail(){
    const temp = this.personalInfoForm.get('email')
    if(temp.errors){
      if (temp.errors.invalidEmailAddress && !temp.errors.required) {
        this.emailError =  'Email is not valid.'
      } else{
        this.emailError = ''
      }
    }
  }

}
