import { Directive, OnInit, HostListener } from '@angular/core';


@Directive({
    selector: '[cardNumber]'
})

export class CardNumberDirective implements OnInit{
    constructor(){
    }

    ngOnInit(){}
    @HostListener('input', ['$event'])
    onKeyDown(value){
        const input  = value.target as HTMLInputElement;
        let numberInput = input.value.replace(/[^0-9]/g, '');
        if(numberInput.length > 20) numberInput = numberInput.substr(0,20);
        let numbers =[];
        for(let i=0; i < numberInput.length; i+=4){
            numbers.push(numberInput.substr(i, 4))
        }
        input.value = numbers.join(' ');
    }

}