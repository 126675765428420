<app-installation-banner [bannerObj]="bannerObj"></app-installation-banner>
<section id="wrapper" class="mod-login-register" [ngStyle]="{'background-image': bgImg}"  *ngIf="isSfi; else otherContainer">
    <div class="header-wrapper">
        <div class="col-12">
            <div class="row">
                <div class="col-2 hidden-xs-down"></div>
                <div class="col-8 text-center">
                    <img (click)="navTo(true)" src="assets/multiTenantImages/png/SFI-Long-Logo.png" class="text-center" height="50px" alt="SFI Logo" style="cursor: pointer;"/>
                </div>
                <div class="col-2 hidden-sm-up"></div>
                <div class="col-2" style="margin: auto;">
                    <a class="float-right contact-us hidden-xs-down" (click)="navTo(false)">Contact Us</a>
                    <div class="btn-group float-right hidden-sm-up" placement="bottom left" dropdown>
                        <button id="button-basic" dropdownToggle type="button" class="btn btn-info dropdown-toggle" aria-controls="dropdown-basic">
                            <i class="fas fa-bars"></i><span class="caret"></span>
                        </button>
                        <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-basic" style="margin-left: -110px;">
                            <li role="menuitem" (click)="navTo(false)"><i class="icon-fas fas fa-phone"></i>Contact us</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="routing-container">
        <router-outlet></router-outlet>
    </div>
    <div class="footer-wrapper">
        <div class="col-md-12">
            <div class="row">
                <div class="col-md-4 text-center" style="margin: auto;">
                    <!-- <img (click)="navTo(true)" src="assets/multiTenantImages/png/SFI-Long-Logo.png" class="text-center" height="50px" alt="SFI Logo" style="cursor: pointer;" />
                    <h6 class="display-txt-footer">storm after storm, year after year.®</h6> -->
                    <div [innerHTML]="footer_content.footer_left_message | safeHtml"></div>
                </div>
                <div class="col-md-4 customer-care-block">
                    <div [innerHTML]="footer_content.footer_center_message | safeHtml"></div>
                    <!-- <span class="footer-hyperlinks">(877) 333-9992</span> -->
                    <!-- <h6>Customer Service</h6>
                    <h6 class="footer-hyperlinks">(877) 333-9992</h6>
                    <h6>Monday-Friday, 8:00a.m - 8:00p.m, ET</h6>
                    <h6>Saturday-Sunday, 8:00a.m - 5:00p.m, ET</h6> -->
                </div>
                <div class="col-md-4 privacy-policy-block">
                    <div [innerHTML]="footer_content.footer_right_message | safeHtml"></div>
                    <!-- <h6><span class="hyperlinks terms-and-policies" (click)="navToPage('privacy')"> Privacy Policy </span> | <span class="hyperlinks terms-and-policies" (click)="navToPage('terms')">Terms of Use</span></h6>
                    <h6>© Copyright Security First Insurance Company, All Rights Reserved.</h6> -->
                    <h6><span class="version-text" style="font-family:Calibri">{{'FOOTER_VERSION' | translate: {date: date, version:version} }}</span></h6> 
                </div>
            </div>
        </div>
    </div>
</section>

<ng-template #otherContainer>
    <!-- <section id="wrapper" class="mod-login-register" [ngStyle]="{'background-image': bgImg}">
        <div class="login-page-layout hidden-md-down">
            <p class="page-main-title">
               {{pageDetailsObj?.page_configuration?.welcome_message}}
            </p>
        </div>
        <router-outlet></router-outlet>
    </section> -->
    <div class="header-wrapper">
        <div class="col-12">
            <div class="row">
                <div class="col-2 hidden-xs-down"></div>
                <div class="col-8 text-center" style="padding-top: 5px;">
                    <img (click)="navigationPageForDefault('header-icon')" [src]="headerImg" class="text-center" height="50px" alt="Def-tenant Logo" style="cursor: pointer;"/>
                </div>
                <div class="col-2 hidden-sm-up"></div>
                <div class="col-2" style="margin: auto;">
                    <a class="float-right contact-us hidden-xs-down" (click)="navigationPageForDefault('contact-us')">Contact Us</a>
                    <div class="btn-group float-right hidden-sm-up" placement="bottom left" dropdown>
                        <button id="button-basic" dropdownToggle type="button" class="btn btn-info dropdown-toggle" aria-controls="dropdown-basic">
                            <i class="fas fa-bars"></i><span class="caret"></span>
                        </button>
                        <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-basic" style="margin-left: -110px;">
                            <li role="menuitem" (click)="navigationPageForDefault('contact-us')"><i class="icon-fas fas fa-phone"></i>Contact us</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="routing-container">
        <router-outlet></router-outlet>
    </div>
    <div class="footer-wrapper">
        <div class="col-md-12">
            <div class="row">
                <div class="col-md-4 text-center" style="margin: auto;">
                    <div [innerHTML]="footer_content.footer_left_message | safeHtml"></div>
                </div>
                <div class="col-md-4 customer-care-block">
                    <div [innerHTML]="footer_content.footer_center_message | safeHtml"></div>
                </div>
                <div class="col-md-4 privacy-policy-block">
                    <div [innerHTML]="footer_content.footer_right_message | safeHtml"></div>
                    <h6><span class="version-text" style="font-family:Calibri">{{'FOOTER_VERSION' | translate: {date: date, version:version} }}</span></h6> 
                </div>
            </div>
        </div>
    </div>
</ng-template>