<div class="row">
    <div class="col-md-1" style="padding-top: 120px; padding-left: 5%">
        <i class="carousel-arrows" *ngIf="stepper.selectedIndex !== 0" (click)="goBack(stepper)"><a
                class="fas fa-chevron-left"></a></i>
    </div>
    <div class="col-md-7">
        <mat-horizontal-stepper linear #stepper>
            <mat-step [stepControl]="demoGetAQuoteForm1" style="height: 800px;">
                <form [formGroup]="demoGetAQuoteForm1">
                    <div class="col-12">
                        <div class="card">
                            <div class="card-body">
                                <h3 class="card-title text-center custom-title p-b-20">Step 1 </h3>
                                <div class="row">
                                    <div class="form-group col-lg-12">
                                        <div class="custom-title-info account-title">Address</div>
                                        <mat-form-field style="width: 100%;margin-top: -15px;">
                                            <textarea matInput rows="3" formControlName="address"></textarea>
                                        </mat-form-field>
                                        <p class="error-class" style="margin-top: -20px"
                                            *ngIf="demoGetAQuoteForm1.get('address').errors?.required">Address is
                                            required</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </mat-step>
            <mat-step [stepControl]="demoGetAQuoteForm2">
                <form [formGroup]="demoGetAQuoteForm2">
                    <div class="col-12">
                        <div class="card">
                            <div class="card-body">
                                <h3 class="card-title text-center custom-title p-b-20">Step 2 </h3>
                                <div class="row">
                                    <div class="form-group col-lg-6">
                                        <mat-form-field style="width: 100%;margin-top: -15px;">
                                            <input matInput formControlName="first_name" type="text"
                                                placeholder="First Name" />
                                        </mat-form-field>
                                        <p class="error-class" style="margin-top: -20px"
                                            *ngIf="demoGetAQuoteForm2.get('first_name').errors?.required">First Name is
                                            required</p>
                                    </div>
                                    <div class="form-group col-lg-6">
                                        <mat-form-field style="width: 100%;margin-top: -15px;">
                                            <input matInput formControlName="last_name" type="text"
                                                placeholder="Last Name" />
                                        </mat-form-field>
                                        <p class="error-class" style="margin-top: -20px"
                                            *ngIf="demoGetAQuoteForm2.get('last_name').errors?.required">Last Name is
                                            required</p>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="form-group col-lg-12">
                                        <mat-form-field style="width: 100%;margin-top: -15px;">
                                            <input matInput phoneDisplay
                                                [preValue]="demoGetAQuoteForm2.value.phone_number" maxlength="14"
                                                formControlName="phone_number" type="text" placeholder="Phone Number" />
                                        </mat-form-field>
                                        <p class="error-class" style="margin-top: -20px"
                                            *ngIf="demoGetAQuoteForm2.get('phone_number').errors?.required">Phone Number
                                            is required</p>
                                        <p class="error-class" style="margin-top: -20px"
                                            *ngIf="demoGetAQuoteForm2.get('phone_number').errors?.pattern">Invalid Phone
                                            Number</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </mat-step>
            <mat-step [stepControl]="demoGetAQuoteForm3">
                <form [formGroup]="demoGetAQuoteForm3">
                    <div class="col-12">
                        <div class="card">
                            <div class="card-body">
                                <h3 class="card-title text-center custom-title p-b-20">Step 3 </h3>
                                <div class="row">
                                    <div class="form-group col-lg-6">
                                        <div class="custom-title-info account-title">Bedrooms</div>
                                        <ng-select [items]="availableBedrooms" appendTo="body" [hideSelected]="true"
                                            placeholder="Number of Bedrooms" [clearable]="false"
                                            formControlName="noOfBedrooms">
                                        </ng-select>
                                        <p class="error-class" style="margin-top: -20px"
                                            *ngIf="demoGetAQuoteForm3.get('noOfBedrooms').errors?.required">First Name
                                            is required</p>
                                    </div>
                                    <div class="form-group col-lg-6 p-t-20">
                                        <mat-form-field style="width: 100%;margin-top: -15px;">
                                            <input matInput formControlName="total_sqft" type="text"
                                                placeholder="Total Square Feet" />
                                            <span matSuffix>SqFt</span>
                                        </mat-form-field>
                                        <p class="error-class" style="margin-top: -20px"
                                            *ngIf="demoGetAQuoteForm3.get('total_sqft').errors?.required">Total Square
                                            Feet is required</p>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="form-group col-lg-6">
                                        <div class="custom-title-info account-title">Bathrooms</div>
                                        <ng-select [items]="availableBathrooms" appendTo="body" [hideSelected]="true"
                                            placeholder="Number of Bathrooms" [clearable]="false"
                                            formControlName="noOfBathrooms">
                                        </ng-select>
                                        <p class="error-class" style="margin-top: -20px"
                                            *ngIf="demoGetAQuoteForm3.get('noOfBathrooms').errors?.required">First Name
                                            is required</p>
                                    </div>
                                    <div class="form-group col-lg-6">
                                        <div class="custom-title-info account-title">Storeys</div>
                                        <ng-select [items]="availableStoreys" appendTo="body" [hideSelected]="true"
                                            placeholder="Number of Storeys" [clearable]="false"
                                            formControlName="noOfStoreys">
                                        </ng-select>
                                        <p class="error-class" style="margin-top: -20px"
                                            *ngIf="demoGetAQuoteForm3.get('noOfStoreys').errors?.required">First Name is
                                            required</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </mat-step>
            <mat-step>
                <div class="col-12">
                    <div class="card">
                        <div class="card-body">
                            <h3 class="card-title text-center custom-title p-b-20">Step 4 </h3>
                            <div class="row">
                                <div class="form-group col-lg-12">
                                    <div class="custom-title-info account-title">Select a Package<strong>(Prices in
                                            Month)</strong></div>
                                    <div class="row p-t-20">
                                        <div class="col-lg-4">
                                            <div class="payment-body-border"
                                                [ngClass]="{'selected-payment-border': selectedPackage === 'silver'}"
                                                (click)="selectPackage('silver')">
                                                <div class="card-body text-center bg-silver">
                                                    <h4 class="text-white card-title">$1256.90</h4>
                                                    <h6 class="card-subtitle text-white m-b-0">Silver package</h6>
                                                </div>
                                                <div class="card-body">
                                                    <div class="message-widget contact-widget">
                                                        <div class="mail-contnet">Description of Silver Coverage goes
                                                            here</div>
                                                        <hr />
                                                        <div class="mail-contnet">Best choice for smaller homes with
                                                            modest personal property coverage</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-4">
                                            <div class="payment-body-border"
                                                [ngClass]="{'selected-payment-border': selectedPackage === 'gold'}"
                                                (click)="selectPackage('gold')">
                                                <div class="card-body text-center bg-gold">
                                                    <h4 class="text-white card-title">$1590.00</h4>
                                                    <h6 class="card-subtitle text-white m-b-0">Gold package</h6>
                                                </div>
                                                <div class="card-body">
                                                    <div class="message-widget contact-widget">
                                                        <div class="mail-contnet">Description of Gold Coverage goes here
                                                        </div>
                                                        <hr />
                                                        <div class="mail-contnet">Best choice for average homes with
                                                            standard personal property coverage</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-4">
                                            <div class="payment-body-border"
                                                [ngClass]="{'selected-payment-border': selectedPackage === 'platinum'}"
                                                (click)="selectPackage('platinum')">
                                                <div class="card-body text-center bg-platinum">
                                                    <h4 class="text-white card-title">$1789.00</h4>
                                                    <h6 class="card-subtitle text-white m-b-0">Platinum package</h6>
                                                </div>
                                                <div class="card-body">
                                                    <div class="message-widget contact-widget">
                                                        <div class="mail-contnet">Description of Platinum Coverage goes
                                                            here</div>
                                                        <hr />
                                                        <div class="mail-contnet">Best choice for larger homes with
                                                            higher personal property coverage</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </mat-step>
            <mat-step>
                <div class="col-12">
                    <div class="card">
                        <div class="card-body">
                            <h3 class="card-title text-center custom-title p-b-20">Step 5 </h3>
                            <h5> You've selected the {{selectedPackage}} product. We will soon process the information.
                                Thanks {{this.demoGetAQuoteForm2.value.first_name}}</h5>
                            <div class="text-center"><button class="btn btn-info"
                                    (click)="navLocation('login')">Finish</button></div>
                        </div>
                    </div>
                </div>
            </mat-step>

        </mat-horizontal-stepper>
    </div>
    <div class="col-md-1" style="padding-top: 120px; padding-right: 5%">
        <i class="carousel-arrows" *ngIf="stepper.selectedIndex !== 4" (click)="goForward(stepper)"><a
                class="fas fa-chevron-right"></a></i>
    </div>
    <div class="col-md-3">
        <div class="col-12">
            <div class="card">
                <div class="card-body">
                    <div class="text-center">
                        <img class="img-circle bot-img" src="assets/multiTenantImages/png/float_icon_logo.png"
                            alt="bot_picture" />
                        <h3 class="card-title custom-title">Bot</h3>
                    </div>
                    <div class="box bg-light-info">
                        {{messageHeading}}
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>