<div class="row-mobile">
  <div class="col-lg-9 mobile-spacing">
    <div class="card h-100">
      <div class="card-body body-mobile">
        
          <h2 [ngStyle]="styling?.pageTitle" class="text-center header-two-l">
            My Claims
        </h2>
        <div class="row">
            <app-policies-card class="col-lg-12 p-b-30" [policiesDetails]="policyInfo"
                (policyIndexChanges)="getPolicyIndex($event)"></app-policies-card>
        </div>
                
        <div class="row">
          <div class="col-12 text-center p-20">
            <button *ngIf="displayButton.newClaim" class="btn btn-info custom-button-info buttons"
            (click)="navLocation('home/my-claims/new')"
            [ngStyle]="styling?.button?.buttonNavigation">{{'NEW_CLAIM' | translate}}</button>
        </div>
      </div>
		  <hr style="height:5px;border-width:0;color:gray;background-color:gray; margin-top:20px">
        <div class="p-t-20">
          <h4 [ngStyle]="styling?.pageSubTitle" class="header-four-l m-b-10">
            My Open Claims
          </h4>
          <app-custom-table [tableDataObj]="openClaimDataToTable" [openAccordion]="0" [tableName]="'openClaim'" [noDataAvailableTxt]="'NO_OPEN_CLAIMS'" [customTableActions]="customTableActions" (actionEventTable)="actionEventFromTable($event)"></app-custom-table>
        </div>

        <div class="p-t-20">
          <h4 [ngStyle]="styling?.pageSubTitle" class="header-four-l m-b-10">
            My Closed Claims
          </h4>
          <app-custom-table [tableDataObj]="closeClaimDataToTable" [tableName]="'closeClaim'" [noDataAvailableTxt]="'NO_CLOSED_CLAIMS'" [customTableActions]="customTableActions" (actionEventTable)="actionEventFromTable($event)"></app-custom-table>
        </div>

      </div>
    </div>
  </div>

  <div class="col-lg-3 mobile-spacing">
    <app-large-agent-info [agencyObject]="agencyObject" [isLoading]="isLoading"></app-large-agent-info>
                    
  </div>
</div>