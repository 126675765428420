<div class="card h-100">
    <div class="card-body">
        <h2 class="header-font custom-title header-two-l" [ngStyle]="styling?.pageTitle"
            style="margin-top:-10px; margin-bottom:7px; margin-left: 6px; text-align: center;">
            {{'WELCOME' | translate}}, {{customerName}}
        </h2>
        <div class="row">
            <app-policies-card *ngIf="displayButton.policyView" class="col-lg-12 p-b-30" [policiesDetails]="policyInfo"
                (policyIndexChanges)="getPolicyIndex($event)"></app-policies-card>
        </div>
        <div class="row">
            <app-info-card class="col-lg-6 p-b-30" [infoCardDetails]="cardInfo"></app-info-card>
            <app-billing-card *ngIf="displayButton.billingView" class="col-lg-6 p-b-30" [billingDetails]="billingInfo"
                [policyNumber]="policyNo" [allowMakePayment]="allowMakePayment"></app-billing-card>
        </div>

        <div class="row">
            <app-info-card class="col-lg-6 p-b-30" [infoCardDetails]="cardInfo2"></app-info-card>
            
            <app-claim-card *ngIf="displayButton.allClaimView" class="col-lg-6 p-b-30" [claimDetails]="claimInfo"
                [policyNumber]="policyNo"></app-claim-card>
            <app-recent-claim-card *ngIf="displayButton.recentClaimView" class="col-lg-6 p-b-30"
                [recentClaimInfo]="recentClaimInfo" [policyNumber]="policyNo"></app-recent-claim-card>
            <!-- <app-upload-policy-doc *ngIf="displayButton.policyDocUpload" class="col-lg-6 p-b-30" [uploadCardInfo]="uploadCardInfo"></app-upload-policy-doc> -->
            <app-upload-policy-doc-new *ngIf="displayButton.policyDocUpload" class="col-lg-6 p-b-30" [policyNumber]="policyNo"></app-upload-policy-doc-new>
        </div>
    </div>

</div>