import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Router } from '@angular/router';

@Component({
  selector: 'app-user-warning',
  templateUrl: './user-warning.component.html',
  styleUrls: ['./user-warning.component.scss']
})
export class UserWarningComponent implements OnInit {
  page: any;
  content:string;
  header:string;
  tenant: string;
  active = false;
  onClose: Subject<boolean>;
  styling: any;
  constructor(
    private _bsModal: BsModalRef,
    private router: Router,
    ) { }

  ngOnInit(): void {
    this.onClose = new Subject();
    this.styling = this.getItemfromSession('themeObject');
  }

  public showUserWarning(tenant,warningMessage,navigationPage){
    this.page = navigationPage
    this.tenant = tenant
    this.header = warningMessage.header;
    this.content = warningMessage.content;
  }

  navigateToPage(page){
   this.hideUserWarning();
    this.router.navigate([`${this.tenant}/${page}`]);
  }

  hideUserWarning(){
    this.active = false
    this.onClose.next(false)
    this._bsModal.hide()
  }

  getItemfromSession(key, isLocal?){
    let storage = isLocal ? localStorage : sessionStorage
    if(storage.getItem(key) && storage.getItem(key) != 'undefined'){
      return JSON.parse(storage.getItem(key))
    }else{
      return null
    }
  }
}
