// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  isDebugMode: false, // ******* IMP -> FOR (QA and PROD) EBS deploymentchange this flag to false and then run build command  *********
  sfiUrl: window.location.origin + '/sfi/',
  upcUrl: window.location.origin + '/upc/',
  acmeUrl: window.location.origin + '/acme/',
  custPortalUrl: 'https://insuredportal-mw-2023-03-env.hostedinsurance.com/default/',
  // custPortalUrl: 'https://insuredportal-uat-mw.hostedinsurance.com/default/',
  // custPortalUrl: 'http://localhost:8080/default/',
  newApiUrl: 'http://localhost:8080/default/',
  securityCode: {
    "send_customer_portal_create_account_2fa_Code": {
      "inputKey": "P3llJSR1Y2hhQGx1X251aCphbGxlbHUoWWEvbiRhdCloYT5uZA1pdi5JbnV2I3ZlbkF5eWFe",
      "outputKey": "TmF7YWI6YXJ0LGhhX2tvczxpcnVsKXVkJmhbZW5hKHNhbXAtYWQ3YWx1YXxzaF5pXXJ3MWFkaSduYyFoJHU="
    },
    "verify_2fa_Code": {
      "inputKey": "aTh5eXYlTXUhLGFdRVluRVcpY1tBOX1XT3ctYVB0TDBkRzNRdFdMQE83X1dhVVQ0Rns=",
      "outputKey": "SkwwZF4uajhWdnJhTU9nZEptSmw1TkxpKSRsXlN6Ql9+bUktTFpeZGk0UDV+ZEV5Q3Y="
    },
    "forgot_password": {
      "inputKey": "X2JNaVkwNjFCT0BPLXcpUV49KG11a31zPUVSK2RFSHErY1JiZmEkXj0hc1ZZcVJ3TDs=",
      "outputKey": "bkVka1BDPS5ha1J7X2Fnb2w3I1F5RCNuTSlhW15aeDJ7Q1NnRjVHQ1AySk8sUG9BVSw="
    },
    "send_customer_portal_2fa_Code": {
      "inputKey": "bWF3K2FpYyNoJmFhbGFtQGFuY2hpYXRoJWFuJHVtYSpha3VjPWhhYWxhKW5lcnAtaXN0aF5hZHU=",
      "outputKey": "eWFtYXcrYWljI2gmYWFsYW1AYW5lY2hpYXRoJWFuJHVtYSpha3VjPWhhYXNsYSluZXJwLWlzdGheYWR1eXlh"
    },
    "policyNumber": {
      "inputKey": "aTh5eXYlTXUhLGFdRVluRVcpY1tBOX1XT3ctYVB0TDBkRzNRdFdMQE83X1dhVVQ0Rns="
    }
  },
  demoPayment: "demo=y",
  buildVersion: 'CO-2023-03.01',
  buildDate: '11/27/2023',
  gtmID: {
    // urlenv/tenant?: 'id'
    'sfi-qatest': 'GTM-K7BLCLN',
    'sfi': 'GTM-NHCDGZ2',
    'sfi-test-portal': 'GTM-NHCDGZ2',
    'sfi-kalai': 'GTM-NMT3P7B',
    'sfi-prod': 'GTM-T677X9Z'
  },
  idleTime: 30 * 60 // enter value in seconds
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
