<!-- <div class="row" [ngClass]="{'small-Window': isSmallWindow}"> -->
<div class="row-mobile">
    <div class="col-lg-9 mobile-spacing body-mobile" style="background: #fff;">
        <h2 [ngStyle]="styling?.pageTitle" class="text-center header-two-l">
            File New Claim
        </h2>
        <div class="m-t-20">
            <div class="row" style="padding: 0 24px 24px 24px;">
                <app-policies-card class="col-lg-12 p-b-30" [policiesDetails]="policyInfo" [notclickable]="isNotClickable"
                    (policyIndexChanges)="getPolicyIndex($event)"></app-policies-card>
            </div>
        </div>
        <mat-horizontal-stepper linear #stepper class="w-100">
            <mat-step [stepControl]="newClaimForm" [editable]="isEditable" class="w-100">
                <form [formGroup]="newClaimForm">
                    <div class="">
                        <div class="form-group row">
                            <div class="col-lg-12">
                                <div class="custom-title-info account-title" [ngStyle]="styling?.sectionHeader"
                                style="margin-bottom: 5px;">What Happened?</div>
                            
                                <div class="custom-title-info account-title" [ngStyle]="styling?.fieldPlaceHolder">Please describe the damage and provide other relevant information. *</div>
                                <mat-form-field style="width: 100%;" appearance="outline">
                                    <textarea matInput #input maxlength="200" rows="3" style="margin-top: -15px; border-color: gray;" formControlName="lossDescription" [ngStyle]="styling?.fieldValueInput">
                                    </textarea>
                                    <mat-hint align="end">{{input.value?.length || 0}}/200</mat-hint>
                                </mat-form-field>
                                <p class="error-class mat-angular-error-text" style="margin-top: -20px"
                                    *ngIf="newClaimForm.get('lossDescription').errors?.required && isSubmitted">Describe
                                    the loss is required</p>
                            </div>
                        </div>
                        <div class="form-group row">
                            <div class="col-lg-12 custom-title-info account-title" [ngStyle]="styling?.sectionHeader"
                                style="margin-bottom: 5px;">Time Details</div>
                            <div class="col-lg-6 align-self-end mobile-timepicker" style="padding-bottom: 29px;">
                                <div class="custom-title-info account-title" [ngStyle]="styling?.fieldPlaceHolder">Please provide the date and time the damage first occurred. *</div>
                                <input type="text" placeholder="Select a Date" class="form-control custom" bsDatepicker
                                    (bsValueChange)="dateChange($event)" [bsConfig]="datePickerSettings"
                                    [maxDate]="maxDate" formControlName="lostDate"  [ngStyle]="styling?.fieldValueInput">
                                <p class="error-class"
                                    *ngIf="newClaimForm.get('lostDate').errors?.required && isSubmitted">Date of Loss is
                                    required</p>
                                <p class="error-class mat-angular-error-text"
                                    *ngIf="newClaimForm.get('lostDate').errors?.MismatchDate && isSubmitted">Your date
                                    of loss is outside the range of the selected catastrophe</p>
                            </div>
                            <div class="col-lg-6 align-self-end">
                                <timepicker formControlName="lostTime" [minuteStep]="30"  [ngStyle]="styling?.fieldValueInput"></timepicker>
                                <p class="error-class"
                                    *ngIf="newClaimForm.get('lostTime').errors?.required && isSubmitted">Time of Loss is
                                    required</p>

                            </div>
                        </div>
                        <div class="form-group">
                            <div class="row">
                                <div class="col-lg-12 custom-title-info account-title" [ngStyle]="styling?.sectionHeader"
                                style="margin-bottom: 5px;">Damage Details</div>
                                <div class="col-lg-6 spacing-form align-self-end">
                                    <div class="custom-title-info account-title" [ngStyle]="styling?.fieldPlaceHolder">
                                        Was the damage caused by a named storm (e.g. hurricane) or major weather event? *</div>
                                    <ng-select id="catastrophiesList_1" [items]="catastrophiesList" appendTo="body" [hideSelected]="true"
                                        bindLabel="name" placeholder="Catastrophe" (change)="selectedCatastrophy()"
                                        formControlName="catastrophy" [ngStyle]="styling?.fieldDropDown" [clearable]="false" [searchable]="false">
                                    </ng-select>
                                    <p class="error-class"
                                        *ngIf="!newClaimForm.get('catastrophy').valid && isSubmitted">Catastrophe is
                                        required</p>
                                </div>
                                <div class="col-lg-6 spacing-form align-self-end">
                                    <div class="custom-title-info account-title" [ngStyle]="styling?.fieldPlaceHolder">
                                        What is the primary cause of damage for this claim? *</div>
                                    <ng-select id="causeOfLossList_1" [items]="causeOfLossList" appendTo="body" [hideSelected]="true"
                                        bindLabel="displayName" formControlName="causeOfLoss" [searchable]="!isSmallWindow"
                                        (change)="causeOfLossChange($event)" [ngStyle]="styling?.fieldDropDown">
                                    </ng-select>
                                    <p class="error-class"
                                        *ngIf="!newClaimForm.get('causeOfLoss').valid && isSubmitted">Cause of Loss is
                                        required</p>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-lg-6 spacing-form" *ngIf="additional_features.rooms_damaged && isFirstPartyLossType">
                                    <div class="custom-title-info account-title" [ngStyle]="styling?.fieldPlaceHolder">
                                        How many rooms or structures were damaged? *</div>
                                    <ng-select id="roomsDamageList" [items]="roomsDamageList" appendTo="body" [hideSelected]="true"
                                        formControlName="numberOfDamagedRooms" [ngStyle]="styling?.fieldDropDown" [searchable]="!isSmallWindow">
                                    </ng-select>
                                    <p class="error-class"
                                        *ngIf="!newClaimForm.get('numberOfDamagedRooms').valid && isSubmitted">
                                        This field is required</p>
                                </div>
                                <div class="col-lg-6 spacing-form" *ngIf="additional_features.roof_damage && isFirstPartyLossType">
                                    <div class="custom-title-info account-title" [ngStyle]="styling?.fieldPlaceHolder">Does the
                                        claim involve any damage to the roof? *</div>
                                    <div class="d-flex p-t-10">
                                        <div style="width: 100%;">
                                            <input type="radio" value="false" id="notRoofDamage"
                                                formControlName="roofDamage" class="radio-col-light-blue" />
                                            <label [ngStyle]="styling?.fieldValueCheckBox" for="notRoofDamage">No</label>
                                        </div>
                                        <div style="width: 100%;">
                                            <input type="radio" value="true" id="roofDamaged" formControlName="roofDamage"
                                                class="radio-col-light-blue" />
                                            <label [ngStyle]="styling?.fieldValueCheckBox" for="roofDamaged">Yes</label>
                                        </div>
                                        <div style="width: 100%;">
                                            <input type="radio" id="notSureRoofDamage" value="not sure"
                                                formControlName="roofDamage" class="radio-col-light-blue" />
                                            <label [ngStyle]="styling?.fieldValueCheckBox" for="notSureRoofDamage">I'm not sure</label>
                                        </div>
                                    </div>
                                    <p class="error-class" *ngIf="!newClaimForm.get('roofDamage').valid && isSubmitted">
                                        This field is required</p>
    
                                </div>
                            </div>
                            <div class="row m-b-20">
                                <div class="col-lg-6 align-self-end spacing-form" *ngIf="additional_features.moved_out && isFirstPartyLossType">
                                    <div class="custom-title-info account-title" [ngStyle]="styling?.fieldPlaceHolder">As a
                                        direct result of <i>severe damage</i> to the insured property, are residents currently
                                        living elsewhere? *</div>
                                    <div class="d-flex p-t-10">
                                        <div style="width: 100%;">
                                            <input type="radio" value="false" id="notMovedOut" formControlName="movedOut"
                                                class="radio-col-light-blue" />
                                            <label [ngStyle]="styling?.fieldValueCheckBox" for="notMovedOut">No</label>
                                        </div>
                                        <div style="width: 100%;">
                                            <input type="radio" value="true" id="movedOut" formControlName="movedOut"
                                                class="radio-col-light-blue" />
                                            <label [ngStyle]="styling?.fieldValueCheckBox" for="movedOut">Yes</label>
                                        </div>
                                    </div>
                                    <p class="error-class" *ngIf="!newClaimForm.get('movedOut').valid && isSubmitted">
                                        This field is required</p>
                                </div>
                                <div class="col-lg-6 align-self-end spacing-form" *ngIf="additional_features.water_enter && this.isFloodRelated">
                                    <div class="custom-title-info account-title" [ngStyle]="styling?.fieldPlaceHolder">Did water
                                        enter the home due to outside flooding or sewage backup? *
                                        <ng-container>
                                            <a class="fas fa-question-circle" [ngStyle]="styling?.iconStyling"
                                                [popoverTitle]="floodToolTip?.header" placement="auto"
                                                containerClass="custom-tooltip" [adaptivePosition]="true"
                                                triggers="mouseenter:mouseleave" [popover]="popover_content"
                                                container="body"></a>
                                        </ng-container>
                                        <ng-template #popover_content>
                                            <div [innerHTML]="floodToolTip?.content  | safeHtml">
                                            </div>
                                        </ng-template>
                                    </div>
                                    <div class="d-flex p-t-10">
                                        <div style="width: 100%;">
                                            <input type="radio" [value]="false" id="notFloodRelated"
                                                formControlName="waterEntryDueToFlood" class="radio-col-light-blue" />
                                            <label [ngStyle]="styling?.fieldValueCheckBox" for="notFloodRelated">No</label>
                                        </div>
                                        <div style="width: 100%;">
                                            <input type="radio" [value]="true" id="floodRelated"
                                                formControlName="waterEntryDueToFlood" class="radio-col-light-blue" />
                                            <label [ngStyle]="styling?.fieldValueCheckBox" for="floodRelated">Yes</label>
                                        </div>
                                    </div>
                                    <p class="error-class"
                                        *ngIf="!newClaimForm.get('waterEntryDueToFlood').valid && isSubmitted">
                                        This field is required</p>
                                </div>
                            </div>
                        </div>



                        <div class="form-group" *ngIf="this.newClaimForm?.value?.causeOfLoss?.lossPartyType != 'Third Party'">
                            <div class="custom-title-info account-title p-b-10" [ngStyle]="styling?.sectionHeader"
                                style="margin-bottom: 5px;">Mitigation Service Request</div>
                            <div class="p-b-10" style="margin-bottom: 10px;" [ngStyle]="styling?.paragraphText">
                                Please indicate if your home needs one or more of the following
                                emergency services. We will connect you with someone who can help reduce additional
                                damage. Applicable cost, if any, is determined after claim coverage and deductible have
                                been reviewed.
                            </div>
                            <div class="row modal-content-adjust"
                                [formGroup]="newClaimForm.controls['emergencyServices']">
                                <div class="col-lg-6" style="margin-bottom: 10px;"
                                    *ngFor="let emergency of emergencyList">
                                    <div class="checkbox checkbox-info">
                                        <input type="checkbox" [id]="emergency" [formControlName]="emergency">
                                        <label [for]="emergency" style="padding-right: 4px;">
                                            <span [ngStyle]="styling?.fieldValueCheckBox">{{emergency}}</span>
                                        </label>
                                        <a *ngIf="emergency_services_tool_tips" class="fas fa-question-circle" [ngStyle]="styling?.iconStyling" placement="auto"
                                            [popoverTitle]="emergency_services_tool_tips[emergency]?.header"
                                            containerClass="custom-tooltip" [adaptivePosition]="true"
                                            triggers="mouseenter:mouseleave"
                                            [popover]="emergency_services_tool_tips[emergency]?.content"
                                            container="body"></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="form-group">
                            <div class="row">
                                <div class="col-lg-12 custom-title-info account-title" [ngStyle]="styling?.sectionHeader"
                                    style="margin-bottom: 5px;">Contact Details</div>
                                <div class="col-lg-6 spacing-form align-self-end">
                                    <div class="custom-title-info account-title" [ngStyle]="styling?.fieldPlaceHolder">Who is
                                        our main contact for this claim? *</div>
                                    <ng-select id="mainContactList_1" [items]="mainContactList" appendTo="body" [hideSelected]="true"
                                        bindLabel="displayName" formControlName="mainContact" [ngStyle]="styling?.fieldDropDown"
                                        (change)="updateRelationship($event)" [searchable]="!isSmallWindow">
                                    </ng-select>
                                    <p class="error-class" *ngIf="!newClaimForm.get('mainContact').valid && isSubmitted">
                                        Main contact for this claim is required</p>
                                </div>
                                <div class="col-lg-6 spacing-form align-self-end" *ngIf="isOtherContact">
                                    <mat-form-field style="width: 100%">
                                        <mat-label style="font-size: 12px;">What is their relationship to the primary
                                            policyholder? *</mat-label>
                                        <input matInput id="inputplaceholder" formControlName="relationshipToPrimaryholder"
                                            [ngStyle]="styling?.fieldValueInput" type="text" />
                                    </mat-form-field>
                                    <p class="error-class mat-angular-error-text" style="margin-top: -20px"
                                        *ngIf="!newClaimForm.get('relationshipToPrimaryholder').valid && isSubmitted">
                                        Relationship to the primary policyholder is required</p>
                                </div>
                            </div>
                            <ng-container *ngIf="hideInputFields; else showInputFields">
                                <div class="row form-group">
                                    <div class="col-lg-6 m-t-10">
                                        <div class="custom-title-info account-title" [ngStyle]="styling?.attributeKey">Whom to contact for this claim? *</div>
                                        <div class="custom-title-infoDetail account-description body-paragraph-s" [ngStyle]="styling?.attributeValue">{{selectedAddressObj?.primaryInsured?.fullName }}</div>
                                    </div>
                                    <div class="col-lg-2 spacing-form">
                                        <div class="custom-title-info account-title" [ngStyle]="styling?.attributeKey">Country Code</div>
                                        <div class="custom-title-infoDetail account-description body-paragraph-s" [ngStyle]="styling?.attributeValue">+&nbsp;1</div>
                                    </div>
                                    <div class="col-lg-4 spacing-form">
                                        <div class="custom-title-info account-title" [ngStyle]="styling?.attributeKey">Phone Number</div>
                                        <div class="custom-title-infoDetail account-description body-paragraph-s" [ngStyle]="styling?.attributeValue">{{ contactPhoneNo }}</div>
                                    </div>
                                </div>
                                <div class="row form-group">
                                    <div class="col-lg-6">
                                        <div class="custom-title-info account-title" [ngStyle]="styling?.attributeKey">Email</div>
                                        <div class="custom-title-infoDetail account-description body-paragraph-s" [ngStyle]="styling?.attributeValue">{{ selectedAddressObj?.primaryInsured?.email }}</div>
                                    </div>
                                </div>
                            </ng-container>
                            <ng-template #showInputFields>
                                <div class="row">
                                    <div class="col-lg-6 m-t-10">
                                        <mat-form-field style="width: 100%">
                                            <input matInput formControlName="contactClaim" type="text"
                                                [ngStyle]="styling?.fieldValueInput"
                                                placeholder="Whom to contact for this claim? *" />
                                        </mat-form-field>
                                        <p class="error-class mat-angular-error-text" style="margin-top: -20px"
                                            *ngIf="!newClaimForm.get('contactClaim').valid && isSubmitted">Contact Name is
                                            required</p>
                                    </div>
                                    <div class="col-lg-2 spacing-form">
                                        <mat-form-field style="width: 100%">
                                            <input matInput placeholder="Country Code *" formControlName="countryCode"
                                            [ngStyle]="styling?.fieldValueInput" maxlength="3" />
                                            <span matPrefix>+&nbsp;</span>
                                        </mat-form-field>
                                        <div style="margin-top: -20px">
                                            <p class="error-class mat-angular-error-text"
                                                *ngIf="newClaimForm.get('countryCode').errors?.required && isSubmitted">Country
                                                Code is required</p>
                                            <p class="error-class mat-angular-error-text"
                                                *ngIf="newClaimForm.get('countryCode').errors?.pattern && isSubmitted">Numbers
                                                only</p>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 spacing-form">
                                        <mat-form-field style="width: 100%">
                                            <input matInput phoneDisplay [preValue]="newClaimForm.value.ph_number"
                                             [ngStyle]="styling?.fieldValueInput" placeholder="Phone Number *" formControlName="ph_number" maxlength="14" />
                                        </mat-form-field>
                                        <div style="margin-top: -20px">
                                            <p class="error-class mat-angular-error-text"
                                                *ngIf="newClaimForm.get('ph_number').errors?.required && isSubmitted">Phone
                                                Number is required</p>
                                            <p class="error-class mat-angular-error-text"
                                                *ngIf="newClaimForm.get('ph_number').errors?.pattern && isSubmitted">Invalid
                                                Phone Number</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-lg-6 spacing-form">
                                        <mat-form-field style="width: 100%">
                                            <input matInput formControlName="email" (input)="keyEmail()" type="text"
                                                placeholder="Email *" />
                                        </mat-form-field>
                                        
                                        <div style="margin-top: -20px">
                                            <p class="error-class mat-angular-error-text" *ngIf="!newClaimForm.get('email').valid">{{emailError}}
                                        </p>
                                            <p class="error-class mat-angular-error-text" *ngIf="newClaimForm.get('email').errors?.required && isSubmitted">
                                                {{'REQUIRED_FIELD' | translate: {value:'Email'} }}</p>
                                        </div>
                                    </div>
                                </div>
                            </ng-template>
                        </div>

                        
                    </div>
                </form>
            </mat-step>
            <mat-step>
                <form class="form-material m-t-20">
                    <!-- <ng-template matStepLabel>
                        <div class="custom-title-info account-title" [ngStyle]="styling?.attributeKey"
                            [ngClass]="{'selected-tab': stepper.selectedIndex === 1}">Claim Confirmation</div>
                    </ng-template> -->
                    <div class="">
                        <!-- <div class="row">
                            <app-policies-card class="col-lg-12 p-b-30" [policiesDetails]="policyInfo" [notclickable] = "true"
                                (policyIndexChanges)="getPolicyIndex($event)"></app-policies-card>
                        </div> -->
                        <div class="form-group row" *ngIf="!isEditable; else claimUnsuccessful"
                            style="margin-bottom: 0px;">
                            <div class="col-lg-12" style="text-align: center; margin-bottom: 0px;">
                                <img src="assets/multiTenantImages/png/checkmark.svg" style="width: 10%;">
                            </div>
                            <h3 class="col-lg-12 text-center header-three-l" style="color: #26D161">
                                Your Claim has been successfully submitted.
                            </h3>
                            <div class="col-lg-12" style="text-align: center;">
                                <div class="col-lg-12" [ngStyle]="styling?.attributeKey" style="font-weight: 800;">
                                    <h3 class="header-three-s"> Your Claim Number: <span>{{resultSumittedClaim?.claimNumber}}</span></h3>
                                </div>
                                <div class="col-lg-12">
                                    <p class="body-paragraph-s sfi-body-italics text-center" style="font-style: italic;" [ngStyle]="styling?.paragraphText">A confirmation email is on the way!</p>
                                </div>
                            </div>
                            <div class="card" style="margin: 30px; padding: 10px; width: 100%;"
                                *ngIf="emergencySelection || severity >= 4">
                                <div class="card-body">
                                    <div class="col-lg-12" style="text-align: center;"[innerHTML]="claimConfirmation?.card_header  | safeHtml">
                                    </div>
                                    <div class="col-lg-12" [ngStyle]="styling?.content" style="text-align: center;"
                                        *ngIf="emergencySelection">
                                        
                                        <div class="col-lg-12"
                                            [innerHTML]="claimConfirmation?.emergency_selection  | safeHtml">
                                        </div>
                                    </div>
                                    <div class="col-lg-12" [ngStyle]="styling?.content" style="text-align: center;"
                                        *ngIf="!emergencySelection && severity >= 4"
                                        [innerHTML]="claimConfirmation?.high_severity  | safeHtml">
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-12" [innerHTML]="claimConfirmation?.generic  | safeHtml"></div>



                        </div>
                        <ng-template #claimUnsuccessful>
                            <div class="form-group row">
                                <div class="col-lg-12" [innerHTML]="errorText  | safeHtml" [ngStyle]="styling?.content">
                                </div>
                            </div>
                        </ng-template>

                    </div>
                </form>
            </mat-step>
        </mat-horizontal-stepper>

        <div class="col-md-6 offset-md-3">
            <div class="row" style="margin: 0 0 40px 0; justify-content: center;">
                <ng-container *ngIf="stepper.selectedIndex === 0">
                    <button (click)="cancelClaim('home/my-claims/list')" class="btn btn-danger buttons col-lg-3" type="button"
                        [ngStyle]="styling?.button?.buttonNegative">Cancel</button>
                    <span class="m-r-20 m-b-20"></span>
                    <button (click)="goForward(stepper)" class="btn btn-info buttons custom-button-info col-lg-4" type="button"
                        [ngStyle]="styling?.button?.buttonPositive">Submit</button>
                </ng-container>

                <ng-container *ngIf="stepper.selectedIndex === 1">
                    <button *ngIf="isEditable" class="btn btn-danger buttons col-lg-4" type="button"
                        (click)="navLocation('home/my-claims/list')" [ngStyle]="styling?.button?.buttonNegative">Cancel
                        Claim</button>
                    <span class="m-r-20 m-b-20"></span>
                    <button *ngIf="isEditable && knownError" class="btn btn-danger buttons col-lg-5" type="button"
                        (click)="goBack(stepper)" [ngStyle]="styling?.button?.buttonPositive">Back to Claim
                        Information</button>
                    <span class="m-r-20 m-b-20"></span>
                    <button *ngIf="!isEditable" class="btn btn-danger buttons col-lg-4" type="button"
                        (click)="navLocation('home/my-claims/list')" [ngStyle]="styling?.button?.buttonPositive">View My
                        Claims</button>
                </ng-container>
            </div>
        </div>


    </div><span class="m-b-20 m-r-20 hidden-md-up"></span>
    <div class="col-lg-3 mobile-spacing">
        <app-large-agent-info [agencyObject]="agencyObject"></app-large-agent-info>
    </div>
</div>