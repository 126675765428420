export interface Theme {
    name: string;
    properties: any;
}

export const greenTheme: Theme = {
    name: 'Green',
    properties: {
        "--background-topbar": "#00897b", // menubar on top background styling
        "--color-hamburger": "#FFFFFF", // hamburger on topbar styling also used for sidebar text color
        "--background-sidebar": "#FFFFFF", // sidebar's backgroung
        "--background-footer": "#FFFFFF", // footer' background color
        "--background-page-title": "#FFFFFF", // not using currently...the heading above breadcrumbs
        "--background-page-wrapper": "#eef5f9", // background of a page
        "--background-sidebar-links": "#00897b", //when any option in side bar is hovered or is active
        "--background-card-content": "#FFFFFF", //background for a card
        "--color-radio": "#00897b" // radio buttons color
    }
}

export const yellowTheme: Theme = {
    name: 'Yellow',
    properties: {
        "--background-topbar": "#eeff1b",
        "--color-hamburger": "#000000",
        "--background-sidebar": "#FFFFFF",
        "--background-footer": "#FFFFFF",
        "--background-page-title": "#FFFFFF",
        "--background-page-wrapper": "#eef5f9",
        "--background-sidebar-links": "#eeff1b",
        "--background-card-content": "#FFFFFF",
        "--color-radio": "#000000"
    }
}

export const greyTheme: Theme = {
    name: 'Grey',
    properties: {
        "--background-topbar": "#808080",
        "--color-hamburger": "#FFFFFF",
        "--background-sidebar": "#FFFFFF",
        "--background-footer": "#FFFFFF",
        "--background-page-title": "#FFFFFF",
        "--background-page-wrapper": "#eef5f9",
        "--background-sidebar-links": "#808080",
        "--background-card-content": "#FFFFFF",
        "--color-radio": "#808080"
    }
}

export const darkBlueTheme: Theme = {
    name: 'DarkBlue',
    properties: {
        "--background-topbar": "#0071CE",
        "--color-hamburger": "#FFFFFF",
        "--background-sidebar": "#FFFFFF",
        "--background-footer": "#FFFFFF",
        "--background-page-title": "#FFFFFF",
        "--background-page-wrapper": "#eef5f9",
        "--background-sidebar-links": "#0071CE",
        "--background-card-content": "#FFFFFF",
        "--color-radio": "#0071CE"
    }
}

export const defaultTheme: Theme = {
    name: 'Default',
    properties: {
        "--background-topbar": "#393939",
        "--color-hamburger": "#FFFFFF",
        "--background-sidebar": "#FFFFFF",
        "--background-footer": "#FFFFFF",
        "--background-page-title": "#FFFFFF",
        "--background-page-wrapper": "#FFFFFF",
        "--background-sidebar-links": "#D7139D",
        "--background-card-content": "#FFFFFF",
        "--color-radio": "#D7139D"
    }
}

export const redTheme: Theme = {
    name: 'Red',
    properties: {
        "--background-topbar": "#FF0000",
        "--color-hamburger": "#FFFFFF",
        "--background-sidebar": "#FFFFFF",
        "--background-footer": "#FFFFFF",
        "--background-page-title": "#FFFFFF",
        "--background-page-wrapper": "#eef5f9",
        "--background-sidebar-links": "#FF0000",
        "--background-card-content": "#FFFFFF",
        "--color-radio": "#FF0000"
    }
}

export const darkTheme: Theme = {
    name: 'Dark',
    properties: {
        "--background-topbar": "#000000",
        "--color-hamburger": "#FFFFFF",
        "--background-sidebar": "#181c22",
        "--background-footer": "#383f48",
        "--background-page-title": "#191919",
        "--background-page-wrapper": "#383f48",
        "--background-sidebar-links": "#383f48",
        "--background-card-content": "#263238",
        "--color-radio": "#99abb4"
    }
}