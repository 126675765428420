<div class="row" [formGroup]="isHouseForm">
    <div class="col-lg-6 p-b-20">
        <div class="w-100 d-flex justify-content-center">
            <mat-card class="card-selection text-center radio-selector-wrapper"
            (click)="onClick('house')">
                <label class="p-2" for="house">
                    <p class="radio-selector-title">House</p>
    
                    <input type="radio" value="house" formControlName="checkHouse" id="house" class="radio-col-light-blue" />
                    <img class="radio-image-selector p-2" src="assets/multiTenantImages/png/quote_hometype_house.svg">
                </label>
            </mat-card>
        </div>
    </div>
    <div class="col-lg-6">
        <div class="w-100 d-flex justify-content-center">
            <mat-card class="card-selection text-center radio-selector-wrapper"
            (click)="onClick('condo')">
                <label class="p-2" for="condo">
                    <p class="radio-selector-title">Condo</p>
    
                    <input type="radio" value="condo" formControlName="checkHouse" id="condo" class="radio-col-light-blue" />
                <img class="radio-image-selector p-2" src="assets/multiTenantImages/png/quote_hometype_apartment.svg">
                </label>
            </mat-card>
        </div>
    </div>
</div>