<div class="card h-100">
    <div class="card-body body-mobile">
        <h2 [ngStyle]="styling?.pageTitle" class="text-center header-two-l">
            Policy Details
        </h2>
        <div class="row">
            <app-policies-card class="col-lg-12 p-b-30" [policiesDetails]="policyInfo"
                (policyIndexChanges)="getPolicyIndex($event)"></app-policies-card>
        </div>
        <div class="row">
            <app-policy-details-view class="col-lg-12" [selectedAddressObj]="selectedAddressObj"></app-policy-details-view>
        </div>
    </div>
</div>