<!-- <div class="row">
    <div class="col-md-12"> -->
<div class="card custom-card-border">
    <div class="card-body body-mobile">
        <h2 [ngStyle]="styling?.pageTitle" class="text-center header-two-l">
            Make a Payment
        </h2>

        <div class="row">
            <app-policies-card class="col-lg-12" [policiesDetails]="policyInfo" [notclickable]="notClickable"
                (policyIndexChanges)="getPolicyIndex($event)"></app-policies-card>
        </div>
        <mat-horizontal-stepper (selectionChange)="stepperSelected($event)" linear #stepper>
            <div style="color:#D7139D">{{stepper.selectedIndex}} </div>

            <mat-step [stepControl]="paymentForm">

                <form [formGroup]="paymentForm" class="form-material m-t-20" style="padding: 0px;">

                    <ng-template matStepLabel>
                        <div class="custom-title-info account-title" [ngStyle]="styling?.attributeKey"
                            [ngClass]="{'selected-tab': stepper.selectedIndex === 0}" *ngIf="isLargeWindow">{{'STEP1' |
                            translate}}
                        </div>
                        <div class="custom-title-info account-title" [ngStyle]="styling?.attributeKey"
                            [ngClass]="{'selected-tab': stepper.selectedIndex === 2}"
                            *ngIf="!isSmallWindow && !isLargeWindow" style="font-size: 10px;">{{'STEP1_SMALL' |
                            translate}}
                        </div>
                    </ng-template>
                    <!-- <div class="row">
                                <app-policies-card class="col-lg-12 p-b-30" [policiesDetails]="policyInfo"
                                    (policyIndexChanges)="getPolicyIndex($event)"></app-policies-card>
                            </div> -->
                    <div class="col-12" style="padding: 0px;">
                        <div class="card" style="padding: 0px;">
                            <div class="card-body">
                                <div class="form-group row">
                                    <div class="col-lg-12" *ngIf="billTo =='Mortgage Bill'">
                                        <label style="padding: 0px 0px 10px 0px;" [ngStyle]="styling?.AlertMessage">
                                            {{mortgageeAlertMessage1}}
                                        </label>
                                    </div>
                                    <div class="col-lg-12" *ngIf="billTo =='Mortgage Bill'">
                                        <label style="padding: 0px 0px 20px 0px;"
                                            [ngStyle]="styling?.AlertMessageBlack">
                                            {{mortgageeAlertMessage2}}
                                        </label>
                                    </div>
                                    <div class="col-lg-12" *ngIf="policyStatus =='Cancelled'">
                                        <label style="padding: 0px 0px 10px 0px;" [ngStyle]="styling?.AlertMessage">
                                            {{cancelledPolicyMessage}}
                                        </label>
                                    </div>
                                    <div class="col-lg-6">
                                        <div class="col-lg-12" style="padding: 0px 0px 10px 0px;"
                                            [ngStyle]="styling?.fieldPlaceHolder">How much would you like to pay?
                                        </div>

                                        <div class="col-lg-12" style="padding: 0px;"
                                            *ngIf="showPaymentPlanOption(NextAmountDueControl)">
                                            <input type="radio" value="amountDue" formControlName="selectedAmount"
                                                id="amountDue" class="radio-col-light-blue" />
                                            <label for="amountDue" [ngStyle]="styling?.fieldValueCheckBox"
                                                (click)="amountSelection('amountDue')">
                                                Next Amount Due: ${{utilsService.convertNumberToIS(nextPaymentDue)}}
                                            </label>
                                        </div>
                                        <div class="col-lg-12" style="padding: 0px;"
                                            *ngIf="showPaymentPlanOption(RemainingBalanceContol) ">

                                            <input type="radio" value="remainingBalance"
                                                formControlName="selectedAmount" id="remainingBalance"
                                                class="radio-col-light-blue" />
                                            <label for="remainingBalance" [ngStyle]="styling?.fieldValueCheckBox"
                                                (click)="amountSelection('remainingBalance')">
                                                Remaining Balance: ${{utilsService.convertNumberToIS(remainingBalance)}}
                                            </label>
                                        </div>
                                        <div class="col-lg-12" style="padding: 0px;"
                                            *ngIf="showPaymentPlanOption(CustomAmountControl) ">

                                            <input type="radio" value="customAmount" formControlName="selectedAmount"
                                                id="customAmount" class="radio-col-light-blue" />
                                            <label for="customAmount" [ngStyle]="styling?.fieldValueCheckBox"
                                                (click)="amountSelection('customAmount')"> Custom
                                                Amount <a class="fas fa-question-circle" [ngStyle]="styling?.toolTip"
                                                    placement="auto" container="body" popoverTitle="Custom Amount"
                                                    popover="Payment Amount must be greater than or equal to the next payment amount due"
                                                    [adaptivePosition]="false" triggers="mouseenter:mouseleave"></a>
                                            </label>
                                            <mat-form-field class="add-margin"
                                                *ngIf="this.paymentForm.get('selectedAmount').value == 'customAmount'"
                                                style="width: 20%">
                                                <input matInput formControlName="customAmount" type="tel"
                                                    [ngStyle]="styling?.fieldValueInput" mvndrMatCurrencyFormat />
                                            </mat-form-field>
                                        </div>

                                        <p class="error-class mat-angular-error-text"
                                            *ngIf="paymentForm.get('amount').errors?.required && isSubmitted">
                                            Amount is
                                            required</p>
                                        <p class="error-class mat-angular-error-text"
                                            *ngIf="paymentForm.get('customAmount').errors?.noMinimumAmount && isSubmitted">
                                            Payment Amount must be greater than or equal to the next payment
                                            amount due
                                        </p>

                                    </div>
                                    <div class="col-lg-6 custom-title-infoDetail account-description"
                                        [ngStyle]="styling?.fieldPlaceHolder" style="width: 100%">
                                        Payment Method *
                                        <ng-select id="payment_method" [items]="paymentMethodsList"
                                            [ngStyle]="styling?.fieldDropDown"
                                            class="custom-title-infoDetail account-description"
                                            (change)="determinePaymentMethod($event)" appendTo="body"
                                            [hideSelected]="true" formControlName="payment_method" [searchable]="false"
                                            [clearable]="false">
                                        </ng-select>
                                        <p class="error-class"
                                            *ngIf="!paymentForm.get('payment_method').valid && isSubmitted">
                                            Payment method is required</p>
                                    </div>
                                </div>
                                <div class="form-group row"
                                    *ngIf="paymentForm.get('payment_method').value === 'Credit Card' ">
                                    <div class="col-lg-6 custom-title-infoDetail account-description"
                                        [ngStyle]="styling?.fieldPlaceHolder">
                                        <mat-form-field style="width: 100%" [ngStyle]="styling?.fieldValueInput">
                                            <input matInput class="custom-title-infoDetail account-description"
                                                [ngStyle]="styling?.fieldValueInput" formControlName="name" type="text"
                                                placeholder="Name on Card *" />
                                        </mat-form-field>
                                        <p class="error-class mat-angular-error-text"
                                            *ngIf="!paymentForm.get('name').valid && isSubmitted">Card Holder
                                            Name is required</p>
                                    </div>
                                    <div class="col-lg-6 custom-title-infoDetail account-description"
                                        [ngStyle]="styling?.fieldPlaceHolder">
                                        <mat-form-field style="width: 100%">
                                            <input matInput (input)="cardNumberInput($event.target.value)" cardNumber
                                                maxlength="24" [ngStyle]="styling?.fieldValueInput"
                                                class="custom-title-infoDetail account-description"
                                                (blur)="checkErrors($event.target.value)" formControlName="card_number"
                                                type="tel" placeholder="Card Number *" />
                                            <span matSuffix *ngIf="cardType">
                                                <img src="assets/multiTenantImages/cardsImg/{{cardType}}.png"
                                                    class="img_size">
                                            </span>
                                        </mat-form-field>
                                        <p class="error-class mat-angular-error-text"
                                            *ngIf="paymentForm.get('card_number').errors?.required && isSubmitted">
                                            Card Number is required
                                        </p>
                                        <p class="error-class mat-angular-error-text"
                                            *ngIf="paymentForm.get('card_number').errors?.luhnError && isSubmitted">
                                            Card Number is invalid
                                        </p>
                                        <p class="error-class mat-angular-error-text"
                                            *ngIf="paymentForm.get('card_number').errors?.cardType && isSubmitted">
                                            We do not accept {{this.cardType}} as a payment method at this time.
                                        </p>
                                        <p class="error-class mat-angular-error-text"
                                            *ngIf="paymentForm.get('card_number').errors?.cardTypeNull && isSubmitted">
                                            We do not accept this card type as a payment method at this time.
                                        </p>
                                        <p class="error-class mat-angular-error-text"
                                            *ngIf="(paymentForm.get('card_number').errors?.minlength || paymentForm.get('card_number').errors?.maxlength)  && isSubmitted">
                                            Invalid Card Number
                                        </p>
                                    </div>
                                </div>
                                <div class="form-group row"
                                    *ngIf="paymentForm.get('payment_method').value === 'Credit Card'">
                                    <div class="custom-title-infoDetail account-description col-lg-6"
                                        [ngStyle]="styling?.fieldPlaceHolder">
                                        Expiration Date *

                                        <mat-form-field style="width: 15%;padding-left: 3%;">
                                            <input matInput numbersOnly [ngStyle]="styling?.fieldValueInput"
                                                class="custom-title-infoDetail account-description" maxlength="2"
                                                (input)="autoTab($event.target.value)"
                                                formControlName="expiration_month" type="text" placeholder="MM" />
                                        </mat-form-field>
                                        /
                                        <mat-form-field style="width: 15%;padding-left: 1%;">
                                            <input matInput numbersOnly id="yearInput" (input)="checkPastYear()"
                                                [ngStyle]="styling?.fieldValueInput"
                                                class="custom-title-infoDetail account-description" maxlength="2"
                                                formControlName="expiration_year" type="text" placeholder="YY" />
                                        </mat-form-field>
                                        <p class="error-class mat-angular-error-text"
                                            *ngIf="(paymentForm.get('expiration_year').errors?.required || paymentForm.get('expiration_month').errors?.required ) && isSubmitted">
                                            Expiration date is required
                                        </p>
                                        <p class="error-class mat-angular-error-text"
                                            *ngIf="(paymentForm.get('expiration_year').errors?.pattern || paymentForm.get('expiration_month').errors?.pattern ) && isSubmitted">
                                            Invalid Expiration date
                                        </p>
                                        <p class="error-class mat-angular-error-text"
                                            *ngIf="(!paymentForm.get('expiration_month').errors?.required) && paymentForm.get('expiration_year').errors?.pastDate && isSubmitted">
                                            Your card has been expired
                                        </p>
                                    </div>
                                    <div class="col-lg-6 custom-title-infoDetail account-description"
                                        [ngStyle]="styling?.fieldPlaceHolder">
                                        <mat-form-field style="width: 100%">
                                            <input matInput numbersOnly [ngStyle]="styling?.fieldValueInput"
                                                class="custom-title-infoDetail account-description" maxlength="4"
                                                formControlName="cvv" type="password" placeholder="CVV *"
                                                autocomplete="new-password" />
                                        </mat-form-field>
                                        <p class="error-class mat-angular-error-text"
                                            *ngIf="paymentForm.get('cvv').errors?.required && isSubmitted">
                                            CVV is required
                                        </p>
                                        <p class="error-class mat-angular-error-text"
                                            *ngIf="paymentForm.get('cvv').errors?.pattern && isSubmitted">
                                            Invalid CVV
                                        </p>

                                    </div>
                                </div>
                                <!-- bank account start -->
                                <div class="form-group row"
                                    *ngIf="paymentForm.get('payment_method').value === 'Bank Account' ">
                                    <div class="col-lg-6 custom-title-infoDetail account-description"
                                        [ngStyle]="styling?.fieldPlaceHolder">
                                        <mat-form-field style="width: 100%">
                                            <input matInput class="custom-title-infoDetail account-description"
                                                [ngStyle]="styling?.fieldValueInput" formControlName="name" type="text"
                                                placeholder="Name on Account *" />
                                        </mat-form-field>
                                        <p class="error-class mat-angular-error-text"
                                            *ngIf="!paymentForm.get('name').valid && isSubmitted">Acount Holder
                                            Name is required</p>
                                    </div>
                                </div>
                                <div class="form-group row"
                                    *ngIf="paymentForm.get('payment_method').value === 'Bank Account'">
                                    <div class="col-lg-6 custom-title-infoDetail account-description"
                                        [ngStyle]="styling?.fieldPlaceHolder">
                                        <mat-form-field style="width: 100%">
                                            <input matInput numbersOnly [ngStyle]="styling?.fieldValueInput"
                                                class="custom-title-infoDetail account-description"
                                                formControlName="routing_number" placeholder="Routing Number *" />
                                        </mat-form-field>
                                        <p class="error-class mat-angular-error-text"
                                            *ngIf="paymentForm.get('routing_number').errors?.required && isSubmitted">
                                            Routing Number is required
                                        </p>
                                        <p class="error-class mat-angular-error-text"
                                            *ngIf="paymentForm.get('routing_number').errors?.pattern && isSubmitted">
                                            Invalid Routing Number
                                        </p>
                                    </div>

                                    <div class="col-lg-6 custom-title-infoDetail account-description"
                                        [ngStyle]="styling?.fieldPlaceHolder">
                                        <mat-form-field style="width: 100%">
                                            <input matInput numbersOnly [ngStyle]="styling?.fieldValueInput"
                                                class="custom-title-infoDetail account-description" minlength="3"
                                                formControlName="account_number" placeholder="Account Number *" />
                                        </mat-form-field>
                                        <p class="error-class mat-angular-error-text"
                                            *ngIf="paymentForm.get('account_number').errors?.required && isSubmitted">
                                            Account Number is required
                                        </p>
                                        <p class="error-class mat-angular-error-text"
                                            *ngIf="paymentForm.get('account_number').errors?.pattern && isSubmitted">
                                            Invalid Account Number
                                        </p>

                                    </div>
                                </div>

                                <!-- bank account end -->
                                <div class="form-group row">
                                    <div class="col-lg-6 custom-title-infoDetail account-description"
                                        [ngStyle]="styling?.fieldPlaceHolder">
                                        <mat-form-field style="width: 100%">
                                            <input matInput class="custom-title-infoDetail account-description"
                                                [ngStyle]="styling?.fieldValueInput" formControlName="billing_name"
                                                type="text" placeholder="Billing Name *" />
                                        </mat-form-field>
                                        <p class="error-class mat-angular-error-text"
                                            *ngIf="!paymentForm.get('billing_name').valid && isSubmitted">
                                            Billing name is required</p>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <div class="col-lg-6 custom-title-infoDetail account-description"
                                        [ngStyle]="styling?.fieldPlaceHolder">
                                        <mat-form-field style="width: 100%">
                                            <input matInput class="custom-title-infoDetail account-description"
                                                [ngStyle]="styling?.fieldValueInput" formControlName="billing_address"
                                                type="text" placeholder="Billing Address *" />
                                        </mat-form-field>
                                        <p class="error-class mat-angular-error-text"
                                            *ngIf="!paymentForm.get('billing_address').valid && isSubmitted">
                                            Billing address is required</p>
                                    </div>
                                    <div class="col-lg-6 custom-title-infoDetail account-description"
                                        [ngStyle]="styling?.fieldPlaceHolder">
                                        <mat-form-field style="width: 100%">
                                            <input matInput class="custom-title-infoDetail account-description"
                                                [ngStyle]="styling?.fieldValueInput" formControlName="billing_address2"
                                                type="text" placeholder="Billing Address2" />
                                        </mat-form-field>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <div class="col-lg-6 custom-title-infoDetail account-description"
                                        [ngStyle]="styling?.fieldPlaceHolder">
                                        <mat-form-field style="width: 100%">
                                            <input matInput class="custom-title-infoDetail account-description"
                                                [ngStyle]="styling?.fieldValueInput" formControlName="city" type="text"
                                                placeholder="City *" />
                                        </mat-form-field>
                                        <p class="error-class mat-angular-error-text"
                                            *ngIf="!paymentForm.get('city').valid && isSubmitted">
                                            City is required</p>
                                    </div>
                                    <div class="col-lg-6 custom-title-infoDetail account-description"
                                        [ngStyle]="styling?.fieldPlaceHolder">
                                        <mat-form-field style="width: 100%">
                                            <input matInput class="custom-title-infoDetail account-description"
                                                [ngStyle]="styling?.fieldValueInput" formControlName="state" type="text"
                                                placeholder="State *" />
                                        </mat-form-field>
                                        <p class="error-class mat-angular-error-text"
                                            *ngIf="!paymentForm.get('state').valid && isSubmitted">
                                            State is required</p>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <div class="col-lg-6 custom-title-infoDetail account-description"
                                        [ngStyle]="styling?.fieldPlaceHolder">
                                        <mat-form-field style="width: 100%">
                                            <input matInput class="custom-title-infoDetail account-description"
                                                [ngStyle]="styling?.fieldValueInput" formControlName="zip" type="text"
                                                placeholder="Zip Code *" />
                                        </mat-form-field>
                                        <p class="error-class mat-angular-error-text"
                                            *ngIf="!paymentForm.get('zip').valid && isSubmitted">
                                            Zip Code is required</p>
                                    </div>

                                </div>


                            </div>
                        </div>
                    </div>
                </form>
            </mat-step>


            <mat-step>
                <ng-template matStepLabel>
                    <div class="custom-title-info account-title" [ngStyle]="styling?.attributeKey"
                        [ngClass]="{'selected-tab': stepper.selectedIndex === 1}" *ngIf="isLargeWindow">{{'STEP2' |
                        translate}}
                    </div>
                    <div class="custom-title-info account-title" [ngStyle]="styling?.attributeKey"
                        [ngClass]="{'selected-tab': stepper.selectedIndex === 1}"
                        *ngIf="!isSmallWindow && !isLargeWindow" style="font-size: 9px;">{{'STEP2_SMALL' | translate}}
                    </div>
                </ng-template>

                <!-- <div class="row">
                            <app-policies-card class="col-lg-12 p-b-30" [policiesDetails]="policyInfo" [notclickable] = "true"
                                (policyIndexChanges)="getPolicyIndex($event)"></app-policies-card>
                        </div> -->
                <div class="custom-title text-center" style="padding-top: 40px;" [ngStyle]="styling?.paragraphText"
                    *ngIf="paymentForm.get('payment_method').value === 'Credit Card' ">
                    You are about to make a payment of <b
                        style="font-weight: 900;">${{utilsService.convertNumberToIS(amount)}}</b>
                    using your credit card ending in
                    <b style="font-weight: 900;">{{lastfour}}</b>.
                </div>
                <!-- class="form-control-static"  -->
                <div class="custom-title text-center" style="padding-top: 40px;" [ngStyle]="styling?.paragraphText"
                    *ngIf="paymentForm.get('payment_method').value === 'Bank Account' ">
                    You are about to make a payment for: <b
                        style="font-weight: 900;">${{utilsService.convertNumberToIS(amount)}}</b>
                    using your bank account ending in
                    <b style="font-weight: 900;">{{lastfour}}</b>
                </div>
                <div [innerHTML]="paymentAuthorization  | safeHtml" style="text-align: center; margin-top: 16px;">
                </div>

            </mat-step>
            <mat-step>
                <ng-template matStepLabel>
                    <div class="custom-title-info account-title" [ngStyle]="styling?.attributeKey"
                        [ngClass]="{'selected-tab': stepper.selectedIndex === 2}" *ngIf="isLargeWindow">{{'STEP3' |
                        translate}}
                    </div>
                    <div class="custom-title-info account-title" [ngStyle]="styling?.attributeKey"
                        [ngClass]="{'selected-tab': stepper.selectedIndex === 2}"
                        *ngIf="!isSmallWindow && !isLargeWindow" style="font-size: 10px;">{{'STEP3_SMALL' | translate}}
                    </div>
                </ng-template>
                <!-- <div class="row">
                            <app-policies-card class="col-lg-12 p-b-30" [policiesDetails]="policyInfo" [notclickable] = "true"
                                (policyIndexChanges)="getPolicyIndex($event)"></app-policies-card>
                        </div> -->
                <div class="row form-group" *ngIf="(status == 'approved' || status == 'accepted')">
                    <div class="col-lg-12 p-b-5" style="padding-left: 20px; padding-top: 20px;">
                        <p class="custom-title-info account-title" [ngStyle]="styling?.attributeKey">{{'POLICY_NUMBER' |
                            translate}} :
                            <span class="custom-title-infoDetail account-description"
                                [ngStyle]="styling?.attributeValue">{{policyNumber}}</span>
                        </p>
                    </div>
                    <div class="col-lg-12 p-b-5" style="padding-left: 20px;">
                        <p class="custom-title-info account-title" [ngStyle]="styling?.attributeKey">{{'PAYMENT_AMOUNT'
                            | translate}} :
                            <span class="custom-title-infoDetail account-description"
                                [ngStyle]="styling?.attributeValue">${{utilsService.convertNumberToIS(amount)}}</span>
                        </p>
                    </div>
                    <div class="col-lg-12 p-b-5" style="padding-left: 20px;">
                        <p class="custom-title-info account-title" [ngStyle]="styling?.attributeKey">{{'PAYMENT_DATE' |
                            translate}} :
                            <span class="custom-title-infoDetail account-description"
                                [ngStyle]="styling?.attributeValue">{{myDate}}</span>
                        </p>
                    </div>
                    <div class="col-lg-12 p-b-5" style="padding-left: 20px;">
                        <p class="custom-title-info account-title" [ngStyle]="styling?.attributeKey">{{'PAYMENT_METHOD'
                            | translate}} :
                            <span class="custom-title-infoDetail account-description"
                                [ngStyle]="styling?.attributeValue">{{this.paymentForm.value.payment_method}}</span>
                        </p>
                    </div>

                    <div class="col-lg-12 p-b-5" style="padding-left: 20px;">
                        <p class="custom-title-info account-title">{{'PAYMENT_ACCOUNT' | translate}} :
                            <span class="custom-title-infoDetail account-description">{{paymentAccount}}</span>
                        </p>
                    </div>

                    <div class="col-lg-12 p-b-5" style="padding-left: 20px;">
                        <p class="custom-title-info account-title" [ngStyle]="styling?.attributeKey">{{'STATUS' |
                            translate}} :
                            <span class="custom-title-infoDetail account-description"
                                [ngStyle]="styling?.attributeValue">{{status}}</span>
                        </p>
                    </div>
                    <div class="col-lg-12 p-b-5" style="padding-left: 20px;">
                        <p class="custom-title-info account-title" [ngStyle]="styling?.attributeKey"> {{'TRANSACTION_ID'
                            | translate}} :
                            <span class="custom-title-infoDetail account-description"
                                [ngStyle]="styling?.attributeValue">{{transactionId}}</span>
                        </p>
                    </div>
                    <div class="col-lg-12 p-b-5" style="padding-left: 20px;">
                        <p class="custom-title-info account-title" [ngStyle]="styling?.attributeKey">{{'MESSAGE' |
                            translate}} :
                            <span class="custom-title-infoDetail account-description"
                                [ngStyle]="styling?.attributeValue">{{message[status]}}</span>
                        </p>
                    </div>
                </div>
                <div class="form-control-static custom-title-infoDetail account-description"
                    [ngStyle]="styling?.paragraphText" style="text-align: center; padding-top: 40px;"
                    *ngIf="status && status != 'approved' && status != 'accepted' ">
                    <div [ngStyle]="styling?.AlertMessage">Your payment has not been processed.</div>
                    <div [innerHTML]="type | safeHtml" [ngStyle]="styling?.content"></div>
                </div>
            </mat-step>

        </mat-horizontal-stepper>
        <div class="no-print" style="text-align: center;
                    padding: 5%; padding-top: 0px;">
            <button class="large-btn-mobile btn btn-info custom-button-info buttons"
                style="margin-right: 10px;padding-left: 17px;padding-right: 17px;" type="button"
                (click)="navigateToLink('home/my-billing-view')" *ngIf="stepper.selectedIndex === 0"
                [ngClass]="{'disableCursor': (policyStatus=='Cancelled')}" [disabled]="policyStatus=='Cancelled'"
                [ngStyle]="styling?.button?.buttonNegative">{{'CANCEL' | translate }}</button>

            <button (click)="goForward(stepper)" class="large-btn-mobile btn btn-info custom-button-info buttons"
                type="button" *ngIf="stepper.selectedIndex === 0"
                [ngClass]="{'disableCursor': (policyStatus=='Cancelled')}" [disabled]="policyStatus=='Cancelled'"
                [ngStyle]="styling?.button?.buttonPositive">{{('NEXT' | translate)}}</button>

            <button (click)="resetStepper(stepper)" class="large-btn-mobile btn btn-danger"
                style="margin-right: 10px; padding-left: 20px;padding-right: 20px;" type="button"
                [hidden]="stepper.selectedIndex === 0 || stepper.selectedIndex === 2"
                [ngStyle]="styling?.button?.buttonNegative">{{'BACK' | translate}}</button>

            <button (click)="navigateToLink('home/my-billing-view')" class="large-btn-mobile btn btn-danger"
                style="margin-right: 10px;" type="button"
                *ngIf="(stepper.selectedIndex === 2 && (status && status != 'accepted' && status != 'approved'))"
                [ngStyle]="styling?.button?.buttonInfo">{{('CANCEL_PAYMENT' | translate)}}</button>

            <button (click)="resetStepper(stepper)" class="large-btn-mobile btn btn-info custom-button-info buttons"
                style="margin-right: 10px;" type="button"
                *ngIf="stepper.selectedIndex === 2 && (status == 'baddata' || status == 'decline')"
                [ngStyle]="styling?.button?.buttonNavigation">{{('BACK_TO_PAYMENT' | translate)}}</button>


            <!-- <button (click)="navigateToLink('home/my-billing-view')" class="large-btn-mobile btn btn-info custom-button-info buttons"
                        style="margin-right: 10px; padding-left: 20px;padding-right: 20px;" type="button"
                        *ngIf="stepper.selectedIndex === 2 && (status == 'accepted' || status == 'approved') "
                        [ngStyle]="styling?.button?.buttonNavigation">{{'EMAIL_RECEIPT' | translate}}</button> -->

            <button (click)="printReceipt()" class="large-btn-mobile btn btn-info custom-button-info buttons"
                style="margin-right: 10px; padding-left: 20px;padding-right: 20px;" type="button"
                *ngIf="stepper.selectedIndex === 2 && (status == 'accepted' || status == 'approved')"
                [ngStyle]="styling?.button?.buttonNavigation">{{'PRINT_RECEIPT' | translate }}</button>

            <button class="large-btn-mobile btn btn-info custom-button-info buttons" type="button"
                (click)="navigateToLink('home/my-billing-view')"
                *ngIf="stepper.selectedIndex === 2 && (status == 'approved' || status == 'accepted' )"
                [ngStyle]="styling?.button?.buttonNegative">{{'BACK_TO_MY_BILLING' | translate }}</button>




            <button (click)="goForward(stepper)" class="large-btn-mobile btn btn-info custom-button-info buttons"
                style="margin-right: 10px;" type="button" *ngIf="stepper.selectedIndex === 1"
                [ngStyle]="styling?.button?.buttonPositive">{{'CONFIRM' | translate}}</button>

        </div>




    </div>

</div>
<!-- </div>
</div> -->