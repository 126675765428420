import { Component, OnInit } from '@angular/core';
import { DasboardService } from '../dashboard/dasboard.service'
import { CommonApiService } from '../../shared/services/common-api.service';
import { UtilsService } from '../../shared/services/utils.service';
import {PoliciesCardComponent} from '../dashboard/policies-card/policies-card.component';
import { PrivateDataShare } from '../../shared/services/privateDataShare.service';

@Component({
  selector: 'app-view-policies',
  templateUrl: './view-policies.component.html',
  styleUrls: ['./view-policies.component.scss'],
  providers: [CommonApiService, DasboardService,PoliciesCardComponent]
})
export class ViewPoliciesComponent implements OnInit {
  policiesForm: any;
  policyIndex = sessionStorage.getItem('CurPolicySelection')
  policyInfo = this.utilsService.getItemfromSession('policyDetails').PolicySummary
  addressObj = [];
  selectedAddressObj = [];
  styling =this.utilsService.getItemfromSession('themeObject')
  constructor(
   private utilsService: UtilsService,
   private privateDataShare: PrivateDataShare
    ) { }

  ngOnInit(): void {
  }

  getPolicyDetails(policyNo){
    // this.addressObj = JSON.parse(sessionStorage.getItem('policyDetails')).PolicySummary;
    // this.selectedAddressObj = this.addressObj[this.policyIndex].policyDetails 
    let policyDetails = []
    policyDetails = this.privateDataShare.getPolicyDetails().PolicySummary
    if(policyDetails){
      let currentPolicySelected  = policyDetails.find(resp => resp.policyDetails.policyNumber == policyNo)
      if(currentPolicySelected.policyDetails) this.selectedAddressObj = currentPolicySelected.policyDetails
    }
  }

  getPolicyIndex(policyNo){
    console.log(policyNo, 'reached here')
    // this.policyIndex = index
    this.getPolicyDetails(policyNo)
    }

}
